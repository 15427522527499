/**
 * This file only contains the values.
 *
 * It is huge and difficult to edit.
 *
 */
export const Skattesatser2024 = {
    '1060': 34.602,
    '1080': 34.507,
    '1081': 34.517,
    '1082': 35.112,
    '1083': 34.592,
    '1214': 32.832,
    '1230': 31.142,
    '1231': 31.972,
    '1233': 30.502,
    '1256': 33.057,
    '1257': 31.107,
    '1260': 33.072,
    '1261': 30.502,
    '1262': 31.507,
    '1263': 32.157,
    '1264': 32.532,
    '1265': 33.007,
    '1266': 33.102,
    '1267': 33.847,
    '1270': 32.622,
    '1272': 34.517,
    '1273': 34.832,
    '1275': 32.867,
    '1276': 32.782,
    '1277': 32.462,
    '1278': 32.352,
    '1280': 33.162,
    '1281': 33.222,
    '1282': 32.197,
    '1283': 32.169,
    '1284': 31.697,
    '1285': 32.467,
    '1286': 32.092,
    '1287': 32.322,
    '1290': 33.352,
    '1291': 32.617,
    '1292': 32.267,
    '1293': 33.272,
    '1315': 34.977,
    '1380': 33.139,
    '1381': 33.677,
    '1382': 33.427,
    '1383': 32.602,
    '1384': 33.677,
    '1401': 32.827,
    '1402': 32.242,
    '1407': 33.872,
    '1415': 34.002,
    '1419': 34.102,
    '1421': 34.867,
    '1427': 34.412,
    '1430': 35.792,
    '1435': 34.037,
    '1438': 35.779,
    '1439': 35.275,
    '1440': 34.192,
    '1441': 32.962,
    '1442': 33.974,
    '1443': 33.947,
    '1444': 34.325,
    '1445': 34.135,
    '1446': 33.734,
    '1447': 34.885,
    '1452': 33.992,
    '1460': 35.439,
    '1461': 35.107,
    '1462': 34.797,
    '1463': 33.972,
    '1465': 34.542,
    '1466': 34.383,
    '1470': 34.88,
    '1471': 34.51,
    '1472': 33.98,
    '1473': 34.114,
    '1480': 33.302,
    '1481': 32.667,
    '1482': 33.757,
    '1484': 34.862,
    '1485': 34.522,
    '1486': 34.277,
    '1487': 34.579,
    '1488': 34.73,
    '1489': 33.674,
    '1490': 33.775,
    '1491': 33.482,
    '1492': 34.855,
    '1493': 33.695,
    '1494': 33.809,
    '1495': 34.289,
    '1496': 34.064,
    '1497': 34.26,
    '1498': 34.52,
    '1499': 34.355,
    '1715': 34.864,
    '1730': 34.889,
    '1737': 34.672,
    '1760': 35.325,
    '1761': 35.29,
    '1762': 34.529,
    '1763': 34.859,
    '1764': 35.105,
    '1765': 34.762,
    '1766': 34.219,
    '1780': 33.722,
    '1781': 34.514,
    '1782': 34.909,
    '1783': 34.579,
    '1784': 34.349,
    '1785': 34.12,
    '1814': 35.677,
    '1860': 35.447,
    '1861': 34.732,
    '1862': 36.04,
    '1863': 35.317,
    '1864': 34.722,
    '1880': 34.377,
    '1881': 34.627,
    '1882': 34.972,
    '1883': 35.04,
    '1884': 35.532,
    '1885': 35.547,
    '1904': 34.272,
    '1907': 34.057,
    '1960': 33.802,
    '1961': 33.527,
    '1962': 34.512,
    '1980': 31.997,
    '1981': 34.212,
    '1982': 33.872,
    '1983': 33.842,
    '1984': 34.222,
    '2021': 34.862,
    '2023': 35.082,
    '2026': 34.812,
    '2029': 34.407,
    '2031': 34.387,
    '2034': 34.917,
    '2039': 35.462,
    '2061': 34.981,
    '2062': 34.812,
    '2080': 34.482,
    '2081': 34.767,
    '2082': 34.887,
    '2083': 34.752,
    '2084': 34.462,
    '2085': 34.502,
    '2101': 35.152,
    '2104': 35.312,
    '2121': 34.252,
    '2132': 34.942,
    '2161': 34.702,
    '2180': 34.497,
    '2181': 33.962,
    '2182': 33.992,
    '2183': 34.157,
    '2184': 33.864,
    '2260': 35.682,
    '2262': 35.457,
    '2280': 35.642,
    '2281': 34.792,
    '2282': 35.507,
    '2283': 35.692,
    '2284': 35.002,
    '2303': 35.947,
    '2305': 36.117,
    '2309': 34.853,
    '2313': 35.947,
    '2321': 34.897,
    '2326': 35.247,
    '2361': 35.372,
    '2380': 34.582,
    '2401': 35.477,
    '2403': 35.282,
    '2404': 35.587,
    '2409': 35.362,
    '2417': 35.527,
    '2418': 35.652,
    '2421': 35.452,
    '2422': 35.902,
    '2425': 36.077,
    '2460': 35.482,
    '2462': 35.677,
    '2463': 35.877,
    '2480': 34.932,
    '2481': 35.327,
    '2482': 34.842,
    '2505': 35.042,
    '2506': 35.742,
    '2510': 35.217,
    '2513': 34.967,
    '2514': 34.657,
    '2518': 33.967,
    '2521': 35.645,
    '2523': 34.627,
    '2560': 34.572,
    '2580': 34.727,
    '2581': 34.327,
    '2582': 34.692,
    '2583': 34.632,
    '2584': 35.162,
    '0114': 32.497,
    '0115': 32.13,
    '0117': 29.627,
    '0120': 32.072,
    '0123': 32.242,
    '0125': 32.347,
    '0126': 32.527,
    '0127': 33.312,
    '0128': 32.746,
    '0136': 32.762,
    '0138': 32.567,
    '0139': 32.497,
    '0140': 33.162,
    '0160': 30.597,
    '0162': 31.272,
    '0163': 31.177,
    '0180': 30.775,
    '0181': 33.342,
    '0182': 30.977,
    '0183': 32.27,
    '0184': 30.372,
    '0186': 30.832,
    '0187': 32.382,
    '0188': 32.907,
    '0191': 33.122,
    '0192': 33.042,
    '0305': 34.137,
    '0319': 35.242,
    '0330': 33.412,
    '0331': 35.147,
    '0360': 33.987,
    '0380': 33.677,
    '0381': 33.887,
    '0382': 34.312,
    '0428': 34.297,
    '0461': 33.872,
    '0480': 33.322,
    '0481': 33.847,
    '0482': 34.112,
    '0483': 33.752,
    '0484': 33.617,
    '0486': 33.422,
    '0488': 32.992,
    '0509': 35.002,
    '0512': 35.142,
    '0513': 33.922,
    '0560': 34.222,
    '0561': 34.857,
    '0562': 34.552,
    '0563': 34.982,
    '0580': 32.657,
    '0581': 34.327,
    '0582': 34.582,
    '0583': 34.127,
    '0584': 35.267,
    '0586': 34.302,
    '0604': 34.837,
    '0617': 34.977,
    '0642': 34.945,
    '0643': 34.8,
    '0662': 34.617,
    '0665': 34.072,
    '0680': 34.442,
    '0682': 35.027,
    '0683': 34.027,
    '0684': 34.582,
    '0685': 34.727,
    '0686': 35.047,
    '0687': 34.58,
    '0760': 34.752,
    '0761': 34.752,
    '0763': 35.027,
    '0764': 34.407,
    '0765': 34.712,
    '0767': 34.307,
    '0780': 32.992,
    '0781': 33.947,
    '0821': 34.997,
    '0834': 34.692,
    '0840': 35.122,
    '0860': 34.717,
    '0861': 34.857,
    '0862': 34.697,
    '0880': 34.572,
    '0881': 35.052,
    '0882': 35.087,
    '0883': 34.047,
    '0884': 35.157,
    '0885': 34.487,
    '0980': 34.802
};
export const Tabeller2024 = {
    29: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            22
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            52
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            82
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            112
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            142
        ],
        2501: [
            195,
            0,
            183,
            12,
            195,
            172
        ],
        2601: [
            206,
            0,
            191,
            14,
            206,
            202
        ],
        2701: [
            216,
            0,
            200,
            16,
            232,
            232
        ],
        2801: [
            218,
            0,
            200,
            18,
            262,
            262
        ],
        2901: [
            229,
            0,
            208,
            21,
            292,
            292
        ],
        3001: [
            239,
            0,
            216,
            23,
            322,
            322
        ],
        3101: [
            250,
            0,
            225,
            25,
            352,
            352
        ],
        3201: [
            260,
            0,
            233,
            27,
            382,
            382
        ],
        3301: [
            271,
            0,
            241,
            29,
            412,
            412
        ],
        3401: [
            273,
            0,
            241,
            31,
            442,
            442
        ],
        3501: [
            284,
            0,
            250,
            34,
            472,
            472
        ],
        3601: [
            294,
            0,
            258,
            36,
            502,
            502
        ],
        3701: [
            305,
            0,
            266,
            38,
            532,
            532
        ],
        3801: [
            315,
            0,
            275,
            40,
            562,
            562
        ],
        3901: [
            325,
            0,
            283,
            42,
            592,
            592
        ],
        4001: [
            328,
            0,
            283,
            44,
            622,
            622
        ],
        4101: [
            338,
            0,
            291,
            46,
            652,
            652
        ],
        4201: [
            349,
            0,
            300,
            49,
            682,
            682
        ],
        4301: [
            359,
            0,
            308,
            61,
            712,
            712
        ],
        4401: [
            370,
            0,
            316,
            81,
            742,
            742
        ],
        4501: [
            380,
            0,
            325,
            102,
            772,
            772
        ],
        4601: [
            382,
            0,
            325,
            122,
            802,
            802
        ],
        4701: [
            392,
            0,
            333,
            143,
            827,
            827
        ],
        4801: [
            403,
            0,
            341,
            163,
            852,
            852
        ],
        4901: [
            413,
            0,
            350,
            183,
            877,
            877
        ],
        5001: [
            423,
            0,
            358,
            203,
            899,
            899
        ],
        5101: [
            433,
            0,
            366,
            223,
            924,
            924
        ],
        5201: [
            443,
            0,
            375,
            243,
            947,
            947
        ],
        5301: [
            445,
            19,
            376,
            263,
            972,
            972
        ],
        5401: [
            455,
            42,
            386,
            283,
            997,
            997
        ],
        5501: [
            465,
            64,
            396,
            303,
            1019,
            1019
        ],
        5601: [
            475,
            87,
            406,
            322,
            1043,
            1043
        ],
        5701: [
            485,
            109,
            416,
            342,
            1065,
            1065
        ],
        5801: [
            495,
            132,
            426,
            361,
            1090,
            1090
        ],
        5901: [
            497,
            154,
            427,
            381,
            1114,
            1114
        ],
        6001: [
            507,
            177,
            437,
            400,
            1136,
            1136
        ],
        6101: [
            517,
            199,
            447,
            420,
            1160,
            1160
        ],
        6201: [
            527,
            219,
            457,
            439,
            1182,
            1182
        ],
        6301: [
            537,
            242,
            467,
            459,
            1207,
            1207
        ],
        6401: [
            547,
            264,
            477,
            478,
            1231,
            1231
        ],
        6501: [
            549,
            287,
            479,
            498,
            1253,
            1253
        ],
        6601: [
            559,
            309,
            488,
            517,
            1277,
            1277
        ],
        6701: [
            569,
            332,
            498,
            537,
            1299,
            1299
        ],
        6801: [
            579,
            354,
            508,
            556,
            1324,
            1324
        ],
        6901: [
            589,
            377,
            518,
            576,
            1348,
            1348
        ],
        7001: [
            599,
            399,
            528,
            595,
            1370,
            1370
        ],
        7101: [
            601,
            422,
            530,
            615,
            1394,
            1394
        ],
        7201: [
            611,
            444,
            540,
            634,
            1416,
            1416
        ],
        7301: [
            621,
            467,
            550,
            654,
            1441,
            1441
        ],
        7401: [
            632,
            487,
            560,
            673,
            1465,
            1465
        ],
        7501: [
            650,
            509,
            570,
            693,
            1487,
            1487
        ],
        7601: [
            668,
            532,
            580,
            712,
            1511,
            1511
        ],
        7701: [
            686,
            554,
            589,
            732,
            1533,
            1533
        ],
        7801: [
            705,
            577,
            591,
            751,
            1558,
            1558
        ],
        7901: [
            723,
            599,
            601,
            771,
            1582,
            1582
        ],
        8001: [
            741,
            622,
            611,
            790,
            1604,
            1604
        ],
        8101: [
            759,
            644,
            621,
            810,
            1628,
            1628
        ],
        8201: [
            777,
            667,
            631,
            829,
            1650,
            1650
        ],
        8301: [
            796,
            689,
            641,
            849,
            1675,
            1675
        ],
        8401: [
            814,
            712,
            642,
            868,
            1699,
            1699
        ],
        8501: [
            832,
            734,
            652,
            887,
            1721,
            1721
        ],
        8601: [
            850,
            754,
            662,
            907,
            1745,
            1745
        ],
        8701: [
            868,
            777,
            672,
            926,
            1767,
            1767
        ],
        8801: [
            887,
            799,
            682,
            946,
            1792,
            1792
        ],
        8901: [
            905,
            822,
            692,
            966,
            1816,
            1816
        ],
        9001: [
            923,
            844,
            694,
            985,
            1838,
            1838
        ],
        9101: [
            941,
            867,
            704,
            1004,
            1862,
            1862
        ],
        9201: [
            959,
            889,
            714,
            1024,
            1884,
            1884
        ],
        9301: [
            977,
            904,
            723,
            1043,
            1909,
            1909
        ],
        9401: [
            996,
            924,
            733,
            1063,
            1933,
            1933
        ],
        9501: [
            1014,
            942,
            742,
            1082,
            1955,
            1955
        ],
        9601: [
            1032,
            959,
            744,
            1102,
            1979,
            1979
        ],
        9701: [
            1050,
            977,
            753,
            1121,
            2001,
            2001
        ],
        9801: [
            1068,
            997,
            763,
            1141,
            2026,
            2026
        ],
        9901: [
            1087,
            1014,
            773,
            1160,
            2050,
            2050
        ],
        10001: [
            1105,
            1031,
            782,
            1180,
            2072,
            2072
        ],
        10101: [
            1123,
            1048,
            792,
            1199,
            2096,
            2096
        ],
        10201: [
            1141,
            1068,
            802,
            1219,
            2118,
            2118
        ],
        10301: [
            1159,
            1085,
            803,
            1238,
            2143,
            2143
        ],
        10401: [
            1177,
            1102,
            812,
            1258,
            2167,
            2167
        ],
        10501: [
            1196,
            1119,
            822,
            1277,
            2189,
            2189
        ],
        10601: [
            1214,
            1138,
            832,
            1297,
            2213,
            2213
        ],
        10701: [
            1232,
            1155,
            841,
            1316,
            2235,
            2235
        ],
        10801: [
            1250,
            1173,
            851,
            1336,
            2260,
            2260
        ],
        10901: [
            1268,
            1190,
            852,
            1355,
            2284,
            2284
        ],
        11001: [
            1286,
            1209,
            862,
            1375,
            2306,
            2306
        ],
        11101: [
            1305,
            1226,
            872,
            1394,
            2330,
            2330
        ],
        11201: [
            1323,
            1243,
            881,
            1414,
            2352,
            2352
        ],
        11301: [
            1341,
            1263,
            891,
            1433,
            2377,
            2377
        ],
        11401: [
            1359,
            1280,
            901,
            1453,
            2401,
            2401
        ],
        11501: [
            1377,
            1297,
            902,
            1472,
            2423,
            2423
        ],
        11601: [
            1396,
            1314,
            911,
            1492,
            2447,
            2447
        ],
        11701: [
            1414,
            1333,
            921,
            1511,
            2469,
            2469
        ],
        11801: [
            1432,
            1350,
            931,
            1531,
            2494,
            2494
        ],
        11901: [
            1450,
            1368,
            940,
            1550,
            2518,
            2518
        ],
        12001: [
            1468,
            1385,
            950,
            1570,
            2540,
            2540
        ],
        12101: [
            1487,
            1404,
            951,
            1589,
            2564,
            2564
        ],
        12201: [
            1505,
            1421,
            961,
            1609,
            2586,
            2586
        ],
        12301: [
            1523,
            1438,
            971,
            1628,
            2611,
            2611
        ],
        12401: [
            1541,
            1455,
            980,
            1648,
            2635,
            2635
        ],
        12501: [
            1559,
            1475,
            990,
            1667,
            2657,
            2657
        ],
        12601: [
            1578,
            1492,
            1000,
            1687,
            2681,
            2681
        ],
        12701: [
            1596,
            1509,
            1009,
            1706,
            2703,
            2703
        ],
        12801: [
            1614,
            1526,
            1010,
            1726,
            2728,
            2728
        ],
        12901: [
            1632,
            1545,
            1020,
            1745,
            2750,
            2750
        ],
        13001: [
            1650,
            1563,
            1030,
            1765,
            2779,
            2779
        ],
        13101: [
            1669,
            1580,
            1039,
            1785,
            2808,
            2808
        ],
        13201: [
            1687,
            1599,
            1049,
            1804,
            2837,
            2837
        ],
        13301: [
            1706,
            1616,
            1059,
            1824,
            2867,
            2867
        ],
        13401: [
            1724,
            1633,
            1060,
            1844,
            2896,
            2896
        ],
        13501: [
            1743,
            1650,
            1070,
            1864,
            2925,
            2925
        ],
        13601: [
            1761,
            1670,
            1079,
            1883,
            2954,
            2954
        ],
        13701: [
            1780,
            1687,
            1089,
            1903,
            2984,
            2984
        ],
        13801: [
            1798,
            1704,
            1099,
            1922,
            3012,
            3012
        ],
        13901: [
            1815,
            1721,
            1108,
            1941,
            3041,
            3041
        ],
        14001: [
            1833,
            1740,
            1110,
            1960,
            3069,
            3069
        ],
        14101: [
            1850,
            1758,
            1119,
            1979,
            3097,
            3097
        ],
        14201: [
            1867,
            1775,
            1129,
            1997,
            3125,
            3125
        ],
        14301: [
            1885,
            1792,
            1138,
            2016,
            3154,
            3154
        ],
        14401: [
            1902,
            1811,
            1148,
            2035,
            3182,
            3182
        ],
        14501: [
            1920,
            1828,
            1158,
            2054,
            3210,
            3210
        ],
        14601: [
            1937,
            1845,
            1159,
            2072,
            3238,
            3238
        ],
        14701: [
            1955,
            1862,
            1169,
            2091,
            3267,
            3267
        ],
        14801: [
            1972,
            1882,
            1178,
            2110,
            3297,
            3297
        ],
        14901: [
            1990,
            1899,
            1188,
            2129,
            3328,
            3328
        ],
        15001: [
            2007,
            1916,
            1198,
            2148,
            3358,
            3358
        ],
        15101: [
            2025,
            1933,
            1207,
            2166,
            3389,
            3389
        ],
        15201: [
            2042,
            1953,
            1217,
            2185,
            3422,
            3422
        ],
        15301: [
            2060,
            1970,
            1218,
            2204,
            3453,
            3453
        ],
        15401: [
            2079,
            1989,
            1228,
            2225,
            3483,
            3483
        ],
        15501: [
            2102,
            2013,
            1238,
            2250,
            3514,
            3514
        ],
        15601: [
            2126,
            2038,
            1248,
            2275,
            3544,
            3544
        ],
        15701: [
            2150,
            2062,
            1258,
            2299,
            3577,
            3577
        ],
        15801: [
            2173,
            2087,
            1268,
            2324,
            3608,
            3608
        ],
        15901: [
            2197,
            2111,
            1270,
            2349,
            3638,
            3638
        ],
        16001: [
            2221,
            2135,
            1280,
            2373,
            3669,
            3669
        ],
        16101: [
            2245,
            2160,
            1291,
            2398,
            3700,
            3700
        ],
        16201: [
            2268,
            2184,
            1301,
            2423,
            3733,
            3733
        ],
        16301: [
            2292,
            2208,
            1311,
            2448,
            3763,
            3763
        ],
        16401: [
            2316,
            2233,
            1321,
            2472,
            3794,
            3794
        ],
        16501: [
            2339,
            2257,
            1323,
            2497,
            3824,
            3824
        ],
        16601: [
            2363,
            2282,
            1333,
            2522,
            3855,
            3855
        ],
        16701: [
            2387,
            2306,
            1343,
            2547,
            3888,
            3888
        ],
        16801: [
            2411,
            2330,
            1353,
            2571,
            3919,
            3919
        ],
        16901: [
            2434,
            2355,
            1363,
            2596,
            3949,
            3949
        ],
        17001: [
            2458,
            2377,
            1373,
            2621,
            3980,
            3980
        ],
        17101: [
            2482,
            2401,
            1375,
            2646,
            4011,
            4011
        ],
        17201: [
            2505,
            2425,
            1385,
            2670,
            4043,
            4043
        ],
        17301: [
            2529,
            2450,
            1395,
            2695,
            4074,
            4074
        ],
        17401: [
            2553,
            2474,
            1405,
            2720,
            4105,
            4105
        ],
        17501: [
            2577,
            2499,
            1416,
            2744,
            4135,
            4135
        ],
        17601: [
            2600,
            2523,
            1426,
            2769,
            4166,
            4166
        ],
        17701: [
            2624,
            2547,
            1436,
            2794,
            4199,
            4199
        ],
        17801: [
            2648,
            2572,
            1438,
            2819,
            4229,
            4229
        ],
        17901: [
            2671,
            2596,
            1448,
            2843,
            4260,
            4260
        ],
        18001: [
            2695,
            2620,
            1458,
            2868,
            4291,
            4291
        ],
        18101: [
            2719,
            2645,
            1468,
            2893,
            4321,
            4321
        ],
        18201: [
            2743,
            2669,
            1478,
            2917,
            4354,
            4354
        ],
        18301: [
            2766,
            2694,
            1488,
            2942,
            4385,
            4385
        ],
        18401: [
            2790,
            2718,
            1490,
            2967,
            4415,
            4415
        ],
        18501: [
            2814,
            2742,
            1500,
            2992,
            4446,
            4446
        ],
        18601: [
            2837,
            2767,
            1510,
            3016,
            4477,
            4477
        ],
        18701: [
            2861,
            2791,
            1520,
            3041,
            4510,
            4510
        ],
        18801: [
            2885,
            2815,
            1531,
            3066,
            4540,
            4540
        ],
        18901: [
            2909,
            2840,
            1541,
            3091,
            4571,
            4571
        ],
        19001: [
            2932,
            2864,
            1543,
            3115,
            4601,
            4601
        ],
        19101: [
            2956,
            2889,
            1553,
            3140,
            4632,
            4632
        ],
        19201: [
            2980,
            2913,
            1563,
            3165,
            4665,
            4665
        ],
        19301: [
            3004,
            2937,
            1573,
            3190,
            4696,
            4696
        ],
        19401: [
            3027,
            2962,
            1583,
            3214,
            4726,
            4726
        ],
        19501: [
            3051,
            2986,
            1593,
            3239,
            4757,
            4757
        ],
        19601: [
            3075,
            3010,
            1595,
            3264,
            4787,
            4787
        ],
        19701: [
            3098,
            3033,
            1604,
            3288,
            4820,
            4820
        ],
        19801: [
            3122,
            3057,
            1613,
            3313,
            4851,
            4851
        ],
        19901: [
            3146,
            3081,
            1622,
            3338,
            4881,
            4881
        ],
        20001: [
            3193,
            3128,
            1641,
            3387,
            4943,
            4943
        ],
        20201: [
            3241,
            3172,
            1651,
            3437,
            5006,
            5006
        ],
        20401: [
            3288,
            3219,
            1670,
            3486,
            5067,
            5067
        ],
        20601: [
            3336,
            3267,
            1688,
            3536,
            5131,
            5131
        ],
        20801: [
            3383,
            3314,
            1699,
            3585,
            5192,
            5192
        ],
        21001: [
            3430,
            3361,
            1717,
            3635,
            5253,
            5253
        ],
        21201: [
            3478,
            3408,
            1736,
            3684,
            5317,
            5317
        ],
        21401: [
            3525,
            3455,
            1746,
            3733,
            5378,
            5378
        ],
        21601: [
            3573,
            3502,
            1765,
            3783,
            5442,
            5442
        ],
        21801: [
            3620,
            3549,
            1783,
            3832,
            5503,
            5503
        ],
        22001: [
            3668,
            3596,
            1794,
            3882,
            5564,
            5564
        ],
        22201: [
            3715,
            3643,
            1812,
            3931,
            5628,
            5628
        ],
        22401: [
            3762,
            3690,
            1831,
            3981,
            5689,
            5689
        ],
        22601: [
            3810,
            3737,
            1849,
            4030,
            5753,
            5753
        ],
        22801: [
            3858,
            3784,
            1860,
            4081,
            5815,
            5815
        ],
        23001: [
            3907,
            3832,
            1878,
            4132,
            5878,
            5878
        ],
        23201: [
            3957,
            3879,
            1897,
            4183,
            5943,
            5943
        ],
        23401: [
            4006,
            3923,
            1907,
            4234,
            6006,
            6006
        ],
        23601: [
            4055,
            3970,
            1926,
            4285,
            6071,
            6071
        ],
        23801: [
            4104,
            4015,
            1944,
            4336,
            6134,
            6134
        ],
        24001: [
            4153,
            4065,
            1954,
            4387,
            6197,
            6197
        ],
        24201: [
            4202,
            4112,
            1973,
            4438,
            6262,
            6262
        ],
        24401: [
            4251,
            4161,
            1992,
            4489,
            6325,
            6325
        ],
        24601: [
            4300,
            4208,
            2002,
            4541,
            6390,
            6390
        ],
        24801: [
            4349,
            4255,
            2021,
            4592,
            6453,
            6453
        ],
        25001: [
            4398,
            4305,
            2039,
            4643,
            6516,
            6516
        ],
        25201: [
            4447,
            4352,
            2050,
            4694,
            6581,
            6581
        ],
        25401: [
            4496,
            4399,
            2068,
            4745,
            6644,
            6644
        ],
        25601: [
            4546,
            4448,
            2087,
            4796,
            6709,
            6709
        ],
        25801: [
            4595,
            4495,
            2097,
            4847,
            6772,
            6772
        ],
        26001: [
            4644,
            4542,
            2116,
            4898,
            6835,
            6835
        ],
        26201: [
            4693,
            4592,
            2134,
            4949,
            6900,
            6900
        ],
        26401: [
            4742,
            4639,
            2145,
            5001,
            6963,
            6963
        ],
        26601: [
            4791,
            4688,
            2163,
            5052,
            7028,
            7028
        ],
        26801: [
            4840,
            4736,
            2182,
            5103,
            7091,
            7091
        ],
        27001: [
            4889,
            4783,
            2192,
            5154,
            7154,
            7154
        ],
        27201: [
            4938,
            4832,
            2211,
            5205,
            7219,
            7219
        ],
        27401: [
            4987,
            4879,
            2230,
            5256,
            7282,
            7282
        ],
        27601: [
            5036,
            4926,
            2248,
            5307,
            7347,
            7347
        ],
        27801: [
            5085,
            4976,
            2259,
            5358,
            7410,
            7410
        ],
        28001: [
            5134,
            5023,
            2277,
            5409,
            7473,
            7473
        ],
        28201: [
            5184,
            5072,
            2296,
            5460,
            7538,
            7538
        ],
        28401: [
            5233,
            5119,
            2306,
            5512,
            7601,
            7601
        ],
        28601: [
            5282,
            5166,
            2325,
            5563,
            7666,
            7666
        ],
        28801: [
            5331,
            5216,
            2343,
            5614,
            7729,
            7729
        ],
        29001: [
            5380,
            5263,
            2354,
            5665,
            7792,
            7792
        ],
        29201: [
            5429,
            5310,
            2372,
            5716,
            7857,
            7857
        ],
        29401: [
            5478,
            5359,
            2391,
            5767,
            7920,
            7920
        ],
        29601: [
            5527,
            5406,
            2402,
            5818,
            7985,
            7985
        ],
        29801: [
            5576,
            5456,
            2451,
            5869,
            8048,
            8048
        ],
        30001: [
            5625,
            5503,
            2498,
            5920,
            8111,
            8111
        ],
        30201: [
            5674,
            5550,
            2545,
            5972,
            8176,
            8176
        ],
        30401: [
            5723,
            5600,
            2595,
            6023,
            8239,
            8239
        ],
        30601: [
            5773,
            5647,
            2642,
            6074,
            8304,
            8304
        ],
        30801: [
            5822,
            5694,
            2689,
            6125,
            8367,
            8367
        ],
        31001: [
            5871,
            5743,
            2738,
            6176,
            8430,
            8430
        ],
        31201: [
            5920,
            5791,
            2786,
            6227,
            8495,
            8495
        ],
        31401: [
            5969,
            5839,
            2834,
            6278,
            8558,
            8558
        ],
        31601: [
            6018,
            5890,
            2885,
            6329,
            8623,
            8623
        ],
        31801: [
            6067,
            5938,
            2933,
            6380,
            8686,
            8686
        ],
        32001: [
            6116,
            5989,
            2984,
            6431,
            8749,
            8749
        ],
        32201: [
            6165,
            6037,
            3032,
            6483,
            8814,
            8814
        ],
        32401: [
            6214,
            6085,
            3081,
            6534,
            8877,
            8877
        ],
        32601: [
            6263,
            6136,
            3131,
            6585,
            8942,
            8942
        ],
        32801: [
            6313,
            6184,
            3180,
            6636,
            9005,
            9005
        ],
        33001: [
            6362,
            6233,
            3228,
            6687,
            9068,
            9068
        ],
        33201: [
            6411,
            6284,
            3279,
            6738,
            9133,
            9133
        ],
        33401: [
            6460,
            6332,
            3327,
            6789,
            9196,
            9196
        ],
        33601: [
            6509,
            6383,
            3378,
            6840,
            9261,
            9261
        ],
        33801: [
            6558,
            6431,
            3426,
            6891,
            9324,
            9324
        ],
        34001: [
            6607,
            6479,
            3474,
            6942,
            9387,
            9387
        ],
        34201: [
            6656,
            6530,
            3525,
            6994,
            9452,
            9452
        ],
        34401: [
            6705,
            6578,
            3574,
            7045,
            9515,
            9515
        ],
        34601: [
            6754,
            6627,
            3622,
            7096,
            9580,
            9580
        ],
        34801: [
            6803,
            6677,
            3673,
            7147,
            9643,
            9643
        ],
        35001: [
            6852,
            6726,
            3721,
            7198,
            9706,
            9706
        ],
        35201: [
            6902,
            6774,
            3769,
            7249,
            9771,
            9771
        ],
        35401: [
            6951,
            6825,
            3820,
            7300,
            9834,
            9834
        ],
        35601: [
            7000,
            6873,
            3868,
            7351,
            9899,
            9899
        ],
        35801: [
            7049,
            6924,
            3919,
            7402,
            9962,
            9962
        ],
        36001: [
            7098,
            6972,
            3967,
            7454,
            10025,
            10025
        ],
        36201: [
            7147,
            7021,
            4016,
            7505,
            10090,
            10090
        ],
        36401: [
            7196,
            7071,
            4067,
            7556,
            10153,
            10153
        ],
        36601: [
            7245,
            7120,
            4115,
            7607,
            10218,
            10218
        ],
        36801: [
            7294,
            7168,
            4163,
            7658,
            10281,
            10281
        ],
        37001: [
            7343,
            7219,
            4214,
            7709,
            10344,
            10344
        ],
        37201: [
            7392,
            7267,
            4262,
            7760,
            10409,
            10409
        ],
        37401: [
            7441,
            7318,
            4313,
            7811,
            10472,
            10472
        ],
        37601: [
            7490,
            7364,
            4359,
            7862,
            10532,
            10532
        ],
        37801: [
            7539,
            7412,
            4407,
            7913,
            10590,
            10590
        ],
        38001: [
            7588,
            7460,
            4456,
            7964,
            10648,
            10648
        ],
        38201: [
            7637,
            7511,
            4506,
            8015,
            10706,
            10706
        ],
        38401: [
            7687,
            7560,
            4555,
            8066,
            10764,
            10764
        ],
        38601: [
            7745,
            7618,
            4613,
            8117,
            10822,
            10822
        ],
        38801: [
            7803,
            7676,
            4671,
            8167,
            10880,
            10880
        ],
        39001: [
            7861,
            7734,
            4729,
            8218,
            10938,
            10938
        ],
        39201: [
            7919,
            7792,
            4787,
            8269,
            10996,
            10996
        ],
        39401: [
            7977,
            7850,
            4845,
            8320,
            11054,
            11054
        ],
        39601: [
            8035,
            7908,
            4903,
            8371,
            11112,
            11112
        ],
        39801: [
            8093,
            7966,
            4961,
            8422,
            11170,
            11170
        ],
        40001: [
            8151,
            8024,
            5019,
            8473,
            11228,
            11228
        ],
        40201: [
            8209,
            8082,
            5077,
            8524,
            11286,
            11286
        ],
        40401: [
            8267,
            8140,
            5135,
            8574,
            11344,
            11344
        ],
        40601: [
            8325,
            8198,
            5193,
            8625,
            11402,
            11402
        ],
        40801: [
            8383,
            8256,
            5251,
            8676,
            11460,
            11460
        ],
        41001: [
            8441,
            8314,
            5309,
            8727,
            11518,
            11518
        ],
        41201: [
            8499,
            8372,
            5367,
            8778,
            11576,
            11576
        ],
        41401: [
            8557,
            8430,
            5425,
            8829,
            11634,
            11634
        ],
        41601: [
            8615,
            8488,
            5483,
            8880,
            11692,
            11692
        ],
        41801: [
            8673,
            8546,
            5541,
            8931,
            11750,
            11750
        ],
        42001: [
            8731,
            8604,
            5599,
            8981,
            11808,
            11808
        ],
        42201: [
            8789,
            8662,
            5657,
            9032,
            11866,
            11866
        ],
        42401: [
            8847,
            8720,
            5715,
            9083,
            11924,
            11924
        ],
        42601: [
            8905,
            8778,
            5773,
            9134,
            11982,
            11982
        ],
        42801: [
            8963,
            8836,
            5831,
            9185,
            12040,
            12040
        ],
        43001: [
            9021,
            8894,
            5889,
            9236,
            12098,
            12098
        ],
        43201: [
            9079,
            8952,
            5947,
            9287,
            12156,
            12156
        ],
        43401: [
            9137,
            9010,
            6005,
            9338,
            12214,
            12214
        ],
        43601: [
            9195,
            9068,
            6063,
            9388,
            12272,
            12272
        ],
        43801: [
            9253,
            9126,
            6121,
            9439,
            12330,
            12330
        ],
        44001: [
            9311,
            9184,
            6179,
            9490,
            12388,
            12388
        ],
        44201: [
            9369,
            9242,
            6237,
            9541,
            12446,
            12446
        ],
        44401: [
            9427,
            9300,
            6295,
            9592,
            12504,
            12504
        ],
        44601: [
            9485,
            9358,
            6353,
            9643,
            12562,
            12562
        ],
        44801: [
            9543,
            9416,
            6411,
            9694,
            12620,
            12620
        ],
        45001: [
            9601,
            9474,
            6469,
            9745,
            12678,
            12678
        ],
        45201: [
            9659,
            9532,
            6527,
            9795,
            12736,
            12736
        ],
        45401: [
            9717,
            9590,
            6585,
            9846,
            12794,
            12794
        ],
        45601: [
            9775,
            9648,
            6643,
            9897,
            12852,
            12852
        ],
        45801: [
            9833,
            9706,
            6701,
            9948,
            12910,
            12910
        ],
        46001: [
            9891,
            9764,
            6759,
            9999,
            12968,
            12968
        ],
        46201: [
            9949,
            9822,
            6817,
            10050,
            13026,
            13026
        ],
        46401: [
            10007,
            9880,
            6875,
            10101,
            13084,
            13084
        ],
        46601: [
            10065,
            9938,
            6933,
            10152,
            13142,
            13142
        ],
        46801: [
            10123,
            9996,
            6991,
            10202,
            13200,
            13200
        ],
        47001: [
            10181,
            10054,
            7049,
            10253,
            13258,
            13258
        ],
        47201: [
            10239,
            10112,
            7107,
            10304,
            13316,
            13316
        ],
        47401: [
            10297,
            10170,
            7165,
            10355,
            13374,
            13374
        ],
        47601: [
            10355,
            10228,
            7223,
            10406,
            13432,
            13432
        ],
        47801: [
            10413,
            10286,
            7281,
            10457,
            13490,
            13490
        ],
        48001: [
            10471,
            10344,
            7339,
            10508,
            13548,
            13548
        ],
        48201: [
            10529,
            10402,
            7397,
            10559,
            13606,
            13606
        ],
        48401: [
            10587,
            10460,
            7455,
            10609,
            13664,
            13664
        ],
        48601: [
            10645,
            10518,
            7513,
            10660,
            13722,
            13722
        ],
        48801: [
            10703,
            10576,
            7571,
            10711,
            13780,
            13780
        ],
        49001: [
            10761,
            10634,
            7629,
            10762,
            13838,
            13838
        ],
        49201: [
            10819,
            10692,
            7687,
            10813,
            13896,
            13896
        ],
        49401: [
            10877,
            10750,
            7745,
            10864,
            13954,
            13954
        ],
        49601: [
            10935,
            10808,
            7803,
            10915,
            14012,
            14012
        ],
        49801: [
            10993,
            10866,
            7861,
            10965,
            14070,
            14070
        ],
        50001: [
            11051,
            10924,
            7923,
            11016,
            14128,
            14128
        ],
        50201: [
            11109,
            10982,
            7987,
            11067,
            14186,
            14186
        ],
        50401: [
            11167,
            11040,
            8051,
            11118,
            14244,
            14244
        ],
        50601: [
            11225,
            11098,
            8115,
            11169,
            14302,
            14302
        ],
        50801: [
            11283,
            11156,
            8179,
            11220,
            14360,
            14360
        ],
        51001: [
            11341,
            11214,
            8243,
            11271,
            14418,
            14418
        ],
        51201: [
            11424,
            11293,
            8329,
            11347,
            14501,
            14501
        ],
        51401: [
            11522,
            11388,
            8429,
            11437,
            14599,
            14599
        ],
        51601: [
            11620,
            11482,
            8530,
            11528,
            14697,
            14697
        ],
        51801: [
            11718,
            11574,
            8627,
            11619,
            14795,
            14795
        ],
        52001: [
            11816,
            11668,
            8728,
            11710,
            14893,
            14893
        ],
        52201: [
            11914,
            11762,
            8828,
            11801,
            14991,
            14991
        ],
        52401: [
            12012,
            11856,
            8928,
            11892,
            15089,
            15089
        ],
        52601: [
            12110,
            11951,
            9028,
            11983,
            15187,
            15187
        ],
        52801: [
            12208,
            12045,
            9129,
            12074,
            15285,
            15285
        ],
        53001: [
            12306,
            12139,
            9229,
            12164,
            15383,
            15383
        ],
        53201: [
            12404,
            12233,
            9329,
            12255,
            15481,
            15481
        ],
        53401: [
            12502,
            12325,
            9427,
            12346,
            15579,
            15579
        ],
        53601: [
            12600,
            12419,
            9527,
            12437,
            15677,
            15677
        ],
        53801: [
            12698,
            12514,
            9628,
            12528,
            15775,
            15775
        ],
        54001: [
            12796,
            12608,
            9728,
            12619,
            15873,
            15873
        ],
        54201: [
            12894,
            12702,
            9828,
            12710,
            15971,
            15971
        ],
        54401: [
            12992,
            12796,
            9928,
            12801,
            16069,
            16069
        ],
        54601: [
            13090,
            12891,
            10029,
            12891,
            16167,
            16167
        ],
        54801: [
            13188,
            12985,
            10129,
            12982,
            16265,
            16265
        ],
        55001: [
            13286,
            13079,
            10229,
            13073,
            16363,
            16363
        ],
        55201: [
            13384,
            13171,
            10327,
            13164,
            16461,
            16461
        ],
        55401: [
            13482,
            13265,
            10427,
            13255,
            16559,
            16559
        ],
        55601: [
            13580,
            13360,
            10527,
            13346,
            16657,
            16657
        ],
        55801: [
            13678,
            13454,
            10628,
            13437,
            16755,
            16755
        ],
        56001: [
            13776,
            13548,
            10728,
            13528,
            16853,
            16853
        ],
        56201: [
            13874,
            13642,
            10828,
            13619,
            16951,
            16951
        ],
        56401: [
            13972,
            13737,
            10928,
            13709,
            17049,
            17049
        ],
        56601: [
            14070,
            13831,
            11029,
            13800,
            17147,
            17147
        ],
        56801: [
            14168,
            13923,
            11126,
            13891,
            17245,
            17245
        ],
        57001: [
            14266,
            14017,
            11227,
            13982,
            17343,
            17343
        ],
        57201: [
            14364,
            14111,
            11327,
            14073,
            17441,
            17441
        ],
        57401: [
            14462,
            14205,
            11427,
            14164,
            17539,
            17539
        ],
        57601: [
            14560,
            14300,
            11527,
            14255,
            17637,
            17637
        ],
        57801: [
            14658,
            14394,
            11628,
            14345,
            17735,
            17735
        ],
        58001: [
            14756,
            14485,
            11725,
            14436,
            17833,
            17833
        ],
        58201: [
            14854,
            14583,
            11829,
            14527,
            17931,
            17931
        ],
        58401: [
            14952,
            14681,
            11933,
            14618,
            18029,
            18029
        ],
        58601: [
            15050,
            14779,
            12037,
            14709,
            18127,
            18127
        ],
        58801: [
            15148,
            14877,
            12141,
            14800,
            18225,
            18225
        ],
        59001: [
            15246,
            14975,
            12245,
            14891,
            18323,
            18323
        ],
        59201: [
            15344,
            15073,
            12349,
            14982,
            18421,
            18421
        ],
        59401: [
            15442,
            15171,
            12453,
            15072,
            18519,
            18519
        ],
        59601: [
            15540,
            15269,
            12557,
            15163,
            18617,
            18617
        ],
        59801: [
            15638,
            15367,
            12661,
            15254,
            18715,
            18715
        ],
        60001: [
            15736,
            15465,
            12765,
            15345,
            18813,
            18813
        ],
        60201: [
            15834,
            15563,
            12869,
            15436,
            18911,
            18911
        ],
        60401: [
            15932,
            15661,
            12973,
            15527,
            19009,
            19009
        ],
        60601: [
            16030,
            15759,
            13077,
            15618,
            19107,
            19107
        ],
        60801: [
            16128,
            15857,
            13181,
            15709,
            19205,
            19205
        ],
        61001: [
            16226,
            15955,
            13285,
            15799,
            19303,
            19303
        ],
        61201: [
            16324,
            16053,
            13389,
            15890,
            19401,
            19401
        ],
        61401: [
            16422,
            16151,
            13493,
            15981,
            19499,
            19499
        ],
        61601: [
            16520,
            16249,
            13597,
            16072,
            19597,
            19597
        ],
        61801: [
            16618,
            16347,
            13701,
            16163,
            19695,
            19695
        ],
        62001: [
            16716,
            16445,
            13805,
            16254,
            19793,
            19793
        ],
        62201: [
            16814,
            16543,
            13909,
            16345,
            19891,
            19891
        ],
        62401: [
            16912,
            16641,
            14013,
            16435,
            19989,
            19989
        ],
        62601: [
            17010,
            16739,
            14117,
            16526,
            20087,
            20087
        ],
        62801: [
            17108,
            16837,
            14221,
            16617,
            20185,
            20185
        ],
        63001: [
            17206,
            16935,
            14325,
            16708,
            20283,
            20283
        ],
        63201: [
            17304,
            17033,
            14429,
            16799,
            20381,
            20381
        ],
        63401: [
            17402,
            17131,
            14533,
            16890,
            20479,
            20479
        ],
        63601: [
            17500,
            17229,
            14637,
            16981,
            20577,
            20577
        ],
        63801: [
            17598,
            17327,
            14741,
            17072,
            20675,
            20675
        ],
        64001: [
            17696,
            17425,
            14845,
            17162,
            20773,
            20773
        ],
        64201: [
            17794,
            17523,
            14949,
            17253,
            20871,
            20871
        ],
        64401: [
            17892,
            17621,
            15053,
            17344,
            20969,
            20969
        ],
        64601: [
            17994,
            17727,
            15165,
            17435,
            21067,
            21067
        ],
        64801: [
            18098,
            17829,
            15273,
            17526,
            21165,
            21165
        ],
        65001: [
            18202,
            17935,
            15385,
            17617,
            21263,
            21263
        ],
        65201: [
            18306,
            18037,
            15493,
            17708,
            21361,
            21361
        ],
        65401: [
            18410,
            18139,
            15601,
            17799,
            21459,
            21459
        ],
        65601: [
            18514,
            18245,
            15713,
            17889,
            21557,
            21557
        ],
        65801: [
            18618,
            18348,
            15821,
            17980,
            21655,
            21655
        ],
        66001: [
            18722,
            18454,
            15934,
            18071,
            21753,
            21753
        ],
        66201: [
            18826,
            18556,
            16042,
            18162,
            21851,
            21851
        ],
        66401: [
            18930,
            18658,
            16150,
            18253,
            21949,
            21949
        ],
        66601: [
            19034,
            18764,
            16262,
            18344,
            22047,
            22047
        ],
        66801: [
            19138,
            18866,
            16370,
            18435,
            22145,
            22145
        ],
        67001: [
            19242,
            18968,
            16478,
            18526,
            22243,
            22243
        ],
        67201: [
            19346,
            19074,
            16590,
            18616,
            22341,
            22341
        ],
        67401: [
            19450,
            19176,
            16698,
            18707,
            22439,
            22439
        ],
        67601: [
            19554,
            19283,
            16810,
            18798,
            22537,
            22537
        ],
        67801: [
            19658,
            19385,
            16919,
            18889,
            22635,
            22635
        ],
        68001: [
            19762,
            19487,
            17027,
            18980,
            22733,
            22733
        ],
        68201: [
            19866,
            19593,
            17139,
            19071,
            22831,
            22831
        ],
        68401: [
            19970,
            19695,
            17247,
            19162,
            22929,
            22929
        ],
        68601: [
            20074,
            19797,
            17355,
            19253,
            23027,
            23027
        ],
        68801: [
            20178,
            19903,
            17467,
            19343,
            23125,
            23125
        ],
        69001: [
            20282,
            20005,
            17575,
            19434,
            23223,
            23223
        ],
        69201: [
            20386,
            20112,
            17687,
            19525,
            23321,
            23321
        ],
        69401: [
            20490,
            20214,
            17795,
            19616,
            23419,
            23419
        ],
        69601: [
            20594,
            20316,
            17904,
            19707,
            23517,
            23517
        ],
        69801: [
            20698,
            20422,
            18016,
            19798,
            23615,
            23615
        ],
        70001: [
            20802,
            20524,
            18124,
            19889,
            23713,
            23713
        ],
        70201: [
            20906,
            20626,
            18232,
            19980,
            23811,
            23811
        ],
        70401: [
            21010,
            20732,
            18344,
            20070,
            23909,
            23909
        ],
        70601: [
            21114,
            20834,
            18452,
            20161,
            24007,
            24007
        ],
        70801: [
            21218,
            20940,
            18564,
            20252,
            24105,
            24105
        ],
        71001: [
            21322,
            21043,
            18672,
            20343,
            24203,
            24203
        ],
        71201: [
            21426,
            21145,
            18780,
            20434,
            24301,
            24301
        ],
        71401: [
            21530,
            21251,
            18893,
            20525,
            24399,
            24399
        ],
        71601: [
            21634,
            21353,
            19001,
            20616,
            24497,
            24497
        ],
        71801: [
            21738,
            21459,
            19113,
            20707,
            24595,
            24595
        ],
        72001: [
            21842,
            21561,
            19221,
            20797,
            24693,
            24693
        ],
        72201: [
            21946,
            21663,
            19329,
            20888,
            24791,
            24791
        ],
        72401: [
            22050,
            21769,
            19441,
            20979,
            24889,
            24889
        ],
        72601: [
            22154,
            21871,
            19549,
            21070,
            24987,
            24987
        ],
        72801: [
            22258,
            21974,
            19657,
            21161,
            25085,
            25085
        ],
        73001: [
            22362,
            22080,
            19770,
            21252,
            25183,
            25183
        ],
        73201: [
            22466,
            22182,
            19878,
            21343,
            25281,
            25281
        ],
        73401: [
            22570,
            22288,
            19990,
            21434,
            25379,
            25379
        ],
        73601: [
            22674,
            22390,
            20098,
            21524,
            25477,
            25477
        ],
        73801: [
            22778,
            22492,
            20206,
            21615,
            25575,
            25575
        ],
        74001: [
            22882,
            22598,
            20318,
            21706,
            25673,
            25673
        ],
        74201: [
            22986,
            22700,
            20426,
            21797,
            25771,
            25771
        ],
        74401: [
            23090,
            22802,
            20534,
            21888,
            25869,
            25869
        ],
        74601: [
            23194,
            22909,
            20646,
            21979,
            25967,
            25967
        ],
        74801: [
            23298,
            23011,
            20755,
            22070,
            26065,
            26065
        ],
        75001: [
            23402,
            23117,
            20867,
            22160,
            26163,
            26163
        ],
        75201: [
            23506,
            23219,
            20975,
            22251,
            26261,
            26261
        ],
        75401: [
            23610,
            23321,
            21083,
            22342,
            26359,
            26359
        ],
        75601: [
            23714,
            23427,
            21195,
            22433,
            26457,
            26457
        ],
        75801: [
            23818,
            23529,
            21303,
            22524,
            26555,
            26555
        ],
        76001: [
            23922,
            23631,
            21411,
            22615,
            26653,
            26653
        ],
        76201: [
            24026,
            23738,
            21523,
            22706,
            26751,
            26751
        ],
        76401: [
            24130,
            23840,
            21631,
            22797,
            26849,
            26849
        ],
        76601: [
            24234,
            23946,
            21744,
            22887,
            26947,
            26947
        ],
        76801: [
            24338,
            24048,
            21852,
            22978,
            27045,
            27045
        ],
        77001: [
            24442,
            24150,
            21960,
            23069,
            27143,
            27143
        ],
        77201: [
            24546,
            24256,
            22072,
            23160,
            27241,
            27241
        ],
        77401: [
            24650,
            24358,
            22180,
            23251,
            27339,
            27339
        ],
        77601: [
            24754,
            24460,
            22288,
            23342,
            27437,
            27437
        ],
        77801: [
            24858,
            24566,
            22400,
            23433,
            27535,
            27535
        ],
        78001: [
            24962,
            24669,
            22508,
            23524,
            27633,
            27633
        ],
        78201: [
            25066,
            24775,
            22621,
            23614,
            27731,
            27731
        ],
        78401: [
            25170,
            24877,
            22729,
            23705,
            27829,
            27829
        ],
        78601: [
            25274,
            24979,
            22837,
            23796,
            27927,
            27927
        ],
        78801: [
            25378,
            25085,
            22949,
            23887,
            28025,
            28025
        ],
        79001: [
            25482,
            25187,
            23057,
            23978,
            28123,
            28123
        ],
        79201: [
            25586,
            25289,
            23165,
            24069,
            28221,
            28221
        ],
        79401: [
            25690,
            25395,
            23277,
            24160,
            28319,
            28319
        ],
        79601: [
            25794,
            25497,
            23385,
            24251,
            28417,
            28417
        ],
        79801: [
            25898,
            25604,
            23497,
            24341,
            28515,
            28515
        ],
        80001: [
            32,
            32,
            29,
            30,
            36,
            36,
            '%'
        ],
        80201: [
            32,
            32,
            29,
            31,
            36,
            36,
            '%'
        ],
        80401: [
            33,
            32,
            30,
            31,
            36,
            36,
            '%'
        ],
        82001: [
            33,
            33,
            30,
            31,
            36,
            36,
            '%'
        ],
        83601: [
            33,
            33,
            31,
            31,
            36,
            36,
            '%'
        ],
        84801: [
            34,
            33,
            31,
            31,
            36,
            36,
            '%'
        ],
        85401: [
            34,
            33,
            31,
            31,
            37,
            37,
            '%'
        ],
        86001: [
            34,
            33,
            31,
            32,
            37,
            37,
            '%'
        ],
        86401: [
            34,
            34,
            31,
            32,
            37,
            37,
            '%'
        ],
        87201: [
            34,
            34,
            32,
            32,
            37,
            37,
            '%'
        ],
        89601: [
            35,
            34,
            32,
            32,
            37,
            37,
            '%'
        ],
        91201: [
            35,
            34,
            33,
            32,
            37,
            37,
            '%'
        ],
        91401: [
            35,
            35,
            33,
            32,
            37,
            37,
            '%'
        ],
        92801: [
            35,
            35,
            33,
            33,
            38,
            38,
            '%'
        ],
        95001: [
            36,
            35,
            33,
            33,
            38,
            38,
            '%'
        ],
        95401: [
            36,
            35,
            34,
            33,
            38,
            38,
            '%'
        ],
        97001: [
            36,
            36,
            34,
            33,
            38,
            38,
            '%'
        ],
        100001: [
            36,
            36,
            35,
            33,
            38,
            38,
            '%'
        ],
        100401: [
            36,
            36,
            35,
            34,
            38,
            38,
            '%'
        ],
        101201: [
            37,
            36,
            35,
            34,
            38,
            38,
            '%'
        ],
        101601: [
            37,
            36,
            35,
            34,
            39,
            39,
            '%'
        ],
        103401: [
            37,
            37,
            35,
            34,
            39,
            39,
            '%'
        ],
        105201: [
            37,
            37,
            36,
            34,
            39,
            39,
            '%'
        ],
        108201: [
            38,
            37,
            36,
            34,
            39,
            39,
            '%'
        ],
        109601: [
            38,
            37,
            36,
            35,
            39,
            39,
            '%'
        ],
        110601: [
            38,
            38,
            36,
            35,
            39,
            39,
            '%'
        ],
        111001: [
            38,
            38,
            37,
            35,
            39,
            39,
            '%'
        ],
        112401: [
            38,
            38,
            37,
            35,
            40,
            40,
            '%'
        ],
        116201: [
            39,
            38,
            37,
            35,
            40,
            40,
            '%'
        ],
        117401: [
            39,
            38,
            38,
            35,
            40,
            40,
            '%'
        ],
        119001: [
            39,
            39,
            38,
            35,
            40,
            40,
            '%'
        ],
        120801: [
            39,
            39,
            38,
            36,
            40,
            40,
            '%'
        ],
        124601: [
            39,
            39,
            39,
            36,
            40,
            40,
            '%'
        ],
        125601: [
            40,
            39,
            39,
            36,
            41,
            41,
            '%'
        ],
        128601: [
            40,
            40,
            39,
            36,
            41,
            41,
            '%'
        ],
        132801: [
            40,
            40,
            40,
            36,
            41,
            41,
            '%'
        ],
        134201: [
            40,
            40,
            40,
            37,
            41,
            41,
            '%'
        ],
        136401: [
            41,
            40,
            40,
            37,
            41,
            41,
            '%'
        ],
        140001: [
            41,
            41,
            40,
            37,
            41,
            41,
            '%'
        ],
        142201: [
            41,
            41,
            41,
            37,
            41,
            41,
            '%'
        ],
        142401: [
            41,
            41,
            41,
            37,
            42,
            42,
            '%'
        ],
        149401: [
            42,
            41,
            41,
            37,
            42,
            42,
            '%'
        ],
        151201: [
            42,
            41,
            41,
            38,
            42,
            42,
            '%'
        ],
        153601: [
            42,
            42,
            42,
            38,
            42,
            42,
            '%'
        ],
        164201: [
            42,
            42,
            42,
            38,
            43,
            43,
            '%'
        ],
        165201: [
            43,
            42,
            42,
            38,
            43,
            43,
            '%'
        ],
        170001: [
            43,
            43,
            43,
            38,
            43,
            43,
            '%'
        ],
        173001: [
            43,
            43,
            43,
            39,
            43,
            43,
            '%'
        ],
        194201: [
            44,
            44,
            44,
            39,
            44,
            44,
            '%'
        ],
        202201: [
            44,
            44,
            44,
            40,
            44,
            44,
            '%'
        ],
        237401: [
            45,
            45,
            45,
            40,
            45,
            45,
            '%'
        ],
        243201: [
            45,
            45,
            45,
            41,
            45,
            45,
            '%'
        ],
        305001: [
            45,
            45,
            45,
            42,
            45,
            45,
            '%'
        ],
        305201: [
            46,
            46,
            46,
            42,
            46,
            46,
            '%'
        ],
        408801: [
            46,
            46,
            46,
            43,
            46,
            46,
            '%'
        ],
        427201: [
            47,
            47,
            47,
            43,
            47,
            47,
            '%'
        ],
        620001: [
            47,
            47,
            47,
            44,
            47,
            47,
            '%'
        ],
        712201: [
            48,
            48,
            48,
            44,
            48,
            48,
            '%'
        ],
        1282001: [
            48,
            48,
            48,
            45,
            48,
            48,
            '%'
        ]
    },
    30: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            23
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            54
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            85
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            116
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            147
        ],
        2501: [
            195,
            0,
            183,
            12,
            195,
            178
        ],
        2601: [
            206,
            0,
            191,
            14,
            209,
            209
        ],
        2701: [
            216,
            0,
            200,
            16,
            240,
            240
        ],
        2801: [
            218,
            0,
            200,
            18,
            271,
            271
        ],
        2901: [
            229,
            0,
            208,
            21,
            302,
            302
        ],
        3001: [
            239,
            0,
            216,
            23,
            333,
            333
        ],
        3101: [
            250,
            0,
            225,
            25,
            364,
            364
        ],
        3201: [
            260,
            0,
            233,
            27,
            395,
            395
        ],
        3301: [
            271,
            0,
            241,
            29,
            426,
            426
        ],
        3401: [
            273,
            0,
            241,
            31,
            457,
            457
        ],
        3501: [
            284,
            0,
            250,
            34,
            488,
            488
        ],
        3601: [
            294,
            0,
            258,
            36,
            519,
            519
        ],
        3701: [
            305,
            0,
            266,
            38,
            550,
            550
        ],
        3801: [
            315,
            0,
            275,
            40,
            581,
            581
        ],
        3901: [
            325,
            0,
            283,
            42,
            612,
            612
        ],
        4001: [
            328,
            0,
            283,
            44,
            643,
            643
        ],
        4101: [
            338,
            0,
            291,
            46,
            674,
            674
        ],
        4201: [
            349,
            0,
            300,
            49,
            705,
            705
        ],
        4301: [
            359,
            0,
            308,
            61,
            736,
            736
        ],
        4401: [
            370,
            0,
            316,
            82,
            767,
            767
        ],
        4501: [
            380,
            0,
            325,
            104,
            798,
            798
        ],
        4601: [
            382,
            0,
            325,
            125,
            829,
            829
        ],
        4701: [
            392,
            0,
            333,
            146,
            855,
            855
        ],
        4801: [
            403,
            0,
            341,
            166,
            880,
            880
        ],
        4901: [
            413,
            0,
            350,
            187,
            906,
            906
        ],
        5001: [
            423,
            0,
            358,
            208,
            929,
            929
        ],
        5101: [
            433,
            0,
            366,
            229,
            955,
            955
        ],
        5201: [
            443,
            0,
            375,
            249,
            979,
            979
        ],
        5301: [
            445,
            20,
            376,
            270,
            1004,
            1004
        ],
        5401: [
            455,
            43,
            386,
            291,
            1030,
            1030
        ],
        5501: [
            465,
            67,
            396,
            311,
            1053,
            1053
        ],
        5601: [
            475,
            90,
            406,
            331,
            1078,
            1078
        ],
        5701: [
            485,
            113,
            416,
            351,
            1101,
            1101
        ],
        5801: [
            495,
            136,
            426,
            372,
            1126,
            1126
        ],
        5901: [
            497,
            160,
            427,
            392,
            1151,
            1151
        ],
        6001: [
            507,
            183,
            437,
            412,
            1174,
            1174
        ],
        6101: [
            517,
            206,
            447,
            432,
            1199,
            1199
        ],
        6201: [
            527,
            227,
            457,
            452,
            1222,
            1222
        ],
        6301: [
            537,
            250,
            467,
            472,
            1247,
            1247
        ],
        6401: [
            547,
            273,
            477,
            493,
            1272,
            1272
        ],
        6501: [
            549,
            297,
            479,
            513,
            1295,
            1295
        ],
        6601: [
            559,
            320,
            488,
            533,
            1320,
            1320
        ],
        6701: [
            569,
            343,
            498,
            553,
            1343,
            1343
        ],
        6801: [
            579,
            366,
            508,
            573,
            1368,
            1368
        ],
        6901: [
            589,
            390,
            518,
            593,
            1393,
            1393
        ],
        7001: [
            599,
            413,
            528,
            613,
            1416,
            1416
        ],
        7101: [
            601,
            436,
            530,
            634,
            1441,
            1441
        ],
        7201: [
            614,
            459,
            540,
            654,
            1464,
            1464
        ],
        7301: [
            633,
            483,
            550,
            674,
            1489,
            1489
        ],
        7401: [
            651,
            503,
            560,
            694,
            1514,
            1514
        ],
        7501: [
            670,
            526,
            570,
            714,
            1537,
            1537
        ],
        7601: [
            689,
            550,
            580,
            734,
            1562,
            1562
        ],
        7701: [
            708,
            573,
            589,
            754,
            1585,
            1585
        ],
        7801: [
            727,
            596,
            591,
            775,
            1610,
            1610
        ],
        7901: [
            745,
            619,
            601,
            795,
            1635,
            1635
        ],
        8001: [
            764,
            643,
            611,
            815,
            1658,
            1658
        ],
        8101: [
            783,
            666,
            621,
            835,
            1683,
            1683
        ],
        8201: [
            802,
            689,
            631,
            855,
            1706,
            1706
        ],
        8301: [
            820,
            712,
            641,
            875,
            1731,
            1731
        ],
        8401: [
            839,
            736,
            642,
            895,
            1756,
            1756
        ],
        8501: [
            858,
            759,
            652,
            916,
            1779,
            1779
        ],
        8601: [
            877,
            780,
            662,
            936,
            1804,
            1804
        ],
        8701: [
            896,
            803,
            672,
            956,
            1827,
            1827
        ],
        8801: [
            915,
            826,
            682,
            976,
            1852,
            1852
        ],
        8901: [
            933,
            849,
            692,
            996,
            1877,
            1877
        ],
        9001: [
            952,
            873,
            694,
            1016,
            1900,
            1900
        ],
        9101: [
            971,
            896,
            704,
            1037,
            1925,
            1925
        ],
        9201: [
            990,
            919,
            714,
            1057,
            1948,
            1948
        ],
        9301: [
            1008,
            935,
            723,
            1077,
            1973,
            1973
        ],
        9401: [
            1027,
            955,
            733,
            1097,
            1998,
            1998
        ],
        9501: [
            1046,
            973,
            742,
            1117,
            2021,
            2021
        ],
        9601: [
            1065,
            991,
            744,
            1137,
            2046,
            2046
        ],
        9701: [
            1084,
            1010,
            753,
            1157,
            2069,
            2069
        ],
        9801: [
            1102,
            1030,
            763,
            1178,
            2094,
            2094
        ],
        9901: [
            1121,
            1048,
            773,
            1198,
            2119,
            2119
        ],
        10001: [
            1140,
            1066,
            782,
            1218,
            2142,
            2142
        ],
        10101: [
            1159,
            1083,
            792,
            1238,
            2167,
            2167
        ],
        10201: [
            1178,
            1103,
            802,
            1258,
            2190,
            2190
        ],
        10301: [
            1196,
            1121,
            803,
            1278,
            2215,
            2215
        ],
        10401: [
            1215,
            1139,
            812,
            1298,
            2240,
            2240
        ],
        10501: [
            1234,
            1156,
            822,
            1318,
            2263,
            2263
        ],
        10601: [
            1253,
            1177,
            832,
            1339,
            2288,
            2288
        ],
        10701: [
            1271,
            1194,
            841,
            1359,
            2311,
            2311
        ],
        10801: [
            1290,
            1212,
            851,
            1379,
            2336,
            2336
        ],
        10901: [
            1309,
            1229,
            852,
            1399,
            2361,
            2361
        ],
        11001: [
            1328,
            1250,
            862,
            1419,
            2384,
            2384
        ],
        11101: [
            1347,
            1267,
            872,
            1439,
            2409,
            2409
        ],
        11201: [
            1365,
            1285,
            881,
            1460,
            2432,
            2432
        ],
        11301: [
            1384,
            1305,
            891,
            1480,
            2457,
            2457
        ],
        11401: [
            1403,
            1323,
            901,
            1500,
            2482,
            2482
        ],
        11501: [
            1422,
            1340,
            902,
            1520,
            2505,
            2505
        ],
        11601: [
            1441,
            1358,
            911,
            1540,
            2530,
            2530
        ],
        11701: [
            1459,
            1378,
            921,
            1560,
            2553,
            2553
        ],
        11801: [
            1478,
            1396,
            931,
            1581,
            2578,
            2578
        ],
        11901: [
            1497,
            1413,
            940,
            1601,
            2603,
            2603
        ],
        12001: [
            1516,
            1431,
            950,
            1621,
            2626,
            2626
        ],
        12101: [
            1535,
            1451,
            951,
            1641,
            2651,
            2651
        ],
        12201: [
            1553,
            1469,
            961,
            1661,
            2674,
            2674
        ],
        12301: [
            1572,
            1487,
            971,
            1681,
            2699,
            2699
        ],
        12401: [
            1591,
            1504,
            980,
            1701,
            2724,
            2724
        ],
        12501: [
            1610,
            1524,
            990,
            1721,
            2747,
            2747
        ],
        12601: [
            1629,
            1542,
            1000,
            1742,
            2772,
            2772
        ],
        12701: [
            1647,
            1560,
            1009,
            1762,
            2795,
            2795
        ],
        12801: [
            1666,
            1577,
            1010,
            1782,
            2820,
            2820
        ],
        12901: [
            1685,
            1597,
            1020,
            1802,
            2843,
            2843
        ],
        13001: [
            1704,
            1615,
            1030,
            1822,
            2873,
            2873
        ],
        13101: [
            1723,
            1633,
            1039,
            1843,
            2903,
            2903
        ],
        13201: [
            1742,
            1653,
            1049,
            1863,
            2934,
            2934
        ],
        13301: [
            1761,
            1671,
            1059,
            1884,
            2964,
            2964
        ],
        13401: [
            1780,
            1688,
            1060,
            1904,
            2994,
            2994
        ],
        13501: [
            1799,
            1706,
            1070,
            1925,
            3024,
            3024
        ],
        13601: [
            1819,
            1726,
            1079,
            1945,
            3055,
            3055
        ],
        13701: [
            1838,
            1744,
            1089,
            1965,
            3085,
            3085
        ],
        13801: [
            1856,
            1761,
            1099,
            1985,
            3114,
            3114
        ],
        13901: [
            1874,
            1779,
            1108,
            2005,
            3144,
            3144
        ],
        14001: [
            1892,
            1799,
            1110,
            2024,
            3173,
            3173
        ],
        14101: [
            1910,
            1817,
            1119,
            2044,
            3202,
            3202
        ],
        14201: [
            1928,
            1834,
            1129,
            2063,
            3231,
            3231
        ],
        14301: [
            1946,
            1852,
            1138,
            2082,
            3261,
            3261
        ],
        14401: [
            1965,
            1872,
            1148,
            2102,
            3290,
            3290
        ],
        14501: [
            1983,
            1890,
            1158,
            2121,
            3319,
            3319
        ],
        14601: [
            2001,
            1908,
            1159,
            2141,
            3348,
            3348
        ],
        14701: [
            2019,
            1925,
            1169,
            2160,
            3378,
            3378
        ],
        14801: [
            2037,
            1945,
            1178,
            2180,
            3409,
            3409
        ],
        14901: [
            2055,
            1963,
            1188,
            2199,
            3441,
            3441
        ],
        15001: [
            2073,
            1981,
            1198,
            2219,
            3473,
            3473
        ],
        15101: [
            2091,
            1998,
            1207,
            2238,
            3504,
            3504
        ],
        15201: [
            2109,
            2018,
            1217,
            2258,
            3539,
            3539
        ],
        15301: [
            2128,
            2036,
            1218,
            2277,
            3570,
            3570
        ],
        15401: [
            2147,
            2056,
            1228,
            2299,
            3602,
            3602
        ],
        15501: [
            2171,
            2081,
            1238,
            2324,
            3634,
            3634
        ],
        15601: [
            2196,
            2107,
            1248,
            2350,
            3665,
            3665
        ],
        15701: [
            2221,
            2132,
            1258,
            2376,
            3699,
            3699
        ],
        15801: [
            2245,
            2157,
            1268,
            2401,
            3731,
            3731
        ],
        15901: [
            2270,
            2182,
            1270,
            2427,
            3763,
            3763
        ],
        16001: [
            2294,
            2208,
            1280,
            2452,
            3795,
            3795
        ],
        16101: [
            2319,
            2233,
            1291,
            2478,
            3826,
            3826
        ],
        16201: [
            2343,
            2258,
            1301,
            2504,
            3860,
            3860
        ],
        16301: [
            2368,
            2283,
            1311,
            2529,
            3892,
            3892
        ],
        16401: [
            2392,
            2308,
            1321,
            2555,
            3924,
            3924
        ],
        16501: [
            2417,
            2334,
            1323,
            2580,
            3955,
            3955
        ],
        16601: [
            2442,
            2359,
            1333,
            2606,
            3987,
            3987
        ],
        16701: [
            2466,
            2384,
            1343,
            2632,
            4021,
            4021
        ],
        16801: [
            2491,
            2409,
            1353,
            2657,
            4053,
            4053
        ],
        16901: [
            2515,
            2434,
            1363,
            2683,
            4085,
            4085
        ],
        17001: [
            2540,
            2457,
            1373,
            2708,
            4116,
            4116
        ],
        17101: [
            2564,
            2482,
            1375,
            2734,
            4148,
            4148
        ],
        17201: [
            2589,
            2508,
            1385,
            2760,
            4182,
            4182
        ],
        17301: [
            2613,
            2533,
            1395,
            2785,
            4214,
            4214
        ],
        17401: [
            2638,
            2558,
            1405,
            2811,
            4245,
            4245
        ],
        17501: [
            2663,
            2583,
            1416,
            2836,
            4277,
            4277
        ],
        17601: [
            2687,
            2608,
            1426,
            2862,
            4309,
            4309
        ],
        17701: [
            2712,
            2634,
            1436,
            2888,
            4343,
            4343
        ],
        17801: [
            2736,
            2659,
            1438,
            2913,
            4375,
            4375
        ],
        17901: [
            2761,
            2684,
            1448,
            2939,
            4406,
            4406
        ],
        18001: [
            2785,
            2709,
            1458,
            2964,
            4438,
            4438
        ],
        18101: [
            2810,
            2734,
            1468,
            2990,
            4470,
            4470
        ],
        18201: [
            2834,
            2760,
            1478,
            3016,
            4504,
            4504
        ],
        18301: [
            2859,
            2785,
            1488,
            3041,
            4536,
            4536
        ],
        18401: [
            2884,
            2810,
            1490,
            3067,
            4567,
            4567
        ],
        18501: [
            2908,
            2835,
            1500,
            3092,
            4599,
            4599
        ],
        18601: [
            2933,
            2861,
            1510,
            3118,
            4631,
            4631
        ],
        18701: [
            2957,
            2886,
            1520,
            3144,
            4665,
            4665
        ],
        18801: [
            2982,
            2911,
            1531,
            3169,
            4696,
            4696
        ],
        18901: [
            3006,
            2936,
            1541,
            3195,
            4728,
            4728
        ],
        19001: [
            3031,
            2961,
            1543,
            3220,
            4760,
            4760
        ],
        19101: [
            3055,
            2987,
            1553,
            3246,
            4791,
            4791
        ],
        19201: [
            3080,
            3012,
            1563,
            3272,
            4826,
            4826
        ],
        19301: [
            3105,
            3037,
            1573,
            3297,
            4857,
            4857
        ],
        19401: [
            3129,
            3062,
            1583,
            3323,
            4889,
            4889
        ],
        19501: [
            3154,
            3087,
            1593,
            3348,
            4921,
            4921
        ],
        19601: [
            3178,
            3112,
            1595,
            3374,
            4952,
            4952
        ],
        19701: [
            3203,
            3136,
            1604,
            3400,
            4986,
            4986
        ],
        19801: [
            3227,
            3161,
            1613,
            3425,
            5018,
            5018
        ],
        19901: [
            3252,
            3185,
            1622,
            3451,
            5050,
            5050
        ],
        20001: [
            3301,
            3234,
            1641,
            3502,
            5113,
            5113
        ],
        20201: [
            3350,
            3280,
            1651,
            3553,
            5179,
            5179
        ],
        20401: [
            3399,
            3329,
            1670,
            3604,
            5242,
            5242
        ],
        20601: [
            3448,
            3378,
            1688,
            3656,
            5308,
            5308
        ],
        20801: [
            3497,
            3426,
            1699,
            3707,
            5372,
            5372
        ],
        21001: [
            3546,
            3475,
            1717,
            3758,
            5435,
            5435
        ],
        21201: [
            3596,
            3524,
            1736,
            3809,
            5501,
            5501
        ],
        21401: [
            3645,
            3573,
            1746,
            3860,
            5564,
            5564
        ],
        21601: [
            3694,
            3621,
            1765,
            3912,
            5630,
            5630
        ],
        21801: [
            3743,
            3670,
            1783,
            3963,
            5693,
            5693
        ],
        22001: [
            3792,
            3719,
            1794,
            4014,
            5757,
            5757
        ],
        22201: [
            3841,
            3768,
            1812,
            4065,
            5823,
            5823
        ],
        22401: [
            3890,
            3816,
            1831,
            4116,
            5886,
            5886
        ],
        22601: [
            3939,
            3865,
            1849,
            4168,
            5952,
            5952
        ],
        22801: [
            3989,
            3914,
            1860,
            4220,
            6016,
            6016
        ],
        23001: [
            4040,
            3963,
            1878,
            4272,
            6081,
            6081
        ],
        23201: [
            4091,
            4011,
            1897,
            4325,
            6149,
            6149
        ],
        23401: [
            4142,
            4058,
            1907,
            4378,
            6214,
            6214
        ],
        23601: [
            4192,
            4107,
            1926,
            4431,
            6281,
            6281
        ],
        23801: [
            4243,
            4153,
            1944,
            4484,
            6346,
            6346
        ],
        24001: [
            4294,
            4204,
            1954,
            4537,
            6411,
            6411
        ],
        24201: [
            4345,
            4253,
            1973,
            4590,
            6479,
            6479
        ],
        24401: [
            4395,
            4304,
            1992,
            4642,
            6544,
            6544
        ],
        24601: [
            4446,
            4353,
            2002,
            4695,
            6611,
            6611
        ],
        24801: [
            4497,
            4401,
            2021,
            4748,
            6676,
            6676
        ],
        25001: [
            4548,
            4453,
            2039,
            4801,
            6741,
            6741
        ],
        25201: [
            4598,
            4501,
            2050,
            4854,
            6809,
            6809
        ],
        25401: [
            4649,
            4550,
            2068,
            4907,
            6874,
            6874
        ],
        25601: [
            4700,
            4601,
            2087,
            4960,
            6941,
            6941
        ],
        25801: [
            4751,
            4650,
            2097,
            5012,
            7006,
            7006
        ],
        26001: [
            4801,
            4699,
            2116,
            5065,
            7071,
            7071
        ],
        26201: [
            4852,
            4750,
            2134,
            5118,
            7139,
            7139
        ],
        26401: [
            4903,
            4799,
            2145,
            5171,
            7204,
            7204
        ],
        26601: [
            4954,
            4850,
            2163,
            5224,
            7271,
            7271
        ],
        26801: [
            5004,
            4899,
            2182,
            5277,
            7336,
            7336
        ],
        27001: [
            5055,
            4947,
            2192,
            5329,
            7401,
            7401
        ],
        27201: [
            5106,
            4999,
            2211,
            5382,
            7469,
            7469
        ],
        27401: [
            5157,
            5047,
            2230,
            5435,
            7534,
            7534
        ],
        27601: [
            5208,
            5096,
            2248,
            5488,
            7601,
            7601
        ],
        27801: [
            5258,
            5147,
            2259,
            5541,
            7666,
            7666
        ],
        28001: [
            5309,
            5196,
            2277,
            5594,
            7731,
            7731
        ],
        28201: [
            5360,
            5247,
            2296,
            5647,
            7799,
            7799
        ],
        28401: [
            5411,
            5296,
            2306,
            5699,
            7864,
            7864
        ],
        28601: [
            5461,
            5345,
            2340,
            5752,
            7931,
            7931
        ],
        28801: [
            5512,
            5396,
            2391,
            5805,
            7996,
            7996
        ],
        29001: [
            5563,
            5445,
            2440,
            5858,
            8061,
            8061
        ],
        29201: [
            5614,
            5493,
            2489,
            5911,
            8129,
            8129
        ],
        29401: [
            5664,
            5545,
            2540,
            5964,
            8194,
            8194
        ],
        29601: [
            5715,
            5593,
            2589,
            6017,
            8261,
            8261
        ],
        29801: [
            5766,
            5645,
            2640,
            6069,
            8326,
            8326
        ],
        30001: [
            5817,
            5693,
            2688,
            6122,
            8391,
            8391
        ],
        30201: [
            5867,
            5742,
            2737,
            6175,
            8459,
            8459
        ],
        30401: [
            5918,
            5793,
            2789,
            6228,
            8524,
            8524
        ],
        30601: [
            5969,
            5842,
            2837,
            6281,
            8591,
            8591
        ],
        30801: [
            6020,
            5891,
            2886,
            6334,
            8656,
            8656
        ],
        31001: [
            6070,
            5942,
            2937,
            6386,
            8721,
            8721
        ],
        31201: [
            6121,
            5991,
            2986,
            6439,
            8789,
            8789
        ],
        31401: [
            6172,
            6041,
            3036,
            6492,
            8854,
            8854
        ],
        31601: [
            6223,
            6094,
            3089,
            6545,
            8921,
            8921
        ],
        31801: [
            6273,
            6144,
            3139,
            6598,
            8986,
            8986
        ],
        32001: [
            6324,
            6196,
            3191,
            6651,
            9051,
            9051
        ],
        32201: [
            6375,
            6246,
            3241,
            6704,
            9119,
            9119
        ],
        32401: [
            6426,
            6296,
            3291,
            6756,
            9184,
            9184
        ],
        32601: [
            6476,
            6349,
            3344,
            6809,
            9251,
            9251
        ],
        32801: [
            6527,
            6399,
            3394,
            6862,
            9316,
            9316
        ],
        33001: [
            6578,
            6449,
            3444,
            6915,
            9381,
            9381
        ],
        33201: [
            6629,
            6501,
            3496,
            6968,
            9449,
            9449
        ],
        33401: [
            6679,
            6551,
            3546,
            7021,
            9514,
            9514
        ],
        33601: [
            6730,
            6604,
            3599,
            7074,
            9581,
            9581
        ],
        33801: [
            6781,
            6654,
            3649,
            7126,
            9646,
            9646
        ],
        34001: [
            6832,
            6704,
            3699,
            7179,
            9711,
            9711
        ],
        34201: [
            6882,
            6756,
            3751,
            7232,
            9779,
            9779
        ],
        34401: [
            6933,
            6806,
            3801,
            7285,
            9844,
            9844
        ],
        34601: [
            6984,
            6856,
            3851,
            7338,
            9911,
            9911
        ],
        34801: [
            7035,
            6909,
            3904,
            7391,
            9976,
            9976
        ],
        35001: [
            7085,
            6959,
            3954,
            7443,
            10041,
            10041
        ],
        35201: [
            7136,
            7009,
            4004,
            7496,
            10109,
            10109
        ],
        35401: [
            7187,
            7061,
            4056,
            7549,
            10174,
            10174
        ],
        35601: [
            7238,
            7111,
            4106,
            7602,
            10241,
            10241
        ],
        35801: [
            7288,
            7164,
            4159,
            7655,
            10306,
            10306
        ],
        36001: [
            7339,
            7214,
            4209,
            7708,
            10371,
            10371
        ],
        36201: [
            7390,
            7264,
            4259,
            7761,
            10439,
            10439
        ],
        36401: [
            7441,
            7316,
            4311,
            7813,
            10504,
            10504
        ],
        36601: [
            7492,
            7366,
            4361,
            7866,
            10571,
            10571
        ],
        36801: [
            7542,
            7416,
            4411,
            7919,
            10636,
            10636
        ],
        37001: [
            7593,
            7469,
            4464,
            7972,
            10701,
            10701
        ],
        37201: [
            7644,
            7519,
            4514,
            8025,
            10769,
            10769
        ],
        37401: [
            7694,
            7571,
            4566,
            8078,
            10834,
            10834
        ],
        37601: [
            7745,
            7619,
            4614,
            8130,
            10896,
            10896
        ],
        37801: [
            7796,
            7669,
            4664,
            8183,
            10956,
            10956
        ],
        38001: [
            7846,
            7719,
            4714,
            8236,
            11016,
            11016
        ],
        38201: [
            7897,
            7771,
            4766,
            8288,
            11076,
            11076
        ],
        38401: [
            7948,
            7821,
            4816,
            8341,
            11136,
            11136
        ],
        38601: [
            8008,
            7881,
            4876,
            8393,
            11196,
            11196
        ],
        38801: [
            8068,
            7941,
            4936,
            8446,
            11256,
            11256
        ],
        39001: [
            8128,
            8001,
            4996,
            8499,
            11316,
            11316
        ],
        39201: [
            8188,
            8061,
            5056,
            8551,
            11376,
            11376
        ],
        39401: [
            8248,
            8121,
            5116,
            8604,
            11436,
            11436
        ],
        39601: [
            8308,
            8181,
            5176,
            8657,
            11496,
            11496
        ],
        39801: [
            8368,
            8241,
            5236,
            8709,
            11556,
            11556
        ],
        40001: [
            8428,
            8301,
            5296,
            8762,
            11616,
            11616
        ],
        40201: [
            8488,
            8361,
            5356,
            8814,
            11676,
            11676
        ],
        40401: [
            8548,
            8421,
            5416,
            8867,
            11736,
            11736
        ],
        40601: [
            8608,
            8481,
            5476,
            8920,
            11796,
            11796
        ],
        40801: [
            8668,
            8541,
            5536,
            8972,
            11856,
            11856
        ],
        41001: [
            8728,
            8601,
            5596,
            9025,
            11916,
            11916
        ],
        41201: [
            8788,
            8661,
            5656,
            9078,
            11976,
            11976
        ],
        41401: [
            8848,
            8721,
            5716,
            9130,
            12036,
            12036
        ],
        41601: [
            8908,
            8781,
            5776,
            9183,
            12096,
            12096
        ],
        41801: [
            8968,
            8841,
            5836,
            9235,
            12156,
            12156
        ],
        42001: [
            9028,
            8901,
            5896,
            9288,
            12216,
            12216
        ],
        42201: [
            9088,
            8961,
            5956,
            9341,
            12276,
            12276
        ],
        42401: [
            9148,
            9021,
            6016,
            9393,
            12336,
            12336
        ],
        42601: [
            9208,
            9081,
            6076,
            9446,
            12396,
            12396
        ],
        42801: [
            9268,
            9141,
            6136,
            9498,
            12456,
            12456
        ],
        43001: [
            9328,
            9201,
            6196,
            9551,
            12516,
            12516
        ],
        43201: [
            9388,
            9261,
            6256,
            9604,
            12576,
            12576
        ],
        43401: [
            9448,
            9321,
            6316,
            9656,
            12636,
            12636
        ],
        43601: [
            9508,
            9381,
            6376,
            9709,
            12696,
            12696
        ],
        43801: [
            9568,
            9441,
            6436,
            9761,
            12756,
            12756
        ],
        44001: [
            9628,
            9501,
            6496,
            9814,
            12816,
            12816
        ],
        44201: [
            9688,
            9561,
            6556,
            9867,
            12876,
            12876
        ],
        44401: [
            9748,
            9621,
            6616,
            9919,
            12936,
            12936
        ],
        44601: [
            9808,
            9681,
            6676,
            9972,
            12996,
            12996
        ],
        44801: [
            9868,
            9741,
            6736,
            10025,
            13056,
            13056
        ],
        45001: [
            9928,
            9801,
            6796,
            10077,
            13116,
            13116
        ],
        45201: [
            9988,
            9861,
            6856,
            10130,
            13176,
            13176
        ],
        45401: [
            10048,
            9921,
            6916,
            10182,
            13236,
            13236
        ],
        45601: [
            10108,
            9981,
            6976,
            10235,
            13296,
            13296
        ],
        45801: [
            10168,
            10041,
            7036,
            10288,
            13356,
            13356
        ],
        46001: [
            10228,
            10101,
            7096,
            10340,
            13416,
            13416
        ],
        46201: [
            10288,
            10161,
            7156,
            10393,
            13476,
            13476
        ],
        46401: [
            10348,
            10221,
            7216,
            10445,
            13536,
            13536
        ],
        46601: [
            10408,
            10281,
            7276,
            10498,
            13596,
            13596
        ],
        46801: [
            10468,
            10341,
            7336,
            10551,
            13656,
            13656
        ],
        47001: [
            10528,
            10401,
            7396,
            10603,
            13716,
            13716
        ],
        47201: [
            10588,
            10461,
            7456,
            10656,
            13776,
            13776
        ],
        47401: [
            10648,
            10521,
            7516,
            10709,
            13836,
            13836
        ],
        47601: [
            10708,
            10581,
            7576,
            10761,
            13896,
            13896
        ],
        47801: [
            10768,
            10641,
            7636,
            10814,
            13956,
            13956
        ],
        48001: [
            10828,
            10701,
            7696,
            10866,
            14016,
            14016
        ],
        48201: [
            10888,
            10761,
            7756,
            10919,
            14076,
            14076
        ],
        48401: [
            10948,
            10821,
            7816,
            10972,
            14136,
            14136
        ],
        48601: [
            11008,
            10881,
            7876,
            11024,
            14196,
            14196
        ],
        48801: [
            11068,
            10941,
            7936,
            11077,
            14256,
            14256
        ],
        49001: [
            11128,
            11001,
            7996,
            11130,
            14316,
            14316
        ],
        49201: [
            11188,
            11061,
            8056,
            11182,
            14376,
            14376
        ],
        49401: [
            11248,
            11121,
            8116,
            11235,
            14436,
            14436
        ],
        49601: [
            11308,
            11181,
            8176,
            11287,
            14496,
            14496
        ],
        49801: [
            11368,
            11241,
            8236,
            11340,
            14556,
            14556
        ],
        50001: [
            11428,
            11301,
            8301,
            11393,
            14616,
            14616
        ],
        50201: [
            11488,
            11361,
            8367,
            11445,
            14676,
            14676
        ],
        50401: [
            11548,
            11421,
            8433,
            11498,
            14736,
            14736
        ],
        50601: [
            11608,
            11481,
            8499,
            11550,
            14796,
            14796
        ],
        50801: [
            11668,
            11541,
            8565,
            11603,
            14856,
            14856
        ],
        51001: [
            11728,
            11601,
            8631,
            11656,
            14916,
            14916
        ],
        51201: [
            11813,
            11684,
            8719,
            11733,
            15001,
            15001
        ],
        51401: [
            11913,
            11781,
            8823,
            11826,
            15101,
            15101
        ],
        51601: [
            12013,
            11879,
            8926,
            11919,
            15201,
            15201
        ],
        51801: [
            12113,
            11974,
            9027,
            12011,
            15301,
            15301
        ],
        52001: [
            12213,
            12071,
            9131,
            12104,
            15401,
            15401
        ],
        52201: [
            12313,
            12169,
            9234,
            12196,
            15501,
            15501
        ],
        52401: [
            12413,
            12266,
            9338,
            12289,
            15601,
            15601
        ],
        52601: [
            12513,
            12364,
            9441,
            12382,
            15701,
            15701
        ],
        52801: [
            12613,
            12461,
            9545,
            12474,
            15801,
            15801
        ],
        53001: [
            12713,
            12559,
            9648,
            12567,
            15901,
            15901
        ],
        53201: [
            12813,
            12656,
            9752,
            12659,
            16001,
            16001
        ],
        53401: [
            12913,
            12751,
            9853,
            12752,
            16101,
            16101
        ],
        53601: [
            13013,
            12849,
            9956,
            12845,
            16201,
            16201
        ],
        53801: [
            13113,
            12946,
            10060,
            12937,
            16301,
            16301
        ],
        54001: [
            13213,
            13044,
            10163,
            13030,
            16401,
            16401
        ],
        54201: [
            13313,
            13141,
            10267,
            13123,
            16501,
            16501
        ],
        54401: [
            13413,
            13239,
            10370,
            13215,
            16601,
            16601
        ],
        54601: [
            13513,
            13336,
            10474,
            13308,
            16701,
            16701
        ],
        54801: [
            13613,
            13434,
            10577,
            13400,
            16801,
            16801
        ],
        55001: [
            13713,
            13531,
            10681,
            13493,
            16901,
            16901
        ],
        55201: [
            13813,
            13626,
            10782,
            13586,
            17001,
            17001
        ],
        55401: [
            13913,
            13724,
            10885,
            13678,
            17101,
            17101
        ],
        55601: [
            14013,
            13821,
            10989,
            13771,
            17201,
            17201
        ],
        55801: [
            14113,
            13919,
            11092,
            13863,
            17301,
            17301
        ],
        56001: [
            14213,
            14016,
            11196,
            13956,
            17401,
            17401
        ],
        56201: [
            14313,
            14114,
            11299,
            14049,
            17501,
            17501
        ],
        56401: [
            14413,
            14211,
            11403,
            14141,
            17601,
            17601
        ],
        56601: [
            14513,
            14309,
            11506,
            14234,
            17701,
            17701
        ],
        56801: [
            14613,
            14404,
            11607,
            14327,
            17801,
            17801
        ],
        57001: [
            14713,
            14501,
            11711,
            14419,
            17901,
            17901
        ],
        57201: [
            14813,
            14599,
            11814,
            14512,
            18001,
            18001
        ],
        57401: [
            14913,
            14696,
            11918,
            14604,
            18101,
            18101
        ],
        57601: [
            15013,
            14794,
            12021,
            14697,
            18201,
            18201
        ],
        57801: [
            15113,
            14891,
            12125,
            14790,
            18301,
            18301
        ],
        58001: [
            15213,
            14984,
            12224,
            14882,
            18401,
            18401
        ],
        58201: [
            15313,
            15084,
            12330,
            14975,
            18501,
            18501
        ],
        58401: [
            15413,
            15184,
            12436,
            15068,
            18601,
            18601
        ],
        58601: [
            15513,
            15284,
            12542,
            15160,
            18701,
            18701
        ],
        58801: [
            15613,
            15384,
            12648,
            15253,
            18801,
            18801
        ],
        59001: [
            15713,
            15484,
            12754,
            15345,
            18901,
            18901
        ],
        59201: [
            15813,
            15584,
            12860,
            15438,
            19001,
            19001
        ],
        59401: [
            15913,
            15684,
            12966,
            15531,
            19101,
            19101
        ],
        59601: [
            16013,
            15784,
            13072,
            15623,
            19201,
            19201
        ],
        59801: [
            16113,
            15884,
            13178,
            15716,
            19301,
            19301
        ],
        60001: [
            16213,
            15984,
            13284,
            15808,
            19401,
            19401
        ],
        60201: [
            16313,
            16084,
            13390,
            15901,
            19501,
            19501
        ],
        60401: [
            16413,
            16184,
            13496,
            15994,
            19601,
            19601
        ],
        60601: [
            16513,
            16284,
            13602,
            16086,
            19701,
            19701
        ],
        60801: [
            16613,
            16384,
            13708,
            16179,
            19801,
            19801
        ],
        61001: [
            16713,
            16484,
            13814,
            16272,
            19901,
            19901
        ],
        61201: [
            16813,
            16584,
            13920,
            16364,
            20001,
            20001
        ],
        61401: [
            16913,
            16684,
            14026,
            16457,
            20101,
            20101
        ],
        61601: [
            17013,
            16784,
            14132,
            16549,
            20201,
            20201
        ],
        61801: [
            17113,
            16884,
            14238,
            16642,
            20301,
            20301
        ],
        62001: [
            17213,
            16984,
            14344,
            16735,
            20401,
            20401
        ],
        62201: [
            17313,
            17084,
            14450,
            16827,
            20501,
            20501
        ],
        62401: [
            17413,
            17184,
            14556,
            16920,
            20601,
            20601
        ],
        62601: [
            17513,
            17284,
            14662,
            17012,
            20701,
            20701
        ],
        62801: [
            17613,
            17384,
            14768,
            17105,
            20801,
            20801
        ],
        63001: [
            17713,
            17484,
            14874,
            17198,
            20901,
            20901
        ],
        63201: [
            17813,
            17584,
            14980,
            17290,
            21001,
            21001
        ],
        63401: [
            17913,
            17684,
            15086,
            17383,
            21101,
            21101
        ],
        63601: [
            18013,
            17784,
            15192,
            17476,
            21201,
            21201
        ],
        63801: [
            18113,
            17884,
            15298,
            17568,
            21301,
            21301
        ],
        64001: [
            18213,
            17984,
            15404,
            17661,
            21401,
            21401
        ],
        64201: [
            18313,
            18084,
            15510,
            17753,
            21501,
            21501
        ],
        64401: [
            18413,
            18184,
            15616,
            17846,
            21601,
            21601
        ],
        64601: [
            18518,
            18293,
            15731,
            17939,
            21701,
            21701
        ],
        64801: [
            18624,
            18397,
            15841,
            18031,
            21801,
            21801
        ],
        65001: [
            18730,
            18505,
            15955,
            18124,
            21901,
            21901
        ],
        65201: [
            18836,
            18609,
            16065,
            18216,
            22001,
            22001
        ],
        65401: [
            18942,
            18714,
            16175,
            18309,
            22101,
            22101
        ],
        65601: [
            19048,
            18822,
            16290,
            18402,
            22201,
            22201
        ],
        65801: [
            19154,
            18926,
            16400,
            18494,
            22301,
            22301
        ],
        66001: [
            19260,
            19034,
            16514,
            18587,
            22401,
            22401
        ],
        66201: [
            19366,
            19139,
            16624,
            18680,
            22501,
            22501
        ],
        66401: [
            19472,
            19243,
            16735,
            18772,
            22601,
            22601
        ],
        66601: [
            19578,
            19351,
            16849,
            18865,
            22701,
            22701
        ],
        66801: [
            19684,
            19455,
            16959,
            18957,
            22801,
            22801
        ],
        67001: [
            19790,
            19559,
            17069,
            19050,
            22901,
            22901
        ],
        67201: [
            19896,
            19668,
            17184,
            19143,
            23001,
            23001
        ],
        67401: [
            20002,
            19772,
            17294,
            19235,
            23101,
            23101
        ],
        67601: [
            20108,
            19880,
            17408,
            19328,
            23201,
            23201
        ],
        67801: [
            20214,
            19984,
            17518,
            19421,
            23301,
            23301
        ],
        68001: [
            20320,
            20089,
            17628,
            19513,
            23401,
            23401
        ],
        68201: [
            20426,
            20197,
            17743,
            19606,
            23501,
            23501
        ],
        68401: [
            20532,
            20301,
            17853,
            19698,
            23601,
            23601
        ],
        68601: [
            20638,
            20405,
            17963,
            19791,
            23701,
            23701
        ],
        68801: [
            20744,
            20514,
            18077,
            19884,
            23801,
            23801
        ],
        69001: [
            20850,
            20618,
            18188,
            19976,
            23901,
            23901
        ],
        69201: [
            20956,
            20726,
            18302,
            20069,
            24001,
            24001
        ],
        69401: [
            21062,
            20830,
            18412,
            20161,
            24101,
            24101
        ],
        69601: [
            21168,
            20934,
            18522,
            20254,
            24201,
            24201
        ],
        69801: [
            21274,
            21043,
            18637,
            20347,
            24301,
            24301
        ],
        70001: [
            21380,
            21147,
            18747,
            20439,
            24401,
            24401
        ],
        70201: [
            21486,
            21251,
            18857,
            20532,
            24501,
            24501
        ],
        70401: [
            21592,
            21359,
            18971,
            20625,
            24601,
            24601
        ],
        70601: [
            21698,
            21464,
            19081,
            20717,
            24701,
            24701
        ],
        70801: [
            21804,
            21572,
            19196,
            20810,
            24801,
            24801
        ],
        71001: [
            21910,
            21676,
            19306,
            20902,
            24901,
            24901
        ],
        71201: [
            22016,
            21780,
            19416,
            20995,
            25001,
            25001
        ],
        71401: [
            22122,
            21889,
            19530,
            21088,
            25101,
            25101
        ],
        71601: [
            22228,
            21993,
            19641,
            21180,
            25201,
            25201
        ],
        71801: [
            22334,
            22101,
            19755,
            21273,
            25301,
            25301
        ],
        72001: [
            22440,
            22205,
            19865,
            21365,
            25401,
            25401
        ],
        72201: [
            22546,
            22309,
            19975,
            21458,
            25501,
            25501
        ],
        72401: [
            22652,
            22418,
            20090,
            21551,
            25601,
            25601
        ],
        72601: [
            22758,
            22522,
            20200,
            21643,
            25701,
            25701
        ],
        72801: [
            22864,
            22626,
            20310,
            21736,
            25801,
            25801
        ],
        73001: [
            22970,
            22734,
            20424,
            21829,
            25901,
            25901
        ],
        73201: [
            23076,
            22839,
            20534,
            21921,
            26001,
            26001
        ],
        73401: [
            23182,
            22947,
            20649,
            22014,
            26101,
            26101
        ],
        73601: [
            23288,
            23051,
            20759,
            22106,
            26201,
            26201
        ],
        73801: [
            23394,
            23155,
            20869,
            22199,
            26301,
            26301
        ],
        74001: [
            23500,
            23264,
            20983,
            22292,
            26401,
            26401
        ],
        74201: [
            23606,
            23368,
            21094,
            22384,
            26501,
            26501
        ],
        74401: [
            23712,
            23472,
            21204,
            22477,
            26601,
            26601
        ],
        74601: [
            23818,
            23580,
            21318,
            22569,
            26701,
            26701
        ],
        74801: [
            23924,
            23684,
            21428,
            22662,
            26801,
            26801
        ],
        75001: [
            24030,
            23793,
            21543,
            22755,
            26901,
            26901
        ],
        75201: [
            24136,
            23897,
            21653,
            22847,
            27001,
            27001
        ],
        75401: [
            24242,
            24001,
            21763,
            22940,
            27101,
            27101
        ],
        75601: [
            24348,
            24109,
            21877,
            23033,
            27201,
            27201
        ],
        75801: [
            24454,
            24214,
            21987,
            23125,
            27301,
            27301
        ],
        76001: [
            24560,
            24318,
            22098,
            23218,
            27401,
            27401
        ],
        76201: [
            24666,
            24426,
            22212,
            23311,
            27501,
            27501
        ],
        76401: [
            24772,
            24530,
            22322,
            23403,
            27601,
            27601
        ],
        76601: [
            24878,
            24639,
            22436,
            23496,
            27701,
            27701
        ],
        76801: [
            24984,
            24743,
            22547,
            23588,
            27801,
            27801
        ],
        77001: [
            25090,
            24847,
            22657,
            23681,
            27901,
            27901
        ],
        77201: [
            25196,
            24955,
            22771,
            23774,
            28001,
            28001
        ],
        77401: [
            25302,
            25059,
            22881,
            23866,
            28101,
            28101
        ],
        77601: [
            25408,
            25164,
            22991,
            23959,
            28201,
            28201
        ],
        77801: [
            25514,
            25272,
            23106,
            24051,
            28301,
            28301
        ],
        78001: [
            25620,
            25376,
            23216,
            24144,
            28401,
            28401
        ],
        78201: [
            25726,
            25484,
            23330,
            24237,
            28501,
            28501
        ],
        78401: [
            25832,
            25589,
            23440,
            24329,
            28601,
            28601
        ],
        78601: [
            25938,
            25693,
            23551,
            24422,
            28701,
            28701
        ],
        78801: [
            26044,
            25801,
            23665,
            24514,
            28801,
            28801
        ],
        79001: [
            26150,
            25905,
            23775,
            24607,
            28901,
            28901
        ],
        79201: [
            26256,
            26009,
            23885,
            24700,
            29001,
            29001
        ],
        79401: [
            26362,
            26118,
            24000,
            24792,
            29101,
            29101
        ],
        79601: [
            26468,
            26222,
            24110,
            24885,
            29201,
            29201
        ],
        79801: [
            26574,
            26330,
            24224,
            24978,
            29301,
            29301
        ],
        80001: [
            33,
            33,
            30,
            31,
            37,
            37,
            '%'
        ],
        80601: [
            33,
            33,
            31,
            31,
            37,
            37,
            '%'
        ],
        81001: [
            34,
            33,
            31,
            31,
            37,
            37,
            '%'
        ],
        81401: [
            34,
            33,
            31,
            32,
            37,
            37,
            '%'
        ],
        82401: [
            34,
            34,
            31,
            32,
            37,
            37,
            '%'
        ],
        84001: [
            34,
            34,
            32,
            32,
            37,
            37,
            '%'
        ],
        85401: [
            35,
            34,
            32,
            32,
            38,
            38,
            '%'
        ],
        86801: [
            35,
            35,
            32,
            32,
            38,
            38,
            '%'
        ],
        87401: [
            35,
            35,
            32,
            33,
            38,
            38,
            '%'
        ],
        87601: [
            35,
            35,
            33,
            33,
            38,
            38,
            '%'
        ],
        90401: [
            36,
            35,
            33,
            33,
            38,
            38,
            '%'
        ],
        91401: [
            36,
            35,
            34,
            33,
            38,
            38,
            '%'
        ],
        91801: [
            36,
            36,
            34,
            33,
            38,
            38,
            '%'
        ],
        93001: [
            36,
            36,
            34,
            33,
            39,
            39,
            '%'
        ],
        94201: [
            36,
            36,
            34,
            34,
            39,
            39,
            '%'
        ],
        95801: [
            37,
            36,
            35,
            34,
            39,
            39,
            '%'
        ],
        97401: [
            37,
            37,
            35,
            34,
            39,
            39,
            '%'
        ],
        100401: [
            37,
            37,
            36,
            34,
            39,
            39,
            '%'
        ],
        101801: [
            37,
            37,
            36,
            34,
            40,
            40,
            '%'
        ],
        102001: [
            38,
            37,
            36,
            34,
            40,
            40,
            '%'
        ],
        102201: [
            38,
            37,
            36,
            35,
            40,
            40,
            '%'
        ],
        103801: [
            38,
            38,
            36,
            35,
            40,
            40,
            '%'
        ],
        105601: [
            38,
            38,
            37,
            35,
            40,
            40,
            '%'
        ],
        109001: [
            39,
            38,
            37,
            35,
            40,
            40,
            '%'
        ],
        111001: [
            39,
            39,
            37,
            35,
            40,
            40,
            '%'
        ],
        111401: [
            39,
            39,
            38,
            35,
            40,
            40,
            '%'
        ],
        111601: [
            39,
            39,
            38,
            36,
            40,
            40,
            '%'
        ],
        112601: [
            39,
            39,
            38,
            36,
            41,
            41,
            '%'
        ],
        117201: [
            40,
            39,
            38,
            36,
            41,
            41,
            '%'
        ],
        117801: [
            40,
            39,
            39,
            36,
            41,
            41,
            '%'
        ],
        119401: [
            40,
            40,
            39,
            36,
            41,
            41,
            '%'
        ],
        123001: [
            40,
            40,
            39,
            37,
            41,
            41,
            '%'
        ],
        125001: [
            40,
            40,
            40,
            37,
            41,
            41,
            '%'
        ],
        125801: [
            40,
            40,
            40,
            37,
            42,
            42,
            '%'
        ],
        126601: [
            41,
            40,
            40,
            37,
            42,
            42,
            '%'
        ],
        129001: [
            41,
            41,
            40,
            37,
            42,
            42,
            '%'
        ],
        133201: [
            41,
            41,
            41,
            37,
            42,
            42,
            '%'
        ],
        137001: [
            41,
            41,
            41,
            38,
            42,
            42,
            '%'
        ],
        137601: [
            42,
            41,
            41,
            38,
            42,
            42,
            '%'
        ],
        140401: [
            42,
            42,
            41,
            38,
            42,
            42,
            '%'
        ],
        142401: [
            42,
            42,
            42,
            38,
            42,
            42,
            '%'
        ],
        142601: [
            42,
            42,
            42,
            38,
            43,
            43,
            '%'
        ],
        150601: [
            43,
            42,
            42,
            38,
            43,
            43,
            '%'
        ],
        153801: [
            43,
            43,
            43,
            38,
            43,
            43,
            '%'
        ],
        154401: [
            43,
            43,
            43,
            39,
            43,
            43,
            '%'
        ],
        164401: [
            43,
            43,
            43,
            39,
            44,
            44,
            '%'
        ],
        166401: [
            44,
            43,
            43,
            39,
            44,
            44,
            '%'
        ],
        170401: [
            44,
            44,
            44,
            39,
            44,
            44,
            '%'
        ],
        177201: [
            44,
            44,
            44,
            40,
            44,
            44,
            '%'
        ],
        194401: [
            45,
            45,
            45,
            40,
            45,
            45,
            '%'
        ],
        207601: [
            45,
            45,
            45,
            41,
            45,
            45,
            '%'
        ],
        237601: [
            46,
            46,
            46,
            41,
            46,
            46,
            '%'
        ],
        250801: [
            46,
            46,
            46,
            42,
            46,
            46,
            '%'
        ],
        305601: [
            47,
            47,
            47,
            42,
            47,
            47,
            '%'
        ],
        316801: [
            47,
            47,
            47,
            43,
            47,
            47,
            '%'
        ],
        427801: [
            48,
            48,
            48,
            43,
            48,
            48,
            '%'
        ],
        429601: [
            48,
            48,
            48,
            44,
            48,
            48,
            '%'
        ],
        667201: [
            48,
            48,
            48,
            45,
            48,
            48,
            '%'
        ],
        713201: [
            49,
            49,
            49,
            45,
            49,
            49,
            '%'
        ],
        1492601: [
            49,
            49,
            49,
            46,
            49,
            49,
            '%'
        ]
    },
    31: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            23
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            55
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            87
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            119
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            151
        ],
        2501: [
            195,
            0,
            183,
            12,
            195,
            183
        ],
        2601: [
            206,
            0,
            191,
            14,
            215,
            215
        ],
        2701: [
            216,
            0,
            200,
            16,
            247,
            247
        ],
        2801: [
            218,
            0,
            200,
            18,
            279,
            279
        ],
        2901: [
            229,
            0,
            208,
            21,
            311,
            311
        ],
        3001: [
            239,
            0,
            216,
            23,
            343,
            343
        ],
        3101: [
            250,
            0,
            225,
            25,
            375,
            375
        ],
        3201: [
            260,
            0,
            233,
            27,
            407,
            407
        ],
        3301: [
            271,
            0,
            241,
            29,
            439,
            439
        ],
        3401: [
            273,
            0,
            241,
            31,
            471,
            471
        ],
        3501: [
            284,
            0,
            250,
            34,
            503,
            503
        ],
        3601: [
            294,
            0,
            258,
            36,
            535,
            535
        ],
        3701: [
            305,
            0,
            266,
            38,
            567,
            567
        ],
        3801: [
            315,
            0,
            275,
            40,
            600,
            600
        ],
        3901: [
            325,
            0,
            283,
            42,
            631,
            631
        ],
        4001: [
            328,
            0,
            283,
            44,
            663,
            663
        ],
        4101: [
            338,
            0,
            291,
            46,
            695,
            695
        ],
        4201: [
            349,
            0,
            300,
            49,
            727,
            727
        ],
        4301: [
            359,
            0,
            308,
            62,
            759,
            759
        ],
        4401: [
            370,
            0,
            316,
            83,
            791,
            791
        ],
        4501: [
            380,
            0,
            325,
            105,
            823,
            823
        ],
        4601: [
            382,
            0,
            325,
            127,
            855,
            855
        ],
        4701: [
            392,
            0,
            333,
            149,
            882,
            882
        ],
        4801: [
            403,
            0,
            341,
            170,
            909,
            909
        ],
        4901: [
            413,
            0,
            350,
            192,
            935,
            935
        ],
        5001: [
            423,
            0,
            358,
            213,
            959,
            959
        ],
        5101: [
            433,
            0,
            366,
            234,
            986,
            986
        ],
        5201: [
            443,
            0,
            375,
            256,
            1010,
            1010
        ],
        5301: [
            445,
            21,
            376,
            277,
            1037,
            1037
        ],
        5401: [
            455,
            45,
            386,
            299,
            1063,
            1063
        ],
        5501: [
            465,
            69,
            396,
            319,
            1087,
            1087
        ],
        5601: [
            475,
            93,
            406,
            340,
            1113,
            1113
        ],
        5701: [
            485,
            117,
            416,
            361,
            1136,
            1136
        ],
        5801: [
            495,
            141,
            426,
            382,
            1163,
            1163
        ],
        5901: [
            497,
            165,
            427,
            403,
            1189,
            1189
        ],
        6001: [
            507,
            189,
            437,
            423,
            1212,
            1212
        ],
        6101: [
            517,
            213,
            447,
            444,
            1238,
            1238
        ],
        6201: [
            527,
            234,
            457,
            465,
            1262,
            1262
        ],
        6301: [
            537,
            258,
            467,
            486,
            1288,
            1288
        ],
        6401: [
            547,
            282,
            477,
            507,
            1314,
            1314
        ],
        6501: [
            549,
            306,
            479,
            527,
            1337,
            1337
        ],
        6601: [
            559,
            330,
            488,
            548,
            1363,
            1363
        ],
        6701: [
            569,
            354,
            498,
            569,
            1386,
            1386
        ],
        6801: [
            579,
            378,
            508,
            590,
            1413,
            1413
        ],
        6901: [
            589,
            402,
            518,
            611,
            1439,
            1439
        ],
        7001: [
            599,
            426,
            528,
            631,
            1462,
            1462
        ],
        7101: [
            612,
            450,
            530,
            652,
            1488,
            1488
        ],
        7201: [
            632,
            474,
            540,
            673,
            1511,
            1511
        ],
        7301: [
            651,
            498,
            550,
            694,
            1538,
            1538
        ],
        7401: [
            671,
            519,
            560,
            715,
            1564,
            1564
        ],
        7501: [
            690,
            543,
            570,
            736,
            1587,
            1587
        ],
        7601: [
            709,
            567,
            580,
            756,
            1613,
            1613
        ],
        7701: [
            729,
            591,
            589,
            777,
            1636,
            1636
        ],
        7801: [
            748,
            615,
            591,
            798,
            1663,
            1663
        ],
        7901: [
            768,
            639,
            601,
            819,
            1689,
            1689
        ],
        8001: [
            787,
            663,
            611,
            840,
            1712,
            1712
        ],
        8101: [
            807,
            687,
            621,
            860,
            1738,
            1738
        ],
        8201: [
            826,
            711,
            631,
            881,
            1761,
            1761
        ],
        8301: [
            845,
            735,
            641,
            902,
            1788,
            1788
        ],
        8401: [
            865,
            759,
            642,
            923,
            1814,
            1814
        ],
        8501: [
            884,
            783,
            652,
            944,
            1837,
            1837
        ],
        8601: [
            904,
            805,
            662,
            964,
            1863,
            1863
        ],
        8701: [
            923,
            829,
            672,
            985,
            1886,
            1886
        ],
        8801: [
            942,
            853,
            682,
            1006,
            1913,
            1913
        ],
        8901: [
            962,
            877,
            692,
            1027,
            1939,
            1939
        ],
        9001: [
            981,
            901,
            694,
            1048,
            1962,
            1962
        ],
        9101: [
            1001,
            925,
            704,
            1069,
            1988,
            1988
        ],
        9201: [
            1020,
            949,
            714,
            1089,
            2011,
            2011
        ],
        9301: [
            1039,
            965,
            723,
            1110,
            2038,
            2038
        ],
        9401: [
            1059,
            986,
            733,
            1131,
            2064,
            2064
        ],
        9501: [
            1078,
            1005,
            742,
            1152,
            2087,
            2087
        ],
        9601: [
            1098,
            1023,
            744,
            1173,
            2113,
            2113
        ],
        9701: [
            1117,
            1042,
            753,
            1193,
            2136,
            2136
        ],
        9801: [
            1136,
            1063,
            763,
            1214,
            2163,
            2163
        ],
        9901: [
            1156,
            1082,
            773,
            1235,
            2189,
            2189
        ],
        10001: [
            1175,
            1100,
            782,
            1256,
            2212,
            2212
        ],
        10101: [
            1195,
            1118,
            792,
            1277,
            2238,
            2238
        ],
        10201: [
            1214,
            1139,
            802,
            1297,
            2261,
            2261
        ],
        10301: [
            1233,
            1157,
            803,
            1318,
            2288,
            2288
        ],
        10401: [
            1253,
            1176,
            812,
            1339,
            2314,
            2314
        ],
        10501: [
            1272,
            1194,
            822,
            1360,
            2337,
            2337
        ],
        10601: [
            1292,
            1215,
            832,
            1381,
            2363,
            2363
        ],
        10701: [
            1311,
            1233,
            841,
            1401,
            2386,
            2386
        ],
        10801: [
            1331,
            1251,
            851,
            1422,
            2413,
            2413
        ],
        10901: [
            1350,
            1269,
            852,
            1443,
            2439,
            2439
        ],
        11001: [
            1369,
            1290,
            862,
            1464,
            2462,
            2462
        ],
        11101: [
            1389,
            1308,
            872,
            1485,
            2488,
            2488
        ],
        11201: [
            1408,
            1327,
            881,
            1505,
            2511,
            2511
        ],
        11301: [
            1428,
            1347,
            891,
            1526,
            2538,
            2538
        ],
        11401: [
            1447,
            1366,
            901,
            1547,
            2564,
            2564
        ],
        11501: [
            1466,
            1384,
            902,
            1568,
            2587,
            2587
        ],
        11601: [
            1486,
            1402,
            911,
            1589,
            2613,
            2613
        ],
        11701: [
            1505,
            1423,
            921,
            1609,
            2636,
            2636
        ],
        11801: [
            1525,
            1441,
            931,
            1630,
            2663,
            2663
        ],
        11901: [
            1544,
            1459,
            940,
            1651,
            2689,
            2689
        ],
        12001: [
            1563,
            1478,
            950,
            1672,
            2712,
            2712
        ],
        12101: [
            1583,
            1498,
            951,
            1693,
            2738,
            2738
        ],
        12201: [
            1602,
            1517,
            961,
            1713,
            2761,
            2761
        ],
        12301: [
            1622,
            1535,
            971,
            1734,
            2788,
            2788
        ],
        12401: [
            1641,
            1553,
            980,
            1755,
            2814,
            2814
        ],
        12501: [
            1660,
            1574,
            990,
            1776,
            2837,
            2837
        ],
        12601: [
            1680,
            1592,
            1000,
            1797,
            2863,
            2863
        ],
        12701: [
            1699,
            1610,
            1009,
            1818,
            2886,
            2886
        ],
        12801: [
            1719,
            1629,
            1010,
            1838,
            2913,
            2913
        ],
        12901: [
            1738,
            1649,
            1020,
            1859,
            2936,
            2936
        ],
        13001: [
            1758,
            1668,
            1030,
            1880,
            2967,
            2967
        ],
        13101: [
            1777,
            1686,
            1039,
            1901,
            2998,
            2998
        ],
        13201: [
            1797,
            1707,
            1049,
            1922,
            3030,
            3030
        ],
        13301: [
            1817,
            1725,
            1059,
            1943,
            3061,
            3061
        ],
        13401: [
            1836,
            1743,
            1060,
            1965,
            3092,
            3092
        ],
        13501: [
            1856,
            1761,
            1070,
            1986,
            3123,
            3123
        ],
        13601: [
            1876,
            1782,
            1079,
            2007,
            3155,
            3155
        ],
        13701: [
            1896,
            1801,
            1089,
            2028,
            3186,
            3186
        ],
        13801: [
            1915,
            1819,
            1099,
            2048,
            3217,
            3217
        ],
        13901: [
            1933,
            1837,
            1108,
            2068,
            3247,
            3247
        ],
        14001: [
            1952,
            1858,
            1110,
            2089,
            3277,
            3277
        ],
        14101: [
            1971,
            1876,
            1119,
            2109,
            3307,
            3307
        ],
        14201: [
            1989,
            1894,
            1129,
            2129,
            3338,
            3338
        ],
        14301: [
            2008,
            1913,
            1138,
            2149,
            3368,
            3368
        ],
        14401: [
            2027,
            1933,
            1148,
            2169,
            3398,
            3398
        ],
        14501: [
            2045,
            1952,
            1158,
            2189,
            3428,
            3428
        ],
        14601: [
            2064,
            1970,
            1159,
            2209,
            3459,
            3459
        ],
        14701: [
            2083,
            1988,
            1169,
            2229,
            3489,
            3489
        ],
        14801: [
            2102,
            2009,
            1178,
            2249,
            3522,
            3522
        ],
        14901: [
            2120,
            2027,
            1188,
            2269,
            3554,
            3554
        ],
        15001: [
            2139,
            2045,
            1198,
            2290,
            3587,
            3587
        ],
        15101: [
            2158,
            2064,
            1207,
            2310,
            3620,
            3620
        ],
        15201: [
            2177,
            2084,
            1217,
            2330,
            3655,
            3655
        ],
        15301: [
            2195,
            2103,
            1218,
            2350,
            3688,
            3688
        ],
        15401: [
            2215,
            2123,
            1228,
            2372,
            3721,
            3721
        ],
        15501: [
            2241,
            2149,
            1238,
            2399,
            3754,
            3754
        ],
        15601: [
            2266,
            2176,
            1248,
            2425,
            3786,
            3786
        ],
        15701: [
            2292,
            2202,
            1258,
            2452,
            3822,
            3822
        ],
        15801: [
            2317,
            2228,
            1268,
            2478,
            3854,
            3854
        ],
        15901: [
            2342,
            2254,
            1270,
            2505,
            3887,
            3887
        ],
        16001: [
            2368,
            2280,
            1280,
            2531,
            3920,
            3920
        ],
        16101: [
            2393,
            2306,
            1291,
            2558,
            3953,
            3953
        ],
        16201: [
            2418,
            2332,
            1301,
            2584,
            3988,
            3988
        ],
        16301: [
            2444,
            2358,
            1311,
            2611,
            4021,
            4021
        ],
        16401: [
            2469,
            2384,
            1321,
            2637,
            4054,
            4054
        ],
        16501: [
            2495,
            2410,
            1323,
            2664,
            4086,
            4086
        ],
        16601: [
            2520,
            2436,
            1333,
            2690,
            4119,
            4119
        ],
        16701: [
            2545,
            2462,
            1343,
            2717,
            4154,
            4154
        ],
        16801: [
            2571,
            2488,
            1353,
            2743,
            4187,
            4187
        ],
        16901: [
            2596,
            2514,
            1363,
            2769,
            4220,
            4220
        ],
        17001: [
            2622,
            2538,
            1373,
            2796,
            4253,
            4253
        ],
        17101: [
            2647,
            2564,
            1375,
            2822,
            4286,
            4286
        ],
        17201: [
            2672,
            2590,
            1385,
            2849,
            4321,
            4321
        ],
        17301: [
            2698,
            2616,
            1395,
            2875,
            4354,
            4354
        ],
        17401: [
            2723,
            2642,
            1405,
            2902,
            4386,
            4386
        ],
        17501: [
            2748,
            2668,
            1416,
            2928,
            4419,
            4419
        ],
        17601: [
            2774,
            2694,
            1426,
            2955,
            4452,
            4452
        ],
        17701: [
            2799,
            2720,
            1436,
            2981,
            4487,
            4487
        ],
        17801: [
            2825,
            2746,
            1438,
            3008,
            4520,
            4520
        ],
        17901: [
            2850,
            2772,
            1448,
            3034,
            4553,
            4553
        ],
        18001: [
            2875,
            2798,
            1458,
            3061,
            4585,
            4585
        ],
        18101: [
            2901,
            2824,
            1468,
            3087,
            4618,
            4618
        ],
        18201: [
            2926,
            2850,
            1478,
            3114,
            4653,
            4653
        ],
        18301: [
            2952,
            2876,
            1488,
            3140,
            4686,
            4686
        ],
        18401: [
            2977,
            2902,
            1490,
            3167,
            4719,
            4719
        ],
        18501: [
            3002,
            2928,
            1500,
            3193,
            4752,
            4752
        ],
        18601: [
            3028,
            2954,
            1510,
            3219,
            4785,
            4785
        ],
        18701: [
            3053,
            2980,
            1520,
            3246,
            4820,
            4820
        ],
        18801: [
            3079,
            3006,
            1531,
            3273,
            4853,
            4853
        ],
        18901: [
            3104,
            3032,
            1541,
            3299,
            4885,
            4885
        ],
        19001: [
            3129,
            3058,
            1543,
            3325,
            4918,
            4918
        ],
        19101: [
            3155,
            3084,
            1553,
            3352,
            4951,
            4951
        ],
        19201: [
            3180,
            3110,
            1563,
            3378,
            4986,
            4986
        ],
        19301: [
            3206,
            3136,
            1573,
            3405,
            5019,
            5019
        ],
        19401: [
            3231,
            3163,
            1583,
            3431,
            5052,
            5052
        ],
        19501: [
            3256,
            3189,
            1593,
            3458,
            5085,
            5085
        ],
        19601: [
            3282,
            3214,
            1595,
            3484,
            5117,
            5117
        ],
        19701: [
            3307,
            3239,
            1604,
            3511,
            5153,
            5153
        ],
        19801: [
            3332,
            3265,
            1613,
            3537,
            5185,
            5185
        ],
        19901: [
            3358,
            3290,
            1622,
            3564,
            5218,
            5218
        ],
        20001: [
            3409,
            3340,
            1641,
            3617,
            5284,
            5284
        ],
        20201: [
            3459,
            3388,
            1651,
            3670,
            5352,
            5352
        ],
        20401: [
            3510,
            3438,
            1670,
            3722,
            5417,
            5417
        ],
        20601: [
            3561,
            3489,
            1688,
            3775,
            5485,
            5485
        ],
        20801: [
            3612,
            3539,
            1699,
            3828,
            5551,
            5551
        ],
        21001: [
            3662,
            3590,
            1717,
            3881,
            5616,
            5616
        ],
        21201: [
            3713,
            3640,
            1736,
            3934,
            5685,
            5685
        ],
        21401: [
            3764,
            3691,
            1746,
            3987,
            5750,
            5750
        ],
        21601: [
            3815,
            3741,
            1765,
            4040,
            5818,
            5818
        ],
        21801: [
            3866,
            3791,
            1783,
            4093,
            5884,
            5884
        ],
        22001: [
            3916,
            3842,
            1794,
            4146,
            5949,
            5949
        ],
        22201: [
            3967,
            3892,
            1812,
            4199,
            6017,
            6017
        ],
        22401: [
            4018,
            3943,
            1831,
            4252,
            6083,
            6083
        ],
        22601: [
            4069,
            3993,
            1849,
            4305,
            6151,
            6151
        ],
        22801: [
            4120,
            4043,
            1860,
            4359,
            6217,
            6217
        ],
        23001: [
            4173,
            4094,
            1878,
            4413,
            6285,
            6285
        ],
        23201: [
            4225,
            4144,
            1897,
            4468,
            6354,
            6354
        ],
        23401: [
            4278,
            4192,
            1907,
            4522,
            6421,
            6421
        ],
        23601: [
            4330,
            4243,
            1926,
            4577,
            6491,
            6491
        ],
        23801: [
            4383,
            4290,
            1944,
            4632,
            6558,
            6558
        ],
        24001: [
            4435,
            4343,
            1954,
            4686,
            6626,
            6626
        ],
        24201: [
            4487,
            4394,
            1973,
            4741,
            6695,
            6695
        ],
        24401: [
            4540,
            4447,
            1992,
            4795,
            6762,
            6762
        ],
        24601: [
            4592,
            4497,
            2002,
            4850,
            6832,
            6832
        ],
        24801: [
            4645,
            4548,
            2021,
            4905,
            6899,
            6899
        ],
        25001: [
            4697,
            4601,
            2039,
            4959,
            6967,
            6967
        ],
        25201: [
            4750,
            4651,
            2050,
            5014,
            7036,
            7036
        ],
        25401: [
            4802,
            4701,
            2068,
            5068,
            7103,
            7103
        ],
        25601: [
            4854,
            4754,
            2087,
            5123,
            7173,
            7173
        ],
        25801: [
            4907,
            4805,
            2097,
            5178,
            7240,
            7240
        ],
        26001: [
            4959,
            4855,
            2116,
            5232,
            7308,
            7308
        ],
        26201: [
            5012,
            4908,
            2134,
            5287,
            7377,
            7377
        ],
        26401: [
            5064,
            4959,
            2145,
            5341,
            7444,
            7444
        ],
        26601: [
            5117,
            5011,
            2163,
            5396,
            7514,
            7514
        ],
        26801: [
            5169,
            5062,
            2182,
            5451,
            7581,
            7581
        ],
        27001: [
            5221,
            5112,
            2192,
            5505,
            7649,
            7649
        ],
        27201: [
            5274,
            5165,
            2211,
            5560,
            7718,
            7718
        ],
        27401: [
            5326,
            5216,
            2230,
            5614,
            7785,
            7785
        ],
        27601: [
            5379,
            5266,
            2261,
            5669,
            7855,
            7855
        ],
        27801: [
            5431,
            5319,
            2314,
            5724,
            7922,
            7922
        ],
        28001: [
            5483,
            5369,
            2365,
            5778,
            7990,
            7990
        ],
        28201: [
            5536,
            5422,
            2418,
            5833,
            8059,
            8059
        ],
        28401: [
            5588,
            5473,
            2468,
            5887,
            8126,
            8126
        ],
        28601: [
            5641,
            5523,
            2518,
            5942,
            8196,
            8196
        ],
        28801: [
            5693,
            5576,
            2571,
            5997,
            8263,
            8263
        ],
        29001: [
            5746,
            5627,
            2622,
            6051,
            8331,
            8331
        ],
        29201: [
            5798,
            5677,
            2672,
            6106,
            8400,
            8400
        ],
        29401: [
            5850,
            5730,
            2725,
            6160,
            8467,
            8467
        ],
        29601: [
            5903,
            5780,
            2775,
            6215,
            8537,
            8537
        ],
        29801: [
            5955,
            5833,
            2828,
            6269,
            8604,
            8604
        ],
        30001: [
            6008,
            5884,
            2879,
            6324,
            8672,
            8672
        ],
        30201: [
            6060,
            5934,
            2929,
            6379,
            8741,
            8741
        ],
        30401: [
            6113,
            5987,
            2982,
            6433,
            8808,
            8808
        ],
        30601: [
            6165,
            6037,
            3033,
            6488,
            8878,
            8878
        ],
        30801: [
            6217,
            6088,
            3083,
            6542,
            8945,
            8945
        ],
        31001: [
            6270,
            6141,
            3136,
            6597,
            9013,
            9013
        ],
        31201: [
            6322,
            6192,
            3187,
            6652,
            9082,
            9082
        ],
        31401: [
            6375,
            6243,
            3238,
            6706,
            9149,
            9149
        ],
        31601: [
            6427,
            6297,
            3293,
            6761,
            9219,
            9219
        ],
        31801: [
            6480,
            6349,
            3344,
            6815,
            9286,
            9286
        ],
        32001: [
            6532,
            6403,
            3399,
            6870,
            9354,
            9354
        ],
        32201: [
            6585,
            6455,
            3450,
            6925,
            9423,
            9423
        ],
        32401: [
            6637,
            6507,
            3502,
            6979,
            9490,
            9490
        ],
        32601: [
            6689,
            6561,
            3556,
            7034,
            9560,
            9560
        ],
        32801: [
            6742,
            6613,
            3608,
            7088,
            9627,
            9627
        ],
        33001: [
            6794,
            6664,
            3659,
            7143,
            9695,
            9695
        ],
        33201: [
            6847,
            6719,
            3714,
            7198,
            9764,
            9764
        ],
        33401: [
            6899,
            6770,
            3765,
            7252,
            9831,
            9831
        ],
        33601: [
            6952,
            6824,
            3820,
            7307,
            9901,
            9901
        ],
        33801: [
            7004,
            6876,
            3871,
            7361,
            9968,
            9968
        ],
        34001: [
            7056,
            6928,
            3923,
            7416,
            10036,
            10036
        ],
        34201: [
            7109,
            6982,
            3977,
            7471,
            10105,
            10105
        ],
        34401: [
            7161,
            7034,
            4029,
            7525,
            10172,
            10172
        ],
        34601: [
            7214,
            7085,
            4081,
            7580,
            10242,
            10242
        ],
        34801: [
            7266,
            7140,
            4135,
            7634,
            10309,
            10309
        ],
        35001: [
            7318,
            7191,
            4186,
            7689,
            10377,
            10377
        ],
        35201: [
            7371,
            7243,
            4238,
            7744,
            10446,
            10446
        ],
        35401: [
            7423,
            7297,
            4292,
            7798,
            10513,
            10513
        ],
        35601: [
            7476,
            7349,
            4344,
            7853,
            10583,
            10583
        ],
        35801: [
            7528,
            7403,
            4398,
            7907,
            10650,
            10650
        ],
        36001: [
            7581,
            7455,
            4450,
            7962,
            10718,
            10718
        ],
        36201: [
            7633,
            7506,
            4502,
            8016,
            10787,
            10787
        ],
        36401: [
            7685,
            7561,
            4556,
            8071,
            10854,
            10854
        ],
        36601: [
            7738,
            7612,
            4608,
            8126,
            10924,
            10924
        ],
        36801: [
            7790,
            7664,
            4659,
            8180,
            10991,
            10991
        ],
        37001: [
            7843,
            7718,
            4713,
            8235,
            11059,
            11059
        ],
        37201: [
            7895,
            7770,
            4765,
            8289,
            11128,
            11128
        ],
        37401: [
            7948,
            7824,
            4819,
            8344,
            11195,
            11195
        ],
        37601: [
            8000,
            7873,
            4868,
            8398,
            11260,
            11260
        ],
        37801: [
            8052,
            7925,
            4920,
            8453,
            11322,
            11322
        ],
        38001: [
            8104,
            7977,
            4972,
            8507,
            11384,
            11384
        ],
        38201: [
            8156,
            8031,
            5026,
            8562,
            11446,
            11446
        ],
        38401: [
            8210,
            8083,
            5078,
            8616,
            11508,
            11508
        ],
        38601: [
            8272,
            8145,
            5140,
            8670,
            11570,
            11570
        ],
        38801: [
            8334,
            8207,
            5202,
            8725,
            11632,
            11632
        ],
        39001: [
            8396,
            8269,
            5264,
            8779,
            11694,
            11694
        ],
        39201: [
            8458,
            8331,
            5326,
            8833,
            11756,
            11756
        ],
        39401: [
            8520,
            8393,
            5388,
            8888,
            11818,
            11818
        ],
        39601: [
            8582,
            8455,
            5450,
            8942,
            11880,
            11880
        ],
        39801: [
            8644,
            8517,
            5512,
            8996,
            11942,
            11942
        ],
        40001: [
            8706,
            8579,
            5574,
            9051,
            12004,
            12004
        ],
        40201: [
            8768,
            8641,
            5636,
            9105,
            12066,
            12066
        ],
        40401: [
            8830,
            8703,
            5698,
            9159,
            12128,
            12128
        ],
        40601: [
            8892,
            8765,
            5760,
            9214,
            12190,
            12190
        ],
        40801: [
            8954,
            8827,
            5822,
            9268,
            12252,
            12252
        ],
        41001: [
            9016,
            8889,
            5884,
            9323,
            12314,
            12314
        ],
        41201: [
            9078,
            8951,
            5946,
            9377,
            12376,
            12376
        ],
        41401: [
            9140,
            9013,
            6008,
            9431,
            12438,
            12438
        ],
        41601: [
            9202,
            9075,
            6070,
            9486,
            12500,
            12500
        ],
        41801: [
            9264,
            9137,
            6132,
            9540,
            12562,
            12562
        ],
        42001: [
            9326,
            9199,
            6194,
            9594,
            12624,
            12624
        ],
        42201: [
            9388,
            9261,
            6256,
            9649,
            12686,
            12686
        ],
        42401: [
            9450,
            9323,
            6318,
            9703,
            12748,
            12748
        ],
        42601: [
            9512,
            9385,
            6380,
            9757,
            12810,
            12810
        ],
        42801: [
            9574,
            9447,
            6442,
            9812,
            12872,
            12872
        ],
        43001: [
            9636,
            9509,
            6504,
            9866,
            12934,
            12934
        ],
        43201: [
            9698,
            9571,
            6566,
            9921,
            12996,
            12996
        ],
        43401: [
            9760,
            9633,
            6628,
            9975,
            13058,
            13058
        ],
        43601: [
            9822,
            9695,
            6690,
            10029,
            13120,
            13120
        ],
        43801: [
            9884,
            9757,
            6752,
            10084,
            13182,
            13182
        ],
        44001: [
            9946,
            9819,
            6814,
            10138,
            13244,
            13244
        ],
        44201: [
            10008,
            9881,
            6876,
            10192,
            13306,
            13306
        ],
        44401: [
            10070,
            9943,
            6938,
            10247,
            13368,
            13368
        ],
        44601: [
            10132,
            10005,
            7000,
            10301,
            13430,
            13430
        ],
        44801: [
            10194,
            10067,
            7062,
            10355,
            13492,
            13492
        ],
        45001: [
            10256,
            10129,
            7124,
            10410,
            13554,
            13554
        ],
        45201: [
            10318,
            10191,
            7186,
            10464,
            13616,
            13616
        ],
        45401: [
            10380,
            10253,
            7248,
            10519,
            13678,
            13678
        ],
        45601: [
            10442,
            10315,
            7310,
            10573,
            13740,
            13740
        ],
        45801: [
            10504,
            10377,
            7372,
            10627,
            13802,
            13802
        ],
        46001: [
            10566,
            10439,
            7434,
            10682,
            13864,
            13864
        ],
        46201: [
            10628,
            10501,
            7496,
            10736,
            13926,
            13926
        ],
        46401: [
            10690,
            10563,
            7558,
            10790,
            13988,
            13988
        ],
        46601: [
            10752,
            10625,
            7620,
            10845,
            14050,
            14050
        ],
        46801: [
            10814,
            10687,
            7682,
            10899,
            14112,
            14112
        ],
        47001: [
            10876,
            10749,
            7744,
            10953,
            14174,
            14174
        ],
        47201: [
            10938,
            10811,
            7806,
            11008,
            14236,
            14236
        ],
        47401: [
            11000,
            10873,
            7868,
            11062,
            14298,
            14298
        ],
        47601: [
            11062,
            10935,
            7930,
            11116,
            14360,
            14360
        ],
        47801: [
            11124,
            10997,
            7992,
            11171,
            14422,
            14422
        ],
        48001: [
            11186,
            11059,
            8054,
            11225,
            14484,
            14484
        ],
        48201: [
            11248,
            11121,
            8116,
            11280,
            14546,
            14546
        ],
        48401: [
            11310,
            11183,
            8178,
            11334,
            14608,
            14608
        ],
        48601: [
            11372,
            11245,
            8240,
            11388,
            14670,
            14670
        ],
        48801: [
            11434,
            11307,
            8302,
            11443,
            14732,
            14732
        ],
        49001: [
            11496,
            11369,
            8364,
            11497,
            14794,
            14794
        ],
        49201: [
            11558,
            11431,
            8426,
            11551,
            14856,
            14856
        ],
        49401: [
            11620,
            11493,
            8488,
            11606,
            14918,
            14918
        ],
        49601: [
            11682,
            11555,
            8550,
            11660,
            14980,
            14980
        ],
        49801: [
            11744,
            11617,
            8612,
            11714,
            15042,
            15042
        ],
        50001: [
            11806,
            11679,
            8678,
            11769,
            15104,
            15104
        ],
        50201: [
            11868,
            11741,
            8746,
            11823,
            15166,
            15166
        ],
        50401: [
            11930,
            11803,
            8814,
            11878,
            15228,
            15228
        ],
        50601: [
            11992,
            11865,
            8882,
            11932,
            15290,
            15290
        ],
        50801: [
            12054,
            11927,
            8950,
            11986,
            15352,
            15352
        ],
        51001: [
            12116,
            11989,
            9018,
            12041,
            15414,
            15414
        ],
        51201: [
            12203,
            12074,
            9110,
            12120,
            15501,
            15501
        ],
        51401: [
            12305,
            12175,
            9216,
            12214,
            15603,
            15603
        ],
        51601: [
            12407,
            12275,
            9323,
            12309,
            15705,
            15705
        ],
        51801: [
            12509,
            12373,
            9427,
            12403,
            15807,
            15807
        ],
        52001: [
            12611,
            12474,
            9534,
            12497,
            15909,
            15909
        ],
        52201: [
            12713,
            12575,
            9641,
            12592,
            16011,
            16011
        ],
        52401: [
            12815,
            12676,
            9748,
            12686,
            16113,
            16113
        ],
        52601: [
            12917,
            12776,
            9854,
            12781,
            16215,
            16215
        ],
        52801: [
            13019,
            12877,
            9961,
            12875,
            16317,
            16317
        ],
        53001: [
            13121,
            12978,
            10068,
            12969,
            16419,
            16419
        ],
        53201: [
            13223,
            13079,
            10175,
            13064,
            16521,
            16521
        ],
        53401: [
            13325,
            13177,
            10279,
            13158,
            16623,
            16623
        ],
        53601: [
            13427,
            13278,
            10385,
            13252,
            16725,
            16725
        ],
        53801: [
            13529,
            13378,
            10492,
            13347,
            16827,
            16827
        ],
        54001: [
            13631,
            13479,
            10599,
            13441,
            16929,
            16929
        ],
        54201: [
            13733,
            13580,
            10706,
            13535,
            17031,
            17031
        ],
        54401: [
            13835,
            13681,
            10812,
            13630,
            17133,
            17133
        ],
        54601: [
            13937,
            13781,
            10919,
            13724,
            17235,
            17235
        ],
        54801: [
            14039,
            13882,
            11026,
            13818,
            17337,
            17337
        ],
        55001: [
            14141,
            13983,
            11133,
            13913,
            17439,
            17439
        ],
        55201: [
            14243,
            14081,
            11237,
            14007,
            17541,
            17541
        ],
        55401: [
            14345,
            14182,
            11344,
            14102,
            17643,
            17643
        ],
        55601: [
            14447,
            14283,
            11450,
            14196,
            17745,
            17745
        ],
        55801: [
            14549,
            14383,
            11557,
            14290,
            17847,
            17847
        ],
        56001: [
            14651,
            14484,
            11664,
            14385,
            17949,
            17949
        ],
        56201: [
            14753,
            14585,
            11771,
            14479,
            18051,
            18051
        ],
        56401: [
            14855,
            14686,
            11877,
            14573,
            18153,
            18153
        ],
        56601: [
            14957,
            14786,
            11984,
            14668,
            18255,
            18255
        ],
        56801: [
            15059,
            14884,
            12088,
            14762,
            18357,
            18357
        ],
        57001: [
            15161,
            14985,
            12195,
            14856,
            18459,
            18459
        ],
        57201: [
            15263,
            15086,
            12302,
            14951,
            18561,
            18561
        ],
        57401: [
            15365,
            15187,
            12409,
            15045,
            18663,
            18663
        ],
        57601: [
            15467,
            15287,
            12515,
            15140,
            18765,
            18765
        ],
        57801: [
            15569,
            15388,
            12622,
            15234,
            18867,
            18867
        ],
        58001: [
            15671,
            15484,
            12724,
            15328,
            18969,
            18969
        ],
        58201: [
            15773,
            15586,
            12832,
            15423,
            19071,
            19071
        ],
        58401: [
            15875,
            15688,
            12940,
            15517,
            19173,
            19173
        ],
        58601: [
            15977,
            15790,
            13048,
            15611,
            19275,
            19275
        ],
        58801: [
            16079,
            15892,
            13156,
            15706,
            19377,
            19377
        ],
        59001: [
            16181,
            15994,
            13264,
            15800,
            19479,
            19479
        ],
        59201: [
            16283,
            16096,
            13372,
            15894,
            19581,
            19581
        ],
        59401: [
            16385,
            16198,
            13480,
            15989,
            19683,
            19683
        ],
        59601: [
            16487,
            16300,
            13588,
            16083,
            19785,
            19785
        ],
        59801: [
            16589,
            16402,
            13696,
            16178,
            19887,
            19887
        ],
        60001: [
            16691,
            16504,
            13804,
            16272,
            19989,
            19989
        ],
        60201: [
            16793,
            16606,
            13912,
            16366,
            20091,
            20091
        ],
        60401: [
            16895,
            16708,
            14020,
            16461,
            20193,
            20193
        ],
        60601: [
            16997,
            16810,
            14128,
            16555,
            20295,
            20295
        ],
        60801: [
            17099,
            16912,
            14236,
            16649,
            20397,
            20397
        ],
        61001: [
            17201,
            17014,
            14344,
            16744,
            20499,
            20499
        ],
        61201: [
            17303,
            17116,
            14452,
            16838,
            20601,
            20601
        ],
        61401: [
            17405,
            17218,
            14560,
            16932,
            20703,
            20703
        ],
        61601: [
            17507,
            17320,
            14668,
            17027,
            20805,
            20805
        ],
        61801: [
            17609,
            17422,
            14776,
            17121,
            20907,
            20907
        ],
        62001: [
            17711,
            17524,
            14884,
            17215,
            21009,
            21009
        ],
        62201: [
            17813,
            17626,
            14992,
            17310,
            21111,
            21111
        ],
        62401: [
            17915,
            17728,
            15100,
            17404,
            21213,
            21213
        ],
        62601: [
            18017,
            17830,
            15208,
            17499,
            21315,
            21315
        ],
        62801: [
            18119,
            17932,
            15316,
            17593,
            21417,
            21417
        ],
        63001: [
            18221,
            18034,
            15424,
            17687,
            21519,
            21519
        ],
        63201: [
            18323,
            18136,
            15532,
            17782,
            21621,
            21621
        ],
        63401: [
            18425,
            18238,
            15640,
            17876,
            21723,
            21723
        ],
        63601: [
            18527,
            18340,
            15748,
            17970,
            21825,
            21825
        ],
        63801: [
            18629,
            18442,
            15856,
            18065,
            21927,
            21927
        ],
        64001: [
            18731,
            18544,
            15964,
            18159,
            22029,
            22029
        ],
        64201: [
            18833,
            18646,
            16072,
            18253,
            22131,
            22131
        ],
        64401: [
            18935,
            18748,
            16180,
            18348,
            22233,
            22233
        ],
        64601: [
            19041,
            18859,
            16296,
            18442,
            22335,
            22335
        ],
        64801: [
            19149,
            18965,
            16409,
            18537,
            22437,
            22437
        ],
        65001: [
            19257,
            19075,
            16525,
            18631,
            22539,
            22539
        ],
        65201: [
            19365,
            19182,
            16637,
            18725,
            22641,
            22641
        ],
        65401: [
            19473,
            19288,
            16750,
            18820,
            22743,
            22743
        ],
        65601: [
            19581,
            19398,
            16866,
            18914,
            22845,
            22845
        ],
        65801: [
            19689,
            19505,
            16978,
            19008,
            22947,
            22947
        ],
        66001: [
            19797,
            19615,
            17095,
            19103,
            23049,
            23049
        ],
        66201: [
            19905,
            19721,
            17207,
            19197,
            23151,
            23151
        ],
        66401: [
            20013,
            19828,
            17319,
            19291,
            23253,
            23253
        ],
        66601: [
            20121,
            19938,
            17436,
            19386,
            23355,
            23355
        ],
        66801: [
            20229,
            20044,
            17548,
            19480,
            23457,
            23457
        ],
        67001: [
            20337,
            20151,
            17660,
            19575,
            23559,
            23559
        ],
        67201: [
            20445,
            20261,
            17777,
            19669,
            23661,
            23661
        ],
        67401: [
            20553,
            20367,
            17889,
            19763,
            23763,
            23763
        ],
        67601: [
            20661,
            20478,
            18006,
            19858,
            23865,
            23865
        ],
        67801: [
            20769,
            20584,
            18118,
            19952,
            23967,
            23967
        ],
        68001: [
            20877,
            20690,
            18230,
            20046,
            24069,
            24069
        ],
        68201: [
            20985,
            20801,
            18347,
            20141,
            24171,
            24171
        ],
        68401: [
            21093,
            20907,
            18459,
            20235,
            24273,
            24273
        ],
        68601: [
            21201,
            21013,
            18571,
            20329,
            24375,
            24375
        ],
        68801: [
            21309,
            21124,
            18688,
            20424,
            24477,
            24477
        ],
        69001: [
            21417,
            21230,
            18800,
            20518,
            24579,
            24579
        ],
        69201: [
            21525,
            21341,
            18916,
            20612,
            24681,
            24681
        ],
        69401: [
            21633,
            21447,
            19029,
            20707,
            24783,
            24783
        ],
        69601: [
            21741,
            21553,
            19141,
            20801,
            24885,
            24885
        ],
        69801: [
            21849,
            21664,
            19257,
            20896,
            24987,
            24987
        ],
        70001: [
            21957,
            21770,
            19370,
            20990,
            25089,
            25089
        ],
        70201: [
            22065,
            21876,
            19482,
            21084,
            25191,
            25191
        ],
        70401: [
            22173,
            21987,
            19598,
            21179,
            25293,
            25293
        ],
        70601: [
            22281,
            22093,
            19711,
            21273,
            25395,
            25395
        ],
        70801: [
            22389,
            22203,
            19827,
            21367,
            25497,
            25497
        ],
        71001: [
            22497,
            22310,
            19939,
            21462,
            25599,
            25599
        ],
        71201: [
            22605,
            22416,
            20052,
            21556,
            25701,
            25701
        ],
        71401: [
            22713,
            22526,
            20168,
            21650,
            25803,
            25803
        ],
        71601: [
            22821,
            22633,
            20280,
            21745,
            25905,
            25905
        ],
        71801: [
            22929,
            22743,
            20397,
            21839,
            26007,
            26007
        ],
        72001: [
            23037,
            22849,
            20509,
            21934,
            26109,
            26109
        ],
        72201: [
            23145,
            22956,
            20621,
            22028,
            26211,
            26211
        ],
        72401: [
            23253,
            23066,
            20738,
            22122,
            26313,
            26313
        ],
        72601: [
            23361,
            23172,
            20850,
            22217,
            26415,
            26415
        ],
        72801: [
            23469,
            23279,
            20962,
            22311,
            26517,
            26517
        ],
        73001: [
            23577,
            23389,
            21079,
            22405,
            26619,
            26619
        ],
        73201: [
            23685,
            23495,
            21191,
            22500,
            26721,
            26721
        ],
        73401: [
            23793,
            23606,
            21308,
            22594,
            26823,
            26823
        ],
        73601: [
            23901,
            23712,
            21420,
            22688,
            26925,
            26925
        ],
        73801: [
            24009,
            23818,
            21532,
            22783,
            27027,
            27027
        ],
        74001: [
            24117,
            23929,
            21649,
            22877,
            27129,
            27129
        ],
        74201: [
            24225,
            24035,
            21761,
            22972,
            27231,
            27231
        ],
        74401: [
            24333,
            24141,
            21873,
            23066,
            27333,
            27333
        ],
        74601: [
            24441,
            24252,
            21990,
            23160,
            27435,
            27435
        ],
        74801: [
            24549,
            24358,
            22102,
            23255,
            27537,
            27537
        ],
        75001: [
            24657,
            24469,
            22218,
            23349,
            27639,
            27639
        ],
        75201: [
            24765,
            24575,
            22331,
            23443,
            27741,
            27741
        ],
        75401: [
            24873,
            24681,
            22443,
            23538,
            27843,
            27843
        ],
        75601: [
            24981,
            24792,
            22559,
            23632,
            27945,
            27945
        ],
        75801: [
            25089,
            24898,
            22672,
            23726,
            28047,
            28047
        ],
        76001: [
            25197,
            25004,
            22784,
            23821,
            28149,
            28149
        ],
        76201: [
            25305,
            25115,
            22900,
            23915,
            28251,
            28251
        ],
        76401: [
            25413,
            25221,
            23013,
            24009,
            28353,
            28353
        ],
        76601: [
            25521,
            25331,
            23129,
            24104,
            28455,
            28455
        ],
        76801: [
            25629,
            25438,
            23241,
            24198,
            28557,
            28557
        ],
        77001: [
            25737,
            25544,
            23354,
            24293,
            28659,
            28659
        ],
        77201: [
            25845,
            25654,
            23470,
            24387,
            28761,
            28761
        ],
        77401: [
            25953,
            25761,
            23582,
            24481,
            28863,
            28863
        ],
        77601: [
            26061,
            25867,
            23695,
            24576,
            28965,
            28965
        ],
        77801: [
            26169,
            25977,
            23811,
            24670,
            29067,
            29067
        ],
        78001: [
            26277,
            26084,
            23923,
            24764,
            29169,
            29169
        ],
        78201: [
            26385,
            26194,
            24040,
            24859,
            29271,
            29271
        ],
        78401: [
            26493,
            26300,
            24152,
            24953,
            29373,
            29373
        ],
        78601: [
            26601,
            26407,
            24264,
            25047,
            29475,
            29475
        ],
        78801: [
            26709,
            26517,
            24381,
            25142,
            29577,
            29577
        ],
        79001: [
            26817,
            26623,
            24493,
            25236,
            29679,
            29679
        ],
        79201: [
            26925,
            26730,
            24605,
            25331,
            29781,
            29781
        ],
        79401: [
            27033,
            26840,
            24722,
            25425,
            29883,
            29883
        ],
        79601: [
            27141,
            26946,
            24834,
            25519,
            29985,
            29985
        ],
        79801: [
            27249,
            27057,
            24951,
            25614,
            30087,
            30087
        ],
        80001: [
            34,
            34,
            31,
            32,
            38,
            38,
            '%'
        ],
        80801: [
            34,
            34,
            32,
            32,
            38,
            38,
            '%'
        ],
        81601: [
            35,
            34,
            32,
            32,
            38,
            38,
            '%'
        ],
        82601: [
            35,
            35,
            32,
            33,
            38,
            38,
            '%'
        ],
        84201: [
            35,
            35,
            33,
            33,
            38,
            38,
            '%'
        ],
        85601: [
            35,
            35,
            33,
            33,
            39,
            39,
            '%'
        ],
        86201: [
            36,
            35,
            33,
            33,
            39,
            39,
            '%'
        ],
        87201: [
            36,
            36,
            33,
            33,
            39,
            39,
            '%'
        ],
        87801: [
            36,
            36,
            34,
            33,
            39,
            39,
            '%'
        ],
        88601: [
            36,
            36,
            34,
            34,
            39,
            39,
            '%'
        ],
        91001: [
            37,
            36,
            34,
            34,
            39,
            39,
            '%'
        ],
        91801: [
            37,
            36,
            35,
            34,
            39,
            39,
            '%'
        ],
        92201: [
            37,
            37,
            35,
            34,
            39,
            39,
            '%'
        ],
        93001: [
            37,
            37,
            35,
            34,
            40,
            40,
            '%'
        ],
        95601: [
            37,
            37,
            35,
            35,
            40,
            40,
            '%'
        ],
        96001: [
            37,
            37,
            36,
            35,
            40,
            40,
            '%'
        ],
        96601: [
            38,
            37,
            36,
            35,
            40,
            40,
            '%'
        ],
        97801: [
            38,
            38,
            36,
            35,
            40,
            40,
            '%'
        ],
        100801: [
            38,
            38,
            37,
            35,
            40,
            40,
            '%'
        ],
        102001: [
            38,
            38,
            37,
            35,
            41,
            41,
            '%'
        ],
        102801: [
            39,
            38,
            37,
            35,
            41,
            41,
            '%'
        ],
        103801: [
            39,
            38,
            37,
            36,
            41,
            41,
            '%'
        ],
        104201: [
            39,
            39,
            37,
            36,
            41,
            41,
            '%'
        ],
        105801: [
            39,
            39,
            38,
            36,
            41,
            41,
            '%'
        ],
        110001: [
            40,
            39,
            38,
            36,
            41,
            41,
            '%'
        ],
        111401: [
            40,
            40,
            38,
            36,
            41,
            41,
            '%'
        ],
        111601: [
            40,
            40,
            39,
            36,
            41,
            41,
            '%'
        ],
        112601: [
            40,
            40,
            39,
            36,
            42,
            42,
            '%'
        ],
        113401: [
            40,
            40,
            39,
            37,
            42,
            42,
            '%'
        ],
        118001: [
            41,
            40,
            40,
            37,
            42,
            42,
            '%'
        ],
        119601: [
            41,
            41,
            40,
            37,
            42,
            42,
            '%'
        ],
        125201: [
            41,
            41,
            41,
            38,
            42,
            42,
            '%'
        ],
        126001: [
            41,
            41,
            41,
            38,
            43,
            43,
            '%'
        ],
        127601: [
            42,
            41,
            41,
            38,
            43,
            43,
            '%'
        ],
        129401: [
            42,
            42,
            41,
            38,
            43,
            43,
            '%'
        ],
        133401: [
            42,
            42,
            42,
            38,
            43,
            43,
            '%'
        ],
        138601: [
            43,
            42,
            42,
            38,
            43,
            43,
            '%'
        ],
        139601: [
            43,
            42,
            42,
            39,
            43,
            43,
            '%'
        ],
        140601: [
            43,
            43,
            42,
            39,
            43,
            43,
            '%'
        ],
        142601: [
            43,
            43,
            43,
            39,
            43,
            43,
            '%'
        ],
        142801: [
            43,
            43,
            43,
            39,
            44,
            44,
            '%'
        ],
        151801: [
            44,
            43,
            43,
            39,
            44,
            44,
            '%'
        ],
        154201: [
            44,
            44,
            44,
            39,
            44,
            44,
            '%'
        ],
        157801: [
            44,
            44,
            44,
            40,
            44,
            44,
            '%'
        ],
        164801: [
            44,
            44,
            44,
            40,
            45,
            45,
            '%'
        ],
        167801: [
            45,
            44,
            44,
            40,
            45,
            45,
            '%'
        ],
        170601: [
            45,
            45,
            45,
            40,
            45,
            45,
            '%'
        ],
        181401: [
            45,
            45,
            45,
            41,
            45,
            45,
            '%'
        ],
        194601: [
            46,
            46,
            46,
            41,
            46,
            46,
            '%'
        ],
        213401: [
            46,
            46,
            46,
            42,
            46,
            46,
            '%'
        ],
        238001: [
            47,
            47,
            47,
            42,
            47,
            47,
            '%'
        ],
        259001: [
            47,
            47,
            47,
            43,
            47,
            47,
            '%'
        ],
        306001: [
            48,
            48,
            48,
            43,
            48,
            48,
            '%'
        ],
        329401: [
            48,
            48,
            48,
            44,
            48,
            48,
            '%'
        ],
        428401: [
            49,
            49,
            49,
            44,
            49,
            49,
            '%'
        ],
        452401: [
            49,
            49,
            49,
            45,
            49,
            49,
            '%'
        ],
        714001: [
            50,
            50,
            50,
            45,
            50,
            50,
            '%'
        ],
        721801: [
            50,
            50,
            50,
            46,
            50,
            50,
            '%'
        ],
        1782601: [
            50,
            50,
            50,
            47,
            50,
            50,
            '%'
        ]
    },
    32: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            24
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            57
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            90
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            123
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            156
        ],
        2501: [
            195,
            0,
            183,
            12,
            195,
            189
        ],
        2601: [
            206,
            0,
            191,
            14,
            222,
            222
        ],
        2701: [
            216,
            0,
            200,
            16,
            255,
            255
        ],
        2801: [
            218,
            0,
            200,
            18,
            288,
            288
        ],
        2901: [
            229,
            0,
            208,
            21,
            321,
            321
        ],
        3001: [
            239,
            0,
            216,
            23,
            354,
            354
        ],
        3101: [
            250,
            0,
            225,
            25,
            387,
            387
        ],
        3201: [
            260,
            0,
            233,
            27,
            420,
            420
        ],
        3301: [
            271,
            0,
            241,
            29,
            453,
            453
        ],
        3401: [
            273,
            0,
            241,
            31,
            486,
            486
        ],
        3501: [
            284,
            0,
            250,
            34,
            519,
            519
        ],
        3601: [
            294,
            0,
            258,
            36,
            552,
            552
        ],
        3701: [
            305,
            0,
            266,
            38,
            585,
            585
        ],
        3801: [
            315,
            0,
            275,
            40,
            618,
            618
        ],
        3901: [
            325,
            0,
            283,
            42,
            651,
            651
        ],
        4001: [
            328,
            0,
            283,
            44,
            684,
            684
        ],
        4101: [
            338,
            0,
            291,
            46,
            717,
            717
        ],
        4201: [
            349,
            0,
            300,
            49,
            750,
            750
        ],
        4301: [
            359,
            0,
            308,
            62,
            783,
            783
        ],
        4401: [
            370,
            0,
            316,
            84,
            816,
            816
        ],
        4501: [
            380,
            0,
            325,
            107,
            849,
            849
        ],
        4601: [
            382,
            0,
            325,
            129,
            882,
            882
        ],
        4701: [
            392,
            0,
            333,
            152,
            910,
            910
        ],
        4801: [
            403,
            0,
            341,
            174,
            937,
            937
        ],
        4901: [
            413,
            0,
            350,
            196,
            965,
            965
        ],
        5001: [
            423,
            0,
            358,
            218,
            989,
            989
        ],
        5101: [
            433,
            0,
            366,
            240,
            1017,
            1017
        ],
        5201: [
            443,
            0,
            375,
            262,
            1042,
            1042
        ],
        5301: [
            445,
            21,
            376,
            284,
            1069,
            1069
        ],
        5401: [
            455,
            46,
            386,
            306,
            1097,
            1097
        ],
        5501: [
            465,
            71,
            396,
            328,
            1121,
            1121
        ],
        5601: [
            475,
            96,
            406,
            349,
            1148,
            1148
        ],
        5701: [
            485,
            120,
            416,
            371,
            1172,
            1172
        ],
        5801: [
            495,
            145,
            426,
            392,
            1199,
            1199
        ],
        5901: [
            497,
            170,
            427,
            414,
            1226,
            1226
        ],
        6001: [
            507,
            195,
            437,
            435,
            1250,
            1250
        ],
        6101: [
            517,
            219,
            447,
            457,
            1277,
            1277
        ],
        6201: [
            527,
            241,
            457,
            478,
            1301,
            1301
        ],
        6301: [
            537,
            266,
            467,
            499,
            1328,
            1328
        ],
        6401: [
            547,
            291,
            477,
            521,
            1355,
            1355
        ],
        6501: [
            549,
            316,
            479,
            542,
            1379,
            1379
        ],
        6601: [
            559,
            340,
            488,
            564,
            1406,
            1406
        ],
        6701: [
            569,
            365,
            498,
            585,
            1430,
            1430
        ],
        6801: [
            579,
            390,
            508,
            607,
            1457,
            1457
        ],
        6901: [
            590,
            415,
            518,
            628,
            1484,
            1484
        ],
        7001: [
            610,
            439,
            528,
            650,
            1508,
            1508
        ],
        7101: [
            630,
            464,
            530,
            671,
            1535,
            1535
        ],
        7201: [
            650,
            489,
            540,
            693,
            1559,
            1559
        ],
        7301: [
            670,
            514,
            550,
            714,
            1586,
            1586
        ],
        7401: [
            690,
            536,
            560,
            736,
            1613,
            1613
        ],
        7501: [
            710,
            560,
            570,
            757,
            1637,
            1637
        ],
        7601: [
            730,
            585,
            580,
            779,
            1664,
            1664
        ],
        7701: [
            750,
            610,
            589,
            800,
            1688,
            1688
        ],
        7801: [
            770,
            635,
            591,
            821,
            1715,
            1715
        ],
        7901: [
            790,
            659,
            601,
            843,
            1742,
            1742
        ],
        8001: [
            810,
            684,
            611,
            864,
            1766,
            1766
        ],
        8101: [
            830,
            709,
            621,
            886,
            1793,
            1793
        ],
        8201: [
            850,
            734,
            631,
            907,
            1817,
            1817
        ],
        8301: [
            870,
            758,
            641,
            929,
            1844,
            1844
        ],
        8401: [
            890,
            783,
            642,
            950,
            1871,
            1871
        ],
        8501: [
            910,
            808,
            652,
            972,
            1895,
            1895
        ],
        8601: [
            930,
            830,
            662,
            993,
            1922,
            1922
        ],
        8701: [
            950,
            855,
            672,
            1015,
            1946,
            1946
        ],
        8801: [
            970,
            879,
            682,
            1036,
            1973,
            1973
        ],
        8901: [
            990,
            904,
            692,
            1058,
            2000,
            2000
        ],
        9001: [
            1010,
            929,
            694,
            1079,
            2024,
            2024
        ],
        9101: [
            1030,
            954,
            704,
            1101,
            2051,
            2051
        ],
        9201: [
            1050,
            978,
            714,
            1122,
            2075,
            2075
        ],
        9301: [
            1070,
            995,
            723,
            1143,
            2102,
            2102
        ],
        9401: [
            1090,
            1017,
            733,
            1165,
            2129,
            2129
        ],
        9501: [
            1110,
            1036,
            742,
            1186,
            2153,
            2153
        ],
        9601: [
            1130,
            1055,
            744,
            1208,
            2180,
            2180
        ],
        9701: [
            1150,
            1075,
            753,
            1229,
            2204,
            2204
        ],
        9801: [
            1170,
            1097,
            763,
            1251,
            2231,
            2231
        ],
        9901: [
            1190,
            1116,
            773,
            1272,
            2258,
            2258
        ],
        10001: [
            1210,
            1134,
            782,
            1294,
            2282,
            2282
        ],
        10101: [
            1231,
            1153,
            792,
            1315,
            2309,
            2309
        ],
        10201: [
            1250,
            1175,
            802,
            1337,
            2333,
            2333
        ],
        10301: [
            1271,
            1194,
            803,
            1358,
            2360,
            2360
        ],
        10401: [
            1291,
            1212,
            812,
            1380,
            2387,
            2387
        ],
        10501: [
            1311,
            1231,
            822,
            1401,
            2411,
            2411
        ],
        10601: [
            1331,
            1253,
            832,
            1423,
            2438,
            2438
        ],
        10701: [
            1351,
            1271,
            841,
            1444,
            2462,
            2462
        ],
        10801: [
            1371,
            1290,
            851,
            1465,
            2489,
            2489
        ],
        10901: [
            1391,
            1309,
            852,
            1487,
            2516,
            2516
        ],
        11001: [
            1411,
            1331,
            862,
            1508,
            2540,
            2540
        ],
        11101: [
            1431,
            1349,
            872,
            1530,
            2567,
            2567
        ],
        11201: [
            1451,
            1368,
            881,
            1551,
            2591,
            2591
        ],
        11301: [
            1471,
            1390,
            891,
            1573,
            2618,
            2618
        ],
        11401: [
            1491,
            1409,
            901,
            1594,
            2645,
            2645
        ],
        11501: [
            1511,
            1427,
            902,
            1616,
            2669,
            2669
        ],
        11601: [
            1531,
            1446,
            911,
            1637,
            2696,
            2696
        ],
        11701: [
            1551,
            1468,
            921,
            1659,
            2720,
            2720
        ],
        11801: [
            1571,
            1486,
            931,
            1680,
            2747,
            2747
        ],
        11901: [
            1591,
            1505,
            940,
            1702,
            2774,
            2774
        ],
        12001: [
            1611,
            1524,
            950,
            1723,
            2798,
            2798
        ],
        12101: [
            1631,
            1546,
            951,
            1745,
            2825,
            2825
        ],
        12201: [
            1651,
            1564,
            961,
            1766,
            2849,
            2849
        ],
        12301: [
            1671,
            1583,
            971,
            1787,
            2876,
            2876
        ],
        12401: [
            1691,
            1602,
            980,
            1809,
            2903,
            2903
        ],
        12501: [
            1711,
            1624,
            990,
            1830,
            2927,
            2927
        ],
        12601: [
            1731,
            1642,
            1000,
            1852,
            2954,
            2954
        ],
        12701: [
            1751,
            1661,
            1009,
            1873,
            2978,
            2978
        ],
        12801: [
            1771,
            1680,
            1010,
            1895,
            3005,
            3005
        ],
        12901: [
            1791,
            1701,
            1020,
            1916,
            3029,
            3029
        ],
        13001: [
            1811,
            1720,
            1030,
            1938,
            3061,
            3061
        ],
        13101: [
            1832,
            1739,
            1039,
            1960,
            3094,
            3094
        ],
        13201: [
            1852,
            1761,
            1049,
            1981,
            3126,
            3126
        ],
        13301: [
            1872,
            1779,
            1059,
            2003,
            3158,
            3158
        ],
        13401: [
            1893,
            1798,
            1060,
            2025,
            3190,
            3190
        ],
        13501: [
            1913,
            1817,
            1070,
            2047,
            3223,
            3223
        ],
        13601: [
            1933,
            1839,
            1079,
            2068,
            3255,
            3255
        ],
        13701: [
            1953,
            1857,
            1089,
            2090,
            3287,
            3287
        ],
        13801: [
            1973,
            1876,
            1099,
            2111,
            3319,
            3319
        ],
        13901: [
            1992,
            1895,
            1108,
            2132,
            3350,
            3350
        ],
        14001: [
            2012,
            1916,
            1110,
            2153,
            3381,
            3381
        ],
        14101: [
            2031,
            1935,
            1119,
            2174,
            3413,
            3413
        ],
        14201: [
            2050,
            1954,
            1129,
            2194,
            3444,
            3444
        ],
        14301: [
            2070,
            1973,
            1138,
            2215,
            3475,
            3475
        ],
        14401: [
            2089,
            1994,
            1148,
            2236,
            3506,
            3506
        ],
        14501: [
            2108,
            2013,
            1158,
            2257,
            3538,
            3538
        ],
        14601: [
            2128,
            2032,
            1159,
            2277,
            3569,
            3569
        ],
        14701: [
            2147,
            2051,
            1169,
            2298,
            3600,
            3600
        ],
        14801: [
            2166,
            2072,
            1178,
            2319,
            3634,
            3634
        ],
        14901: [
            2186,
            2091,
            1188,
            2340,
            3668,
            3668
        ],
        15001: [
            2205,
            2110,
            1198,
            2360,
            3702,
            3702
        ],
        15101: [
            2224,
            2129,
            1207,
            2381,
            3735,
            3735
        ],
        15201: [
            2244,
            2150,
            1217,
            2402,
            3772,
            3772
        ],
        15301: [
            2263,
            2169,
            1218,
            2423,
            3806,
            3806
        ],
        15401: [
            2284,
            2191,
            1228,
            2446,
            3840,
            3840
        ],
        15501: [
            2310,
            2217,
            1238,
            2473,
            3873,
            3873
        ],
        15601: [
            2336,
            2244,
            1248,
            2501,
            3907,
            3907
        ],
        15701: [
            2362,
            2271,
            1258,
            2528,
            3944,
            3944
        ],
        15801: [
            2389,
            2298,
            1268,
            2555,
            3978,
            3978
        ],
        15901: [
            2415,
            2325,
            1270,
            2583,
            4011,
            4011
        ],
        16001: [
            2441,
            2352,
            1280,
            2610,
            4045,
            4045
        ],
        16101: [
            2467,
            2379,
            1291,
            2637,
            4079,
            4079
        ],
        16201: [
            2493,
            2406,
            1301,
            2665,
            4116,
            4116
        ],
        16301: [
            2520,
            2432,
            1311,
            2692,
            4150,
            4150
        ],
        16401: [
            2546,
            2459,
            1321,
            2719,
            4183,
            4183
        ],
        16501: [
            2572,
            2486,
            1323,
            2747,
            4217,
            4217
        ],
        16601: [
            2598,
            2513,
            1333,
            2774,
            4251,
            4251
        ],
        16701: [
            2625,
            2540,
            1343,
            2802,
            4288,
            4288
        ],
        16801: [
            2651,
            2567,
            1353,
            2829,
            4321,
            4321
        ],
        16901: [
            2677,
            2594,
            1363,
            2856,
            4355,
            4355
        ],
        17001: [
            2703,
            2618,
            1373,
            2884,
            4389,
            4389
        ],
        17101: [
            2730,
            2645,
            1375,
            2911,
            4423,
            4423
        ],
        17201: [
            2756,
            2672,
            1385,
            2938,
            4459,
            4459
        ],
        17301: [
            2782,
            2699,
            1395,
            2966,
            4493,
            4493
        ],
        17401: [
            2808,
            2725,
            1405,
            2993,
            4527,
            4527
        ],
        17501: [
            2834,
            2752,
            1416,
            3020,
            4561,
            4561
        ],
        17601: [
            2861,
            2779,
            1426,
            3048,
            4595,
            4595
        ],
        17701: [
            2887,
            2806,
            1436,
            3075,
            4631,
            4631
        ],
        17801: [
            2913,
            2833,
            1438,
            3102,
            4665,
            4665
        ],
        17901: [
            2939,
            2860,
            1448,
            3130,
            4699,
            4699
        ],
        18001: [
            2966,
            2887,
            1458,
            3157,
            4733,
            4733
        ],
        18101: [
            2992,
            2914,
            1468,
            3184,
            4767,
            4767
        ],
        18201: [
            3018,
            2940,
            1478,
            3212,
            4803,
            4803
        ],
        18301: [
            3044,
            2967,
            1488,
            3239,
            4837,
            4837
        ],
        18401: [
            3070,
            2994,
            1490,
            3266,
            4871,
            4871
        ],
        18501: [
            3097,
            3021,
            1500,
            3294,
            4905,
            4905
        ],
        18601: [
            3123,
            3048,
            1510,
            3321,
            4939,
            4939
        ],
        18701: [
            3149,
            3075,
            1520,
            3348,
            4975,
            4975
        ],
        18801: [
            3175,
            3102,
            1531,
            3376,
            5009,
            5009
        ],
        18901: [
            3202,
            3129,
            1541,
            3403,
            5043,
            5043
        ],
        19001: [
            3228,
            3155,
            1543,
            3430,
            5077,
            5077
        ],
        19101: [
            3254,
            3182,
            1553,
            3458,
            5110,
            5110
        ],
        19201: [
            3280,
            3209,
            1563,
            3485,
            5147,
            5147
        ],
        19301: [
            3306,
            3236,
            1573,
            3512,
            5181,
            5181
        ],
        19401: [
            3333,
            3263,
            1583,
            3540,
            5215,
            5215
        ],
        19501: [
            3359,
            3290,
            1593,
            3567,
            5248,
            5248
        ],
        19601: [
            3385,
            3316,
            1595,
            3594,
            5282,
            5282
        ],
        19701: [
            3411,
            3342,
            1604,
            3622,
            5319,
            5319
        ],
        19801: [
            3438,
            3368,
            1613,
            3649,
            5353,
            5353
        ],
        19901: [
            3464,
            3394,
            1622,
            3676,
            5386,
            5386
        ],
        20001: [
            3516,
            3446,
            1641,
            3731,
            5454,
            5454
        ],
        20201: [
            3569,
            3496,
            1651,
            3786,
            5525,
            5525
        ],
        20401: [
            3621,
            3548,
            1670,
            3841,
            5592,
            5592
        ],
        20601: [
            3674,
            3600,
            1688,
            3895,
            5663,
            5663
        ],
        20801: [
            3726,
            3652,
            1699,
            3950,
            5730,
            5730
        ],
        21001: [
            3778,
            3704,
            1717,
            4005,
            5798,
            5798
        ],
        21201: [
            3831,
            3756,
            1736,
            4059,
            5868,
            5868
        ],
        21401: [
            3883,
            3808,
            1746,
            4114,
            5936,
            5936
        ],
        21601: [
            3936,
            3860,
            1765,
            4169,
            6006,
            6006
        ],
        21801: [
            3988,
            3913,
            1783,
            4223,
            6074,
            6074
        ],
        22001: [
            4041,
            3965,
            1794,
            4278,
            6142,
            6142
        ],
        22201: [
            4093,
            4017,
            1812,
            4333,
            6212,
            6212
        ],
        22401: [
            4146,
            4069,
            1831,
            4387,
            6280,
            6280
        ],
        22601: [
            4198,
            4121,
            1849,
            4442,
            6350,
            6350
        ],
        22801: [
            4251,
            4173,
            1860,
            4498,
            6419,
            6419
        ],
        23001: [
            4306,
            4225,
            1878,
            4554,
            6488,
            6488
        ],
        23201: [
            4360,
            4277,
            1897,
            4610,
            6560,
            6560
        ],
        23401: [
            4414,
            4327,
            1907,
            4667,
            6629,
            6629
        ],
        23601: [
            4468,
            4379,
            1926,
            4723,
            6701,
            6701
        ],
        23801: [
            4522,
            4428,
            1944,
            4779,
            6771,
            6771
        ],
        24001: [
            4576,
            4483,
            1954,
            4836,
            6840,
            6840
        ],
        24201: [
            4630,
            4535,
            1973,
            4892,
            6912,
            6912
        ],
        24401: [
            4684,
            4590,
            1992,
            4948,
            6981,
            6981
        ],
        24601: [
            4738,
            4642,
            2002,
            5005,
            7053,
            7053
        ],
        24801: [
            4792,
            4694,
            2021,
            5061,
            7123,
            7123
        ],
        25001: [
            4847,
            4749,
            2039,
            5117,
            7192,
            7192
        ],
        25201: [
            4901,
            4801,
            2050,
            5174,
            7264,
            7264
        ],
        25401: [
            4955,
            4853,
            2068,
            5230,
            7333,
            7333
        ],
        25601: [
            5009,
            4907,
            2087,
            5287,
            7405,
            7405
        ],
        25801: [
            5063,
            4959,
            2097,
            5343,
            7475,
            7475
        ],
        26001: [
            5117,
            5011,
            2116,
            5399,
            7544,
            7544
        ],
        26201: [
            5171,
            5066,
            2134,
            5456,
            7616,
            7616
        ],
        26401: [
            5225,
            5118,
            2145,
            5512,
            7685,
            7685
        ],
        26601: [
            5279,
            5173,
            2168,
            5568,
            7757,
            7757
        ],
        26801: [
            5333,
            5225,
            2220,
            5625,
            7827,
            7827
        ],
        27001: [
            5388,
            5277,
            2272,
            5681,
            7896,
            7896
        ],
        27201: [
            5442,
            5332,
            2327,
            5737,
            7968,
            7968
        ],
        27401: [
            5496,
            5384,
            2379,
            5793,
            8037,
            8037
        ],
        27601: [
            5550,
            5436,
            2431,
            5850,
            8109,
            8109
        ],
        27801: [
            5604,
            5491,
            2486,
            5906,
            8179,
            8179
        ],
        28001: [
            5658,
            5543,
            2538,
            5962,
            8248,
            8248
        ],
        28201: [
            5712,
            5597,
            2593,
            6019,
            8320,
            8320
        ],
        28401: [
            5766,
            5650,
            2645,
            6075,
            8389,
            8389
        ],
        28601: [
            5820,
            5702,
            2697,
            6132,
            8461,
            8461
        ],
        28801: [
            5874,
            5756,
            2751,
            6188,
            8531,
            8531
        ],
        29001: [
            5928,
            5808,
            2804,
            6244,
            8600,
            8600
        ],
        29201: [
            5983,
            5860,
            2856,
            6301,
            8672,
            8672
        ],
        29401: [
            6037,
            5915,
            2910,
            6357,
            8741,
            8741
        ],
        29601: [
            6091,
            5967,
            2962,
            6413,
            8813,
            8813
        ],
        29801: [
            6145,
            6022,
            3017,
            6470,
            8883,
            8883
        ],
        30001: [
            6199,
            6074,
            3069,
            6526,
            8952,
            8952
        ],
        30201: [
            6253,
            6126,
            3121,
            6582,
            9024,
            9024
        ],
        30401: [
            6307,
            6181,
            3176,
            6639,
            9093,
            9093
        ],
        30601: [
            6361,
            6233,
            3228,
            6695,
            9165,
            9165
        ],
        30801: [
            6415,
            6285,
            3280,
            6751,
            9235,
            9235
        ],
        31001: [
            6469,
            6340,
            3335,
            6808,
            9304,
            9304
        ],
        31201: [
            6524,
            6392,
            3387,
            6864,
            9376,
            9376
        ],
        31401: [
            6578,
            6445,
            3441,
            6920,
            9445,
            9445
        ],
        31601: [
            6632,
            6501,
            3497,
            6977,
            9517,
            9517
        ],
        31801: [
            6686,
            6555,
            3550,
            7033,
            9587,
            9587
        ],
        32001: [
            6740,
            6611,
            3606,
            7089,
            9656,
            9656
        ],
        32201: [
            6794,
            6664,
            3659,
            7146,
            9728,
            9728
        ],
        32401: [
            6848,
            6717,
            3713,
            7202,
            9797,
            9797
        ],
        32601: [
            6902,
            6773,
            3769,
            7258,
            9869,
            9869
        ],
        32801: [
            6956,
            6827,
            3822,
            7315,
            9939,
            9939
        ],
        33001: [
            7010,
            6880,
            3875,
            7371,
            10008,
            10008
        ],
        33201: [
            7065,
            6936,
            3931,
            7427,
            10080,
            10080
        ],
        33401: [
            7119,
            6989,
            3985,
            7484,
            10149,
            10149
        ],
        33601: [
            7173,
            7045,
            4041,
            7540,
            10221,
            10221
        ],
        33801: [
            7227,
            7099,
            4094,
            7596,
            10291,
            10291
        ],
        34001: [
            7281,
            7152,
            4147,
            7653,
            10360,
            10360
        ],
        34201: [
            7335,
            7208,
            4203,
            7709,
            10432,
            10432
        ],
        34401: [
            7389,
            7261,
            4257,
            7765,
            10501,
            10501
        ],
        34601: [
            7443,
            7315,
            4310,
            7822,
            10573,
            10573
        ],
        34801: [
            7497,
            7371,
            4366,
            7878,
            10643,
            10643
        ],
        35001: [
            7551,
            7424,
            4419,
            7934,
            10712,
            10712
        ],
        35201: [
            7606,
            7477,
            4473,
            7991,
            10784,
            10784
        ],
        35401: [
            7660,
            7533,
            4529,
            8047,
            10853,
            10853
        ],
        35601: [
            7714,
            7587,
            4582,
            8103,
            10925,
            10925
        ],
        35801: [
            7768,
            7643,
            4638,
            8160,
            10995,
            10995
        ],
        36001: [
            7822,
            7696,
            4691,
            8216,
            11064,
            11064
        ],
        36201: [
            7876,
            7749,
            4745,
            8272,
            11136,
            11136
        ],
        36401: [
            7930,
            7805,
            4801,
            8329,
            11205,
            11205
        ],
        36601: [
            7984,
            7859,
            4854,
            8385,
            11277,
            11277
        ],
        36801: [
            8038,
            7912,
            4907,
            8441,
            11347,
            11347
        ],
        37001: [
            8092,
            7968,
            4963,
            8498,
            11416,
            11416
        ],
        37201: [
            8147,
            8021,
            5017,
            8554,
            11488,
            11488
        ],
        37401: [
            8201,
            8077,
            5073,
            8610,
            11557,
            11557
        ],
        37601: [
            8255,
            8128,
            5123,
            8667,
            11624,
            11624
        ],
        37801: [
            8308,
            8181,
            5177,
            8723,
            11688,
            11688
        ],
        38001: [
            8362,
            8235,
            5230,
            8779,
            11752,
            11752
        ],
        38201: [
            8416,
            8291,
            5286,
            8835,
            11816,
            11816
        ],
        38401: [
            8471,
            8344,
            5339,
            8891,
            11880,
            11880
        ],
        38601: [
            8535,
            8408,
            5403,
            8947,
            11944,
            11944
        ],
        38801: [
            8599,
            8472,
            5467,
            9003,
            12008,
            12008
        ],
        39001: [
            8663,
            8536,
            5531,
            9059,
            12072,
            12072
        ],
        39201: [
            8727,
            8600,
            5595,
            9115,
            12136,
            12136
        ],
        39401: [
            8791,
            8664,
            5659,
            9171,
            12200,
            12200
        ],
        39601: [
            8855,
            8728,
            5723,
            9228,
            12264,
            12264
        ],
        39801: [
            8919,
            8792,
            5787,
            9284,
            12328,
            12328
        ],
        40001: [
            8983,
            8856,
            5851,
            9340,
            12392,
            12392
        ],
        40201: [
            9047,
            8920,
            5915,
            9396,
            12456,
            12456
        ],
        40401: [
            9111,
            8984,
            5979,
            9452,
            12520,
            12520
        ],
        40601: [
            9175,
            9048,
            6043,
            9508,
            12584,
            12584
        ],
        40801: [
            9239,
            9112,
            6107,
            9564,
            12648,
            12648
        ],
        41001: [
            9303,
            9176,
            6171,
            9620,
            12712,
            12712
        ],
        41201: [
            9367,
            9240,
            6235,
            9677,
            12776,
            12776
        ],
        41401: [
            9431,
            9304,
            6299,
            9733,
            12840,
            12840
        ],
        41601: [
            9495,
            9368,
            6363,
            9789,
            12904,
            12904
        ],
        41801: [
            9559,
            9432,
            6427,
            9845,
            12968,
            12968
        ],
        42001: [
            9623,
            9496,
            6491,
            9901,
            13032,
            13032
        ],
        42201: [
            9687,
            9560,
            6555,
            9957,
            13096,
            13096
        ],
        42401: [
            9751,
            9624,
            6619,
            10013,
            13160,
            13160
        ],
        42601: [
            9815,
            9688,
            6683,
            10069,
            13224,
            13224
        ],
        42801: [
            9879,
            9752,
            6747,
            10125,
            13288,
            13288
        ],
        43001: [
            9943,
            9816,
            6811,
            10181,
            13352,
            13352
        ],
        43201: [
            10007,
            9880,
            6875,
            10237,
            13416,
            13416
        ],
        43401: [
            10071,
            9944,
            6939,
            10294,
            13480,
            13480
        ],
        43601: [
            10135,
            10008,
            7003,
            10350,
            13544,
            13544
        ],
        43801: [
            10199,
            10072,
            7067,
            10406,
            13608,
            13608
        ],
        44001: [
            10263,
            10136,
            7131,
            10462,
            13672,
            13672
        ],
        44201: [
            10327,
            10200,
            7195,
            10518,
            13736,
            13736
        ],
        44401: [
            10391,
            10264,
            7259,
            10574,
            13800,
            13800
        ],
        44601: [
            10455,
            10328,
            7323,
            10630,
            13864,
            13864
        ],
        44801: [
            10519,
            10392,
            7387,
            10686,
            13928,
            13928
        ],
        45001: [
            10583,
            10456,
            7451,
            10742,
            13992,
            13992
        ],
        45201: [
            10647,
            10520,
            7515,
            10799,
            14056,
            14056
        ],
        45401: [
            10711,
            10584,
            7579,
            10855,
            14120,
            14120
        ],
        45601: [
            10775,
            10648,
            7643,
            10911,
            14184,
            14184
        ],
        45801: [
            10839,
            10712,
            7707,
            10967,
            14248,
            14248
        ],
        46001: [
            10903,
            10776,
            7771,
            11023,
            14312,
            14312
        ],
        46201: [
            10967,
            10840,
            7835,
            11079,
            14376,
            14376
        ],
        46401: [
            11031,
            10904,
            7899,
            11135,
            14440,
            14440
        ],
        46601: [
            11095,
            10968,
            7963,
            11191,
            14504,
            14504
        ],
        46801: [
            11159,
            11032,
            8027,
            11247,
            14568,
            14568
        ],
        47001: [
            11223,
            11096,
            8091,
            11303,
            14632,
            14632
        ],
        47201: [
            11287,
            11160,
            8155,
            11360,
            14696,
            14696
        ],
        47401: [
            11351,
            11224,
            8219,
            11416,
            14760,
            14760
        ],
        47601: [
            11415,
            11288,
            8283,
            11472,
            14824,
            14824
        ],
        47801: [
            11479,
            11352,
            8347,
            11528,
            14888,
            14888
        ],
        48001: [
            11543,
            11416,
            8411,
            11584,
            14952,
            14952
        ],
        48201: [
            11607,
            11480,
            8475,
            11640,
            15016,
            15016
        ],
        48401: [
            11671,
            11544,
            8539,
            11696,
            15080,
            15080
        ],
        48601: [
            11735,
            11608,
            8603,
            11752,
            15144,
            15144
        ],
        48801: [
            11799,
            11672,
            8667,
            11808,
            15208,
            15208
        ],
        49001: [
            11863,
            11736,
            8731,
            11865,
            15272,
            15272
        ],
        49201: [
            11927,
            11800,
            8795,
            11921,
            15336,
            15336
        ],
        49401: [
            11991,
            11864,
            8859,
            11977,
            15400,
            15400
        ],
        49601: [
            12055,
            11928,
            8923,
            12033,
            15464,
            15464
        ],
        49801: [
            12119,
            11992,
            8987,
            12089,
            15528,
            15528
        ],
        50001: [
            12183,
            12056,
            9056,
            12145,
            15592,
            15592
        ],
        50201: [
            12247,
            12120,
            9126,
            12201,
            15656,
            15656
        ],
        50401: [
            12311,
            12184,
            9196,
            12257,
            15720,
            15720
        ],
        50601: [
            12375,
            12248,
            9266,
            12313,
            15784,
            15784
        ],
        50801: [
            12439,
            12312,
            9336,
            12369,
            15848,
            15848
        ],
        51001: [
            12503,
            12376,
            9406,
            12426,
            15912,
            15912
        ],
        51201: [
            12592,
            12464,
            9500,
            12507,
            16001,
            16001
        ],
        51401: [
            12696,
            12568,
            9610,
            12603,
            16105,
            16105
        ],
        51601: [
            12800,
            12672,
            9720,
            12699,
            16209,
            16209
        ],
        51801: [
            12904,
            12773,
            9827,
            12795,
            16313,
            16313
        ],
        52001: [
            13008,
            12877,
            9937,
            12891,
            16417,
            16417
        ],
        52201: [
            13112,
            12981,
            10047,
            12987,
            16521,
            16521
        ],
        52401: [
            13216,
            13085,
            10157,
            13083,
            16625,
            16625
        ],
        52601: [
            13320,
            13189,
            10267,
            13179,
            16729,
            16729
        ],
        52801: [
            13424,
            13293,
            10377,
            13276,
            16833,
            16833
        ],
        53001: [
            13528,
            13397,
            10487,
            13372,
            16937,
            16937
        ],
        53201: [
            13632,
            13501,
            10597,
            13468,
            17041,
            17041
        ],
        53401: [
            13736,
            13603,
            10705,
            13564,
            17145,
            17145
        ],
        53601: [
            13840,
            13707,
            10815,
            13660,
            17249,
            17249
        ],
        53801: [
            13944,
            13811,
            10925,
            13756,
            17353,
            17353
        ],
        54001: [
            14048,
            13915,
            11035,
            13852,
            17457,
            17457
        ],
        54201: [
            14152,
            14019,
            11145,
            13948,
            17561,
            17561
        ],
        54401: [
            14256,
            14123,
            11255,
            14044,
            17665,
            17665
        ],
        54601: [
            14360,
            14227,
            11365,
            14140,
            17769,
            17769
        ],
        54801: [
            14464,
            14331,
            11475,
            14237,
            17873,
            17873
        ],
        55001: [
            14568,
            14435,
            11585,
            14333,
            17977,
            17977
        ],
        55201: [
            14672,
            14536,
            11692,
            14429,
            18081,
            18081
        ],
        55401: [
            14776,
            14640,
            11802,
            14525,
            18185,
            18185
        ],
        55601: [
            14880,
            14744,
            11912,
            14621,
            18289,
            18289
        ],
        55801: [
            14984,
            14848,
            12022,
            14717,
            18393,
            18393
        ],
        56001: [
            15088,
            14952,
            12132,
            14813,
            18497,
            18497
        ],
        56201: [
            15192,
            15056,
            12242,
            14909,
            18601,
            18601
        ],
        56401: [
            15296,
            15160,
            12352,
            15005,
            18705,
            18705
        ],
        56601: [
            15400,
            15264,
            12462,
            15102,
            18809,
            18809
        ],
        56801: [
            15504,
            15365,
            12569,
            15198,
            18913,
            18913
        ],
        57001: [
            15608,
            15469,
            12679,
            15294,
            19017,
            19017
        ],
        57201: [
            15712,
            15573,
            12789,
            15390,
            19121,
            19121
        ],
        57401: [
            15816,
            15677,
            12899,
            15486,
            19225,
            19225
        ],
        57601: [
            15920,
            15781,
            13009,
            15582,
            19329,
            19329
        ],
        57801: [
            16024,
            15885,
            13119,
            15678,
            19433,
            19433
        ],
        58001: [
            16128,
            15984,
            13224,
            15774,
            19537,
            19537
        ],
        58201: [
            16232,
            16088,
            13334,
            15870,
            19641,
            19641
        ],
        58401: [
            16336,
            16192,
            13444,
            15966,
            19745,
            19745
        ],
        58601: [
            16440,
            16296,
            13554,
            16063,
            19849,
            19849
        ],
        58801: [
            16544,
            16400,
            13664,
            16159,
            19953,
            19953
        ],
        59001: [
            16648,
            16504,
            13774,
            16255,
            20057,
            20057
        ],
        59201: [
            16752,
            16608,
            13884,
            16351,
            20161,
            20161
        ],
        59401: [
            16856,
            16712,
            13994,
            16447,
            20265,
            20265
        ],
        59601: [
            16960,
            16816,
            14104,
            16543,
            20369,
            20369
        ],
        59801: [
            17064,
            16920,
            14214,
            16639,
            20473,
            20473
        ],
        60001: [
            17168,
            17024,
            14324,
            16735,
            20577,
            20577
        ],
        60201: [
            17272,
            17128,
            14434,
            16831,
            20681,
            20681
        ],
        60401: [
            17376,
            17232,
            14544,
            16928,
            20785,
            20785
        ],
        60601: [
            17480,
            17336,
            14654,
            17024,
            20889,
            20889
        ],
        60801: [
            17584,
            17440,
            14764,
            17120,
            20993,
            20993
        ],
        61001: [
            17688,
            17544,
            14874,
            17216,
            21097,
            21097
        ],
        61201: [
            17792,
            17648,
            14984,
            17312,
            21201,
            21201
        ],
        61401: [
            17896,
            17752,
            15094,
            17408,
            21305,
            21305
        ],
        61601: [
            18000,
            17856,
            15204,
            17504,
            21409,
            21409
        ],
        61801: [
            18104,
            17960,
            15314,
            17600,
            21513,
            21513
        ],
        62001: [
            18208,
            18064,
            15424,
            17696,
            21617,
            21617
        ],
        62201: [
            18312,
            18168,
            15534,
            17792,
            21721,
            21721
        ],
        62401: [
            18416,
            18272,
            15644,
            17889,
            21825,
            21825
        ],
        62601: [
            18520,
            18376,
            15754,
            17985,
            21929,
            21929
        ],
        62801: [
            18624,
            18480,
            15864,
            18081,
            22033,
            22033
        ],
        63001: [
            18728,
            18584,
            15974,
            18177,
            22137,
            22137
        ],
        63201: [
            18832,
            18688,
            16084,
            18273,
            22241,
            22241
        ],
        63401: [
            18936,
            18792,
            16194,
            18369,
            22345,
            22345
        ],
        63601: [
            19040,
            18896,
            16304,
            18465,
            22449,
            22449
        ],
        63801: [
            19144,
            19000,
            16414,
            18561,
            22553,
            22553
        ],
        64001: [
            19248,
            19104,
            16524,
            18657,
            22657,
            22657
        ],
        64201: [
            19352,
            19208,
            16634,
            18754,
            22761,
            22761
        ],
        64401: [
            19456,
            19312,
            16744,
            18850,
            22865,
            22865
        ],
        64601: [
            19565,
            19424,
            16862,
            18946,
            22969,
            22969
        ],
        64801: [
            19675,
            19533,
            16977,
            19042,
            23073,
            23073
        ],
        65001: [
            19785,
            19645,
            17095,
            19138,
            23177,
            23177
        ],
        65201: [
            19895,
            19754,
            17210,
            19234,
            23281,
            23281
        ],
        65401: [
            20005,
            19862,
            17324,
            19330,
            23385,
            23385
        ],
        65601: [
            20115,
            19975,
            17443,
            19426,
            23489,
            23489
        ],
        65801: [
            20225,
            20083,
            17557,
            19522,
            23593,
            23593
        ],
        66001: [
            20335,
            20196,
            17676,
            19618,
            23697,
            23697
        ],
        66201: [
            20445,
            20304,
            17790,
            19715,
            23801,
            23801
        ],
        66401: [
            20555,
            20412,
            17904,
            19811,
            23905,
            23905
        ],
        66601: [
            20665,
            20525,
            18023,
            19907,
            24009,
            24009
        ],
        66801: [
            20775,
            20633,
            18137,
            20003,
            24113,
            24113
        ],
        67001: [
            20885,
            20742,
            18252,
            20099,
            24217,
            24217
        ],
        67201: [
            20995,
            20854,
            18370,
            20195,
            24321,
            24321
        ],
        67401: [
            21105,
            20963,
            18485,
            20291,
            24425,
            24425
        ],
        67601: [
            21215,
            21075,
            18603,
            20387,
            24529,
            24529
        ],
        67801: [
            21325,
            21184,
            18718,
            20483,
            24633,
            24633
        ],
        68001: [
            21435,
            21292,
            18832,
            20580,
            24737,
            24737
        ],
        68201: [
            21545,
            21405,
            18951,
            20676,
            24841,
            24841
        ],
        68401: [
            21655,
            21513,
            19065,
            20772,
            24945,
            24945
        ],
        68601: [
            21765,
            21621,
            19179,
            20868,
            25049,
            25049
        ],
        68801: [
            21875,
            21734,
            19298,
            20964,
            25153,
            25153
        ],
        69001: [
            21985,
            21842,
            19412,
            21060,
            25257,
            25257
        ],
        69201: [
            22095,
            21955,
            19531,
            21156,
            25361,
            25361
        ],
        69401: [
            22205,
            22063,
            19645,
            21252,
            25465,
            25465
        ],
        69601: [
            22315,
            22172,
            19760,
            21348,
            25569,
            25569
        ],
        69801: [
            22425,
            22284,
            19878,
            21444,
            25673,
            25673
        ],
        70001: [
            22535,
            22393,
            19993,
            21541,
            25777,
            25777
        ],
        70201: [
            22645,
            22501,
            20107,
            21637,
            25881,
            25881
        ],
        70401: [
            22755,
            22614,
            20226,
            21733,
            25985,
            25985
        ],
        70601: [
            22865,
            22722,
            20340,
            21829,
            26089,
            26089
        ],
        70801: [
            22975,
            22835,
            20459,
            21925,
            26193,
            26193
        ],
        71001: [
            23085,
            22943,
            20573,
            22021,
            26297,
            26297
        ],
        71201: [
            23195,
            23051,
            20687,
            22117,
            26401,
            26401
        ],
        71401: [
            23305,
            23164,
            20806,
            22213,
            26505,
            26505
        ],
        71601: [
            23415,
            23272,
            20920,
            22309,
            26609,
            26609
        ],
        71801: [
            23525,
            23385,
            21039,
            22405,
            26713,
            26713
        ],
        72001: [
            23635,
            23493,
            21153,
            22502,
            26817,
            26817
        ],
        72201: [
            23745,
            23602,
            21268,
            22598,
            26921,
            26921
        ],
        72401: [
            23855,
            23714,
            21386,
            22694,
            27025,
            27025
        ],
        72601: [
            23965,
            23823,
            21501,
            22790,
            27129,
            27129
        ],
        72801: [
            24075,
            23931,
            21615,
            22886,
            27233,
            27233
        ],
        73001: [
            24185,
            24044,
            21734,
            22982,
            27337,
            27337
        ],
        73201: [
            24295,
            24152,
            21848,
            23078,
            27441,
            27441
        ],
        73401: [
            24405,
            24265,
            21967,
            23174,
            27545,
            27545
        ],
        73601: [
            24515,
            24373,
            22081,
            23270,
            27649,
            27649
        ],
        73801: [
            24625,
            24481,
            22195,
            23367,
            27753,
            27753
        ],
        74001: [
            24735,
            24594,
            22314,
            23463,
            27857,
            27857
        ],
        74201: [
            24845,
            24702,
            22428,
            23559,
            27961,
            27961
        ],
        74401: [
            24955,
            24811,
            22543,
            23655,
            28065,
            28065
        ],
        74601: [
            25065,
            24923,
            22661,
            23751,
            28169,
            28169
        ],
        74801: [
            25175,
            25032,
            22776,
            23847,
            28273,
            28273
        ],
        75001: [
            25285,
            25144,
            22894,
            23943,
            28377,
            28377
        ],
        75201: [
            25395,
            25253,
            23009,
            24039,
            28481,
            28481
        ],
        75401: [
            25505,
            25361,
            23123,
            24135,
            28585,
            28585
        ],
        75601: [
            25615,
            25474,
            23242,
            24231,
            28689,
            28689
        ],
        75801: [
            25725,
            25582,
            23356,
            24328,
            28793,
            28793
        ],
        76001: [
            25835,
            25690,
            23470,
            24424,
            28897,
            28897
        ],
        76201: [
            25945,
            25803,
            23589,
            24520,
            29001,
            29001
        ],
        76401: [
            26055,
            25911,
            23703,
            24616,
            29105,
            29105
        ],
        76601: [
            26165,
            26024,
            23822,
            24712,
            29209,
            29209
        ],
        76801: [
            26275,
            26132,
            23936,
            24808,
            29313,
            29313
        ],
        77001: [
            26385,
            26241,
            24051,
            24904,
            29417,
            29417
        ],
        77201: [
            26495,
            26353,
            24169,
            25000,
            29521,
            29521
        ],
        77401: [
            26605,
            26462,
            24284,
            25096,
            29625,
            29625
        ],
        77601: [
            26715,
            26570,
            24398,
            25193,
            29729,
            29729
        ],
        77801: [
            26825,
            26683,
            24517,
            25289,
            29833,
            29833
        ],
        78001: [
            26935,
            26791,
            24631,
            25385,
            29937,
            29937
        ],
        78201: [
            27045,
            26904,
            24750,
            25481,
            30041,
            30041
        ],
        78401: [
            27155,
            27012,
            24864,
            25577,
            30145,
            30145
        ],
        78601: [
            27265,
            27120,
            24978,
            25673,
            30249,
            30249
        ],
        78801: [
            27375,
            27233,
            25097,
            25769,
            30353,
            30353
        ],
        79001: [
            27485,
            27341,
            25211,
            25865,
            30457,
            30457
        ],
        79201: [
            27595,
            27450,
            25326,
            25961,
            30561,
            30561
        ],
        79401: [
            27705,
            27562,
            25444,
            26057,
            30665,
            30665
        ],
        79601: [
            27815,
            27671,
            25559,
            26154,
            30769,
            30769
        ],
        79801: [
            27925,
            27783,
            25677,
            26250,
            30873,
            30873
        ],
        80001: [
            35,
            35,
            32,
            33,
            39,
            39,
            '%'
        ],
        81201: [
            35,
            35,
            33,
            33,
            39,
            39,
            '%'
        ],
        82401: [
            36,
            35,
            33,
            33,
            39,
            39,
            '%'
        ],
        83001: [
            36,
            36,
            33,
            33,
            39,
            39,
            '%'
        ],
        83601: [
            36,
            36,
            33,
            34,
            39,
            39,
            '%'
        ],
        84401: [
            36,
            36,
            34,
            34,
            39,
            39,
            '%'
        ],
        85801: [
            36,
            36,
            34,
            34,
            40,
            40,
            '%'
        ],
        86801: [
            37,
            36,
            34,
            34,
            40,
            40,
            '%'
        ],
        87601: [
            37,
            37,
            34,
            34,
            40,
            40,
            '%'
        ],
        88001: [
            37,
            37,
            35,
            34,
            40,
            40,
            '%'
        ],
        89801: [
            37,
            37,
            35,
            35,
            40,
            40,
            '%'
        ],
        91801: [
            38,
            37,
            35,
            35,
            40,
            40,
            '%'
        ],
        92001: [
            38,
            37,
            36,
            35,
            40,
            40,
            '%'
        ],
        92601: [
            38,
            38,
            36,
            35,
            40,
            40,
            '%'
        ],
        93201: [
            38,
            38,
            36,
            35,
            41,
            41,
            '%'
        ],
        96201: [
            38,
            38,
            37,
            35,
            41,
            41,
            '%'
        ],
        97001: [
            38,
            38,
            37,
            36,
            41,
            41,
            '%'
        ],
        97401: [
            39,
            38,
            37,
            36,
            41,
            41,
            '%'
        ],
        98201: [
            39,
            39,
            37,
            36,
            41,
            41,
            '%'
        ],
        101001: [
            39,
            39,
            38,
            36,
            41,
            41,
            '%'
        ],
        102001: [
            39,
            39,
            38,
            36,
            42,
            42,
            '%'
        ],
        103601: [
            40,
            39,
            38,
            36,
            42,
            42,
            '%'
        ],
        104601: [
            40,
            40,
            38,
            36,
            42,
            42,
            '%'
        ],
        105401: [
            40,
            40,
            38,
            37,
            42,
            42,
            '%'
        ],
        106201: [
            40,
            40,
            39,
            37,
            42,
            42,
            '%'
        ],
        110801: [
            41,
            40,
            39,
            37,
            42,
            42,
            '%'
        ],
        111801: [
            41,
            41,
            39,
            37,
            42,
            42,
            '%'
        ],
        112001: [
            41,
            41,
            40,
            37,
            42,
            42,
            '%'
        ],
        112801: [
            41,
            41,
            40,
            37,
            43,
            43,
            '%'
        ],
        115401: [
            41,
            41,
            40,
            38,
            43,
            43,
            '%'
        ],
        118401: [
            41,
            41,
            41,
            38,
            43,
            43,
            '%'
        ],
        119001: [
            42,
            41,
            41,
            38,
            43,
            43,
            '%'
        ],
        120001: [
            42,
            42,
            41,
            38,
            43,
            43,
            '%'
        ],
        125601: [
            42,
            42,
            42,
            38,
            43,
            43,
            '%'
        ],
        126001: [
            42,
            42,
            42,
            38,
            44,
            44,
            '%'
        ],
        127601: [
            42,
            42,
            42,
            39,
            44,
            44,
            '%'
        ],
        128401: [
            43,
            42,
            42,
            39,
            44,
            44,
            '%'
        ],
        129601: [
            43,
            43,
            42,
            39,
            44,
            44,
            '%'
        ],
        133601: [
            43,
            43,
            43,
            39,
            44,
            44,
            '%'
        ],
        139601: [
            44,
            43,
            43,
            39,
            44,
            44,
            '%'
        ],
        141001: [
            44,
            44,
            43,
            39,
            44,
            44,
            '%'
        ],
        142401: [
            44,
            44,
            43,
            40,
            44,
            44,
            '%'
        ],
        142801: [
            44,
            44,
            44,
            40,
            44,
            44,
            '%'
        ],
        143001: [
            44,
            44,
            44,
            40,
            45,
            45,
            '%'
        ],
        153001: [
            45,
            44,
            44,
            40,
            45,
            45,
            '%'
        ],
        154401: [
            45,
            45,
            45,
            40,
            45,
            45,
            '%'
        ],
        161401: [
            45,
            45,
            45,
            41,
            45,
            45,
            '%'
        ],
        165001: [
            45,
            45,
            45,
            41,
            46,
            46,
            '%'
        ],
        169201: [
            46,
            45,
            45,
            41,
            46,
            46,
            '%'
        ],
        170801: [
            46,
            46,
            46,
            41,
            46,
            46,
            '%'
        ],
        186001: [
            46,
            46,
            46,
            42,
            46,
            46,
            '%'
        ],
        195001: [
            47,
            47,
            47,
            42,
            47,
            47,
            '%'
        ],
        219401: [
            47,
            47,
            47,
            43,
            47,
            47,
            '%'
        ],
        238201: [
            48,
            48,
            48,
            43,
            48,
            48,
            '%'
        ],
        267801: [
            48,
            48,
            48,
            44,
            48,
            48,
            '%'
        ],
        306401: [
            49,
            49,
            49,
            44,
            49,
            49,
            '%'
        ],
        343001: [
            49,
            49,
            49,
            45,
            49,
            49,
            '%'
        ],
        429001: [
            50,
            50,
            50,
            45,
            50,
            50,
            '%'
        ],
        477601: [
            50,
            50,
            50,
            46,
            50,
            50,
            '%'
        ],
        715001: [
            51,
            51,
            51,
            46,
            51,
            51,
            '%'
        ],
        785201: [
            51,
            51,
            51,
            47,
            51,
            51,
            '%'
        ]
    },
    33: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            25
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            59
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            93
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            127
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            161
        ],
        2501: [
            195,
            0,
            183,
            12,
            195,
            195
        ],
        2601: [
            206,
            0,
            191,
            14,
            229,
            229
        ],
        2701: [
            216,
            0,
            200,
            16,
            263,
            263
        ],
        2801: [
            218,
            0,
            200,
            18,
            297,
            297
        ],
        2901: [
            229,
            0,
            208,
            21,
            331,
            331
        ],
        3001: [
            239,
            0,
            216,
            23,
            365,
            365
        ],
        3101: [
            250,
            0,
            225,
            25,
            399,
            399
        ],
        3201: [
            260,
            0,
            233,
            27,
            433,
            433
        ],
        3301: [
            271,
            0,
            241,
            29,
            467,
            467
        ],
        3401: [
            273,
            0,
            241,
            31,
            501,
            501
        ],
        3501: [
            284,
            0,
            250,
            34,
            535,
            535
        ],
        3601: [
            294,
            0,
            258,
            36,
            569,
            569
        ],
        3701: [
            305,
            0,
            266,
            38,
            603,
            603
        ],
        3801: [
            315,
            0,
            275,
            40,
            637,
            637
        ],
        3901: [
            325,
            0,
            283,
            42,
            671,
            671
        ],
        4001: [
            328,
            0,
            283,
            44,
            705,
            705
        ],
        4101: [
            338,
            0,
            291,
            46,
            739,
            739
        ],
        4201: [
            349,
            0,
            300,
            49,
            773,
            773
        ],
        4301: [
            359,
            0,
            308,
            62,
            807,
            807
        ],
        4401: [
            370,
            0,
            316,
            85,
            841,
            841
        ],
        4501: [
            380,
            0,
            325,
            109,
            875,
            875
        ],
        4601: [
            382,
            0,
            325,
            132,
            909,
            909
        ],
        4701: [
            392,
            0,
            333,
            155,
            937,
            937
        ],
        4801: [
            403,
            0,
            341,
            177,
            966,
            966
        ],
        4901: [
            413,
            0,
            350,
            200,
            994,
            994
        ],
        5001: [
            423,
            0,
            358,
            223,
            1019,
            1019
        ],
        5101: [
            433,
            0,
            366,
            246,
            1048,
            1048
        ],
        5201: [
            443,
            0,
            375,
            268,
            1073,
            1073
        ],
        5301: [
            445,
            22,
            376,
            291,
            1102,
            1102
        ],
        5401: [
            455,
            48,
            386,
            314,
            1130,
            1130
        ],
        5501: [
            465,
            73,
            396,
            336,
            1155,
            1155
        ],
        5601: [
            475,
            99,
            406,
            358,
            1183,
            1183
        ],
        5701: [
            485,
            124,
            416,
            380,
            1208,
            1208
        ],
        5801: [
            495,
            150,
            426,
            402,
            1235,
            1235
        ],
        5901: [
            497,
            175,
            427,
            425,
            1263,
            1263
        ],
        6001: [
            507,
            201,
            437,
            447,
            1288,
            1288
        ],
        6101: [
            517,
            226,
            447,
            469,
            1316,
            1316
        ],
        6201: [
            527,
            249,
            457,
            491,
            1341,
            1341
        ],
        6301: [
            537,
            274,
            467,
            513,
            1368,
            1368
        ],
        6401: [
            547,
            300,
            477,
            535,
            1396,
            1396
        ],
        6501: [
            549,
            325,
            479,
            557,
            1421,
            1421
        ],
        6601: [
            559,
            351,
            488,
            579,
            1449,
            1449
        ],
        6701: [
            569,
            376,
            498,
            601,
            1474,
            1474
        ],
        6801: [
            585,
            402,
            508,
            624,
            1501,
            1501
        ],
        6901: [
            606,
            427,
            518,
            646,
            1529,
            1529
        ],
        7001: [
            627,
            453,
            528,
            668,
            1554,
            1554
        ],
        7101: [
            647,
            478,
            530,
            690,
            1582,
            1582
        ],
        7201: [
            668,
            504,
            540,
            712,
            1607,
            1607
        ],
        7301: [
            689,
            529,
            550,
            734,
            1634,
            1634
        ],
        7401: [
            709,
            552,
            560,
            756,
            1662,
            1662
        ],
        7501: [
            730,
            577,
            570,
            779,
            1687,
            1687
        ],
        7601: [
            751,
            603,
            580,
            801,
            1715,
            1715
        ],
        7701: [
            771,
            628,
            589,
            823,
            1740,
            1740
        ],
        7801: [
            792,
            654,
            591,
            845,
            1767,
            1767
        ],
        7901: [
            812,
            679,
            601,
            867,
            1795,
            1795
        ],
        8001: [
            833,
            705,
            611,
            889,
            1820,
            1820
        ],
        8101: [
            854,
            730,
            621,
            911,
            1848,
            1848
        ],
        8201: [
            874,
            756,
            631,
            933,
            1873,
            1873
        ],
        8301: [
            895,
            781,
            641,
            956,
            1900,
            1900
        ],
        8401: [
            916,
            807,
            642,
            978,
            1928,
            1928
        ],
        8501: [
            936,
            832,
            652,
            1000,
            1953,
            1953
        ],
        8601: [
            957,
            855,
            662,
            1022,
            1981,
            1981
        ],
        8701: [
            977,
            881,
            672,
            1044,
            2006,
            2006
        ],
        8801: [
            998,
            906,
            682,
            1066,
            2033,
            2033
        ],
        8901: [
            1019,
            932,
            692,
            1088,
            2061,
            2061
        ],
        9001: [
            1039,
            957,
            694,
            1110,
            2086,
            2086
        ],
        9101: [
            1060,
            983,
            704,
            1133,
            2114,
            2114
        ],
        9201: [
            1081,
            1008,
            714,
            1155,
            2139,
            2139
        ],
        9301: [
            1101,
            1025,
            723,
            1177,
            2166,
            2166
        ],
        9401: [
            1122,
            1048,
            733,
            1199,
            2194,
            2194
        ],
        9501: [
            1143,
            1068,
            742,
            1221,
            2219,
            2219
        ],
        9601: [
            1163,
            1087,
            744,
            1243,
            2247,
            2247
        ],
        9701: [
            1184,
            1107,
            753,
            1265,
            2272,
            2272
        ],
        9801: [
            1204,
            1130,
            763,
            1287,
            2299,
            2299
        ],
        9901: [
            1225,
            1149,
            773,
            1310,
            2327,
            2327
        ],
        10001: [
            1246,
            1169,
            782,
            1332,
            2352,
            2352
        ],
        10101: [
            1266,
            1188,
            792,
            1354,
            2380,
            2380
        ],
        10201: [
            1287,
            1210,
            802,
            1376,
            2405,
            2405
        ],
        10301: [
            1308,
            1230,
            803,
            1398,
            2432,
            2432
        ],
        10401: [
            1328,
            1249,
            812,
            1420,
            2460,
            2460
        ],
        10501: [
            1349,
            1269,
            822,
            1442,
            2485,
            2485
        ],
        10601: [
            1370,
            1291,
            832,
            1464,
            2513,
            2513
        ],
        10701: [
            1390,
            1310,
            841,
            1486,
            2538,
            2538
        ],
        10801: [
            1411,
            1330,
            851,
            1509,
            2565,
            2565
        ],
        10901: [
            1431,
            1349,
            852,
            1531,
            2593,
            2593
        ],
        11001: [
            1452,
            1371,
            862,
            1553,
            2618,
            2618
        ],
        11101: [
            1473,
            1391,
            872,
            1575,
            2646,
            2646
        ],
        11201: [
            1493,
            1410,
            881,
            1597,
            2671,
            2671
        ],
        11301: [
            1514,
            1432,
            891,
            1619,
            2698,
            2698
        ],
        11401: [
            1535,
            1451,
            901,
            1642,
            2726,
            2726
        ],
        11501: [
            1555,
            1471,
            902,
            1664,
            2751,
            2751
        ],
        11601: [
            1576,
            1490,
            911,
            1686,
            2779,
            2779
        ],
        11701: [
            1596,
            1512,
            921,
            1708,
            2804,
            2804
        ],
        11801: [
            1617,
            1532,
            931,
            1730,
            2831,
            2831
        ],
        11901: [
            1638,
            1551,
            940,
            1752,
            2859,
            2859
        ],
        12001: [
            1658,
            1571,
            950,
            1774,
            2884,
            2884
        ],
        12101: [
            1679,
            1593,
            951,
            1796,
            2912,
            2912
        ],
        12201: [
            1700,
            1612,
            961,
            1818,
            2937,
            2937
        ],
        12301: [
            1720,
            1632,
            971,
            1841,
            2964,
            2964
        ],
        12401: [
            1741,
            1651,
            980,
            1863,
            2992,
            2992
        ],
        12501: [
            1762,
            1673,
            990,
            1885,
            3017,
            3017
        ],
        12601: [
            1782,
            1693,
            1000,
            1907,
            3045,
            3045
        ],
        12701: [
            1803,
            1712,
            1009,
            1929,
            3070,
            3070
        ],
        12801: [
            1823,
            1731,
            1010,
            1951,
            3097,
            3097
        ],
        12901: [
            1844,
            1753,
            1020,
            1973,
            3122,
            3122
        ],
        13001: [
            1865,
            1773,
            1030,
            1996,
            3156,
            3156
        ],
        13101: [
            1886,
            1792,
            1039,
            2018,
            3189,
            3189
        ],
        13201: [
            1907,
            1814,
            1049,
            2040,
            3222,
            3222
        ],
        13301: [
            1928,
            1834,
            1059,
            2063,
            3255,
            3255
        ],
        13401: [
            1949,
            1853,
            1060,
            2085,
            3289,
            3289
        ],
        13501: [
            1970,
            1873,
            1070,
            2108,
            3322,
            3322
        ],
        13601: [
            1990,
            1895,
            1079,
            2130,
            3355,
            3355
        ],
        13701: [
            2011,
            1914,
            1089,
            2153,
            3388,
            3388
        ],
        13801: [
            2032,
            1934,
            1099,
            2174,
            3421,
            3421
        ],
        13901: [
            2052,
            1953,
            1108,
            2196,
            3453,
            3453
        ],
        14001: [
            2072,
            1975,
            1110,
            2217,
            3486,
            3486
        ],
        14101: [
            2091,
            1995,
            1119,
            2239,
            3518,
            3518
        ],
        14201: [
            2111,
            2014,
            1129,
            2260,
            3550,
            3550
        ],
        14301: [
            2131,
            2033,
            1138,
            2281,
            3582,
            3582
        ],
        14401: [
            2151,
            2056,
            1148,
            2303,
            3614,
            3614
        ],
        14501: [
            2171,
            2075,
            1158,
            2324,
            3647,
            3647
        ],
        14601: [
            2191,
            2094,
            1159,
            2346,
            3679,
            3679
        ],
        14701: [
            2211,
            2114,
            1169,
            2367,
            3711,
            3711
        ],
        14801: [
            2231,
            2136,
            1178,
            2388,
            3746,
            3746
        ],
        14901: [
            2251,
            2155,
            1188,
            2410,
            3781,
            3781
        ],
        15001: [
            2271,
            2175,
            1198,
            2431,
            3816,
            3816
        ],
        15101: [
            2291,
            2194,
            1207,
            2453,
            3851,
            3851
        ],
        15201: [
            2311,
            2216,
            1217,
            2474,
            3889,
            3889
        ],
        15301: [
            2331,
            2236,
            1218,
            2496,
            3924,
            3924
        ],
        15401: [
            2352,
            2258,
            1228,
            2520,
            3958,
            3958
        ],
        15501: [
            2379,
            2285,
            1238,
            2548,
            3993,
            3993
        ],
        15601: [
            2406,
            2313,
            1248,
            2576,
            4028,
            4028
        ],
        15701: [
            2433,
            2341,
            1258,
            2604,
            4066,
            4066
        ],
        15801: [
            2460,
            2369,
            1268,
            2633,
            4101,
            4101
        ],
        15901: [
            2487,
            2396,
            1270,
            2661,
            4136,
            4136
        ],
        16001: [
            2514,
            2424,
            1280,
            2689,
            4171,
            4171
        ],
        16101: [
            2542,
            2452,
            1291,
            2717,
            4206,
            4206
        ],
        16201: [
            2569,
            2479,
            1301,
            2745,
            4243,
            4243
        ],
        16301: [
            2596,
            2507,
            1311,
            2774,
            4278,
            4278
        ],
        16401: [
            2623,
            2535,
            1321,
            2802,
            4313,
            4313
        ],
        16501: [
            2650,
            2563,
            1323,
            2830,
            4348,
            4348
        ],
        16601: [
            2677,
            2590,
            1333,
            2858,
            4383,
            4383
        ],
        16701: [
            2704,
            2618,
            1343,
            2887,
            4421,
            4421
        ],
        16801: [
            2731,
            2646,
            1353,
            2915,
            4456,
            4456
        ],
        16901: [
            2758,
            2673,
            1363,
            2943,
            4491,
            4491
        ],
        17001: [
            2785,
            2698,
            1373,
            2971,
            4526,
            4526
        ],
        17101: [
            2812,
            2726,
            1375,
            2999,
            4561,
            4561
        ],
        17201: [
            2839,
            2754,
            1385,
            3028,
            4598,
            4598
        ],
        17301: [
            2866,
            2781,
            1395,
            3056,
            4633,
            4633
        ],
        17401: [
            2893,
            2809,
            1405,
            3084,
            4668,
            4668
        ],
        17501: [
            2920,
            2837,
            1416,
            3112,
            4703,
            4703
        ],
        17601: [
            2947,
            2865,
            1426,
            3140,
            4738,
            4738
        ],
        17701: [
            2975,
            2892,
            1436,
            3169,
            4775,
            4775
        ],
        17801: [
            3002,
            2920,
            1438,
            3197,
            4810,
            4810
        ],
        17901: [
            3029,
            2948,
            1448,
            3225,
            4845,
            4845
        ],
        18001: [
            3056,
            2975,
            1458,
            3253,
            4880,
            4880
        ],
        18101: [
            3083,
            3003,
            1468,
            3281,
            4915,
            4915
        ],
        18201: [
            3110,
            3031,
            1478,
            3310,
            4953,
            4953
        ],
        18301: [
            3137,
            3059,
            1488,
            3338,
            4988,
            4988
        ],
        18401: [
            3164,
            3086,
            1490,
            3366,
            5023,
            5023
        ],
        18501: [
            3191,
            3114,
            1500,
            3394,
            5058,
            5058
        ],
        18601: [
            3218,
            3142,
            1510,
            3423,
            5093,
            5093
        ],
        18701: [
            3245,
            3169,
            1520,
            3451,
            5130,
            5130
        ],
        18801: [
            3272,
            3197,
            1531,
            3479,
            5165,
            5165
        ],
        18901: [
            3299,
            3225,
            1541,
            3507,
            5200,
            5200
        ],
        19001: [
            3326,
            3253,
            1543,
            3535,
            5235,
            5235
        ],
        19101: [
            3353,
            3280,
            1553,
            3564,
            5270,
            5270
        ],
        19201: [
            3380,
            3308,
            1563,
            3592,
            5308,
            5308
        ],
        19301: [
            3407,
            3336,
            1573,
            3620,
            5343,
            5343
        ],
        19401: [
            3435,
            3363,
            1583,
            3648,
            5377,
            5377
        ],
        19501: [
            3462,
            3391,
            1593,
            3676,
            5412,
            5412
        ],
        19601: [
            3489,
            3418,
            1595,
            3705,
            5447,
            5447
        ],
        19701: [
            3516,
            3445,
            1604,
            3733,
            5485,
            5485
        ],
        19801: [
            3543,
            3472,
            1613,
            3761,
            5520,
            5520
        ],
        19901: [
            3570,
            3499,
            1622,
            3789,
            5555,
            5555
        ],
        20001: [
            3624,
            3553,
            1641,
            3846,
            5625,
            5625
        ],
        20201: [
            3678,
            3604,
            1651,
            3902,
            5697,
            5697
        ],
        20401: [
            3732,
            3657,
            1670,
            3959,
            5767,
            5767
        ],
        20601: [
            3786,
            3711,
            1688,
            4015,
            5840,
            5840
        ],
        20801: [
            3840,
            3765,
            1699,
            4072,
            5910,
            5910
        ],
        21001: [
            3895,
            3819,
            1717,
            4128,
            5979,
            5979
        ],
        21201: [
            3949,
            3872,
            1736,
            4184,
            6052,
            6052
        ],
        21401: [
            4003,
            3926,
            1746,
            4241,
            6122,
            6122
        ],
        21601: [
            4057,
            3980,
            1765,
            4297,
            6194,
            6194
        ],
        21801: [
            4111,
            4034,
            1783,
            4354,
            6264,
            6264
        ],
        22001: [
            4165,
            4087,
            1794,
            4410,
            6334,
            6334
        ],
        22201: [
            4219,
            4141,
            1812,
            4467,
            6407,
            6407
        ],
        22401: [
            4273,
            4195,
            1831,
            4523,
            6477,
            6477
        ],
        22601: [
            4327,
            4249,
            1849,
            4579,
            6549,
            6549
        ],
        22801: [
            4383,
            4302,
            1860,
            4637,
            6620,
            6620
        ],
        23001: [
            4438,
            4356,
            1878,
            4695,
            6692,
            6692
        ],
        23201: [
            4494,
            4410,
            1897,
            4753,
            6766,
            6766
        ],
        23401: [
            4550,
            4461,
            1907,
            4811,
            6837,
            6837
        ],
        23601: [
            4606,
            4515,
            1926,
            4869,
            6912,
            6912
        ],
        23801: [
            4661,
            4566,
            1944,
            4927,
            6983,
            6983
        ],
        24001: [
            4717,
            4622,
            1954,
            4985,
            7055,
            7055
        ],
        24201: [
            4773,
            4676,
            1973,
            5043,
            7129,
            7129
        ],
        24401: [
            4829,
            4732,
            1992,
            5101,
            7200,
            7200
        ],
        24601: [
            4885,
            4786,
            2002,
            5160,
            7275,
            7275
        ],
        24801: [
            4940,
            4840,
            2021,
            5218,
            7346,
            7346
        ],
        25001: [
            4996,
            4896,
            2039,
            5276,
            7418,
            7418
        ],
        25201: [
            5052,
            4950,
            2050,
            5334,
            7492,
            7492
        ],
        25401: [
            5108,
            5004,
            2068,
            5392,
            7563,
            7563
        ],
        25601: [
            5163,
            5060,
            2087,
            5450,
            7638,
            7638
        ],
        25801: [
            5219,
            5114,
            2109,
            5508,
            7709,
            7709
        ],
        26001: [
            5275,
            5168,
            2163,
            5566,
            7781,
            7781
        ],
        26201: [
            5331,
            5224,
            2219,
            5624,
            7855,
            7855
        ],
        26401: [
            5386,
            5278,
            2273,
            5682,
            7926,
            7926
        ],
        26601: [
            5442,
            5334,
            2330,
            5740,
            8001,
            8001
        ],
        26801: [
            5498,
            5388,
            2383,
            5798,
            8072,
            8072
        ],
        27001: [
            5554,
            5442,
            2437,
            5856,
            8144,
            8144
        ],
        27201: [
            5609,
            5498,
            2494,
            5915,
            8218,
            8218
        ],
        27401: [
            5665,
            5552,
            2547,
            5973,
            8289,
            8289
        ],
        27601: [
            5721,
            5606,
            2601,
            6031,
            8364,
            8364
        ],
        27801: [
            5777,
            5662,
            2657,
            6089,
            8435,
            8435
        ],
        28001: [
            5833,
            5716,
            2711,
            6147,
            8507,
            8507
        ],
        28201: [
            5888,
            5773,
            2768,
            6205,
            8581,
            8581
        ],
        28401: [
            5944,
            5826,
            2821,
            6263,
            8652,
            8652
        ],
        28601: [
            6000,
            5880,
            2875,
            6321,
            8727,
            8727
        ],
        28801: [
            6056,
            5936,
            2932,
            6379,
            8798,
            8798
        ],
        29001: [
            6111,
            5990,
            2985,
            6437,
            8870,
            8870
        ],
        29201: [
            6167,
            6044,
            3039,
            6495,
            8944,
            8944
        ],
        29401: [
            6223,
            6100,
            3096,
            6553,
            9015,
            9015
        ],
        29601: [
            6279,
            6154,
            3149,
            6612,
            9090,
            9090
        ],
        29801: [
            6334,
            6211,
            3206,
            6670,
            9161,
            9161
        ],
        30001: [
            6390,
            6264,
            3259,
            6728,
            9233,
            9233
        ],
        30201: [
            6446,
            6318,
            3313,
            6786,
            9307,
            9307
        ],
        30401: [
            6502,
            6375,
            3370,
            6844,
            9378,
            9378
        ],
        30601: [
            6558,
            6428,
            3423,
            6902,
            9453,
            9453
        ],
        30801: [
            6613,
            6482,
            3477,
            6960,
            9524,
            9524
        ],
        31001: [
            6669,
            6538,
            3534,
            7018,
            9596,
            9596
        ],
        31201: [
            6725,
            6593,
            3588,
            7076,
            9670,
            9670
        ],
        31401: [
            6781,
            6648,
            3643,
            7134,
            9741,
            9741
        ],
        31601: [
            6836,
            6705,
            3700,
            7192,
            9816,
            9816
        ],
        31801: [
            6892,
            6760,
            3755,
            7250,
            9887,
            9887
        ],
        32001: [
            6948,
            6818,
            3813,
            7308,
            9959,
            9959
        ],
        32201: [
            7004,
            6873,
            3868,
            7367,
            10033,
            10033
        ],
        32401: [
            7059,
            6928,
            3923,
            7425,
            10104,
            10104
        ],
        32601: [
            7115,
            6986,
            3981,
            7483,
            10179,
            10179
        ],
        32801: [
            7171,
            7041,
            4036,
            7541,
            10250,
            10250
        ],
        33001: [
            7227,
            7096,
            4091,
            7599,
            10322,
            10322
        ],
        33201: [
            7283,
            7154,
            4149,
            7657,
            10396,
            10396
        ],
        33401: [
            7338,
            7209,
            4204,
            7715,
            10467,
            10467
        ],
        33601: [
            7394,
            7266,
            4261,
            7773,
            10542,
            10542
        ],
        33801: [
            7450,
            7321,
            4316,
            7831,
            10613,
            10613
        ],
        34001: [
            7506,
            7376,
            4371,
            7889,
            10685,
            10685
        ],
        34201: [
            7561,
            7434,
            4429,
            7947,
            10759,
            10759
        ],
        34401: [
            7617,
            7489,
            4484,
            8005,
            10830,
            10830
        ],
        34601: [
            7673,
            7544,
            4539,
            8064,
            10905,
            10905
        ],
        34801: [
            7729,
            7602,
            4597,
            8122,
            10976,
            10976
        ],
        35001: [
            7784,
            7657,
            4652,
            8180,
            11048,
            11048
        ],
        35201: [
            7840,
            7712,
            4707,
            8238,
            11122,
            11122
        ],
        35401: [
            7896,
            7770,
            4765,
            8296,
            11193,
            11193
        ],
        35601: [
            7952,
            7825,
            4820,
            8354,
            11268,
            11268
        ],
        35801: [
            8008,
            7882,
            4878,
            8412,
            11339,
            11339
        ],
        36001: [
            8063,
            7937,
            4932,
            8470,
            11411,
            11411
        ],
        36201: [
            8119,
            7992,
            4987,
            8528,
            11485,
            11485
        ],
        36401: [
            8175,
            8050,
            5045,
            8586,
            11556,
            11556
        ],
        36601: [
            8231,
            8105,
            5100,
            8644,
            11631,
            11631
        ],
        36801: [
            8286,
            8160,
            5155,
            8702,
            11702,
            11702
        ],
        37001: [
            8342,
            8218,
            5213,
            8761,
            11774,
            11774
        ],
        37201: [
            8398,
            8273,
            5268,
            8819,
            11848,
            11848
        ],
        37401: [
            8454,
            8331,
            5326,
            8877,
            11919,
            11919
        ],
        37601: [
            8509,
            8383,
            5378,
            8935,
            11988,
            11988
        ],
        37801: [
            8565,
            8438,
            5433,
            8992,
            12054,
            12054
        ],
        38001: [
            8620,
            8493,
            5488,
            9050,
            12120,
            12120
        ],
        38201: [
            8676,
            8551,
            5546,
            9108,
            12186,
            12186
        ],
        38401: [
            8733,
            8606,
            5601,
            9166,
            12252,
            12252
        ],
        38601: [
            8799,
            8672,
            5667,
            9224,
            12318,
            12318
        ],
        38801: [
            8865,
            8738,
            5733,
            9282,
            12384,
            12384
        ],
        39001: [
            8931,
            8804,
            5799,
            9340,
            12450,
            12450
        ],
        39201: [
            8997,
            8870,
            5865,
            9397,
            12516,
            12516
        ],
        39401: [
            9063,
            8936,
            5931,
            9455,
            12582,
            12582
        ],
        39601: [
            9129,
            9002,
            5997,
            9513,
            12648,
            12648
        ],
        39801: [
            9195,
            9068,
            6063,
            9571,
            12714,
            12714
        ],
        40001: [
            9261,
            9134,
            6129,
            9629,
            12780,
            12780
        ],
        40201: [
            9327,
            9200,
            6195,
            9687,
            12846,
            12846
        ],
        40401: [
            9393,
            9266,
            6261,
            9745,
            12912,
            12912
        ],
        40601: [
            9459,
            9332,
            6327,
            9802,
            12978,
            12978
        ],
        40801: [
            9525,
            9398,
            6393,
            9860,
            13044,
            13044
        ],
        41001: [
            9591,
            9464,
            6459,
            9918,
            13110,
            13110
        ],
        41201: [
            9657,
            9530,
            6525,
            9976,
            13176,
            13176
        ],
        41401: [
            9723,
            9596,
            6591,
            10034,
            13242,
            13242
        ],
        41601: [
            9789,
            9662,
            6657,
            10092,
            13308,
            13308
        ],
        41801: [
            9855,
            9728,
            6723,
            10149,
            13374,
            13374
        ],
        42001: [
            9921,
            9794,
            6789,
            10207,
            13440,
            13440
        ],
        42201: [
            9987,
            9860,
            6855,
            10265,
            13506,
            13506
        ],
        42401: [
            10053,
            9926,
            6921,
            10323,
            13572,
            13572
        ],
        42601: [
            10119,
            9992,
            6987,
            10381,
            13638,
            13638
        ],
        42801: [
            10185,
            10058,
            7053,
            10439,
            13704,
            13704
        ],
        43001: [
            10251,
            10124,
            7119,
            10497,
            13770,
            13770
        ],
        43201: [
            10317,
            10190,
            7185,
            10554,
            13836,
            13836
        ],
        43401: [
            10383,
            10256,
            7251,
            10612,
            13902,
            13902
        ],
        43601: [
            10449,
            10322,
            7317,
            10670,
            13968,
            13968
        ],
        43801: [
            10515,
            10388,
            7383,
            10728,
            14034,
            14034
        ],
        44001: [
            10581,
            10454,
            7449,
            10786,
            14100,
            14100
        ],
        44201: [
            10647,
            10520,
            7515,
            10844,
            14166,
            14166
        ],
        44401: [
            10713,
            10586,
            7581,
            10902,
            14232,
            14232
        ],
        44601: [
            10779,
            10652,
            7647,
            10959,
            14298,
            14298
        ],
        44801: [
            10845,
            10718,
            7713,
            11017,
            14364,
            14364
        ],
        45001: [
            10911,
            10784,
            7779,
            11075,
            14430,
            14430
        ],
        45201: [
            10977,
            10850,
            7845,
            11133,
            14496,
            14496
        ],
        45401: [
            11043,
            10916,
            7911,
            11191,
            14562,
            14562
        ],
        45601: [
            11109,
            10982,
            7977,
            11249,
            14628,
            14628
        ],
        45801: [
            11175,
            11048,
            8043,
            11306,
            14694,
            14694
        ],
        46001: [
            11241,
            11114,
            8109,
            11364,
            14760,
            14760
        ],
        46201: [
            11307,
            11180,
            8175,
            11422,
            14826,
            14826
        ],
        46401: [
            11373,
            11246,
            8241,
            11480,
            14892,
            14892
        ],
        46601: [
            11439,
            11312,
            8307,
            11538,
            14958,
            14958
        ],
        46801: [
            11505,
            11378,
            8373,
            11596,
            15024,
            15024
        ],
        47001: [
            11571,
            11444,
            8439,
            11654,
            15090,
            15090
        ],
        47201: [
            11637,
            11510,
            8505,
            11711,
            15156,
            15156
        ],
        47401: [
            11703,
            11576,
            8571,
            11769,
            15222,
            15222
        ],
        47601: [
            11769,
            11642,
            8637,
            11827,
            15288,
            15288
        ],
        47801: [
            11835,
            11708,
            8703,
            11885,
            15354,
            15354
        ],
        48001: [
            11901,
            11774,
            8769,
            11943,
            15420,
            15420
        ],
        48201: [
            11967,
            11840,
            8835,
            12001,
            15486,
            15486
        ],
        48401: [
            12033,
            11906,
            8901,
            12058,
            15552,
            15552
        ],
        48601: [
            12099,
            11972,
            8967,
            12116,
            15618,
            15618
        ],
        48801: [
            12165,
            12038,
            9033,
            12174,
            15684,
            15684
        ],
        49001: [
            12231,
            12104,
            9099,
            12232,
            15750,
            15750
        ],
        49201: [
            12297,
            12170,
            9165,
            12290,
            15816,
            15816
        ],
        49401: [
            12363,
            12236,
            9231,
            12348,
            15882,
            15882
        ],
        49601: [
            12429,
            12302,
            9297,
            12406,
            15948,
            15948
        ],
        49801: [
            12495,
            12368,
            9363,
            12463,
            16014,
            16014
        ],
        50001: [
            12561,
            12434,
            9433,
            12521,
            16080,
            16080
        ],
        50201: [
            12627,
            12500,
            9505,
            12579,
            16146,
            16146
        ],
        50401: [
            12693,
            12566,
            9577,
            12637,
            16212,
            16212
        ],
        50601: [
            12759,
            12632,
            9649,
            12695,
            16278,
            16278
        ],
        50801: [
            12825,
            12698,
            9721,
            12753,
            16344,
            16344
        ],
        51001: [
            12891,
            12764,
            9793,
            12811,
            16410,
            16410
        ],
        51201: [
            12982,
            12854,
            9890,
            12893,
            16501,
            16501
        ],
        51401: [
            13088,
            12962,
            10003,
            12991,
            16607,
            16607
        ],
        51601: [
            13194,
            13069,
            10117,
            13089,
            16713,
            16713
        ],
        51801: [
            13300,
            13173,
            10227,
            13187,
            16819,
            16819
        ],
        52001: [
            13406,
            13281,
            10340,
            13285,
            16925,
            16925
        ],
        52201: [
            13512,
            13388,
            10454,
            13383,
            17031,
            17031
        ],
        52401: [
            13618,
            13495,
            10567,
            13480,
            17137,
            17137
        ],
        52601: [
            13724,
            13602,
            10680,
            13578,
            17243,
            17243
        ],
        52801: [
            13830,
            13710,
            10793,
            13676,
            17349,
            17349
        ],
        53001: [
            13936,
            13817,
            10907,
            13774,
            17455,
            17455
        ],
        53201: [
            14042,
            13924,
            11020,
            13872,
            17561,
            17561
        ],
        53401: [
            14148,
            14029,
            11130,
            13970,
            17667,
            17667
        ],
        53601: [
            14254,
            14136,
            11244,
            14068,
            17773,
            17773
        ],
        53801: [
            14360,
            14243,
            11357,
            14165,
            17879,
            17879
        ],
        54001: [
            14466,
            14350,
            11470,
            14263,
            17985,
            17985
        ],
        54201: [
            14572,
            14458,
            11583,
            14361,
            18091,
            18091
        ],
        54401: [
            14678,
            14565,
            11697,
            14459,
            18197,
            18197
        ],
        54601: [
            14784,
            14672,
            11810,
            14557,
            18303,
            18303
        ],
        54801: [
            14890,
            14779,
            11923,
            14655,
            18409,
            18409
        ],
        55001: [
            14996,
            14887,
            12036,
            14753,
            18515,
            18515
        ],
        55201: [
            15102,
            14991,
            12147,
            14850,
            18621,
            18621
        ],
        55401: [
            15208,
            15098,
            12260,
            14948,
            18727,
            18727
        ],
        55601: [
            15314,
            15206,
            12373,
            15046,
            18833,
            18833
        ],
        55801: [
            15420,
            15313,
            12487,
            15144,
            18939,
            18939
        ],
        56001: [
            15526,
            15420,
            12600,
            15242,
            19045,
            19045
        ],
        56201: [
            15632,
            15527,
            12713,
            15340,
            19151,
            19151
        ],
        56401: [
            15738,
            15635,
            12826,
            15437,
            19257,
            19257
        ],
        56601: [
            15844,
            15742,
            12940,
            15535,
            19363,
            19363
        ],
        56801: [
            15950,
            15846,
            13050,
            15633,
            19469,
            19469
        ],
        57001: [
            16056,
            15954,
            13163,
            15731,
            19575,
            19575
        ],
        57201: [
            16162,
            16061,
            13277,
            15829,
            19681,
            19681
        ],
        57401: [
            16268,
            16168,
            13390,
            15927,
            19787,
            19787
        ],
        57601: [
            16374,
            16275,
            13503,
            16025,
            19893,
            19893
        ],
        57801: [
            16480,
            16383,
            13616,
            16122,
            19999,
            19999
        ],
        58001: [
            16586,
            16483,
            13723,
            16220,
            20105,
            20105
        ],
        58201: [
            16692,
            16589,
            13835,
            16318,
            20211,
            20211
        ],
        58401: [
            16798,
            16695,
            13947,
            16416,
            20317,
            20317
        ],
        58601: [
            16904,
            16801,
            14059,
            16514,
            20423,
            20423
        ],
        58801: [
            17010,
            16907,
            14171,
            16612,
            20529,
            20529
        ],
        59001: [
            17116,
            17013,
            14283,
            16709,
            20635,
            20635
        ],
        59201: [
            17222,
            17119,
            14395,
            16807,
            20741,
            20741
        ],
        59401: [
            17328,
            17225,
            14507,
            16905,
            20847,
            20847
        ],
        59601: [
            17434,
            17331,
            14619,
            17003,
            20953,
            20953
        ],
        59801: [
            17540,
            17437,
            14731,
            17101,
            21059,
            21059
        ],
        60001: [
            17646,
            17543,
            14843,
            17199,
            21165,
            21165
        ],
        60201: [
            17752,
            17649,
            14955,
            17297,
            21271,
            21271
        ],
        60401: [
            17858,
            17755,
            15067,
            17394,
            21377,
            21377
        ],
        60601: [
            17964,
            17861,
            15179,
            17492,
            21483,
            21483
        ],
        60801: [
            18070,
            17967,
            15291,
            17590,
            21589,
            21589
        ],
        61001: [
            18176,
            18073,
            15403,
            17688,
            21695,
            21695
        ],
        61201: [
            18282,
            18179,
            15515,
            17786,
            21801,
            21801
        ],
        61401: [
            18388,
            18285,
            15627,
            17884,
            21907,
            21907
        ],
        61601: [
            18494,
            18391,
            15739,
            17982,
            22013,
            22013
        ],
        61801: [
            18600,
            18497,
            15851,
            18079,
            22119,
            22119
        ],
        62001: [
            18706,
            18603,
            15963,
            18177,
            22225,
            22225
        ],
        62201: [
            18812,
            18709,
            16075,
            18275,
            22331,
            22331
        ],
        62401: [
            18918,
            18815,
            16187,
            18373,
            22437,
            22437
        ],
        62601: [
            19024,
            18921,
            16299,
            18471,
            22543,
            22543
        ],
        62801: [
            19130,
            19027,
            16411,
            18569,
            22649,
            22649
        ],
        63001: [
            19236,
            19133,
            16523,
            18666,
            22755,
            22755
        ],
        63201: [
            19342,
            19239,
            16635,
            18764,
            22861,
            22861
        ],
        63401: [
            19448,
            19345,
            16747,
            18862,
            22967,
            22967
        ],
        63601: [
            19554,
            19451,
            16859,
            18960,
            23073,
            23073
        ],
        63801: [
            19660,
            19557,
            16971,
            19058,
            23179,
            23179
        ],
        64001: [
            19766,
            19663,
            17083,
            19156,
            23285,
            23285
        ],
        64201: [
            19872,
            19769,
            17195,
            19254,
            23391,
            23391
        ],
        64401: [
            19978,
            19875,
            17307,
            19351,
            23497,
            23497
        ],
        64601: [
            20088,
            19990,
            17428,
            19449,
            23603,
            23603
        ],
        64801: [
            20200,
            20101,
            17544,
            19547,
            23709,
            23709
        ],
        65001: [
            20312,
            20215,
            17665,
            19645,
            23815,
            23815
        ],
        65201: [
            20424,
            20326,
            17782,
            19743,
            23921,
            23921
        ],
        65401: [
            20536,
            20436,
            17898,
            19841,
            24027,
            24027
        ],
        65601: [
            20648,
            20551,
            18019,
            19939,
            24133,
            24133
        ],
        65801: [
            20760,
            20662,
            18135,
            20036,
            24239,
            24239
        ],
        66001: [
            20872,
            20776,
            18256,
            20134,
            24345,
            24345
        ],
        66201: [
            20984,
            20887,
            18373,
            20232,
            24451,
            24451
        ],
        66401: [
            21096,
            20997,
            18489,
            20330,
            24557,
            24557
        ],
        66601: [
            21208,
            21112,
            18610,
            20428,
            24663,
            24663
        ],
        66801: [
            21320,
            21222,
            18726,
            20526,
            24769,
            24769
        ],
        67001: [
            21432,
            21333,
            18843,
            20623,
            24875,
            24875
        ],
        67201: [
            21544,
            21448,
            18964,
            20721,
            24981,
            24981
        ],
        67401: [
            21656,
            21558,
            19080,
            20819,
            25087,
            25087
        ],
        67601: [
            21768,
            21673,
            19201,
            20917,
            25193,
            25193
        ],
        67801: [
            21880,
            21783,
            19317,
            21015,
            25299,
            25299
        ],
        68001: [
            21992,
            21894,
            19434,
            21113,
            25405,
            25405
        ],
        68201: [
            22104,
            22009,
            19554,
            21211,
            25511,
            25511
        ],
        68401: [
            22216,
            22119,
            19671,
            21308,
            25617,
            25617
        ],
        68601: [
            22328,
            22229,
            19787,
            21406,
            25723,
            25723
        ],
        68801: [
            22440,
            22344,
            19908,
            21504,
            25829,
            25829
        ],
        69001: [
            22552,
            22455,
            20025,
            21602,
            25935,
            25935
        ],
        69201: [
            22664,
            22570,
            20145,
            21700,
            26041,
            26041
        ],
        69401: [
            22776,
            22680,
            20262,
            21798,
            26147,
            26147
        ],
        69601: [
            22888,
            22790,
            20378,
            21895,
            26253,
            26253
        ],
        69801: [
            23000,
            22905,
            20499,
            21993,
            26359,
            26359
        ],
        70001: [
            23112,
            23016,
            20616,
            22091,
            26465,
            26465
        ],
        70201: [
            23224,
            23126,
            20732,
            22189,
            26571,
            26571
        ],
        70401: [
            23336,
            23241,
            20853,
            22287,
            26677,
            26677
        ],
        70601: [
            23448,
            23351,
            20969,
            22385,
            26783,
            26783
        ],
        70801: [
            23560,
            23466,
            21090,
            22483,
            26889,
            26889
        ],
        71001: [
            23672,
            23577,
            21206,
            22580,
            26995,
            26995
        ],
        71201: [
            23784,
            23687,
            21323,
            22678,
            27101,
            27101
        ],
        71401: [
            23896,
            23802,
            21444,
            22776,
            27207,
            27207
        ],
        71601: [
            24008,
            23912,
            21560,
            22874,
            27313,
            27313
        ],
        71801: [
            24120,
            24027,
            21681,
            22972,
            27419,
            27419
        ],
        72001: [
            24232,
            24137,
            21797,
            23070,
            27525,
            27525
        ],
        72201: [
            24344,
            24248,
            21914,
            23168,
            27631,
            27631
        ],
        72401: [
            24456,
            24363,
            22035,
            23265,
            27737,
            27737
        ],
        72601: [
            24568,
            24473,
            22151,
            23363,
            27843,
            27843
        ],
        72801: [
            24680,
            24584,
            22267,
            23461,
            27949,
            27949
        ],
        73001: [
            24792,
            24698,
            22388,
            23559,
            28055,
            28055
        ],
        73201: [
            24904,
            24809,
            22505,
            23657,
            28161,
            28161
        ],
        73401: [
            25016,
            24924,
            22625,
            23755,
            28267,
            28267
        ],
        73601: [
            25128,
            25034,
            22742,
            23852,
            28373,
            28373
        ],
        73801: [
            25240,
            25144,
            22858,
            23950,
            28479,
            28479
        ],
        74001: [
            25352,
            25259,
            22979,
            24048,
            28585,
            28585
        ],
        74201: [
            25464,
            25370,
            23096,
            24146,
            28691,
            28691
        ],
        74401: [
            25576,
            25480,
            23212,
            24244,
            28797,
            28797
        ],
        74601: [
            25688,
            25595,
            23333,
            24342,
            28903,
            28903
        ],
        74801: [
            25800,
            25705,
            23449,
            24440,
            29009,
            29009
        ],
        75001: [
            25912,
            25820,
            23570,
            24537,
            29115,
            29115
        ],
        75201: [
            26024,
            25931,
            23686,
            24635,
            29221,
            29221
        ],
        75401: [
            26136,
            26041,
            23803,
            24733,
            29327,
            29327
        ],
        75601: [
            26248,
            26156,
            23924,
            24831,
            29433,
            29433
        ],
        75801: [
            26360,
            26266,
            24040,
            24929,
            29539,
            29539
        ],
        76001: [
            26472,
            26377,
            24157,
            25027,
            29645,
            29645
        ],
        76201: [
            26584,
            26492,
            24277,
            25125,
            29751,
            29751
        ],
        76401: [
            26696,
            26602,
            24394,
            25222,
            29857,
            29857
        ],
        76601: [
            26808,
            26717,
            24515,
            25320,
            29963,
            29963
        ],
        76801: [
            26920,
            26827,
            24631,
            25418,
            30069,
            30069
        ],
        77001: [
            27032,
            26938,
            24747,
            25516,
            30175,
            30175
        ],
        77201: [
            27144,
            27052,
            24868,
            25614,
            30281,
            30281
        ],
        77401: [
            27256,
            27163,
            24985,
            25712,
            30387,
            30387
        ],
        77601: [
            27368,
            27273,
            25101,
            25809,
            30493,
            30493
        ],
        77801: [
            27480,
            27388,
            25222,
            25907,
            30599,
            30599
        ],
        78001: [
            27592,
            27499,
            25338,
            26005,
            30705,
            30705
        ],
        78201: [
            27704,
            27613,
            25459,
            26103,
            30811,
            30811
        ],
        78401: [
            27816,
            27724,
            25576,
            26201,
            30917,
            30917
        ],
        78601: [
            27928,
            27834,
            25692,
            26299,
            31023,
            31023
        ],
        78801: [
            28040,
            27949,
            25813,
            26397,
            31129,
            31129
        ],
        79001: [
            28152,
            28059,
            25929,
            26494,
            31235,
            31235
        ],
        79201: [
            28264,
            28170,
            26046,
            26592,
            31341,
            31341
        ],
        79401: [
            28376,
            28285,
            26167,
            26690,
            31447,
            31447
        ],
        79601: [
            28488,
            28395,
            26283,
            26788,
            31553,
            31553
        ],
        79801: [
            28600,
            28510,
            26404,
            26886,
            31659,
            31659
        ],
        80001: [
            36,
            36,
            33,
            34,
            40,
            40,
            '%'
        ],
        81401: [
            36,
            36,
            34,
            34,
            40,
            40,
            '%'
        ],
        83001: [
            37,
            36,
            34,
            34,
            40,
            40,
            '%'
        ],
        83401: [
            37,
            37,
            34,
            34,
            40,
            40,
            '%'
        ],
        84801: [
            37,
            37,
            35,
            35,
            40,
            40,
            '%'
        ],
        85801: [
            37,
            37,
            35,
            35,
            41,
            41,
            '%'
        ],
        87401: [
            38,
            37,
            35,
            35,
            41,
            41,
            '%'
        ],
        88001: [
            38,
            38,
            35,
            35,
            41,
            41,
            '%'
        ],
        88401: [
            38,
            38,
            36,
            35,
            41,
            41,
            '%'
        ],
        91201: [
            38,
            38,
            36,
            36,
            41,
            41,
            '%'
        ],
        92401: [
            39,
            38,
            37,
            36,
            41,
            41,
            '%'
        ],
        93001: [
            39,
            39,
            37,
            36,
            41,
            41,
            '%'
        ],
        93201: [
            39,
            39,
            37,
            36,
            42,
            42,
            '%'
        ],
        96601: [
            39,
            39,
            38,
            36,
            42,
            42,
            '%'
        ],
        98001: [
            40,
            39,
            38,
            36,
            42,
            42,
            '%'
        ],
        98601: [
            40,
            40,
            38,
            37,
            42,
            42,
            '%'
        ],
        101401: [
            40,
            40,
            39,
            37,
            42,
            42,
            '%'
        ],
        102201: [
            40,
            40,
            39,
            37,
            43,
            43,
            '%'
        ],
        104401: [
            41,
            40,
            39,
            37,
            43,
            43,
            '%'
        ],
        105001: [
            41,
            41,
            39,
            37,
            43,
            43,
            '%'
        ],
        106401: [
            41,
            41,
            40,
            37,
            43,
            43,
            '%'
        ],
        107201: [
            41,
            41,
            40,
            38,
            43,
            43,
            '%'
        ],
        111601: [
            42,
            41,
            40,
            38,
            43,
            43,
            '%'
        ],
        112201: [
            42,
            42,
            41,
            38,
            43,
            43,
            '%'
        ],
        113001: [
            42,
            42,
            41,
            38,
            44,
            44,
            '%'
        ],
        117401: [
            42,
            42,
            41,
            39,
            44,
            44,
            '%'
        ],
        118601: [
            42,
            42,
            42,
            39,
            44,
            44,
            '%'
        ],
        119801: [
            43,
            42,
            42,
            39,
            44,
            44,
            '%'
        ],
        120401: [
            43,
            43,
            42,
            39,
            44,
            44,
            '%'
        ],
        125801: [
            43,
            43,
            43,
            39,
            44,
            44,
            '%'
        ],
        126201: [
            43,
            43,
            43,
            39,
            45,
            45,
            '%'
        ],
        129401: [
            44,
            43,
            43,
            39,
            45,
            45,
            '%'
        ],
        130001: [
            44,
            44,
            43,
            40,
            45,
            45,
            '%'
        ],
        134001: [
            44,
            44,
            44,
            40,
            45,
            45,
            '%'
        ],
        140801: [
            45,
            44,
            44,
            40,
            45,
            45,
            '%'
        ],
        141401: [
            45,
            45,
            44,
            40,
            45,
            45,
            '%'
        ],
        143201: [
            45,
            45,
            45,
            40,
            46,
            46,
            '%'
        ],
        145401: [
            45,
            45,
            45,
            41,
            46,
            46,
            '%'
        ],
        154201: [
            46,
            45,
            45,
            41,
            46,
            46,
            '%'
        ],
        154801: [
            46,
            46,
            46,
            41,
            46,
            46,
            '%'
        ],
        165001: [
            46,
            46,
            46,
            42,
            46,
            46,
            '%'
        ],
        165201: [
            46,
            46,
            46,
            42,
            47,
            47,
            '%'
        ],
        170401: [
            47,
            46,
            46,
            42,
            47,
            47,
            '%'
        ],
        171001: [
            47,
            47,
            47,
            42,
            47,
            47,
            '%'
        ],
        190601: [
            47,
            47,
            47,
            43,
            47,
            47,
            '%'
        ],
        195201: [
            48,
            48,
            48,
            43,
            48,
            48,
            '%'
        ],
        225801: [
            48,
            48,
            48,
            44,
            48,
            48,
            '%'
        ],
        238601: [
            49,
            49,
            49,
            44,
            49,
            49,
            '%'
        ],
        276801: [
            49,
            49,
            49,
            45,
            49,
            49,
            '%'
        ],
        306801: [
            50,
            50,
            50,
            45,
            50,
            50,
            '%'
        ],
        357801: [
            50,
            50,
            50,
            46,
            50,
            50,
            '%'
        ],
        429601: [
            51,
            51,
            51,
            46,
            51,
            51,
            '%'
        ],
        505201: [
            51,
            51,
            51,
            47,
            51,
            51,
            '%'
        ],
        716001: [
            52,
            52,
            52,
            47,
            52,
            52,
            '%'
        ],
        860001: [
            52,
            52,
            52,
            48,
            52,
            52,
            '%'
        ]
    },
    34: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            26
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            61
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            96
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            131
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            166
        ],
        2501: [
            195,
            0,
            183,
            12,
            201,
            201
        ],
        2601: [
            206,
            0,
            191,
            14,
            236,
            236
        ],
        2701: [
            216,
            0,
            200,
            16,
            271,
            271
        ],
        2801: [
            218,
            0,
            200,
            18,
            306,
            306
        ],
        2901: [
            229,
            0,
            208,
            21,
            341,
            341
        ],
        3001: [
            239,
            0,
            216,
            23,
            376,
            376
        ],
        3101: [
            250,
            0,
            225,
            25,
            411,
            411
        ],
        3201: [
            260,
            0,
            233,
            27,
            446,
            446
        ],
        3301: [
            271,
            0,
            241,
            29,
            481,
            481
        ],
        3401: [
            273,
            0,
            241,
            31,
            516,
            516
        ],
        3501: [
            284,
            0,
            250,
            34,
            551,
            551
        ],
        3601: [
            294,
            0,
            258,
            36,
            586,
            586
        ],
        3701: [
            305,
            0,
            266,
            38,
            621,
            621
        ],
        3801: [
            315,
            0,
            275,
            40,
            656,
            656
        ],
        3901: [
            325,
            0,
            283,
            42,
            691,
            691
        ],
        4001: [
            328,
            0,
            283,
            44,
            726,
            726
        ],
        4101: [
            338,
            0,
            291,
            46,
            761,
            761
        ],
        4201: [
            349,
            0,
            300,
            49,
            796,
            796
        ],
        4301: [
            359,
            0,
            308,
            63,
            831,
            831
        ],
        4401: [
            370,
            0,
            316,
            86,
            866,
            866
        ],
        4501: [
            380,
            0,
            325,
            110,
            901,
            901
        ],
        4601: [
            382,
            0,
            325,
            134,
            936,
            936
        ],
        4701: [
            392,
            0,
            333,
            158,
            965,
            965
        ],
        4801: [
            403,
            0,
            341,
            181,
            994,
            994
        ],
        4901: [
            413,
            0,
            350,
            204,
            1023,
            1023
        ],
        5001: [
            423,
            0,
            358,
            228,
            1049,
            1049
        ],
        5101: [
            433,
            0,
            366,
            251,
            1079,
            1079
        ],
        5201: [
            443,
            0,
            375,
            274,
            1105,
            1105
        ],
        5301: [
            445,
            23,
            376,
            298,
            1134,
            1134
        ],
        5401: [
            455,
            49,
            386,
            321,
            1163,
            1163
        ],
        5501: [
            465,
            75,
            396,
            344,
            1189,
            1189
        ],
        5601: [
            475,
            102,
            406,
            367,
            1218,
            1218
        ],
        5701: [
            485,
            128,
            416,
            390,
            1243,
            1243
        ],
        5801: [
            495,
            154,
            426,
            413,
            1272,
            1272
        ],
        5901: [
            497,
            180,
            427,
            435,
            1300,
            1300
        ],
        6001: [
            507,
            207,
            437,
            458,
            1326,
            1326
        ],
        6101: [
            517,
            233,
            447,
            481,
            1355,
            1355
        ],
        6201: [
            527,
            256,
            457,
            504,
            1380,
            1380
        ],
        6301: [
            537,
            282,
            467,
            527,
            1409,
            1409
        ],
        6401: [
            547,
            309,
            477,
            549,
            1437,
            1437
        ],
        6501: [
            549,
            335,
            479,
            572,
            1463,
            1463
        ],
        6601: [
            559,
            361,
            488,
            595,
            1492,
            1492
        ],
        6701: [
            580,
            387,
            498,
            618,
            1517,
            1517
        ],
        6801: [
            601,
            414,
            508,
            640,
            1546,
            1546
        ],
        6901: [
            622,
            440,
            518,
            663,
            1574,
            1574
        ],
        7001: [
            644,
            466,
            528,
            686,
            1600,
            1600
        ],
        7101: [
            665,
            492,
            530,
            709,
            1629,
            1629
        ],
        7201: [
            686,
            519,
            540,
            732,
            1654,
            1654
        ],
        7301: [
            707,
            545,
            550,
            754,
            1683,
            1683
        ],
        7401: [
            729,
            568,
            560,
            777,
            1711,
            1711
        ],
        7501: [
            750,
            594,
            570,
            800,
            1737,
            1737
        ],
        7601: [
            771,
            621,
            580,
            823,
            1766,
            1766
        ],
        7701: [
            792,
            647,
            589,
            845,
            1791,
            1791
        ],
        7801: [
            814,
            673,
            591,
            868,
            1820,
            1820
        ],
        7901: [
            835,
            699,
            601,
            891,
            1848,
            1848
        ],
        8001: [
            856,
            726,
            611,
            914,
            1874,
            1874
        ],
        8101: [
            877,
            752,
            621,
            937,
            1903,
            1903
        ],
        8201: [
            899,
            778,
            631,
            959,
            1928,
            1928
        ],
        8301: [
            920,
            804,
            641,
            982,
            1957,
            1957
        ],
        8401: [
            941,
            831,
            642,
            1005,
            1985,
            1985
        ],
        8501: [
            962,
            857,
            652,
            1028,
            2011,
            2011
        ],
        8601: [
            984,
            880,
            662,
            1051,
            2040,
            2040
        ],
        8701: [
            1005,
            907,
            672,
            1073,
            2065,
            2065
        ],
        8801: [
            1026,
            933,
            682,
            1096,
            2094,
            2094
        ],
        8901: [
            1047,
            959,
            692,
            1119,
            2122,
            2122
        ],
        9001: [
            1068,
            985,
            694,
            1142,
            2148,
            2148
        ],
        9101: [
            1090,
            1012,
            704,
            1165,
            2177,
            2177
        ],
        9201: [
            1111,
            1038,
            714,
            1187,
            2202,
            2202
        ],
        9301: [
            1132,
            1055,
            723,
            1210,
            2231,
            2231
        ],
        9401: [
            1154,
            1079,
            733,
            1233,
            2259,
            2259
        ],
        9501: [
            1175,
            1099,
            742,
            1256,
            2285,
            2285
        ],
        9601: [
            1196,
            1119,
            744,
            1279,
            2314,
            2314
        ],
        9701: [
            1217,
            1140,
            753,
            1301,
            2339,
            2339
        ],
        9801: [
            1239,
            1163,
            763,
            1324,
            2368,
            2368
        ],
        9901: [
            1260,
            1183,
            773,
            1347,
            2396,
            2396
        ],
        10001: [
            1281,
            1203,
            782,
            1370,
            2422,
            2422
        ],
        10101: [
            1302,
            1223,
            792,
            1393,
            2451,
            2451
        ],
        10201: [
            1323,
            1246,
            802,
            1415,
            2476,
            2476
        ],
        10301: [
            1345,
            1266,
            803,
            1438,
            2505,
            2505
        ],
        10401: [
            1366,
            1286,
            812,
            1461,
            2533,
            2533
        ],
        10501: [
            1387,
            1306,
            822,
            1483,
            2559,
            2559
        ],
        10601: [
            1408,
            1329,
            832,
            1506,
            2588,
            2588
        ],
        10701: [
            1430,
            1349,
            841,
            1529,
            2613,
            2613
        ],
        10801: [
            1451,
            1369,
            851,
            1552,
            2642,
            2642
        ],
        10901: [
            1472,
            1389,
            852,
            1575,
            2670,
            2670
        ],
        11001: [
            1493,
            1412,
            862,
            1597,
            2696,
            2696
        ],
        11101: [
            1515,
            1432,
            872,
            1620,
            2725,
            2725
        ],
        11201: [
            1536,
            1452,
            881,
            1643,
            2750,
            2750
        ],
        11301: [
            1557,
            1474,
            891,
            1666,
            2779,
            2779
        ],
        11401: [
            1579,
            1494,
            901,
            1689,
            2807,
            2807
        ],
        11501: [
            1600,
            1514,
            902,
            1711,
            2833,
            2833
        ],
        11601: [
            1621,
            1534,
            911,
            1734,
            2862,
            2862
        ],
        11701: [
            1642,
            1557,
            921,
            1757,
            2887,
            2887
        ],
        11801: [
            1663,
            1577,
            931,
            1780,
            2916,
            2916
        ],
        11901: [
            1685,
            1597,
            940,
            1803,
            2944,
            2944
        ],
        12001: [
            1706,
            1617,
            950,
            1825,
            2970,
            2970
        ],
        12101: [
            1727,
            1640,
            951,
            1848,
            2999,
            2999
        ],
        12201: [
            1748,
            1660,
            961,
            1871,
            3024,
            3024
        ],
        12301: [
            1770,
            1680,
            971,
            1894,
            3053,
            3053
        ],
        12401: [
            1791,
            1700,
            980,
            1917,
            3081,
            3081
        ],
        12501: [
            1812,
            1723,
            990,
            1939,
            3107,
            3107
        ],
        12601: [
            1833,
            1743,
            1000,
            1962,
            3136,
            3136
        ],
        12701: [
            1855,
            1763,
            1009,
            1985,
            3161,
            3161
        ],
        12801: [
            1876,
            1783,
            1010,
            2008,
            3190,
            3190
        ],
        12901: [
            1897,
            1805,
            1020,
            2030,
            3215,
            3215
        ],
        13001: [
            1919,
            1825,
            1030,
            2053,
            3250,
            3250
        ],
        13101: [
            1940,
            1845,
            1039,
            2076,
            3284,
            3284
        ],
        13201: [
            1962,
            1868,
            1049,
            2100,
            3318,
            3318
        ],
        13301: [
            1983,
            1888,
            1059,
            2123,
            3352,
            3352
        ],
        13401: [
            2005,
            1908,
            1060,
            2146,
            3387,
            3387
        ],
        13501: [
            2026,
            1928,
            1070,
            2169,
            3421,
            3421
        ],
        13601: [
            2048,
            1951,
            1079,
            2192,
            3455,
            3455
        ],
        13701: [
            2069,
            1971,
            1089,
            2215,
            3489,
            3489
        ],
        13801: [
            2090,
            1991,
            1099,
            2237,
            3523,
            3523
        ],
        13901: [
            2111,
            2011,
            1108,
            2259,
            3556,
            3556
        ],
        14001: [
            2131,
            2034,
            1110,
            2282,
            3590,
            3590
        ],
        14101: [
            2152,
            2054,
            1119,
            2304,
            3623,
            3623
        ],
        14201: [
            2172,
            2074,
            1129,
            2326,
            3656,
            3656
        ],
        14301: [
            2193,
            2094,
            1138,
            2348,
            3689,
            3689
        ],
        14401: [
            2213,
            2117,
            1148,
            2370,
            3723,
            3723
        ],
        14501: [
            2234,
            2137,
            1158,
            2392,
            3756,
            3756
        ],
        14601: [
            2254,
            2157,
            1159,
            2414,
            3789,
            3789
        ],
        14701: [
            2275,
            2177,
            1169,
            2436,
            3822,
            3822
        ],
        14801: [
            2296,
            2199,
            1178,
            2458,
            3858,
            3858
        ],
        14901: [
            2316,
            2219,
            1188,
            2480,
            3894,
            3894
        ],
        15001: [
            2337,
            2239,
            1198,
            2502,
            3930,
            3930
        ],
        15101: [
            2357,
            2259,
            1207,
            2524,
            3966,
            3966
        ],
        15201: [
            2378,
            2282,
            1217,
            2547,
            4005,
            4005
        ],
        15301: [
            2398,
            2302,
            1218,
            2569,
            4041,
            4041
        ],
        15401: [
            2420,
            2325,
            1228,
            2593,
            4077,
            4077
        ],
        15501: [
            2448,
            2353,
            1238,
            2622,
            4113,
            4113
        ],
        15601: [
            2476,
            2382,
            1248,
            2651,
            4149,
            4149
        ],
        15701: [
            2504,
            2411,
            1258,
            2681,
            4188,
            4188
        ],
        15801: [
            2532,
            2439,
            1268,
            2710,
            4224,
            4224
        ],
        15901: [
            2560,
            2468,
            1270,
            2739,
            4260,
            4260
        ],
        16001: [
            2588,
            2496,
            1280,
            2768,
            4296,
            4296
        ],
        16101: [
            2616,
            2525,
            1291,
            2797,
            4332,
            4332
        ],
        16201: [
            2644,
            2553,
            1301,
            2826,
            4371,
            4371
        ],
        16301: [
            2672,
            2582,
            1311,
            2855,
            4407,
            4407
        ],
        16401: [
            2699,
            2610,
            1321,
            2884,
            4443,
            4443
        ],
        16501: [
            2727,
            2639,
            1323,
            2913,
            4479,
            4479
        ],
        16601: [
            2755,
            2667,
            1333,
            2942,
            4515,
            4515
        ],
        16701: [
            2783,
            2696,
            1343,
            2971,
            4554,
            4554
        ],
        16801: [
            2811,
            2725,
            1353,
            3001,
            4590,
            4590
        ],
        16901: [
            2839,
            2753,
            1363,
            3030,
            4626,
            4626
        ],
        17001: [
            2867,
            2779,
            1373,
            3059,
            4662,
            4662
        ],
        17101: [
            2895,
            2807,
            1375,
            3088,
            4698,
            4698
        ],
        17201: [
            2923,
            2836,
            1385,
            3117,
            4737,
            4737
        ],
        17301: [
            2951,
            2864,
            1395,
            3146,
            4773,
            4773
        ],
        17401: [
            2978,
            2893,
            1405,
            3175,
            4809,
            4809
        ],
        17501: [
            3006,
            2921,
            1416,
            3204,
            4845,
            4845
        ],
        17601: [
            3034,
            2950,
            1426,
            3233,
            4881,
            4881
        ],
        17701: [
            3062,
            2979,
            1436,
            3262,
            4920,
            4920
        ],
        17801: [
            3090,
            3007,
            1438,
            3291,
            4956,
            4956
        ],
        17901: [
            3118,
            3036,
            1448,
            3320,
            4992,
            4992
        ],
        18001: [
            3146,
            3064,
            1458,
            3350,
            5028,
            5028
        ],
        18101: [
            3174,
            3093,
            1468,
            3379,
            5064,
            5064
        ],
        18201: [
            3202,
            3121,
            1478,
            3408,
            5102,
            5102
        ],
        18301: [
            3229,
            3150,
            1488,
            3437,
            5139,
            5139
        ],
        18401: [
            3257,
            3178,
            1490,
            3466,
            5175,
            5175
        ],
        18501: [
            3285,
            3207,
            1500,
            3495,
            5211,
            5211
        ],
        18601: [
            3313,
            3236,
            1510,
            3524,
            5247,
            5247
        ],
        18701: [
            3341,
            3264,
            1520,
            3553,
            5285,
            5285
        ],
        18801: [
            3369,
            3293,
            1531,
            3582,
            5321,
            5321
        ],
        18901: [
            3397,
            3321,
            1541,
            3611,
            5357,
            5357
        ],
        19001: [
            3425,
            3350,
            1543,
            3640,
            5393,
            5393
        ],
        19101: [
            3453,
            3378,
            1553,
            3670,
            5429,
            5429
        ],
        19201: [
            3480,
            3407,
            1563,
            3699,
            5468,
            5468
        ],
        19301: [
            3508,
            3435,
            1573,
            3728,
            5504,
            5504
        ],
        19401: [
            3536,
            3464,
            1583,
            3757,
            5540,
            5540
        ],
        19501: [
            3564,
            3492,
            1593,
            3786,
            5576,
            5576
        ],
        19601: [
            3592,
            3520,
            1595,
            3815,
            5612,
            5612
        ],
        19701: [
            3620,
            3548,
            1604,
            3844,
            5651,
            5651
        ],
        19801: [
            3648,
            3576,
            1613,
            3873,
            5687,
            5687
        ],
        19901: [
            3676,
            3603,
            1622,
            3902,
            5723,
            5723
        ],
        20001: [
            3732,
            3659,
            1641,
            3960,
            5795,
            5795
        ],
        20201: [
            3787,
            3712,
            1651,
            4019,
            5870,
            5870
        ],
        20401: [
            3843,
            3767,
            1670,
            4077,
            5942,
            5942
        ],
        20601: [
            3899,
            3822,
            1688,
            4135,
            6017,
            6017
        ],
        20801: [
            3955,
            3878,
            1699,
            4193,
            6089,
            6089
        ],
        21001: [
            4011,
            3933,
            1717,
            4251,
            6161,
            6161
        ],
        21201: [
            4066,
            3989,
            1736,
            4310,
            6236,
            6236
        ],
        21401: [
            4122,
            4044,
            1746,
            4368,
            6308,
            6308
        ],
        21601: [
            4178,
            4099,
            1765,
            4426,
            6383,
            6383
        ],
        21801: [
            4234,
            4155,
            1783,
            4484,
            6455,
            6455
        ],
        22001: [
            4289,
            4210,
            1794,
            4542,
            6527,
            6527
        ],
        22201: [
            4345,
            4266,
            1812,
            4600,
            6602,
            6602
        ],
        22401: [
            4401,
            4321,
            1831,
            4659,
            6674,
            6674
        ],
        22601: [
            4457,
            4377,
            1849,
            4717,
            6748,
            6748
        ],
        22801: [
            4514,
            4432,
            1860,
            4776,
            6821,
            6821
        ],
        23001: [
            4571,
            4487,
            1878,
            4836,
            6895,
            6895
        ],
        23201: [
            4629,
            4543,
            1897,
            4896,
            6972,
            6972
        ],
        23401: [
            4686,
            4595,
            1907,
            4955,
            7045,
            7045
        ],
        23601: [
            4743,
            4651,
            1926,
            5015,
            7122,
            7122
        ],
        23801: [
            4801,
            4703,
            1944,
            5075,
            7195,
            7195
        ],
        24001: [
            4858,
            4762,
            1954,
            5135,
            7269,
            7269
        ],
        24201: [
            4916,
            4817,
            1973,
            5195,
            7346,
            7346
        ],
        24401: [
            4973,
            4875,
            1992,
            5254,
            7419,
            7419
        ],
        24601: [
            5031,
            4931,
            2002,
            5314,
            7496,
            7496
        ],
        24801: [
            5088,
            4986,
            2021,
            5374,
            7569,
            7569
        ],
        25001: [
            5145,
            5044,
            2040,
            5434,
            7643,
            7643
        ],
        25201: [
            5203,
            5100,
            2095,
            5494,
            7720,
            7720
        ],
        25401: [
            5260,
            5155,
            2150,
            5554,
            7793,
            7793
        ],
        25601: [
            5318,
            5213,
            2208,
            5613,
            7870,
            7870
        ],
        25801: [
            5375,
            5269,
            2264,
            5673,
            7943,
            7943
        ],
        26001: [
            5433,
            5324,
            2319,
            5733,
            8017,
            8017
        ],
        26201: [
            5490,
            5382,
            2378,
            5793,
            8094,
            8094
        ],
        26401: [
            5548,
            5438,
            2433,
            5853,
            8167,
            8167
        ],
        26601: [
            5605,
            5496,
            2491,
            5913,
            8244,
            8244
        ],
        26801: [
            5662,
            5551,
            2547,
            5972,
            8317,
            8317
        ],
        27001: [
            5720,
            5607,
            2602,
            6032,
            8391,
            8391
        ],
        27201: [
            5777,
            5665,
            2660,
            6092,
            8468,
            8468
        ],
        27401: [
            5835,
            5720,
            2716,
            6152,
            8541,
            8541
        ],
        27601: [
            5892,
            5776,
            2771,
            6212,
            8618,
            8618
        ],
        27801: [
            5950,
            5834,
            2829,
            6271,
            8691,
            8691
        ],
        28001: [
            6007,
            5889,
            2885,
            6331,
            8765,
            8765
        ],
        28201: [
            6065,
            5948,
            2943,
            6391,
            8842,
            8842
        ],
        28401: [
            6122,
            6003,
            2998,
            6451,
            8915,
            8915
        ],
        28601: [
            6179,
            6058,
            3054,
            6511,
            8992,
            8992
        ],
        28801: [
            6237,
            6117,
            3112,
            6571,
            9065,
            9065
        ],
        29001: [
            6294,
            6172,
            3167,
            6630,
            9139,
            9139
        ],
        29201: [
            6352,
            6227,
            3223,
            6690,
            9216,
            9216
        ],
        29401: [
            6409,
            6286,
            3281,
            6750,
            9289,
            9289
        ],
        29601: [
            6467,
            6341,
            3336,
            6810,
            9366,
            9366
        ],
        29801: [
            6524,
            6399,
            3394,
            6870,
            9439,
            9439
        ],
        30001: [
            6581,
            6455,
            3450,
            6930,
            9513,
            9513
        ],
        30201: [
            6639,
            6510,
            3505,
            6989,
            9590,
            9590
        ],
        30401: [
            6696,
            6568,
            3564,
            7049,
            9663,
            9663
        ],
        30601: [
            6754,
            6624,
            3619,
            7109,
            9740,
            9740
        ],
        30801: [
            6811,
            6679,
            3674,
            7169,
            9813,
            9813
        ],
        31001: [
            6869,
            6737,
            3732,
            7229,
            9887,
            9887
        ],
        31201: [
            6926,
            6793,
            3788,
            7289,
            9964,
            9964
        ],
        31401: [
            6984,
            6850,
            3845,
            7348,
            10037,
            10037
        ],
        31601: [
            7041,
            6909,
            3904,
            7408,
            10114,
            10114
        ],
        31801: [
            7098,
            6966,
            3961,
            7468,
            10187,
            10187
        ],
        32001: [
            7156,
            7025,
            4021,
            7528,
            10261,
            10261
        ],
        32201: [
            7213,
            7082,
            4077,
            7588,
            10338,
            10338
        ],
        32401: [
            7271,
            7139,
            4134,
            7647,
            10411,
            10411
        ],
        32601: [
            7328,
            7198,
            4193,
            7707,
            10488,
            10488
        ],
        32801: [
            7386,
            7255,
            4250,
            7767,
            10561,
            10561
        ],
        33001: [
            7443,
            7312,
            4307,
            7827,
            10635,
            10635
        ],
        33201: [
            7501,
            7371,
            4366,
            7887,
            10712,
            10712
        ],
        33401: [
            7558,
            7428,
            4423,
            7947,
            10785,
            10785
        ],
        33601: [
            7615,
            7487,
            4482,
            8006,
            10862,
            10862
        ],
        33801: [
            7673,
            7544,
            4539,
            8066,
            10935,
            10935
        ],
        34001: [
            7730,
            7601,
            4596,
            8126,
            11009,
            11009
        ],
        34201: [
            7788,
            7660,
            4655,
            8186,
            11086,
            11086
        ],
        34401: [
            7845,
            7717,
            4712,
            8246,
            11159,
            11159
        ],
        34601: [
            7903,
            7773,
            4769,
            8306,
            11236,
            11236
        ],
        34801: [
            7960,
            7833,
            4828,
            8365,
            11309,
            11309
        ],
        35001: [
            8017,
            7890,
            4885,
            8425,
            11383,
            11383
        ],
        35201: [
            8075,
            7946,
            4941,
            8485,
            11460,
            11460
        ],
        35401: [
            8132,
            8006,
            5001,
            8545,
            11533,
            11533
        ],
        35601: [
            8190,
            8062,
            5058,
            8605,
            11610,
            11610
        ],
        35801: [
            8247,
            8122,
            5117,
            8664,
            11683,
            11683
        ],
        36001: [
            8305,
            8179,
            5174,
            8724,
            11757,
            11757
        ],
        36201: [
            8362,
            8235,
            5230,
            8784,
            11834,
            11834
        ],
        36401: [
            8420,
            8295,
            5290,
            8844,
            11907,
            11907
        ],
        36601: [
            8477,
            8351,
            5347,
            8904,
            11984,
            11984
        ],
        36801: [
            8534,
            8408,
            5403,
            8964,
            12057,
            12057
        ],
        37001: [
            8592,
            8468,
            5463,
            9023,
            12131,
            12131
        ],
        37201: [
            8649,
            8524,
            5519,
            9083,
            12208,
            12208
        ],
        37401: [
            8707,
            8584,
            5579,
            9143,
            12281,
            12281
        ],
        37601: [
            8764,
            8638,
            5633,
            9203,
            12352,
            12352
        ],
        37801: [
            8821,
            8694,
            5689,
            9262,
            12420,
            12420
        ],
        38001: [
            8878,
            8751,
            5746,
            9322,
            12488,
            12488
        ],
        38201: [
            8936,
            8810,
            5806,
            9382,
            12556,
            12556
        ],
        38401: [
            8994,
            8867,
            5862,
            9441,
            12624,
            12624
        ],
        38601: [
            9062,
            8935,
            5930,
            9501,
            12692,
            12692
        ],
        38801: [
            9130,
            9003,
            5998,
            9560,
            12760,
            12760
        ],
        39001: [
            9198,
            9071,
            6066,
            9620,
            12828,
            12828
        ],
        39201: [
            9266,
            9139,
            6134,
            9679,
            12896,
            12896
        ],
        39401: [
            9334,
            9207,
            6202,
            9739,
            12964,
            12964
        ],
        39601: [
            9402,
            9275,
            6270,
            9799,
            13032,
            13032
        ],
        39801: [
            9470,
            9343,
            6338,
            9858,
            13100,
            13100
        ],
        40001: [
            9538,
            9411,
            6406,
            9918,
            13168,
            13168
        ],
        40201: [
            9606,
            9479,
            6474,
            9977,
            13236,
            13236
        ],
        40401: [
            9674,
            9547,
            6542,
            10037,
            13304,
            13304
        ],
        40601: [
            9742,
            9615,
            6610,
            10097,
            13372,
            13372
        ],
        40801: [
            9810,
            9683,
            6678,
            10156,
            13440,
            13440
        ],
        41001: [
            9878,
            9751,
            6746,
            10216,
            13508,
            13508
        ],
        41201: [
            9946,
            9819,
            6814,
            10276,
            13576,
            13576
        ],
        41401: [
            10014,
            9887,
            6882,
            10335,
            13644,
            13644
        ],
        41601: [
            10082,
            9955,
            6950,
            10395,
            13712,
            13712
        ],
        41801: [
            10150,
            10023,
            7018,
            10454,
            13780,
            13780
        ],
        42001: [
            10218,
            10091,
            7086,
            10514,
            13848,
            13848
        ],
        42201: [
            10286,
            10159,
            7154,
            10573,
            13916,
            13916
        ],
        42401: [
            10354,
            10227,
            7222,
            10633,
            13984,
            13984
        ],
        42601: [
            10422,
            10295,
            7290,
            10693,
            14052,
            14052
        ],
        42801: [
            10490,
            10363,
            7358,
            10752,
            14120,
            14120
        ],
        43001: [
            10558,
            10431,
            7426,
            10812,
            14188,
            14188
        ],
        43201: [
            10626,
            10499,
            7494,
            10871,
            14256,
            14256
        ],
        43401: [
            10694,
            10567,
            7562,
            10931,
            14324,
            14324
        ],
        43601: [
            10762,
            10635,
            7630,
            10991,
            14392,
            14392
        ],
        43801: [
            10830,
            10703,
            7698,
            11050,
            14460,
            14460
        ],
        44001: [
            10898,
            10771,
            7766,
            11110,
            14528,
            14528
        ],
        44201: [
            10966,
            10839,
            7834,
            11169,
            14596,
            14596
        ],
        44401: [
            11034,
            10907,
            7902,
            11229,
            14664,
            14664
        ],
        44601: [
            11102,
            10975,
            7970,
            11289,
            14732,
            14732
        ],
        44801: [
            11170,
            11043,
            8038,
            11348,
            14800,
            14800
        ],
        45001: [
            11238,
            11111,
            8106,
            11408,
            14868,
            14868
        ],
        45201: [
            11306,
            11179,
            8174,
            11467,
            14936,
            14936
        ],
        45401: [
            11374,
            11247,
            8242,
            11527,
            15004,
            15004
        ],
        45601: [
            11442,
            11315,
            8310,
            11586,
            15072,
            15072
        ],
        45801: [
            11510,
            11383,
            8378,
            11646,
            15140,
            15140
        ],
        46001: [
            11578,
            11451,
            8446,
            11706,
            15208,
            15208
        ],
        46201: [
            11646,
            11519,
            8514,
            11765,
            15276,
            15276
        ],
        46401: [
            11714,
            11587,
            8582,
            11825,
            15344,
            15344
        ],
        46601: [
            11782,
            11655,
            8650,
            11884,
            15412,
            15412
        ],
        46801: [
            11850,
            11723,
            8718,
            11944,
            15480,
            15480
        ],
        47001: [
            11918,
            11791,
            8786,
            12004,
            15548,
            15548
        ],
        47201: [
            11986,
            11859,
            8854,
            12063,
            15616,
            15616
        ],
        47401: [
            12054,
            11927,
            8922,
            12123,
            15684,
            15684
        ],
        47601: [
            12122,
            11995,
            8990,
            12182,
            15752,
            15752
        ],
        47801: [
            12190,
            12063,
            9058,
            12242,
            15820,
            15820
        ],
        48001: [
            12258,
            12131,
            9126,
            12302,
            15888,
            15888
        ],
        48201: [
            12326,
            12199,
            9194,
            12361,
            15956,
            15956
        ],
        48401: [
            12394,
            12267,
            9262,
            12421,
            16024,
            16024
        ],
        48601: [
            12462,
            12335,
            9330,
            12480,
            16092,
            16092
        ],
        48801: [
            12530,
            12403,
            9398,
            12540,
            16160,
            16160
        ],
        49001: [
            12598,
            12471,
            9466,
            12600,
            16228,
            16228
        ],
        49201: [
            12666,
            12539,
            9534,
            12659,
            16296,
            16296
        ],
        49401: [
            12734,
            12607,
            9602,
            12719,
            16364,
            16364
        ],
        49601: [
            12802,
            12675,
            9670,
            12778,
            16432,
            16432
        ],
        49801: [
            12870,
            12743,
            9738,
            12838,
            16500,
            16500
        ],
        50001: [
            12938,
            12811,
            9811,
            12898,
            16568,
            16568
        ],
        50201: [
            13006,
            12879,
            9885,
            12957,
            16636,
            16636
        ],
        50401: [
            13074,
            12947,
            9959,
            13017,
            16704,
            16704
        ],
        50601: [
            13142,
            13015,
            10033,
            13076,
            16772,
            16772
        ],
        50801: [
            13210,
            13083,
            10107,
            13136,
            16840,
            16840
        ],
        51001: [
            13278,
            13151,
            10181,
            13195,
            16908,
            16908
        ],
        51201: [
            13371,
            13245,
            10280,
            13280,
            17001,
            17001
        ],
        51401: [
            13479,
            13355,
            10397,
            13380,
            17109,
            17109
        ],
        51601: [
            13587,
            13466,
            10513,
            13479,
            17217,
            17217
        ],
        51801: [
            13695,
            13573,
            10627,
            13579,
            17325,
            17325
        ],
        52001: [
            13803,
            13684,
            10744,
            13678,
            17433,
            17433
        ],
        52201: [
            13911,
            13794,
            10860,
            13778,
            17541,
            17541
        ],
        52401: [
            14019,
            13905,
            10977,
            13878,
            17649,
            17649
        ],
        52601: [
            14127,
            14015,
            11093,
            13977,
            17757,
            17757
        ],
        52801: [
            14235,
            14126,
            11210,
            14077,
            17865,
            17865
        ],
        53001: [
            14343,
            14236,
            11326,
            14176,
            17973,
            17973
        ],
        53201: [
            14451,
            14347,
            11443,
            14276,
            18081,
            18081
        ],
        53401: [
            14559,
            14454,
            11556,
            14376,
            18189,
            18189
        ],
        53601: [
            14667,
            14565,
            11673,
            14475,
            18297,
            18297
        ],
        53801: [
            14775,
            14675,
            11789,
            14575,
            18405,
            18405
        ],
        54001: [
            14883,
            14786,
            11906,
            14674,
            18513,
            18513
        ],
        54201: [
            14991,
            14896,
            12022,
            14774,
            18621,
            18621
        ],
        54401: [
            15099,
            15007,
            12139,
            14874,
            18729,
            18729
        ],
        54601: [
            15207,
            15117,
            12255,
            14973,
            18837,
            18837
        ],
        54801: [
            15315,
            15228,
            12372,
            15073,
            18945,
            18945
        ],
        55001: [
            15423,
            15338,
            12488,
            15172,
            19053,
            19053
        ],
        55201: [
            15531,
            15446,
            12602,
            15272,
            19161,
            19161
        ],
        55401: [
            15639,
            15557,
            12718,
            15372,
            19269,
            19269
        ],
        55601: [
            15747,
            15667,
            12835,
            15471,
            19377,
            19377
        ],
        55801: [
            15855,
            15778,
            12951,
            15571,
            19485,
            19485
        ],
        56001: [
            15963,
            15888,
            13068,
            15670,
            19593,
            19593
        ],
        56201: [
            16071,
            15999,
            13184,
            15770,
            19701,
            19701
        ],
        56401: [
            16179,
            16109,
            13301,
            15869,
            19809,
            19809
        ],
        56601: [
            16287,
            16220,
            13417,
            15969,
            19917,
            19917
        ],
        56801: [
            16395,
            16327,
            13531,
            16069,
            20025,
            20025
        ],
        57001: [
            16503,
            16438,
            13648,
            16168,
            20133,
            20133
        ],
        57201: [
            16611,
            16548,
            13764,
            16268,
            20241,
            20241
        ],
        57401: [
            16719,
            16659,
            13881,
            16367,
            20349,
            20349
        ],
        57601: [
            16827,
            16769,
            13997,
            16467,
            20457,
            20457
        ],
        57801: [
            16935,
            16880,
            14114,
            16567,
            20565,
            20565
        ],
        58001: [
            17043,
            16983,
            14223,
            16666,
            20673,
            20673
        ],
        58201: [
            17151,
            17091,
            14337,
            16766,
            20781,
            20781
        ],
        58401: [
            17259,
            17199,
            14451,
            16865,
            20889,
            20889
        ],
        58601: [
            17367,
            17307,
            14565,
            16965,
            20997,
            20997
        ],
        58801: [
            17475,
            17415,
            14679,
            17065,
            21105,
            21105
        ],
        59001: [
            17583,
            17523,
            14793,
            17164,
            21213,
            21213
        ],
        59201: [
            17691,
            17631,
            14907,
            17264,
            21321,
            21321
        ],
        59401: [
            17799,
            17739,
            15021,
            17363,
            21429,
            21429
        ],
        59601: [
            17907,
            17847,
            15135,
            17463,
            21537,
            21537
        ],
        59801: [
            18015,
            17955,
            15249,
            17563,
            21645,
            21645
        ],
        60001: [
            18123,
            18063,
            15363,
            17662,
            21753,
            21753
        ],
        60201: [
            18231,
            18171,
            15477,
            17762,
            21861,
            21861
        ],
        60401: [
            18339,
            18279,
            15591,
            17861,
            21969,
            21969
        ],
        60601: [
            18447,
            18387,
            15705,
            17961,
            22077,
            22077
        ],
        60801: [
            18555,
            18495,
            15819,
            18061,
            22185,
            22185
        ],
        61001: [
            18663,
            18603,
            15933,
            18160,
            22293,
            22293
        ],
        61201: [
            18771,
            18711,
            16047,
            18260,
            22401,
            22401
        ],
        61401: [
            18879,
            18819,
            16161,
            18359,
            22509,
            22509
        ],
        61601: [
            18987,
            18927,
            16275,
            18459,
            22617,
            22617
        ],
        61801: [
            19095,
            19035,
            16389,
            18558,
            22725,
            22725
        ],
        62001: [
            19203,
            19143,
            16503,
            18658,
            22833,
            22833
        ],
        62201: [
            19311,
            19251,
            16617,
            18758,
            22941,
            22941
        ],
        62401: [
            19419,
            19359,
            16731,
            18857,
            23049,
            23049
        ],
        62601: [
            19527,
            19467,
            16845,
            18957,
            23157,
            23157
        ],
        62801: [
            19635,
            19575,
            16959,
            19056,
            23265,
            23265
        ],
        63001: [
            19743,
            19683,
            17073,
            19156,
            23373,
            23373
        ],
        63201: [
            19851,
            19791,
            17187,
            19256,
            23481,
            23481
        ],
        63401: [
            19959,
            19899,
            17301,
            19355,
            23589,
            23589
        ],
        63601: [
            20067,
            20007,
            17415,
            19455,
            23697,
            23697
        ],
        63801: [
            20175,
            20115,
            17529,
            19554,
            23805,
            23805
        ],
        64001: [
            20283,
            20223,
            17643,
            19654,
            23913,
            23913
        ],
        64201: [
            20391,
            20331,
            17757,
            19754,
            24021,
            24021
        ],
        64401: [
            20499,
            20439,
            17871,
            19853,
            24129,
            24129
        ],
        64601: [
            20612,
            20556,
            17994,
            19953,
            24237,
            24237
        ],
        64801: [
            20726,
            20669,
            18112,
            20052,
            24345,
            24345
        ],
        65001: [
            20840,
            20786,
            18235,
            20152,
            24453,
            24453
        ],
        65201: [
            20954,
            20898,
            18354,
            20252,
            24561,
            24561
        ],
        65401: [
            21068,
            21011,
            18472,
            20351,
            24669,
            24669
        ],
        65601: [
            21182,
            21128,
            18595,
            20451,
            24777,
            24777
        ],
        65801: [
            21296,
            21240,
            18714,
            20550,
            24885,
            24885
        ],
        66001: [
            21410,
            21357,
            18837,
            20650,
            24993,
            24993
        ],
        66201: [
            21524,
            21470,
            18955,
            20750,
            25101,
            25101
        ],
        66401: [
            21638,
            21582,
            19074,
            20849,
            25209,
            25209
        ],
        66601: [
            21752,
            21699,
            19197,
            20949,
            25317,
            25317
        ],
        66801: [
            21866,
            21812,
            19315,
            21048,
            25425,
            25425
        ],
        67001: [
            21980,
            21924,
            19434,
            21148,
            25533,
            25533
        ],
        67201: [
            22094,
            22041,
            19557,
            21248,
            25641,
            25641
        ],
        67401: [
            22208,
            22154,
            19675,
            21347,
            25749,
            25749
        ],
        67601: [
            22322,
            22271,
            19798,
            21447,
            25857,
            25857
        ],
        67801: [
            22436,
            22383,
            19917,
            21546,
            25965,
            25965
        ],
        68001: [
            22550,
            22496,
            20035,
            21646,
            26073,
            26073
        ],
        68201: [
            22664,
            22613,
            20158,
            21745,
            26181,
            26181
        ],
        68401: [
            22778,
            22725,
            20277,
            21845,
            26289,
            26289
        ],
        68601: [
            22892,
            22838,
            20395,
            21945,
            26397,
            26397
        ],
        68801: [
            23006,
            22955,
            20518,
            22044,
            26505,
            26505
        ],
        69001: [
            23120,
            23067,
            20637,
            22144,
            26613,
            26613
        ],
        69201: [
            23234,
            23184,
            20760,
            22243,
            26721,
            26721
        ],
        69401: [
            23348,
            23297,
            20878,
            22343,
            26829,
            26829
        ],
        69601: [
            23462,
            23409,
            20997,
            22443,
            26937,
            26937
        ],
        69801: [
            23576,
            23526,
            21120,
            22542,
            27045,
            27045
        ],
        70001: [
            23690,
            23639,
            21238,
            22642,
            27153,
            27153
        ],
        70201: [
            23804,
            23751,
            21357,
            22741,
            27261,
            27261
        ],
        70401: [
            23918,
            23868,
            21480,
            22841,
            27369,
            27369
        ],
        70601: [
            24032,
            23981,
            21598,
            22941,
            27477,
            27477
        ],
        70801: [
            24146,
            24098,
            21721,
            23040,
            27585,
            27585
        ],
        71001: [
            24260,
            24210,
            21840,
            23140,
            27693,
            27693
        ],
        71201: [
            24374,
            24323,
            21958,
            23239,
            27801,
            27801
        ],
        71401: [
            24488,
            24440,
            22081,
            23339,
            27909,
            27909
        ],
        71601: [
            24602,
            24552,
            22200,
            23439,
            28017,
            28017
        ],
        71801: [
            24716,
            24669,
            22323,
            23538,
            28125,
            28125
        ],
        72001: [
            24830,
            24782,
            22441,
            23638,
            28233,
            28233
        ],
        72201: [
            24944,
            24894,
            22560,
            23737,
            28341,
            28341
        ],
        72401: [
            25058,
            25011,
            22683,
            23837,
            28449,
            28449
        ],
        72601: [
            25172,
            25124,
            22801,
            23937,
            28557,
            28557
        ],
        72801: [
            25286,
            25236,
            22920,
            24036,
            28665,
            28665
        ],
        73001: [
            25400,
            25353,
            23043,
            24136,
            28773,
            28773
        ],
        73201: [
            25514,
            25466,
            23161,
            24235,
            28881,
            28881
        ],
        73401: [
            25628,
            25583,
            23284,
            24335,
            28989,
            28989
        ],
        73601: [
            25742,
            25695,
            23403,
            24434,
            29097,
            29097
        ],
        73801: [
            25856,
            25808,
            23521,
            24534,
            29205,
            29205
        ],
        74001: [
            25970,
            25925,
            23644,
            24634,
            29313,
            29313
        ],
        74201: [
            26084,
            26037,
            23763,
            24733,
            29421,
            29421
        ],
        74401: [
            26198,
            26150,
            23881,
            24833,
            29529,
            29529
        ],
        74601: [
            26312,
            26267,
            24004,
            24932,
            29637,
            29637
        ],
        74801: [
            26426,
            26379,
            24123,
            25032,
            29745,
            29745
        ],
        75001: [
            26540,
            26496,
            24246,
            25132,
            29853,
            29853
        ],
        75201: [
            26654,
            26609,
            24364,
            25231,
            29961,
            29961
        ],
        75401: [
            26768,
            26721,
            24483,
            25331,
            30069,
            30069
        ],
        75601: [
            26882,
            26838,
            24606,
            25430,
            30177,
            30177
        ],
        75801: [
            26996,
            26951,
            24724,
            25530,
            30285,
            30285
        ],
        76001: [
            27110,
            27063,
            24843,
            25630,
            30393,
            30393
        ],
        76201: [
            27224,
            27180,
            24966,
            25729,
            30501,
            30501
        ],
        76401: [
            27338,
            27293,
            25084,
            25829,
            30609,
            30609
        ],
        76601: [
            27452,
            27410,
            25207,
            25928,
            30717,
            30717
        ],
        76801: [
            27566,
            27522,
            25326,
            26028,
            30825,
            30825
        ],
        77001: [
            27680,
            27635,
            25444,
            26128,
            30933,
            30933
        ],
        77201: [
            27794,
            27752,
            25567,
            26227,
            31041,
            31041
        ],
        77401: [
            27908,
            27864,
            25686,
            26327,
            31149,
            31149
        ],
        77601: [
            28022,
            27977,
            25804,
            26426,
            31257,
            31257
        ],
        77801: [
            28136,
            28094,
            25927,
            26526,
            31365,
            31365
        ],
        78001: [
            28250,
            28206,
            26046,
            26626,
            31473,
            31473
        ],
        78201: [
            28364,
            28323,
            26169,
            26725,
            31581,
            31581
        ],
        78401: [
            28478,
            28436,
            26287,
            26825,
            31689,
            31689
        ],
        78601: [
            28592,
            28548,
            26406,
            26924,
            31797,
            31797
        ],
        78801: [
            28706,
            28665,
            26529,
            27024,
            31905,
            31905
        ],
        79001: [
            28820,
            28778,
            26647,
            27123,
            32013,
            32013
        ],
        79201: [
            28934,
            28890,
            26766,
            27223,
            32121,
            32121
        ],
        79401: [
            29048,
            29007,
            26889,
            27323,
            32229,
            32229
        ],
        79601: [
            29162,
            29120,
            27007,
            27422,
            32337,
            32337
        ],
        79801: [
            29276,
            29237,
            27130,
            27522,
            32445,
            32445
        ],
        80001: [
            37,
            37,
            34,
            34,
            41,
            41,
            '%'
        ],
        80401: [
            37,
            37,
            34,
            35,
            41,
            41,
            '%'
        ],
        81801: [
            37,
            37,
            35,
            35,
            41,
            41,
            '%'
        ],
        83601: [
            38,
            37,
            35,
            35,
            41,
            41,
            '%'
        ],
        83801: [
            38,
            38,
            35,
            35,
            41,
            41,
            '%'
        ],
        85001: [
            38,
            38,
            36,
            35,
            41,
            41,
            '%'
        ],
        86001: [
            38,
            38,
            36,
            36,
            42,
            42,
            '%'
        ],
        88201: [
            39,
            38,
            36,
            36,
            42,
            42,
            '%'
        ],
        88401: [
            39,
            39,
            36,
            36,
            42,
            42,
            '%'
        ],
        88601: [
            39,
            39,
            37,
            36,
            42,
            42,
            '%'
        ],
        92601: [
            39,
            39,
            38,
            37,
            42,
            42,
            '%'
        ],
        93201: [
            40,
            39,
            38,
            37,
            42,
            42,
            '%'
        ],
        93401: [
            40,
            40,
            38,
            37,
            43,
            43,
            '%'
        ],
        96801: [
            40,
            40,
            39,
            37,
            43,
            43,
            '%'
        ],
        98801: [
            41,
            40,
            39,
            37,
            43,
            43,
            '%'
        ],
        99001: [
            41,
            41,
            39,
            37,
            43,
            43,
            '%'
        ],
        100001: [
            41,
            41,
            39,
            38,
            43,
            43,
            '%'
        ],
        101601: [
            41,
            41,
            40,
            38,
            43,
            43,
            '%'
        ],
        102401: [
            41,
            41,
            40,
            38,
            44,
            44,
            '%'
        ],
        105201: [
            42,
            41,
            40,
            38,
            44,
            44,
            '%'
        ],
        105401: [
            42,
            42,
            40,
            38,
            44,
            44,
            '%'
        ],
        106801: [
            42,
            42,
            41,
            38,
            44,
            44,
            '%'
        ],
        109001: [
            42,
            42,
            41,
            39,
            44,
            44,
            '%'
        ],
        112401: [
            43,
            42,
            41,
            39,
            44,
            44,
            '%'
        ],
        112601: [
            43,
            43,
            42,
            39,
            44,
            44,
            '%'
        ],
        113201: [
            43,
            43,
            42,
            39,
            45,
            45,
            '%'
        ],
        119001: [
            43,
            43,
            43,
            39,
            45,
            45,
            '%'
        ],
        119601: [
            43,
            43,
            43,
            40,
            45,
            45,
            '%'
        ],
        120801: [
            44,
            44,
            43,
            40,
            45,
            45,
            '%'
        ],
        126201: [
            44,
            44,
            44,
            40,
            45,
            45,
            '%'
        ],
        126401: [
            44,
            44,
            44,
            40,
            46,
            46,
            '%'
        ],
        130401: [
            45,
            45,
            44,
            40,
            46,
            46,
            '%'
        ],
        132401: [
            45,
            45,
            44,
            41,
            46,
            46,
            '%'
        ],
        134201: [
            45,
            45,
            45,
            41,
            46,
            46,
            '%'
        ],
        141601: [
            45,
            46,
            45,
            41,
            46,
            46,
            '%'
        ],
        141801: [
            46,
            46,
            45,
            41,
            46,
            46,
            '%'
        ],
        143201: [
            46,
            46,
            45,
            41,
            47,
            47,
            '%'
        ],
        143401: [
            46,
            46,
            46,
            41,
            47,
            47,
            '%'
        ],
        148401: [
            46,
            46,
            46,
            42,
            47,
            47,
            '%'
        ],
        155001: [
            46,
            47,
            47,
            42,
            47,
            47,
            '%'
        ],
        155401: [
            47,
            47,
            47,
            42,
            47,
            47,
            '%'
        ],
        165401: [
            47,
            47,
            47,
            42,
            48,
            48,
            '%'
        ],
        168601: [
            47,
            47,
            47,
            43,
            48,
            48,
            '%'
        ],
        171201: [
            47,
            48,
            48,
            43,
            48,
            48,
            '%'
        ],
        171801: [
            48,
            48,
            48,
            43,
            48,
            48,
            '%'
        ],
        195401: [
            49,
            49,
            49,
            44,
            49,
            49,
            '%'
        ],
        232401: [
            49,
            49,
            49,
            45,
            49,
            49,
            '%'
        ],
        238801: [
            50,
            50,
            50,
            45,
            50,
            50,
            '%'
        ],
        286601: [
            50,
            50,
            50,
            46,
            50,
            50,
            '%'
        ],
        307201: [
            51,
            51,
            51,
            46,
            51,
            51,
            '%'
        ],
        373401: [
            51,
            51,
            51,
            47,
            51,
            51,
            '%'
        ],
        430001: [
            52,
            52,
            52,
            47,
            52,
            52,
            '%'
        ],
        536201: [
            52,
            52,
            52,
            48,
            52,
            52,
            '%'
        ],
        716801: [
            53,
            53,
            53,
            48,
            53,
            53,
            '%'
        ],
        949801: [
            53,
            53,
            53,
            49,
            53,
            53,
            '%'
        ]
    },
    35: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            26
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            62
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            98
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            134
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            170
        ],
        2501: [
            195,
            0,
            183,
            12,
            206,
            206
        ],
        2601: [
            206,
            0,
            191,
            14,
            242,
            242
        ],
        2701: [
            216,
            0,
            200,
            16,
            278,
            278
        ],
        2801: [
            218,
            0,
            200,
            18,
            314,
            314
        ],
        2901: [
            229,
            0,
            208,
            21,
            350,
            350
        ],
        3001: [
            239,
            0,
            216,
            23,
            386,
            386
        ],
        3101: [
            250,
            0,
            225,
            25,
            422,
            422
        ],
        3201: [
            260,
            0,
            233,
            27,
            458,
            458
        ],
        3301: [
            271,
            0,
            241,
            29,
            494,
            494
        ],
        3401: [
            273,
            0,
            241,
            31,
            530,
            530
        ],
        3501: [
            284,
            0,
            250,
            34,
            566,
            566
        ],
        3601: [
            294,
            0,
            258,
            36,
            602,
            602
        ],
        3701: [
            305,
            0,
            266,
            38,
            638,
            638
        ],
        3801: [
            315,
            0,
            275,
            40,
            675,
            675
        ],
        3901: [
            325,
            0,
            283,
            42,
            710,
            710
        ],
        4001: [
            328,
            0,
            283,
            44,
            746,
            746
        ],
        4101: [
            338,
            0,
            291,
            46,
            782,
            782
        ],
        4201: [
            349,
            0,
            300,
            49,
            818,
            818
        ],
        4301: [
            359,
            0,
            308,
            63,
            854,
            854
        ],
        4401: [
            370,
            0,
            316,
            87,
            890,
            890
        ],
        4501: [
            380,
            0,
            325,
            112,
            926,
            926
        ],
        4601: [
            382,
            0,
            325,
            136,
            962,
            962
        ],
        4701: [
            392,
            0,
            333,
            161,
            992,
            992
        ],
        4801: [
            403,
            0,
            341,
            185,
            1022,
            1022
        ],
        4901: [
            413,
            0,
            350,
            209,
            1052,
            1052
        ],
        5001: [
            423,
            0,
            358,
            233,
            1079,
            1079
        ],
        5101: [
            433,
            0,
            366,
            257,
            1109,
            1109
        ],
        5201: [
            443,
            0,
            375,
            281,
            1136,
            1136
        ],
        5301: [
            445,
            23,
            376,
            305,
            1166,
            1166
        ],
        5401: [
            455,
            50,
            386,
            329,
            1196,
            1196
        ],
        5501: [
            465,
            77,
            396,
            352,
            1223,
            1223
        ],
        5601: [
            475,
            104,
            406,
            376,
            1252,
            1252
        ],
        5701: [
            485,
            131,
            416,
            399,
            1279,
            1279
        ],
        5801: [
            495,
            158,
            426,
            423,
            1308,
            1308
        ],
        5901: [
            497,
            185,
            427,
            446,
            1338,
            1338
        ],
        6001: [
            507,
            212,
            437,
            470,
            1364,
            1364
        ],
        6101: [
            517,
            239,
            447,
            493,
            1393,
            1393
        ],
        6201: [
            527,
            263,
            457,
            517,
            1420,
            1420
        ],
        6301: [
            537,
            290,
            467,
            540,
            1449,
            1449
        ],
        6401: [
            547,
            317,
            477,
            564,
            1479,
            1479
        ],
        6501: [
            551,
            344,
            479,
            587,
            1505,
            1505
        ],
        6601: [
            573,
            371,
            488,
            610,
            1534,
            1534
        ],
        6701: [
            595,
            398,
            498,
            634,
            1561,
            1561
        ],
        6801: [
            617,
            425,
            508,
            657,
            1590,
            1590
        ],
        6901: [
            639,
            452,
            518,
            681,
            1620,
            1620
        ],
        7001: [
            660,
            479,
            528,
            704,
            1646,
            1646
        ],
        7101: [
            682,
            506,
            530,
            728,
            1675,
            1675
        ],
        7201: [
            704,
            533,
            540,
            751,
            1702,
            1702
        ],
        7301: [
            726,
            560,
            550,
            775,
            1731,
            1731
        ],
        7401: [
            748,
            584,
            560,
            798,
            1761,
            1761
        ],
        7501: [
            770,
            611,
            570,
            821,
            1787,
            1787
        ],
        7601: [
            792,
            638,
            580,
            845,
            1816,
            1816
        ],
        7701: [
            813,
            665,
            589,
            868,
            1843,
            1843
        ],
        7801: [
            835,
            692,
            591,
            892,
            1872,
            1872
        ],
        7901: [
            857,
            719,
            601,
            915,
            1902,
            1902
        ],
        8001: [
            879,
            746,
            611,
            939,
            1928,
            1928
        ],
        8101: [
            901,
            773,
            621,
            962,
            1957,
            1957
        ],
        8201: [
            923,
            800,
            631,
            985,
            1984,
            1984
        ],
        8301: [
            945,
            827,
            641,
            1009,
            2013,
            2013
        ],
        8401: [
            967,
            854,
            642,
            1032,
            2043,
            2043
        ],
        8501: [
            988,
            881,
            652,
            1056,
            2069,
            2069
        ],
        8601: [
            1010,
            905,
            662,
            1079,
            2098,
            2098
        ],
        8701: [
            1032,
            932,
            672,
            1103,
            2125,
            2125
        ],
        8801: [
            1054,
            959,
            682,
            1126,
            2154,
            2154
        ],
        8901: [
            1076,
            986,
            692,
            1150,
            2184,
            2184
        ],
        9001: [
            1098,
            1013,
            694,
            1173,
            2210,
            2210
        ],
        9101: [
            1120,
            1040,
            704,
            1197,
            2239,
            2239
        ],
        9201: [
            1141,
            1067,
            714,
            1220,
            2266,
            2266
        ],
        9301: [
            1163,
            1085,
            723,
            1243,
            2295,
            2295
        ],
        9401: [
            1185,
            1109,
            733,
            1267,
            2325,
            2325
        ],
        9501: [
            1207,
            1130,
            742,
            1290,
            2351,
            2351
        ],
        9601: [
            1229,
            1151,
            744,
            1314,
            2380,
            2380
        ],
        9701: [
            1251,
            1172,
            753,
            1337,
            2407,
            2407
        ],
        9801: [
            1273,
            1196,
            763,
            1361,
            2436,
            2436
        ],
        9901: [
            1294,
            1217,
            773,
            1384,
            2466,
            2466
        ],
        10001: [
            1316,
            1238,
            782,
            1408,
            2492,
            2492
        ],
        10101: [
            1338,
            1258,
            792,
            1431,
            2521,
            2521
        ],
        10201: [
            1360,
            1282,
            802,
            1454,
            2548,
            2548
        ],
        10301: [
            1382,
            1302,
            803,
            1478,
            2577,
            2577
        ],
        10401: [
            1404,
            1323,
            812,
            1501,
            2607,
            2607
        ],
        10501: [
            1425,
            1343,
            822,
            1525,
            2633,
            2633
        ],
        10601: [
            1447,
            1367,
            832,
            1548,
            2662,
            2662
        ],
        10701: [
            1469,
            1387,
            841,
            1572,
            2689,
            2689
        ],
        10801: [
            1491,
            1408,
            851,
            1595,
            2718,
            2718
        ],
        10901: [
            1513,
            1429,
            852,
            1619,
            2748,
            2748
        ],
        11001: [
            1535,
            1452,
            862,
            1642,
            2774,
            2774
        ],
        11101: [
            1557,
            1473,
            872,
            1666,
            2803,
            2803
        ],
        11201: [
            1578,
            1493,
            881,
            1689,
            2830,
            2830
        ],
        11301: [
            1600,
            1517,
            891,
            1712,
            2859,
            2859
        ],
        11401: [
            1622,
            1537,
            901,
            1736,
            2889,
            2889
        ],
        11501: [
            1644,
            1558,
            902,
            1759,
            2915,
            2915
        ],
        11601: [
            1666,
            1578,
            911,
            1783,
            2944,
            2944
        ],
        11701: [
            1688,
            1602,
            921,
            1806,
            2971,
            2971
        ],
        11801: [
            1710,
            1622,
            931,
            1830,
            3000,
            3000
        ],
        11901: [
            1732,
            1643,
            940,
            1853,
            3030,
            3030
        ],
        12001: [
            1753,
            1664,
            950,
            1876,
            3056,
            3056
        ],
        12101: [
            1775,
            1687,
            951,
            1900,
            3085,
            3085
        ],
        12201: [
            1797,
            1708,
            961,
            1923,
            3112,
            3112
        ],
        12301: [
            1819,
            1728,
            971,
            1947,
            3141,
            3141
        ],
        12401: [
            1841,
            1749,
            980,
            1970,
            3171,
            3171
        ],
        12501: [
            1863,
            1772,
            990,
            1994,
            3197,
            3197
        ],
        12601: [
            1885,
            1793,
            1000,
            2017,
            3226,
            3226
        ],
        12701: [
            1906,
            1813,
            1009,
            2041,
            3253,
            3253
        ],
        12801: [
            1928,
            1834,
            1010,
            2064,
            3282,
            3282
        ],
        12901: [
            1950,
            1857,
            1020,
            2087,
            3309,
            3309
        ],
        13001: [
            1972,
            1878,
            1030,
            2111,
            3344,
            3344
        ],
        13101: [
            1994,
            1899,
            1039,
            2135,
            3379,
            3379
        ],
        13201: [
            2016,
            1922,
            1049,
            2159,
            3414,
            3414
        ],
        13301: [
            2039,
            1943,
            1059,
            2182,
            3450,
            3450
        ],
        13401: [
            2061,
            1963,
            1060,
            2206,
            3485,
            3485
        ],
        13501: [
            2083,
            1984,
            1070,
            2230,
            3520,
            3520
        ],
        13601: [
            2105,
            2007,
            1079,
            2254,
            3555,
            3555
        ],
        13701: [
            2127,
            2028,
            1089,
            2277,
            3591,
            3591
        ],
        13801: [
            2149,
            2048,
            1099,
            2300,
            3625,
            3625
        ],
        13901: [
            2170,
            2069,
            1108,
            2323,
            3660,
            3660
        ],
        14001: [
            2191,
            2092,
            1110,
            2346,
            3694,
            3694
        ],
        14101: [
            2212,
            2113,
            1119,
            2369,
            3728,
            3728
        ],
        14201: [
            2233,
            2134,
            1129,
            2391,
            3762,
            3762
        ],
        14301: [
            2254,
            2154,
            1138,
            2414,
            3797,
            3797
        ],
        14401: [
            2276,
            2178,
            1148,
            2437,
            3831,
            3831
        ],
        14501: [
            2297,
            2198,
            1158,
            2459,
            3865,
            3865
        ],
        14601: [
            2318,
            2219,
            1159,
            2482,
            3899,
            3899
        ],
        14701: [
            2339,
            2239,
            1169,
            2505,
            3934,
            3934
        ],
        14801: [
            2360,
            2263,
            1178,
            2528,
            3971,
            3971
        ],
        14901: [
            2381,
            2283,
            1188,
            2550,
            4008,
            4008
        ],
        15001: [
            2403,
            2304,
            1198,
            2573,
            4045,
            4045
        ],
        15101: [
            2424,
            2325,
            1207,
            2596,
            4082,
            4082
        ],
        15201: [
            2445,
            2348,
            1217,
            2619,
            4122,
            4122
        ],
        15301: [
            2466,
            2369,
            1218,
            2642,
            4159,
            4159
        ],
        15401: [
            2489,
            2392,
            1228,
            2667,
            4196,
            4196
        ],
        15501: [
            2518,
            2421,
            1238,
            2697,
            4233,
            4233
        ],
        15601: [
            2546,
            2451,
            1248,
            2727,
            4270,
            4270
        ],
        15701: [
            2575,
            2480,
            1258,
            2757,
            4310,
            4310
        ],
        15801: [
            2604,
            2510,
            1268,
            2787,
            4347,
            4347
        ],
        15901: [
            2632,
            2539,
            1270,
            2817,
            4384,
            4384
        ],
        16001: [
            2661,
            2568,
            1280,
            2847,
            4422,
            4422
        ],
        16101: [
            2690,
            2598,
            1291,
            2877,
            4459,
            4459
        ],
        16201: [
            2719,
            2627,
            1301,
            2907,
            4499,
            4499
        ],
        16301: [
            2747,
            2656,
            1311,
            2937,
            4536,
            4536
        ],
        16401: [
            2776,
            2686,
            1321,
            2967,
            4573,
            4573
        ],
        16501: [
            2805,
            2715,
            1323,
            2997,
            4610,
            4610
        ],
        16601: [
            2834,
            2745,
            1333,
            3026,
            4647,
            4647
        ],
        16701: [
            2862,
            2774,
            1343,
            3056,
            4687,
            4687
        ],
        16801: [
            2891,
            2803,
            1353,
            3086,
            4724,
            4724
        ],
        16901: [
            2920,
            2833,
            1363,
            3116,
            4761,
            4761
        ],
        17001: [
            2949,
            2859,
            1373,
            3146,
            4798,
            4798
        ],
        17101: [
            2977,
            2889,
            1375,
            3176,
            4836,
            4836
        ],
        17201: [
            3006,
            2918,
            1385,
            3206,
            4875,
            4875
        ],
        17301: [
            3035,
            2947,
            1395,
            3236,
            4913,
            4913
        ],
        17401: [
            3064,
            2977,
            1405,
            3266,
            4950,
            4950
        ],
        17501: [
            3092,
            3006,
            1416,
            3296,
            4987,
            4987
        ],
        17601: [
            3121,
            3036,
            1426,
            3326,
            5024,
            5024
        ],
        17701: [
            3150,
            3065,
            1436,
            3356,
            5064,
            5064
        ],
        17801: [
            3178,
            3094,
            1438,
            3386,
            5101,
            5101
        ],
        17901: [
            3207,
            3124,
            1448,
            3416,
            5138,
            5138
        ],
        18001: [
            3236,
            3153,
            1458,
            3446,
            5175,
            5175
        ],
        18101: [
            3265,
            3182,
            1468,
            3476,
            5212,
            5212
        ],
        18201: [
            3293,
            3212,
            1478,
            3506,
            5252,
            5252
        ],
        18301: [
            3322,
            3241,
            1488,
            3536,
            5289,
            5289
        ],
        18401: [
            3351,
            3270,
            1490,
            3566,
            5326,
            5326
        ],
        18501: [
            3380,
            3300,
            1500,
            3596,
            5363,
            5363
        ],
        18601: [
            3408,
            3329,
            1510,
            3626,
            5401,
            5401
        ],
        18701: [
            3437,
            3359,
            1520,
            3656,
            5441,
            5441
        ],
        18801: [
            3466,
            3388,
            1531,
            3686,
            5478,
            5478
        ],
        18901: [
            3494,
            3417,
            1541,
            3716,
            5515,
            5515
        ],
        19001: [
            3523,
            3447,
            1543,
            3746,
            5552,
            5552
        ],
        19101: [
            3552,
            3476,
            1553,
            3775,
            5589,
            5589
        ],
        19201: [
            3581,
            3505,
            1563,
            3805,
            5629,
            5629
        ],
        19301: [
            3609,
            3535,
            1573,
            3835,
            5666,
            5666
        ],
        19401: [
            3638,
            3564,
            1583,
            3865,
            5703,
            5703
        ],
        19501: [
            3667,
            3594,
            1593,
            3895,
            5740,
            5740
        ],
        19601: [
            3696,
            3623,
            1595,
            3925,
            5777,
            5777
        ],
        19701: [
            3724,
            3651,
            1604,
            3955,
            5817,
            5817
        ],
        19801: [
            3753,
            3680,
            1613,
            3985,
            5854,
            5854
        ],
        19901: [
            3782,
            3708,
            1622,
            4015,
            5891,
            5891
        ],
        20001: [
            3839,
            3765,
            1641,
            4075,
            5966,
            5966
        ],
        20201: [
            3897,
            3819,
            1651,
            4135,
            6043,
            6043
        ],
        20401: [
            3954,
            3876,
            1670,
            4195,
            6117,
            6117
        ],
        20601: [
            4012,
            3934,
            1688,
            4255,
            6194,
            6194
        ],
        20801: [
            4069,
            3991,
            1699,
            4315,
            6268,
            6268
        ],
        21001: [
            4127,
            4048,
            1717,
            4375,
            6342,
            6342
        ],
        21201: [
            4184,
            4105,
            1736,
            4435,
            6420,
            6420
        ],
        21401: [
            4241,
            4162,
            1746,
            4494,
            6494,
            6494
        ],
        21601: [
            4299,
            4219,
            1765,
            4554,
            6571,
            6571
        ],
        21801: [
            4356,
            4276,
            1783,
            4614,
            6645,
            6645
        ],
        22001: [
            4414,
            4333,
            1794,
            4674,
            6719,
            6719
        ],
        22201: [
            4471,
            4390,
            1812,
            4734,
            6796,
            6796
        ],
        22401: [
            4529,
            4447,
            1831,
            4794,
            6870,
            6870
        ],
        22601: [
            4586,
            4504,
            1849,
            4854,
            6948,
            6948
        ],
        22801: [
            4645,
            4561,
            1860,
            4915,
            7023,
            7023
        ],
        23001: [
            4704,
            4619,
            1878,
            4976,
            7099,
            7099
        ],
        23201: [
            4763,
            4676,
            1897,
            5038,
            7177,
            7177
        ],
        23401: [
            4822,
            4730,
            1907,
            5100,
            7253,
            7253
        ],
        23601: [
            4881,
            4787,
            1926,
            5161,
            7332,
            7332
        ],
        23801: [
            4940,
            4841,
            1944,
            5223,
            7408,
            7408
        ],
        24001: [
            4999,
            4901,
            1954,
            5284,
            7484,
            7484
        ],
        24201: [
            5058,
            4958,
            1992,
            5346,
            7562,
            7562
        ],
        24401: [
            5118,
            5018,
            2038,
            5407,
            7638,
            7638
        ],
        24601: [
            5177,
            5075,
            2081,
            5469,
            7717,
            7717
        ],
        24801: [
            5236,
            5132,
            2124,
            5531,
            7793,
            7793
        ],
        25001: [
            5295,
            5192,
            2187,
            5592,
            7869,
            7869
        ],
        25201: [
            5354,
            5249,
            2244,
            5654,
            7947,
            7947
        ],
        25401: [
            5413,
            5306,
            2302,
            5715,
            8023,
            8023
        ],
        25601: [
            5472,
            5366,
            2361,
            5777,
            8102,
            8102
        ],
        25801: [
            5531,
            5423,
            2419,
            5838,
            8178,
            8178
        ],
        26001: [
            5590,
            5480,
            2476,
            5900,
            8254,
            8254
        ],
        26201: [
            5650,
            5540,
            2536,
            5962,
            8332,
            8332
        ],
        26401: [
            5709,
            5598,
            2593,
            6023,
            8408,
            8408
        ],
        26601: [
            5768,
            5657,
            2653,
            6085,
            8487,
            8487
        ],
        26801: [
            5827,
            5715,
            2710,
            6146,
            8563,
            8563
        ],
        27001: [
            5886,
            5772,
            2767,
            6208,
            8639,
            8639
        ],
        27201: [
            5945,
            5832,
            2827,
            6269,
            8717,
            8717
        ],
        27401: [
            6004,
            5889,
            2884,
            6331,
            8793,
            8793
        ],
        27601: [
            6063,
            5946,
            2941,
            6393,
            8872,
            8872
        ],
        27801: [
            6122,
            6006,
            3001,
            6454,
            8948,
            8948
        ],
        28001: [
            6182,
            6063,
            3058,
            6516,
            9024,
            9024
        ],
        28201: [
            6241,
            6123,
            3118,
            6577,
            9102,
            9102
        ],
        28401: [
            6300,
            6180,
            3175,
            6639,
            9178,
            9178
        ],
        28601: [
            6359,
            6237,
            3232,
            6700,
            9257,
            9257
        ],
        28801: [
            6418,
            6297,
            3292,
            6762,
            9333,
            9333
        ],
        29001: [
            6477,
            6354,
            3349,
            6823,
            9409,
            9409
        ],
        29201: [
            6536,
            6411,
            3406,
            6885,
            9487,
            9487
        ],
        29401: [
            6595,
            6471,
            3466,
            6947,
            9563,
            9563
        ],
        29601: [
            6655,
            6528,
            3523,
            7008,
            9642,
            9642
        ],
        29801: [
            6714,
            6588,
            3583,
            7070,
            9718,
            9718
        ],
        30001: [
            6773,
            6645,
            3640,
            7131,
            9794,
            9794
        ],
        30201: [
            6832,
            6702,
            3697,
            7193,
            9872,
            9872
        ],
        30401: [
            6891,
            6762,
            3757,
            7255,
            9948,
            9948
        ],
        30601: [
            6950,
            6819,
            3814,
            7316,
            10027,
            10027
        ],
        30801: [
            7009,
            6876,
            3871,
            7378,
            10103,
            10103
        ],
        31001: [
            7068,
            6936,
            3931,
            7439,
            10179,
            10179
        ],
        31201: [
            7127,
            6994,
            3989,
            7501,
            10257,
            10257
        ],
        31401: [
            7186,
            7052,
            4047,
            7562,
            10333,
            10333
        ],
        31601: [
            7246,
            7113,
            4108,
            7624,
            10412,
            10412
        ],
        31801: [
            7305,
            7171,
            4167,
            7686,
            10488,
            10488
        ],
        32001: [
            7364,
            7233,
            4228,
            7747,
            10564,
            10564
        ],
        32201: [
            7423,
            7291,
            4286,
            7809,
            10642,
            10642
        ],
        32401: [
            7482,
            7349,
            4345,
            7870,
            10718,
            10718
        ],
        32601: [
            7541,
            7411,
            4406,
            7932,
            10797,
            10797
        ],
        32801: [
            7600,
            7469,
            4464,
            7993,
            10873,
            10873
        ],
        33001: [
            7659,
            7527,
            4522,
            8055,
            10949,
            10949
        ],
        33201: [
            7719,
            7589,
            4584,
            8117,
            11027,
            11027
        ],
        33401: [
            7778,
            7647,
            4642,
            8178,
            11103,
            11103
        ],
        33601: [
            7837,
            7708,
            4703,
            8240,
            11182,
            11182
        ],
        33801: [
            7896,
            7766,
            4762,
            8301,
            11258,
            11258
        ],
        34001: [
            7955,
            7825,
            4820,
            8363,
            11334,
            11334
        ],
        34201: [
            8014,
            7886,
            4881,
            8424,
            11412,
            11412
        ],
        34401: [
            8073,
            7944,
            4940,
            8486,
            11488,
            11488
        ],
        34601: [
            8132,
            8003,
            4998,
            8548,
            11567,
            11567
        ],
        34801: [
            8191,
            8064,
            5059,
            8609,
            11643,
            11643
        ],
        35001: [
            8250,
            8122,
            5117,
            8671,
            11719,
            11719
        ],
        35201: [
            8310,
            8181,
            5176,
            8732,
            11797,
            11797
        ],
        35401: [
            8369,
            8242,
            5237,
            8794,
            11873,
            11873
        ],
        35601: [
            8428,
            8300,
            5295,
            8855,
            11952,
            11952
        ],
        35801: [
            8487,
            8362,
            5357,
            8917,
            12028,
            12028
        ],
        36001: [
            8546,
            8420,
            5415,
            8978,
            12104,
            12104
        ],
        36201: [
            8605,
            8478,
            5473,
            9040,
            12182,
            12182
        ],
        36401: [
            8664,
            8539,
            5535,
            9102,
            12258,
            12258
        ],
        36601: [
            8723,
            8598,
            5593,
            9163,
            12337,
            12337
        ],
        36801: [
            8783,
            8656,
            5651,
            9225,
            12413,
            12413
        ],
        37001: [
            8842,
            8717,
            5712,
            9286,
            12489,
            12489
        ],
        37201: [
            8901,
            8776,
            5771,
            9348,
            12567,
            12567
        ],
        37401: [
            8960,
            8837,
            5832,
            9409,
            12643,
            12643
        ],
        37601: [
            9019,
            8892,
            5887,
            9471,
            12716,
            12716
        ],
        37801: [
            9078,
            8951,
            5946,
            9532,
            12786,
            12786
        ],
        38001: [
            9137,
            9009,
            6004,
            9594,
            12856,
            12856
        ],
        38201: [
            9195,
            9070,
            6065,
            9655,
            12926,
            12926
        ],
        38401: [
            9256,
            9129,
            6124,
            9716,
            12996,
            12996
        ],
        38601: [
            9326,
            9199,
            6194,
            9778,
            13066,
            13066
        ],
        38801: [
            9396,
            9269,
            6264,
            9839,
            13136,
            13136
        ],
        39001: [
            9466,
            9339,
            6334,
            9900,
            13206,
            13206
        ],
        39201: [
            9536,
            9409,
            6404,
            9962,
            13276,
            13276
        ],
        39401: [
            9606,
            9479,
            6474,
            10023,
            13346,
            13346
        ],
        39601: [
            9676,
            9549,
            6544,
            10084,
            13416,
            13416
        ],
        39801: [
            9746,
            9619,
            6614,
            10146,
            13486,
            13486
        ],
        40001: [
            9816,
            9689,
            6684,
            10207,
            13556,
            13556
        ],
        40201: [
            9886,
            9759,
            6754,
            10268,
            13626,
            13626
        ],
        40401: [
            9956,
            9829,
            6824,
            10330,
            13696,
            13696
        ],
        40601: [
            10026,
            9899,
            6894,
            10391,
            13766,
            13766
        ],
        40801: [
            10096,
            9969,
            6964,
            10452,
            13836,
            13836
        ],
        41001: [
            10166,
            10039,
            7034,
            10514,
            13906,
            13906
        ],
        41201: [
            10236,
            10109,
            7104,
            10575,
            13976,
            13976
        ],
        41401: [
            10306,
            10179,
            7174,
            10636,
            14046,
            14046
        ],
        41601: [
            10376,
            10249,
            7244,
            10698,
            14116,
            14116
        ],
        41801: [
            10446,
            10319,
            7314,
            10759,
            14186,
            14186
        ],
        42001: [
            10516,
            10389,
            7384,
            10820,
            14256,
            14256
        ],
        42201: [
            10586,
            10459,
            7454,
            10882,
            14326,
            14326
        ],
        42401: [
            10656,
            10529,
            7524,
            10943,
            14396,
            14396
        ],
        42601: [
            10726,
            10599,
            7594,
            11004,
            14466,
            14466
        ],
        42801: [
            10796,
            10669,
            7664,
            11066,
            14536,
            14536
        ],
        43001: [
            10866,
            10739,
            7734,
            11127,
            14606,
            14606
        ],
        43201: [
            10936,
            10809,
            7804,
            11188,
            14676,
            14676
        ],
        43401: [
            11006,
            10879,
            7874,
            11250,
            14746,
            14746
        ],
        43601: [
            11076,
            10949,
            7944,
            11311,
            14816,
            14816
        ],
        43801: [
            11146,
            11019,
            8014,
            11372,
            14886,
            14886
        ],
        44001: [
            11216,
            11089,
            8084,
            11434,
            14956,
            14956
        ],
        44201: [
            11286,
            11159,
            8154,
            11495,
            15026,
            15026
        ],
        44401: [
            11356,
            11229,
            8224,
            11556,
            15096,
            15096
        ],
        44601: [
            11426,
            11299,
            8294,
            11618,
            15166,
            15166
        ],
        44801: [
            11496,
            11369,
            8364,
            11679,
            15236,
            15236
        ],
        45001: [
            11566,
            11439,
            8434,
            11740,
            15306,
            15306
        ],
        45201: [
            11636,
            11509,
            8504,
            11802,
            15376,
            15376
        ],
        45401: [
            11706,
            11579,
            8574,
            11863,
            15446,
            15446
        ],
        45601: [
            11776,
            11649,
            8644,
            11924,
            15516,
            15516
        ],
        45801: [
            11846,
            11719,
            8714,
            11986,
            15586,
            15586
        ],
        46001: [
            11916,
            11789,
            8784,
            12047,
            15656,
            15656
        ],
        46201: [
            11986,
            11859,
            8854,
            12108,
            15726,
            15726
        ],
        46401: [
            12056,
            11929,
            8924,
            12170,
            15796,
            15796
        ],
        46601: [
            12126,
            11999,
            8994,
            12231,
            15866,
            15866
        ],
        46801: [
            12196,
            12069,
            9064,
            12292,
            15936,
            15936
        ],
        47001: [
            12266,
            12139,
            9134,
            12354,
            16006,
            16006
        ],
        47201: [
            12336,
            12209,
            9204,
            12415,
            16076,
            16076
        ],
        47401: [
            12406,
            12279,
            9274,
            12476,
            16146,
            16146
        ],
        47601: [
            12476,
            12349,
            9344,
            12538,
            16216,
            16216
        ],
        47801: [
            12546,
            12419,
            9414,
            12599,
            16286,
            16286
        ],
        48001: [
            12616,
            12489,
            9484,
            12660,
            16356,
            16356
        ],
        48201: [
            12686,
            12559,
            9554,
            12722,
            16426,
            16426
        ],
        48401: [
            12756,
            12629,
            9624,
            12783,
            16496,
            16496
        ],
        48601: [
            12826,
            12699,
            9694,
            12844,
            16566,
            16566
        ],
        48801: [
            12896,
            12769,
            9764,
            12906,
            16636,
            16636
        ],
        49001: [
            12966,
            12839,
            9834,
            12967,
            16706,
            16706
        ],
        49201: [
            13036,
            12909,
            9904,
            13028,
            16776,
            16776
        ],
        49401: [
            13106,
            12979,
            9974,
            13090,
            16846,
            16846
        ],
        49601: [
            13176,
            13049,
            10044,
            13151,
            16916,
            16916
        ],
        49801: [
            13246,
            13119,
            10114,
            13212,
            16986,
            16986
        ],
        50001: [
            13316,
            13189,
            10188,
            13274,
            17056,
            17056
        ],
        50201: [
            13386,
            13259,
            10264,
            13335,
            17126,
            17126
        ],
        50401: [
            13456,
            13329,
            10340,
            13396,
            17196,
            17196
        ],
        50601: [
            13526,
            13399,
            10416,
            13458,
            17266,
            17266
        ],
        50801: [
            13596,
            13469,
            10492,
            13519,
            17336,
            17336
        ],
        51001: [
            13666,
            13539,
            10568,
            13580,
            17406,
            17406
        ],
        51201: [
            13761,
            13635,
            10671,
            13667,
            17501,
            17501
        ],
        51401: [
            13871,
            13749,
            10790,
            13768,
            17611,
            17611
        ],
        51601: [
            13981,
            13862,
            10910,
            13869,
            17721,
            17721
        ],
        51801: [
            14091,
            13973,
            11027,
            13971,
            17831,
            17831
        ],
        52001: [
            14201,
            14087,
            11147,
            14072,
            17941,
            17941
        ],
        52201: [
            14311,
            14201,
            11266,
            14173,
            18051,
            18051
        ],
        52401: [
            14421,
            14314,
            11386,
            14275,
            18161,
            18161
        ],
        52601: [
            14531,
            14428,
            11506,
            14376,
            18271,
            18271
        ],
        52801: [
            14641,
            14542,
            11626,
            14477,
            18381,
            18381
        ],
        53001: [
            14751,
            14656,
            11745,
            14579,
            18491,
            18491
        ],
        53201: [
            14861,
            14769,
            11865,
            14680,
            18601,
            18601
        ],
        53401: [
            14971,
            14880,
            11982,
            14781,
            18711,
            18711
        ],
        53601: [
            15081,
            14994,
            12102,
            14883,
            18821,
            18821
        ],
        53801: [
            15191,
            15108,
            12222,
            14984,
            18931,
            18931
        ],
        54001: [
            15301,
            15221,
            12341,
            15085,
            19041,
            19041
        ],
        54201: [
            15411,
            15335,
            12461,
            15187,
            19151,
            19151
        ],
        54401: [
            15521,
            15449,
            12581,
            15288,
            19261,
            19261
        ],
        54601: [
            15631,
            15563,
            12701,
            15389,
            19371,
            19371
        ],
        54801: [
            15741,
            15676,
            12820,
            15491,
            19481,
            19481
        ],
        55001: [
            15851,
            15790,
            12940,
            15592,
            19591,
            19591
        ],
        55201: [
            15961,
            15901,
            13057,
            15694,
            19701,
            19701
        ],
        55401: [
            16071,
            16015,
            13177,
            15795,
            19811,
            19811
        ],
        55601: [
            16181,
            16129,
            13296,
            15896,
            19921,
            19921
        ],
        55801: [
            16291,
            16242,
            13416,
            15998,
            20031,
            20031
        ],
        56001: [
            16401,
            16356,
            13536,
            16099,
            20141,
            20141
        ],
        56201: [
            16511,
            16470,
            13656,
            16200,
            20251,
            20251
        ],
        56401: [
            16621,
            16584,
            13775,
            16302,
            20361,
            20361
        ],
        56601: [
            16731,
            16697,
            13895,
            16403,
            20471,
            20471
        ],
        56801: [
            16841,
            16808,
            14012,
            16504,
            20581,
            20581
        ],
        57001: [
            16951,
            16922,
            14132,
            16606,
            20691,
            20691
        ],
        57201: [
            17061,
            17036,
            14251,
            16707,
            20801,
            20801
        ],
        57401: [
            17171,
            17149,
            14371,
            16808,
            20911,
            20911
        ],
        57601: [
            17281,
            17263,
            14491,
            16910,
            21021,
            21021
        ],
        57801: [
            17391,
            17377,
            14611,
            17011,
            21131,
            21131
        ],
        58001: [
            17501,
            17483,
            14723,
            17112,
            21241,
            21241
        ],
        58201: [
            17611,
            17593,
            14839,
            17214,
            21351,
            21351
        ],
        58401: [
            17721,
            17703,
            14955,
            17315,
            21461,
            21461
        ],
        58601: [
            17831,
            17813,
            15071,
            17416,
            21571,
            21571
        ],
        58801: [
            17941,
            17923,
            15187,
            17518,
            21681,
            21681
        ],
        59001: [
            18051,
            18033,
            15303,
            17619,
            21791,
            21791
        ],
        59201: [
            18161,
            18143,
            15419,
            17720,
            21901,
            21901
        ],
        59401: [
            18271,
            18253,
            15535,
            17822,
            22011,
            22011
        ],
        59601: [
            18381,
            18363,
            15651,
            17923,
            22121,
            22121
        ],
        59801: [
            18491,
            18473,
            15767,
            18024,
            22231,
            22231
        ],
        60001: [
            18601,
            18583,
            15883,
            18126,
            22341,
            22341
        ],
        60201: [
            18711,
            18693,
            15999,
            18227,
            22451,
            22451
        ],
        60401: [
            18821,
            18803,
            16115,
            18328,
            22561,
            22561
        ],
        60601: [
            18931,
            18913,
            16231,
            18430,
            22671,
            22671
        ],
        60801: [
            19041,
            19023,
            16347,
            18531,
            22781,
            22781
        ],
        61001: [
            19151,
            19133,
            16463,
            18632,
            22891,
            22891
        ],
        61201: [
            19261,
            19243,
            16579,
            18734,
            23001,
            23001
        ],
        61401: [
            19371,
            19353,
            16695,
            18835,
            23111,
            23111
        ],
        61601: [
            19481,
            19463,
            16811,
            18936,
            23221,
            23221
        ],
        61801: [
            19591,
            19573,
            16927,
            19038,
            23331,
            23331
        ],
        62001: [
            19701,
            19683,
            17043,
            19139,
            23441,
            23441
        ],
        62201: [
            19811,
            19793,
            17159,
            19240,
            23551,
            23551
        ],
        62401: [
            19921,
            19903,
            17275,
            19342,
            23661,
            23661
        ],
        62601: [
            20031,
            20013,
            17391,
            19443,
            23771,
            23771
        ],
        62801: [
            20141,
            20123,
            17507,
            19544,
            23881,
            23881
        ],
        63001: [
            20251,
            20233,
            17623,
            19646,
            23991,
            23991
        ],
        63201: [
            20361,
            20343,
            17739,
            19747,
            24101,
            24101
        ],
        63401: [
            20471,
            20453,
            17855,
            19848,
            24211,
            24211
        ],
        63601: [
            20581,
            20563,
            17971,
            19950,
            24321,
            24321
        ],
        63801: [
            20691,
            20673,
            18087,
            20051,
            24431,
            24431
        ],
        64001: [
            20801,
            20783,
            18203,
            20152,
            24541,
            24541
        ],
        64201: [
            20911,
            20893,
            18319,
            20254,
            24651,
            24651
        ],
        64401: [
            21021,
            21003,
            18435,
            20355,
            24761,
            24761
        ],
        64601: [
            21135,
            21122,
            18560,
            20456,
            24871,
            24871
        ],
        64801: [
            21251,
            21236,
            18680,
            20558,
            24981,
            24981
        ],
        65001: [
            21367,
            21356,
            18805,
            20659,
            25091,
            25091
        ],
        65201: [
            21483,
            21470,
            18926,
            20760,
            25201,
            25201
        ],
        65401: [
            21599,
            21585,
            19047,
            20862,
            25311,
            25311
        ],
        65601: [
            21715,
            21704,
            19172,
            20963,
            25421,
            25421
        ],
        65801: [
            21831,
            21819,
            19292,
            21064,
            25531,
            25531
        ],
        66001: [
            21947,
            21938,
            19418,
            21166,
            25641,
            25641
        ],
        66201: [
            22063,
            22052,
            19538,
            21267,
            25751,
            25751
        ],
        66401: [
            22179,
            22167,
            19659,
            21368,
            25861,
            25861
        ],
        66601: [
            22295,
            22286,
            19784,
            21470,
            25971,
            25971
        ],
        66801: [
            22411,
            22401,
            19904,
            21571,
            26081,
            26081
        ],
        67001: [
            22527,
            22515,
            20025,
            21672,
            26191,
            26191
        ],
        67201: [
            22643,
            22634,
            20150,
            21774,
            26301,
            26301
        ],
        67401: [
            22759,
            22749,
            20271,
            21875,
            26411,
            26411
        ],
        67601: [
            22875,
            22868,
            20396,
            21976,
            26521,
            26521
        ],
        67801: [
            22991,
            22983,
            20517,
            22078,
            26631,
            26631
        ],
        68001: [
            23107,
            23097,
            20637,
            22179,
            26741,
            26741
        ],
        68201: [
            23223,
            23216,
            20762,
            22280,
            26851,
            26851
        ],
        68401: [
            23339,
            23331,
            20883,
            22382,
            26961,
            26961
        ],
        68601: [
            23455,
            23446,
            21003,
            22483,
            27071,
            27071
        ],
        68801: [
            23571,
            23565,
            21129,
            22584,
            27181,
            27181
        ],
        69001: [
            23687,
            23679,
            21249,
            22686,
            27291,
            27291
        ],
        69201: [
            23803,
            23799,
            21374,
            22787,
            27401,
            27401
        ],
        69401: [
            23919,
            23913,
            21495,
            22888,
            27511,
            27511
        ],
        69601: [
            24035,
            24028,
            21616,
            22990,
            27621,
            27621
        ],
        69801: [
            24151,
            24147,
            21741,
            23091,
            27731,
            27731
        ],
        70001: [
            24267,
            24262,
            21861,
            23192,
            27841,
            27841
        ],
        70201: [
            24383,
            24376,
            21982,
            23294,
            27951,
            27951
        ],
        70401: [
            24499,
            24495,
            22107,
            23395,
            28061,
            28061
        ],
        70601: [
            24615,
            24610,
            22228,
            23496,
            28171,
            28171
        ],
        70801: [
            24731,
            24729,
            22353,
            23598,
            28281,
            28281
        ],
        71001: [
            24847,
            24844,
            22473,
            23699,
            28391,
            28391
        ],
        71201: [
            24963,
            24958,
            22594,
            23801,
            28501,
            28501
        ],
        71401: [
            25079,
            25077,
            22719,
            23902,
            28611,
            28611
        ],
        71601: [
            25195,
            25192,
            22840,
            24003,
            28721,
            28721
        ],
        71801: [
            25311,
            25311,
            22965,
            24104,
            28831,
            28831
        ],
        72001: [
            25427,
            25426,
            23085,
            24206,
            28941,
            28941
        ],
        72201: [
            25543,
            25540,
            23206,
            24307,
            29051,
            29051
        ],
        72401: [
            25659,
            25659,
            23331,
            24408,
            29161,
            29161
        ],
        72601: [
            25775,
            25774,
            23452,
            24510,
            29271,
            29271
        ],
        72801: [
            25891,
            25889,
            23572,
            24611,
            29381,
            29381
        ],
        73001: [
            26007,
            26008,
            23698,
            24712,
            29491,
            29491
        ],
        73201: [
            26123,
            26122,
            23818,
            24814,
            29601,
            29601
        ],
        73401: [
            26239,
            26241,
            23943,
            24915,
            29711,
            29711
        ],
        73601: [
            26355,
            26356,
            24064,
            25017,
            29821,
            29821
        ],
        73801: [
            26471,
            26471,
            24184,
            25118,
            29931,
            29931
        ],
        74001: [
            26587,
            26590,
            24310,
            25219,
            30041,
            30041
        ],
        74201: [
            26703,
            26704,
            24430,
            25321,
            30151,
            30151
        ],
        74401: [
            26819,
            26819,
            24551,
            25422,
            30261,
            30261
        ],
        74601: [
            26935,
            26938,
            24676,
            25523,
            30371,
            30371
        ],
        74801: [
            27051,
            27053,
            24797,
            25625,
            30481,
            30481
        ],
        75001: [
            27167,
            27172,
            24922,
            25726,
            30591,
            30591
        ],
        75201: [
            27283,
            27286,
            25042,
            25827,
            30701,
            30701
        ],
        75401: [
            27399,
            27401,
            25163,
            25929,
            30811,
            30811
        ],
        75601: [
            27515,
            27520,
            25288,
            26030,
            30921,
            30921
        ],
        75801: [
            27631,
            27635,
            25409,
            26131,
            31031,
            31031
        ],
        76001: [
            27747,
            27749,
            25529,
            26233,
            31141,
            31141
        ],
        76201: [
            27863,
            27869,
            25654,
            26334,
            31251,
            31251
        ],
        76401: [
            27979,
            27983,
            25775,
            26435,
            31361,
            31361
        ],
        76601: [
            28095,
            28102,
            25900,
            26537,
            31471,
            31471
        ],
        76801: [
            28211,
            28217,
            26021,
            26638,
            31581,
            31581
        ],
        77001: [
            28327,
            28331,
            26141,
            26739,
            31691,
            31691
        ],
        77201: [
            28443,
            28451,
            26266,
            26841,
            31801,
            31801
        ],
        77401: [
            28559,
            28565,
            26387,
            26942,
            31911,
            31911
        ],
        77601: [
            28675,
            28680,
            26508,
            27043,
            32021,
            32021
        ],
        77801: [
            28791,
            28799,
            26633,
            27145,
            32131,
            32131
        ],
        78001: [
            28907,
            28914,
            26753,
            27246,
            32241,
            32241
        ],
        78201: [
            29023,
            29033,
            26879,
            27347,
            32351,
            32351
        ],
        78401: [
            29139,
            29147,
            26999,
            27449,
            32461,
            32461
        ],
        78601: [
            29255,
            29262,
            27120,
            27550,
            32571,
            32571
        ],
        78801: [
            29371,
            29381,
            27245,
            27651,
            32681,
            32681
        ],
        79001: [
            29487,
            29496,
            27365,
            27753,
            32791,
            32791
        ],
        79201: [
            29603,
            29610,
            27486,
            27854,
            32901,
            32901
        ],
        79401: [
            29719,
            29729,
            27611,
            27955,
            33011,
            33011
        ],
        79601: [
            29835,
            29844,
            27732,
            28057,
            33121,
            33121
        ],
        79801: [
            29951,
            29963,
            27857,
            28158,
            33231,
            33231
        ],
        80001: [
            37,
            38,
            35,
            35,
            42,
            42,
            '%'
        ],
        80201: [
            38,
            38,
            35,
            35,
            42,
            42,
            '%'
        ],
        81401: [
            38,
            38,
            35,
            36,
            42,
            42,
            '%'
        ],
        82001: [
            38,
            38,
            36,
            36,
            42,
            42,
            '%'
        ],
        84201: [
            39,
            39,
            36,
            36,
            42,
            42,
            '%'
        ],
        85401: [
            39,
            39,
            37,
            36,
            42,
            42,
            '%'
        ],
        86001: [
            39,
            39,
            37,
            36,
            43,
            43,
            '%'
        ],
        87201: [
            39,
            39,
            37,
            37,
            43,
            43,
            '%'
        ],
        88601: [
            39,
            40,
            37,
            37,
            43,
            43,
            '%'
        ],
        88801: [
            40,
            40,
            37,
            37,
            43,
            43,
            '%'
        ],
        89001: [
            40,
            40,
            38,
            37,
            43,
            43,
            '%'
        ],
        92801: [
            40,
            40,
            39,
            37,
            43,
            43,
            '%'
        ],
        93601: [
            40,
            41,
            39,
            37,
            44,
            44,
            '%'
        ],
        93801: [
            41,
            41,
            39,
            38,
            44,
            44,
            '%'
        ],
        97201: [
            41,
            41,
            40,
            38,
            44,
            44,
            '%'
        ],
        99401: [
            41,
            42,
            40,
            38,
            44,
            44,
            '%'
        ],
        99601: [
            42,
            42,
            40,
            38,
            44,
            44,
            '%'
        ],
        101601: [
            42,
            42,
            40,
            39,
            44,
            44,
            '%'
        ],
        102001: [
            42,
            42,
            41,
            39,
            44,
            44,
            '%'
        ],
        102401: [
            42,
            42,
            41,
            39,
            45,
            45,
            '%'
        ],
        105801: [
            42,
            43,
            41,
            39,
            45,
            45,
            '%'
        ],
        106001: [
            43,
            43,
            41,
            39,
            45,
            45,
            '%'
        ],
        107001: [
            43,
            43,
            42,
            39,
            45,
            45,
            '%'
        ],
        110801: [
            43,
            43,
            42,
            40,
            45,
            45,
            '%'
        ],
        112801: [
            43,
            43,
            43,
            40,
            45,
            45,
            '%'
        ],
        113001: [
            43,
            44,
            43,
            40,
            45,
            45,
            '%'
        ],
        113201: [
            43,
            44,
            43,
            40,
            46,
            46,
            '%'
        ],
        113401: [
            44,
            44,
            43,
            40,
            46,
            46,
            '%'
        ],
        119201: [
            44,
            44,
            44,
            40,
            46,
            46,
            '%'
        ],
        121201: [
            44,
            45,
            44,
            40,
            46,
            46,
            '%'
        ],
        121601: [
            44,
            45,
            44,
            41,
            46,
            46,
            '%'
        ],
        121801: [
            45,
            45,
            44,
            41,
            46,
            46,
            '%'
        ],
        126401: [
            45,
            45,
            45,
            41,
            46,
            46,
            '%'
        ],
        126601: [
            45,
            45,
            45,
            41,
            47,
            47,
            '%'
        ],
        130801: [
            45,
            46,
            45,
            41,
            47,
            47,
            '%'
        ],
        131401: [
            46,
            46,
            45,
            41,
            47,
            47,
            '%'
        ],
        134401: [
            46,
            46,
            46,
            41,
            47,
            47,
            '%'
        ],
        134801: [
            46,
            46,
            46,
            42,
            47,
            47,
            '%'
        ],
        142001: [
            46,
            47,
            46,
            42,
            47,
            47,
            '%'
        ],
        143001: [
            47,
            47,
            46,
            42,
            47,
            47,
            '%'
        ],
        143401: [
            47,
            47,
            46,
            42,
            48,
            48,
            '%'
        ],
        143601: [
            47,
            47,
            47,
            42,
            48,
            48,
            '%'
        ],
        151401: [
            47,
            47,
            47,
            43,
            48,
            48,
            '%'
        ],
        155401: [
            47,
            48,
            48,
            43,
            48,
            48,
            '%'
        ],
        156601: [
            48,
            48,
            48,
            43,
            48,
            48,
            '%'
        ],
        165601: [
            48,
            48,
            48,
            43,
            49,
            49,
            '%'
        ],
        171401: [
            48,
            49,
            49,
            43,
            49,
            49,
            '%'
        ],
        172601: [
            48,
            49,
            49,
            44,
            49,
            49,
            '%'
        ],
        173001: [
            49,
            49,
            49,
            44,
            49,
            49,
            '%'
        ],
        195601: [
            50,
            50,
            50,
            44,
            50,
            50,
            '%'
        ],
        200601: [
            50,
            50,
            50,
            45,
            50,
            50,
            '%'
        ],
        239201: [
            51,
            51,
            51,
            45,
            51,
            51,
            '%'
        ],
        239401: [
            51,
            51,
            51,
            46,
            51,
            51,
            '%'
        ],
        296801: [
            51,
            51,
            51,
            47,
            51,
            51,
            '%'
        ],
        307601: [
            52,
            52,
            52,
            47,
            52,
            52,
            '%'
        ],
        390601: [
            52,
            52,
            52,
            48,
            52,
            52,
            '%'
        ],
        430601: [
            53,
            53,
            53,
            48,
            53,
            53,
            '%'
        ],
        570601: [
            53,
            53,
            53,
            49,
            53,
            53,
            '%'
        ],
        717801: [
            54,
            54,
            54,
            49,
            54,
            54,
            '%'
        ],
        1059001: [
            54,
            54,
            54,
            50,
            54,
            54,
            '%'
        ]
    },
    36: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            27
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            64
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            101
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            138
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            175
        ],
        2501: [
            195,
            0,
            183,
            12,
            212,
            212
        ],
        2601: [
            206,
            0,
            191,
            14,
            249,
            249
        ],
        2701: [
            216,
            0,
            200,
            16,
            286,
            286
        ],
        2801: [
            218,
            0,
            200,
            18,
            323,
            323
        ],
        2901: [
            229,
            0,
            208,
            21,
            360,
            360
        ],
        3001: [
            239,
            0,
            216,
            23,
            397,
            397
        ],
        3101: [
            250,
            0,
            225,
            25,
            434,
            434
        ],
        3201: [
            260,
            0,
            233,
            27,
            471,
            471
        ],
        3301: [
            271,
            0,
            241,
            29,
            508,
            508
        ],
        3401: [
            273,
            0,
            241,
            31,
            545,
            545
        ],
        3501: [
            284,
            0,
            250,
            34,
            582,
            582
        ],
        3601: [
            294,
            0,
            258,
            36,
            619,
            619
        ],
        3701: [
            305,
            0,
            266,
            38,
            656,
            656
        ],
        3801: [
            315,
            0,
            275,
            40,
            693,
            693
        ],
        3901: [
            325,
            0,
            283,
            42,
            730,
            730
        ],
        4001: [
            328,
            0,
            283,
            44,
            767,
            767
        ],
        4101: [
            338,
            0,
            291,
            46,
            804,
            804
        ],
        4201: [
            349,
            0,
            300,
            49,
            841,
            841
        ],
        4301: [
            359,
            0,
            308,
            63,
            878,
            878
        ],
        4401: [
            370,
            0,
            316,
            89,
            915,
            915
        ],
        4501: [
            380,
            0,
            325,
            114,
            952,
            952
        ],
        4601: [
            382,
            0,
            325,
            139,
            989,
            989
        ],
        4701: [
            392,
            0,
            333,
            164,
            1020,
            1020
        ],
        4801: [
            403,
            0,
            341,
            188,
            1051,
            1051
        ],
        4901: [
            413,
            0,
            350,
            213,
            1082,
            1082
        ],
        5001: [
            423,
            0,
            358,
            238,
            1109,
            1109
        ],
        5101: [
            433,
            0,
            366,
            262,
            1140,
            1140
        ],
        5201: [
            443,
            0,
            375,
            287,
            1168,
            1168
        ],
        5301: [
            445,
            24,
            376,
            312,
            1199,
            1199
        ],
        5401: [
            455,
            52,
            386,
            337,
            1230,
            1230
        ],
        5501: [
            465,
            80,
            396,
            361,
            1257,
            1257
        ],
        5601: [
            475,
            107,
            406,
            385,
            1287,
            1287
        ],
        5701: [
            485,
            135,
            416,
            409,
            1314,
            1314
        ],
        5801: [
            495,
            163,
            426,
            433,
            1345,
            1345
        ],
        5901: [
            497,
            191,
            427,
            457,
            1375,
            1375
        ],
        6001: [
            507,
            218,
            437,
            481,
            1402,
            1402
        ],
        6101: [
            517,
            246,
            447,
            505,
            1432,
            1432
        ],
        6201: [
            527,
            271,
            457,
            530,
            1459,
            1459
        ],
        6301: [
            537,
            299,
            467,
            554,
            1490,
            1490
        ],
        6401: [
            547,
            326,
            477,
            578,
            1520,
            1520
        ],
        6501: [
            565,
            354,
            479,
            602,
            1547,
            1547
        ],
        6601: [
            588,
            382,
            488,
            626,
            1577,
            1577
        ],
        6701: [
            610,
            410,
            498,
            650,
            1604,
            1604
        ],
        6801: [
            632,
            437,
            508,
            674,
            1635,
            1635
        ],
        6901: [
            655,
            465,
            518,
            698,
            1665,
            1665
        ],
        7001: [
            677,
            493,
            528,
            722,
            1692,
            1692
        ],
        7101: [
            700,
            521,
            530,
            747,
            1722,
            1722
        ],
        7201: [
            722,
            548,
            540,
            771,
            1749,
            1749
        ],
        7301: [
            745,
            576,
            550,
            795,
            1780,
            1780
        ],
        7401: [
            767,
            601,
            560,
            819,
            1810,
            1810
        ],
        7501: [
            790,
            628,
            570,
            843,
            1837,
            1837
        ],
        7601: [
            812,
            656,
            580,
            867,
            1867,
            1867
        ],
        7701: [
            835,
            684,
            589,
            891,
            1894,
            1894
        ],
        7801: [
            857,
            712,
            591,
            915,
            1925,
            1925
        ],
        7901: [
            880,
            739,
            601,
            939,
            1955,
            1955
        ],
        8001: [
            902,
            767,
            611,
            963,
            1982,
            1982
        ],
        8101: [
            925,
            795,
            621,
            988,
            2012,
            2012
        ],
        8201: [
            947,
            823,
            631,
            1012,
            2039,
            2039
        ],
        8301: [
            970,
            850,
            641,
            1036,
            2070,
            2070
        ],
        8401: [
            992,
            878,
            642,
            1060,
            2100,
            2100
        ],
        8501: [
            1014,
            906,
            652,
            1084,
            2127,
            2127
        ],
        8601: [
            1037,
            931,
            662,
            1108,
            2157,
            2157
        ],
        8701: [
            1059,
            958,
            672,
            1132,
            2184,
            2184
        ],
        8801: [
            1082,
            986,
            682,
            1156,
            2215,
            2215
        ],
        8901: [
            1104,
            1014,
            692,
            1180,
            2245,
            2245
        ],
        9001: [
            1127,
            1042,
            694,
            1204,
            2272,
            2272
        ],
        9101: [
            1149,
            1069,
            704,
            1229,
            2302,
            2302
        ],
        9201: [
            1172,
            1097,
            714,
            1253,
            2329,
            2329
        ],
        9301: [
            1194,
            1116,
            723,
            1277,
            2360,
            2360
        ],
        9401: [
            1217,
            1140,
            733,
            1301,
            2390,
            2390
        ],
        9501: [
            1239,
            1162,
            742,
            1325,
            2417,
            2417
        ],
        9601: [
            1262,
            1183,
            744,
            1349,
            2447,
            2447
        ],
        9701: [
            1284,
            1205,
            753,
            1373,
            2474,
            2474
        ],
        9801: [
            1307,
            1230,
            763,
            1397,
            2505,
            2505
        ],
        9901: [
            1329,
            1251,
            773,
            1421,
            2535,
            2535
        ],
        10001: [
            1351,
            1272,
            782,
            1445,
            2562,
            2562
        ],
        10101: [
            1374,
            1293,
            792,
            1470,
            2592,
            2592
        ],
        10201: [
            1396,
            1317,
            802,
            1494,
            2619,
            2619
        ],
        10301: [
            1419,
            1339,
            803,
            1518,
            2650,
            2650
        ],
        10401: [
            1441,
            1360,
            812,
            1542,
            2680,
            2680
        ],
        10501: [
            1464,
            1381,
            822,
            1566,
            2707,
            2707
        ],
        10601: [
            1486,
            1405,
            832,
            1590,
            2737,
            2737
        ],
        10701: [
            1509,
            1426,
            841,
            1614,
            2764,
            2764
        ],
        10801: [
            1531,
            1447,
            851,
            1638,
            2795,
            2795
        ],
        10901: [
            1554,
            1468,
            852,
            1663,
            2825,
            2825
        ],
        11001: [
            1576,
            1493,
            862,
            1687,
            2852,
            2852
        ],
        11101: [
            1599,
            1514,
            872,
            1711,
            2882,
            2882
        ],
        11201: [
            1621,
            1535,
            881,
            1735,
            2909,
            2909
        ],
        11301: [
            1644,
            1559,
            891,
            1759,
            2940,
            2940
        ],
        11401: [
            1666,
            1580,
            901,
            1783,
            2970,
            2970
        ],
        11501: [
            1689,
            1601,
            902,
            1807,
            2997,
            2997
        ],
        11601: [
            1711,
            1623,
            911,
            1831,
            3027,
            3027
        ],
        11701: [
            1733,
            1647,
            921,
            1855,
            3054,
            3054
        ],
        11801: [
            1756,
            1668,
            931,
            1879,
            3085,
            3085
        ],
        11901: [
            1778,
            1689,
            940,
            1904,
            3115,
            3115
        ],
        12001: [
            1801,
            1710,
            950,
            1928,
            3142,
            3142
        ],
        12101: [
            1823,
            1734,
            951,
            1952,
            3172,
            3172
        ],
        12201: [
            1846,
            1755,
            961,
            1976,
            3199,
            3199
        ],
        12301: [
            1868,
            1777,
            971,
            2000,
            3230,
            3230
        ],
        12401: [
            1891,
            1798,
            980,
            2024,
            3260,
            3260
        ],
        12501: [
            1913,
            1822,
            990,
            2048,
            3287,
            3287
        ],
        12601: [
            1936,
            1843,
            1000,
            2072,
            3317,
            3317
        ],
        12701: [
            1958,
            1864,
            1009,
            2096,
            3344,
            3344
        ],
        12801: [
            1981,
            1885,
            1010,
            2120,
            3375,
            3375
        ],
        12901: [
            2003,
            1909,
            1020,
            2145,
            3402,
            3402
        ],
        13001: [
            2026,
            1931,
            1030,
            2169,
            3438,
            3438
        ],
        13101: [
            2049,
            1952,
            1039,
            2193,
            3474,
            3474
        ],
        13201: [
            2071,
            1976,
            1049,
            2218,
            3511,
            3511
        ],
        13301: [
            2094,
            1997,
            1059,
            2242,
            3547,
            3547
        ],
        13401: [
            2117,
            2018,
            1060,
            2266,
            3583,
            3583
        ],
        13501: [
            2140,
            2039,
            1070,
            2291,
            3619,
            3619
        ],
        13601: [
            2162,
            2064,
            1079,
            2315,
            3656,
            3656
        ],
        13701: [
            2185,
            2085,
            1089,
            2340,
            3692,
            3692
        ],
        13801: [
            2207,
            2106,
            1099,
            2363,
            3727,
            3727
        ],
        13901: [
            2229,
            2127,
            1108,
            2387,
            3763,
            3763
        ],
        14001: [
            2251,
            2151,
            1110,
            2410,
            3798,
            3798
        ],
        14101: [
            2273,
            2172,
            1119,
            2434,
            3833,
            3833
        ],
        14201: [
            2294,
            2193,
            1129,
            2457,
            3868,
            3868
        ],
        14301: [
            2316,
            2215,
            1138,
            2480,
            3904,
            3904
        ],
        14401: [
            2338,
            2239,
            1148,
            2504,
            3939,
            3939
        ],
        14501: [
            2360,
            2260,
            1158,
            2527,
            3974,
            3974
        ],
        14601: [
            2381,
            2281,
            1159,
            2551,
            4009,
            4009
        ],
        14701: [
            2403,
            2302,
            1169,
            2574,
            4045,
            4045
        ],
        14801: [
            2425,
            2326,
            1178,
            2597,
            4083,
            4083
        ],
        14901: [
            2447,
            2348,
            1188,
            2621,
            4121,
            4121
        ],
        15001: [
            2468,
            2369,
            1198,
            2644,
            4159,
            4159
        ],
        15101: [
            2490,
            2390,
            1207,
            2668,
            4197,
            4197
        ],
        15201: [
            2512,
            2414,
            1217,
            2691,
            4239,
            4239
        ],
        15301: [
            2534,
            2435,
            1218,
            2714,
            4277,
            4277
        ],
        15401: [
            2557,
            2459,
            1228,
            2741,
            4315,
            4315
        ],
        15501: [
            2587,
            2489,
            1238,
            2771,
            4353,
            4353
        ],
        15601: [
            2616,
            2520,
            1248,
            2802,
            4391,
            4391
        ],
        15701: [
            2646,
            2550,
            1258,
            2833,
            4432,
            4432
        ],
        15801: [
            2676,
            2580,
            1268,
            2864,
            4471,
            4471
        ],
        15901: [
            2705,
            2610,
            1270,
            2895,
            4509,
            4509
        ],
        16001: [
            2735,
            2641,
            1280,
            2926,
            4547,
            4547
        ],
        16101: [
            2764,
            2671,
            1291,
            2956,
            4585,
            4585
        ],
        16201: [
            2794,
            2701,
            1301,
            2987,
            4626,
            4626
        ],
        16301: [
            2823,
            2731,
            1311,
            3018,
            4665,
            4665
        ],
        16401: [
            2853,
            2761,
            1321,
            3049,
            4703,
            4703
        ],
        16501: [
            2882,
            2792,
            1323,
            3080,
            4741,
            4741
        ],
        16601: [
            2912,
            2822,
            1333,
            3111,
            4779,
            4779
        ],
        16701: [
            2942,
            2852,
            1343,
            3141,
            4820,
            4820
        ],
        16801: [
            2971,
            2882,
            1353,
            3172,
            4858,
            4858
        ],
        16901: [
            3001,
            2912,
            1363,
            3203,
            4897,
            4897
        ],
        17001: [
            3030,
            2940,
            1373,
            3234,
            4935,
            4935
        ],
        17101: [
            3060,
            2970,
            1375,
            3265,
            4973,
            4973
        ],
        17201: [
            3089,
            3000,
            1385,
            3296,
            5014,
            5014
        ],
        17301: [
            3119,
            3030,
            1395,
            3326,
            5052,
            5052
        ],
        17401: [
            3149,
            3060,
            1405,
            3357,
            5090,
            5090
        ],
        17501: [
            3178,
            3091,
            1416,
            3388,
            5129,
            5129
        ],
        17601: [
            3208,
            3121,
            1426,
            3419,
            5167,
            5167
        ],
        17701: [
            3237,
            3151,
            1436,
            3450,
            5208,
            5208
        ],
        17801: [
            3267,
            3181,
            1438,
            3481,
            5246,
            5246
        ],
        17901: [
            3296,
            3211,
            1448,
            3511,
            5284,
            5284
        ],
        18001: [
            3326,
            3242,
            1458,
            3542,
            5323,
            5323
        ],
        18101: [
            3356,
            3272,
            1468,
            3573,
            5361,
            5361
        ],
        18201: [
            3385,
            3302,
            1478,
            3604,
            5402,
            5402
        ],
        18301: [
            3415,
            3332,
            1488,
            3635,
            5440,
            5440
        ],
        18401: [
            3444,
            3363,
            1490,
            3666,
            5478,
            5478
        ],
        18501: [
            3474,
            3393,
            1500,
            3696,
            5516,
            5516
        ],
        18601: [
            3503,
            3423,
            1510,
            3727,
            5555,
            5555
        ],
        18701: [
            3533,
            3453,
            1520,
            3758,
            5596,
            5596
        ],
        18801: [
            3563,
            3483,
            1531,
            3789,
            5634,
            5634
        ],
        18901: [
            3592,
            3514,
            1541,
            3820,
            5672,
            5672
        ],
        19001: [
            3622,
            3544,
            1543,
            3851,
            5710,
            5710
        ],
        19101: [
            3651,
            3574,
            1553,
            3881,
            5748,
            5748
        ],
        19201: [
            3681,
            3604,
            1563,
            3912,
            5790,
            5790
        ],
        19301: [
            3710,
            3634,
            1573,
            3943,
            5828,
            5828
        ],
        19401: [
            3740,
            3665,
            1583,
            3974,
            5866,
            5866
        ],
        19501: [
            3769,
            3695,
            1593,
            4005,
            5904,
            5904
        ],
        19601: [
            3799,
            3725,
            1595,
            4035,
            5942,
            5942
        ],
        19701: [
            3829,
            3754,
            1604,
            4066,
            5983,
            5983
        ],
        19801: [
            3858,
            3783,
            1613,
            4097,
            6022,
            6022
        ],
        19901: [
            3888,
            3813,
            1622,
            4128,
            6060,
            6060
        ],
        20001: [
            3947,
            3871,
            1641,
            4190,
            6136,
            6136
        ],
        20201: [
            4006,
            3927,
            1651,
            4251,
            6216,
            6216
        ],
        20401: [
            4065,
            3986,
            1670,
            4313,
            6292,
            6292
        ],
        20601: [
            4124,
            4045,
            1688,
            4375,
            6371,
            6371
        ],
        20801: [
            4183,
            4103,
            1699,
            4436,
            6448,
            6448
        ],
        21001: [
            4243,
            4162,
            1717,
            4498,
            6524,
            6524
        ],
        21201: [
            4302,
            4221,
            1736,
            4560,
            6603,
            6603
        ],
        21401: [
            4361,
            4280,
            1746,
            4621,
            6680,
            6680
        ],
        21601: [
            4420,
            4338,
            1765,
            4683,
            6759,
            6759
        ],
        21801: [
            4479,
            4397,
            1783,
            4745,
            6835,
            6835
        ],
        22001: [
            4538,
            4456,
            1794,
            4806,
            6912,
            6912
        ],
        22201: [
            4597,
            4515,
            1812,
            4868,
            6991,
            6991
        ],
        22401: [
            4656,
            4573,
            1831,
            4930,
            7067,
            7067
        ],
        22601: [
            4716,
            4632,
            1849,
            4991,
            7147,
            7147
        ],
        22801: [
            4776,
            4691,
            1860,
            5054,
            7224,
            7224
        ],
        23001: [
            4836,
            4750,
            1878,
            5117,
            7302,
            7302
        ],
        23201: [
            4897,
            4808,
            1912,
            5181,
            7383,
            7383
        ],
        23401: [
            4958,
            4864,
            1954,
            5244,
            7461,
            7461
        ],
        23601: [
            5019,
            4923,
            1999,
            5307,
            7542,
            7542
        ],
        23801: [
            5080,
            4979,
            2041,
            5371,
            7620,
            7620
        ],
        24001: [
            5140,
            5041,
            2088,
            5434,
            7698,
            7698
        ],
        24201: [
            5201,
            5099,
            2133,
            5497,
            7779,
            7779
        ],
        24401: [
            5262,
            5161,
            2181,
            5560,
            7857,
            7857
        ],
        24601: [
            5323,
            5220,
            2226,
            5624,
            7938,
            7938
        ],
        24801: [
            5384,
            5278,
            2270,
            5687,
            8016,
            8016
        ],
        25001: [
            5444,
            5340,
            2335,
            5750,
            8094,
            8094
        ],
        25201: [
            5505,
            5399,
            2394,
            5814,
            8175,
            8175
        ],
        25401: [
            5566,
            5458,
            2453,
            5877,
            8253,
            8253
        ],
        25601: [
            5627,
            5519,
            2514,
            5940,
            8334,
            8334
        ],
        25801: [
            5687,
            5578,
            2573,
            6004,
            8412,
            8412
        ],
        26001: [
            5748,
            5637,
            2632,
            6067,
            8490,
            8490
        ],
        26201: [
            5809,
            5699,
            2694,
            6130,
            8571,
            8571
        ],
        26401: [
            5870,
            5757,
            2752,
            6194,
            8649,
            8649
        ],
        26601: [
            5931,
            5819,
            2814,
            6257,
            8730,
            8730
        ],
        26801: [
            5991,
            5878,
            2873,
            6320,
            8808,
            8808
        ],
        27001: [
            6052,
            5936,
            2932,
            6383,
            8886,
            8886
        ],
        27201: [
            6113,
            5998,
            2993,
            6447,
            8967,
            8967
        ],
        27401: [
            6174,
            6057,
            3052,
            6510,
            9045,
            9045
        ],
        27601: [
            6235,
            6116,
            3111,
            6574,
            9126,
            9126
        ],
        27801: [
            6295,
            6177,
            3172,
            6637,
            9204,
            9204
        ],
        28001: [
            6356,
            6236,
            3231,
            6700,
            9282,
            9282
        ],
        28201: [
            6417,
            6298,
            3293,
            6763,
            9363,
            9363
        ],
        28401: [
            6478,
            6357,
            3352,
            6827,
            9441,
            9441
        ],
        28601: [
            6538,
            6415,
            3410,
            6890,
            9522,
            9522
        ],
        28801: [
            6599,
            6477,
            3472,
            6953,
            9600,
            9600
        ],
        29001: [
            6660,
            6536,
            3531,
            7017,
            9678,
            9678
        ],
        29201: [
            6721,
            6594,
            3590,
            7080,
            9759,
            9759
        ],
        29401: [
            6782,
            6656,
            3651,
            7143,
            9837,
            9837
        ],
        29601: [
            6842,
            6715,
            3710,
            7207,
            9918,
            9918
        ],
        29801: [
            6903,
            6777,
            3772,
            7270,
            9996,
            9996
        ],
        30001: [
            6964,
            6835,
            3830,
            7333,
            10074,
            10074
        ],
        30201: [
            7025,
            6894,
            3889,
            7397,
            10155,
            10155
        ],
        30401: [
            7085,
            6956,
            3951,
            7460,
            10233,
            10233
        ],
        30601: [
            7146,
            7015,
            4010,
            7523,
            10314,
            10314
        ],
        30801: [
            7207,
            7073,
            4068,
            7586,
            10392,
            10392
        ],
        31001: [
            7268,
            7135,
            4130,
            7650,
            10470,
            10470
        ],
        31201: [
            7329,
            7194,
            4189,
            7713,
            10551,
            10551
        ],
        31401: [
            7389,
            7254,
            4249,
            7776,
            10629,
            10629
        ],
        31601: [
            7450,
            7317,
            4312,
            7840,
            10710,
            10710
        ],
        31801: [
            7511,
            7377,
            4372,
            7903,
            10788,
            10788
        ],
        32001: [
            7572,
            7440,
            4435,
            7966,
            10866,
            10866
        ],
        32201: [
            7633,
            7500,
            4495,
            8030,
            10947,
            10947
        ],
        32401: [
            7693,
            7560,
            4555,
            8093,
            11025,
            11025
        ],
        32601: [
            7754,
            7623,
            4618,
            8156,
            11106,
            11106
        ],
        32801: [
            7815,
            7683,
            4678,
            8220,
            11184,
            11184
        ],
        33001: [
            7876,
            7743,
            4738,
            8283,
            11262,
            11262
        ],
        33201: [
            7936,
            7806,
            4801,
            8346,
            11343,
            11343
        ],
        33401: [
            7997,
            7866,
            4861,
            8410,
            11421,
            11421
        ],
        33601: [
            8058,
            7929,
            4924,
            8473,
            11502,
            11502
        ],
        33801: [
            8119,
            7989,
            4984,
            8536,
            11580,
            11580
        ],
        34001: [
            8180,
            8049,
            5044,
            8599,
            11658,
            11658
        ],
        34201: [
            8240,
            8112,
            5107,
            8663,
            11739,
            11739
        ],
        34401: [
            8301,
            8172,
            5167,
            8726,
            11817,
            11817
        ],
        34601: [
            8362,
            8232,
            5227,
            8789,
            11898,
            11898
        ],
        34801: [
            8423,
            8295,
            5290,
            8853,
            11976,
            11976
        ],
        35001: [
            8483,
            8355,
            5350,
            8916,
            12054,
            12054
        ],
        35201: [
            8544,
            8415,
            5410,
            8979,
            12135,
            12135
        ],
        35401: [
            8605,
            8478,
            5473,
            9043,
            12213,
            12213
        ],
        35601: [
            8666,
            8538,
            5533,
            9106,
            12294,
            12294
        ],
        35801: [
            8727,
            8601,
            5596,
            9169,
            12372,
            12372
        ],
        36001: [
            8787,
            8661,
            5656,
            9233,
            12450,
            12450
        ],
        36201: [
            8848,
            8721,
            5716,
            9296,
            12531,
            12531
        ],
        36401: [
            8909,
            8784,
            5779,
            9359,
            12609,
            12609
        ],
        36601: [
            8970,
            8844,
            5839,
            9423,
            12690,
            12690
        ],
        36801: [
            9031,
            8904,
            5899,
            9486,
            12768,
            12768
        ],
        37001: [
            9091,
            8967,
            5962,
            9549,
            12846,
            12846
        ],
        37201: [
            9152,
            9027,
            6022,
            9612,
            12927,
            12927
        ],
        37401: [
            9213,
            9090,
            6085,
            9676,
            13005,
            13005
        ],
        37601: [
            9274,
            9147,
            6142,
            9739,
            13080,
            13080
        ],
        37801: [
            9334,
            9207,
            6202,
            9802,
            13152,
            13152
        ],
        38001: [
            9395,
            9267,
            6262,
            9865,
            13224,
            13224
        ],
        38201: [
            9455,
            9330,
            6325,
            9928,
            13296,
            13296
        ],
        38401: [
            9517,
            9390,
            6385,
            9991,
            13368,
            13368
        ],
        38601: [
            9589,
            9462,
            6457,
            10054,
            13440,
            13440
        ],
        38801: [
            9661,
            9534,
            6529,
            10117,
            13512,
            13512
        ],
        39001: [
            9733,
            9606,
            6601,
            10181,
            13584,
            13584
        ],
        39201: [
            9805,
            9678,
            6673,
            10244,
            13656,
            13656
        ],
        39401: [
            9877,
            9750,
            6745,
            10307,
            13728,
            13728
        ],
        39601: [
            9949,
            9822,
            6817,
            10370,
            13800,
            13800
        ],
        39801: [
            10021,
            9894,
            6889,
            10433,
            13872,
            13872
        ],
        40001: [
            10093,
            9966,
            6961,
            10496,
            13944,
            13944
        ],
        40201: [
            10165,
            10038,
            7033,
            10559,
            14016,
            14016
        ],
        40401: [
            10237,
            10110,
            7105,
            10622,
            14088,
            14088
        ],
        40601: [
            10309,
            10182,
            7177,
            10685,
            14160,
            14160
        ],
        40801: [
            10381,
            10254,
            7249,
            10748,
            14232,
            14232
        ],
        41001: [
            10453,
            10326,
            7321,
            10811,
            14304,
            14304
        ],
        41201: [
            10525,
            10398,
            7393,
            10874,
            14376,
            14376
        ],
        41401: [
            10597,
            10470,
            7465,
            10937,
            14448,
            14448
        ],
        41601: [
            10669,
            10542,
            7537,
            11001,
            14520,
            14520
        ],
        41801: [
            10741,
            10614,
            7609,
            11064,
            14592,
            14592
        ],
        42001: [
            10813,
            10686,
            7681,
            11127,
            14664,
            14664
        ],
        42201: [
            10885,
            10758,
            7753,
            11190,
            14736,
            14736
        ],
        42401: [
            10957,
            10830,
            7825,
            11253,
            14808,
            14808
        ],
        42601: [
            11029,
            10902,
            7897,
            11316,
            14880,
            14880
        ],
        42801: [
            11101,
            10974,
            7969,
            11379,
            14952,
            14952
        ],
        43001: [
            11173,
            11046,
            8041,
            11442,
            15024,
            15024
        ],
        43201: [
            11245,
            11118,
            8113,
            11505,
            15096,
            15096
        ],
        43401: [
            11317,
            11190,
            8185,
            11568,
            15168,
            15168
        ],
        43601: [
            11389,
            11262,
            8257,
            11631,
            15240,
            15240
        ],
        43801: [
            11461,
            11334,
            8329,
            11694,
            15312,
            15312
        ],
        44001: [
            11533,
            11406,
            8401,
            11758,
            15384,
            15384
        ],
        44201: [
            11605,
            11478,
            8473,
            11821,
            15456,
            15456
        ],
        44401: [
            11677,
            11550,
            8545,
            11884,
            15528,
            15528
        ],
        44601: [
            11749,
            11622,
            8617,
            11947,
            15600,
            15600
        ],
        44801: [
            11821,
            11694,
            8689,
            12010,
            15672,
            15672
        ],
        45001: [
            11893,
            11766,
            8761,
            12073,
            15744,
            15744
        ],
        45201: [
            11965,
            11838,
            8833,
            12136,
            15816,
            15816
        ],
        45401: [
            12037,
            11910,
            8905,
            12199,
            15888,
            15888
        ],
        45601: [
            12109,
            11982,
            8977,
            12262,
            15960,
            15960
        ],
        45801: [
            12181,
            12054,
            9049,
            12325,
            16032,
            16032
        ],
        46001: [
            12253,
            12126,
            9121,
            12388,
            16104,
            16104
        ],
        46201: [
            12325,
            12198,
            9193,
            12452,
            16176,
            16176
        ],
        46401: [
            12397,
            12270,
            9265,
            12515,
            16248,
            16248
        ],
        46601: [
            12469,
            12342,
            9337,
            12578,
            16320,
            16320
        ],
        46801: [
            12541,
            12414,
            9409,
            12641,
            16392,
            16392
        ],
        47001: [
            12613,
            12486,
            9481,
            12704,
            16464,
            16464
        ],
        47201: [
            12685,
            12558,
            9553,
            12767,
            16536,
            16536
        ],
        47401: [
            12757,
            12630,
            9625,
            12830,
            16608,
            16608
        ],
        47601: [
            12829,
            12702,
            9697,
            12893,
            16680,
            16680
        ],
        47801: [
            12901,
            12774,
            9769,
            12956,
            16752,
            16752
        ],
        48001: [
            12973,
            12846,
            9841,
            13019,
            16824,
            16824
        ],
        48201: [
            13045,
            12918,
            9913,
            13082,
            16896,
            16896
        ],
        48401: [
            13117,
            12990,
            9985,
            13145,
            16968,
            16968
        ],
        48601: [
            13189,
            13062,
            10057,
            13208,
            17040,
            17040
        ],
        48801: [
            13261,
            13134,
            10129,
            13271,
            17112,
            17112
        ],
        49001: [
            13333,
            13206,
            10201,
            13335,
            17184,
            17184
        ],
        49201: [
            13405,
            13278,
            10273,
            13398,
            17256,
            17256
        ],
        49401: [
            13477,
            13350,
            10345,
            13461,
            17328,
            17328
        ],
        49601: [
            13549,
            13422,
            10417,
            13524,
            17400,
            17400
        ],
        49801: [
            13621,
            13494,
            10489,
            13587,
            17472,
            17472
        ],
        50001: [
            13693,
            13566,
            10566,
            13650,
            17544,
            17544
        ],
        50201: [
            13765,
            13638,
            10644,
            13713,
            17616,
            17616
        ],
        50401: [
            13837,
            13710,
            10722,
            13776,
            17688,
            17688
        ],
        50601: [
            13909,
            13782,
            10800,
            13839,
            17760,
            17760
        ],
        50801: [
            13981,
            13854,
            10878,
            13902,
            17832,
            17832
        ],
        51001: [
            14053,
            13926,
            10956,
            13965,
            17904,
            17904
        ],
        51201: [
            14150,
            14025,
            11061,
            14054,
            18001,
            18001
        ],
        51401: [
            14262,
            14142,
            11184,
            14157,
            18113,
            18113
        ],
        51601: [
            14374,
            14259,
            11307,
            14260,
            18225,
            18225
        ],
        51801: [
            14486,
            14373,
            11427,
            14363,
            18337,
            18337
        ],
        52001: [
            14598,
            14490,
            11550,
            14466,
            18449,
            18449
        ],
        52201: [
            14710,
            14607,
            11673,
            14569,
            18561,
            18561
        ],
        52401: [
            14822,
            14724,
            11796,
            14672,
            18673,
            18673
        ],
        52601: [
            14934,
            14841,
            11919,
            14775,
            18785,
            18785
        ],
        52801: [
            15046,
            14958,
            12042,
            14878,
            18897,
            18897
        ],
        53001: [
            15158,
            15075,
            12165,
            14981,
            19009,
            19009
        ],
        53201: [
            15270,
            15192,
            12288,
            15084,
            19121,
            19121
        ],
        53401: [
            15382,
            15306,
            12408,
            15187,
            19233,
            19233
        ],
        53601: [
            15494,
            15423,
            12531,
            15290,
            19345,
            19345
        ],
        53801: [
            15606,
            15540,
            12654,
            15394,
            19457,
            19457
        ],
        54001: [
            15718,
            15657,
            12777,
            15497,
            19569,
            19569
        ],
        54201: [
            15830,
            15774,
            12900,
            15600,
            19681,
            19681
        ],
        54401: [
            15942,
            15891,
            13023,
            15703,
            19793,
            19793
        ],
        54601: [
            16054,
            16008,
            13146,
            15806,
            19905,
            19905
        ],
        54801: [
            16166,
            16125,
            13269,
            15909,
            20017,
            20017
        ],
        55001: [
            16278,
            16242,
            13392,
            16012,
            20129,
            20129
        ],
        55201: [
            16390,
            16356,
            13512,
            16115,
            20241,
            20241
        ],
        55401: [
            16502,
            16473,
            13635,
            16218,
            20353,
            20353
        ],
        55601: [
            16614,
            16590,
            13758,
            16321,
            20465,
            20465
        ],
        55801: [
            16726,
            16707,
            13881,
            16424,
            20577,
            20577
        ],
        56001: [
            16838,
            16824,
            14004,
            16527,
            20689,
            20689
        ],
        56201: [
            16950,
            16941,
            14127,
            16631,
            20801,
            20801
        ],
        56401: [
            17062,
            17058,
            14250,
            16734,
            20913,
            20913
        ],
        56601: [
            17174,
            17175,
            14373,
            16837,
            21025,
            21025
        ],
        56801: [
            17286,
            17289,
            14493,
            16940,
            21137,
            21137
        ],
        57001: [
            17398,
            17406,
            14616,
            17043,
            21249,
            21249
        ],
        57201: [
            17510,
            17523,
            14739,
            17146,
            21361,
            21361
        ],
        57401: [
            17622,
            17640,
            14862,
            17249,
            21473,
            21473
        ],
        57601: [
            17734,
            17757,
            14985,
            17352,
            21585,
            21585
        ],
        57801: [
            17846,
            17874,
            15108,
            17455,
            21697,
            21697
        ],
        58001: [
            17958,
            17982,
            15222,
            17558,
            21809,
            21809
        ],
        58201: [
            18070,
            18094,
            15340,
            17661,
            21921,
            21921
        ],
        58401: [
            18182,
            18206,
            15458,
            17764,
            22033,
            22033
        ],
        58601: [
            18294,
            18318,
            15576,
            17867,
            22145,
            22145
        ],
        58801: [
            18406,
            18430,
            15694,
            17971,
            22257,
            22257
        ],
        59001: [
            18518,
            18542,
            15812,
            18074,
            22369,
            22369
        ],
        59201: [
            18630,
            18654,
            15930,
            18177,
            22481,
            22481
        ],
        59401: [
            18742,
            18766,
            16048,
            18280,
            22593,
            22593
        ],
        59601: [
            18854,
            18878,
            16166,
            18383,
            22705,
            22705
        ],
        59801: [
            18966,
            18990,
            16284,
            18486,
            22817,
            22817
        ],
        60001: [
            19078,
            19102,
            16402,
            18589,
            22929,
            22929
        ],
        60201: [
            19190,
            19214,
            16520,
            18692,
            23041,
            23041
        ],
        60401: [
            19302,
            19326,
            16638,
            18795,
            23153,
            23153
        ],
        60601: [
            19414,
            19438,
            16756,
            18898,
            23265,
            23265
        ],
        60801: [
            19526,
            19550,
            16874,
            19001,
            23377,
            23377
        ],
        61001: [
            19638,
            19662,
            16992,
            19104,
            23489,
            23489
        ],
        61201: [
            19750,
            19774,
            17110,
            19208,
            23601,
            23601
        ],
        61401: [
            19862,
            19886,
            17228,
            19311,
            23713,
            23713
        ],
        61601: [
            19974,
            19998,
            17346,
            19414,
            23825,
            23825
        ],
        61801: [
            20086,
            20110,
            17464,
            19517,
            23937,
            23937
        ],
        62001: [
            20198,
            20222,
            17582,
            19620,
            24049,
            24049
        ],
        62201: [
            20310,
            20334,
            17700,
            19723,
            24161,
            24161
        ],
        62401: [
            20422,
            20446,
            17818,
            19826,
            24273,
            24273
        ],
        62601: [
            20534,
            20558,
            17936,
            19929,
            24385,
            24385
        ],
        62801: [
            20646,
            20670,
            18054,
            20032,
            24497,
            24497
        ],
        63001: [
            20758,
            20782,
            18172,
            20135,
            24609,
            24609
        ],
        63201: [
            20870,
            20894,
            18290,
            20238,
            24721,
            24721
        ],
        63401: [
            20982,
            21006,
            18408,
            20341,
            24833,
            24833
        ],
        63601: [
            21094,
            21118,
            18526,
            20444,
            24945,
            24945
        ],
        63801: [
            21206,
            21230,
            18644,
            20548,
            25057,
            25057
        ],
        64001: [
            21318,
            21342,
            18762,
            20651,
            25169,
            25169
        ],
        64201: [
            21430,
            21454,
            18880,
            20754,
            25281,
            25281
        ],
        64401: [
            21542,
            21566,
            18998,
            20857,
            25393,
            25393
        ],
        64601: [
            21659,
            21688,
            19126,
            20960,
            25505,
            25505
        ],
        64801: [
            21777,
            21804,
            19248,
            21063,
            25617,
            25617
        ],
        65001: [
            21895,
            21926,
            19376,
            21166,
            25729,
            25729
        ],
        65201: [
            22013,
            22042,
            19498,
            21269,
            25841,
            25841
        ],
        65401: [
            22131,
            22159,
            19621,
            21372,
            25953,
            25953
        ],
        65601: [
            22249,
            22280,
            19748,
            21475,
            26065,
            26065
        ],
        65801: [
            22367,
            22397,
            19871,
            21578,
            26177,
            26177
        ],
        66001: [
            22485,
            22518,
            19998,
            21681,
            26289,
            26289
        ],
        66201: [
            22603,
            22635,
            20121,
            21785,
            26401,
            26401
        ],
        66401: [
            22721,
            22752,
            20244,
            21888,
            26513,
            26513
        ],
        66601: [
            22839,
            22873,
            20371,
            21991,
            26625,
            26625
        ],
        66801: [
            22957,
            22990,
            20494,
            22094,
            26737,
            26737
        ],
        67001: [
            23075,
            23106,
            20616,
            22197,
            26849,
            26849
        ],
        67201: [
            23193,
            23228,
            20744,
            22300,
            26961,
            26961
        ],
        67401: [
            23311,
            23344,
            20866,
            22403,
            27073,
            27073
        ],
        67601: [
            23429,
            23466,
            20994,
            22506,
            27185,
            27185
        ],
        67801: [
            23547,
            23582,
            21116,
            22609,
            27297,
            27297
        ],
        68001: [
            23665,
            23699,
            21239,
            22712,
            27409,
            27409
        ],
        68201: [
            23783,
            23820,
            21366,
            22815,
            27521,
            27521
        ],
        68401: [
            23901,
            23937,
            21489,
            22918,
            27633,
            27633
        ],
        68601: [
            24019,
            24054,
            21612,
            23021,
            27745,
            27745
        ],
        68801: [
            24137,
            24175,
            21739,
            23125,
            27857,
            27857
        ],
        69001: [
            24255,
            24292,
            21862,
            23228,
            27969,
            27969
        ],
        69201: [
            24373,
            24413,
            21989,
            23331,
            28081,
            28081
        ],
        69401: [
            24491,
            24530,
            22112,
            23434,
            28193,
            28193
        ],
        69601: [
            24609,
            24646,
            22234,
            23537,
            28305,
            28305
        ],
        69801: [
            24727,
            24768,
            22362,
            23640,
            28417,
            28417
        ],
        70001: [
            24845,
            24884,
            22484,
            23743,
            28529,
            28529
        ],
        70201: [
            24963,
            25001,
            22607,
            23846,
            28641,
            28641
        ],
        70401: [
            25081,
            25122,
            22734,
            23949,
            28753,
            28753
        ],
        70601: [
            25199,
            25239,
            22857,
            24052,
            28865,
            28865
        ],
        70801: [
            25317,
            25360,
            22984,
            24155,
            28977,
            28977
        ],
        71001: [
            25435,
            25477,
            23107,
            24258,
            29089,
            29089
        ],
        71201: [
            25553,
            25594,
            23230,
            24362,
            29201,
            29201
        ],
        71401: [
            25671,
            25715,
            23357,
            24465,
            29313,
            29313
        ],
        71601: [
            25789,
            25832,
            23480,
            24568,
            29425,
            29425
        ],
        71801: [
            25907,
            25953,
            23607,
            24671,
            29537,
            29537
        ],
        72001: [
            26025,
            26070,
            23730,
            24774,
            29649,
            29649
        ],
        72201: [
            26143,
            26186,
            23852,
            24877,
            29761,
            29761
        ],
        72401: [
            26261,
            26308,
            23980,
            24980,
            29873,
            29873
        ],
        72601: [
            26379,
            26424,
            24102,
            25083,
            29985,
            29985
        ],
        72801: [
            26497,
            26541,
            24225,
            25186,
            30097,
            30097
        ],
        73001: [
            26615,
            26662,
            24352,
            25289,
            30209,
            30209
        ],
        73201: [
            26733,
            26779,
            24475,
            25392,
            30321,
            30321
        ],
        73401: [
            26851,
            26900,
            24602,
            25495,
            30433,
            30433
        ],
        73601: [
            26969,
            27017,
            24725,
            25599,
            30545,
            30545
        ],
        73801: [
            27087,
            27134,
            24848,
            25702,
            30657,
            30657
        ],
        74001: [
            27205,
            27255,
            24975,
            25805,
            30769,
            30769
        ],
        74201: [
            27323,
            27372,
            25098,
            25908,
            30881,
            30881
        ],
        74401: [
            27441,
            27488,
            25220,
            26011,
            30993,
            30993
        ],
        74601: [
            27559,
            27610,
            25348,
            26114,
            31105,
            31105
        ],
        74801: [
            27677,
            27726,
            25470,
            26217,
            31217,
            31217
        ],
        75001: [
            27795,
            27848,
            25598,
            26320,
            31329,
            31329
        ],
        75201: [
            27913,
            27964,
            25720,
            26423,
            31441,
            31441
        ],
        75401: [
            28031,
            28081,
            25843,
            26526,
            31553,
            31553
        ],
        75601: [
            28149,
            28202,
            25970,
            26629,
            31665,
            31665
        ],
        75801: [
            28267,
            28319,
            26093,
            26732,
            31777,
            31777
        ],
        76001: [
            28385,
            28436,
            26216,
            26835,
            31889,
            31889
        ],
        76201: [
            28503,
            28557,
            26343,
            26939,
            32001,
            32001
        ],
        76401: [
            28621,
            28674,
            26466,
            27042,
            32113,
            32113
        ],
        76601: [
            28739,
            28795,
            26593,
            27145,
            32225,
            32225
        ],
        76801: [
            28857,
            28912,
            26716,
            27248,
            32337,
            32337
        ],
        77001: [
            28975,
            29028,
            26838,
            27351,
            32449,
            32449
        ],
        77201: [
            29093,
            29150,
            26966,
            27454,
            32561,
            32561
        ],
        77401: [
            29211,
            29266,
            27088,
            27557,
            32673,
            32673
        ],
        77601: [
            29329,
            29383,
            27211,
            27660,
            32785,
            32785
        ],
        77801: [
            29447,
            29504,
            27338,
            27763,
            32897,
            32897
        ],
        78001: [
            29565,
            29621,
            27461,
            27866,
            33009,
            33009
        ],
        78201: [
            29683,
            29742,
            27588,
            27969,
            33121,
            33121
        ],
        78401: [
            29801,
            29859,
            27711,
            28072,
            33233,
            33233
        ],
        78601: [
            29919,
            29976,
            27834,
            28176,
            33345,
            33345
        ],
        78801: [
            30037,
            30097,
            27961,
            28279,
            33457,
            33457
        ],
        79001: [
            30155,
            30214,
            28084,
            28382,
            33569,
            33569
        ],
        79201: [
            30273,
            30330,
            28206,
            28485,
            33681,
            33681
        ],
        79401: [
            30391,
            30452,
            28334,
            28588,
            33793,
            33793
        ],
        79601: [
            30509,
            30568,
            28456,
            28691,
            33905,
            33905
        ],
        79801: [
            30627,
            30690,
            28584,
            28794,
            34017,
            34017
        ],
        80001: [
            38,
            38,
            36,
            36,
            43,
            43,
            '%'
        ],
        80401: [
            38,
            39,
            36,
            36,
            43,
            43,
            '%'
        ],
        80801: [
            39,
            39,
            36,
            36,
            43,
            43,
            '%'
        ],
        82401: [
            39,
            39,
            37,
            36,
            43,
            43,
            '%'
        ],
        82601: [
            39,
            39,
            37,
            37,
            43,
            43,
            '%'
        ],
        84601: [
            39,
            40,
            37,
            37,
            43,
            43,
            '%'
        ],
        84801: [
            40,
            40,
            37,
            37,
            43,
            43,
            '%'
        ],
        85601: [
            40,
            40,
            38,
            37,
            43,
            43,
            '%'
        ],
        86201: [
            40,
            40,
            38,
            37,
            44,
            44,
            '%'
        ],
        88401: [
            40,
            40,
            38,
            38,
            44,
            44,
            '%'
        ],
        89001: [
            40,
            41,
            38,
            38,
            44,
            44,
            '%'
        ],
        89201: [
            40,
            41,
            39,
            38,
            44,
            44,
            '%'
        ],
        89401: [
            41,
            41,
            39,
            38,
            44,
            44,
            '%'
        ],
        93201: [
            41,
            41,
            40,
            38,
            44,
            44,
            '%'
        ],
        93601: [
            41,
            41,
            40,
            38,
            45,
            45,
            '%'
        ],
        94001: [
            41,
            42,
            40,
            38,
            45,
            45,
            '%'
        ],
        94601: [
            42,
            42,
            40,
            38,
            45,
            45,
            '%'
        ],
        95201: [
            42,
            42,
            40,
            39,
            45,
            45,
            '%'
        ],
        97401: [
            42,
            42,
            41,
            39,
            45,
            45,
            '%'
        ],
        99801: [
            42,
            43,
            41,
            39,
            45,
            45,
            '%'
        ],
        100401: [
            43,
            43,
            41,
            39,
            45,
            45,
            '%'
        ],
        102201: [
            43,
            43,
            42,
            39,
            45,
            45,
            '%'
        ],
        102601: [
            43,
            43,
            42,
            39,
            46,
            46,
            '%'
        ],
        103201: [
            43,
            43,
            42,
            40,
            46,
            46,
            '%'
        ],
        106001: [
            43,
            44,
            42,
            40,
            46,
            46,
            '%'
        ],
        106801: [
            44,
            44,
            42,
            40,
            46,
            46,
            '%'
        ],
        107401: [
            44,
            44,
            43,
            40,
            46,
            46,
            '%'
        ],
        112601: [
            44,
            44,
            43,
            41,
            46,
            46,
            '%'
        ],
        113201: [
            44,
            45,
            44,
            41,
            46,
            46,
            '%'
        ],
        113401: [
            44,
            45,
            44,
            41,
            47,
            47,
            '%'
        ],
        114201: [
            45,
            45,
            44,
            41,
            47,
            47,
            '%'
        ],
        119601: [
            45,
            45,
            45,
            41,
            47,
            47,
            '%'
        ],
        121601: [
            45,
            46,
            45,
            41,
            47,
            47,
            '%'
        ],
        122601: [
            46,
            46,
            45,
            41,
            47,
            47,
            '%'
        ],
        123801: [
            46,
            46,
            45,
            42,
            47,
            47,
            '%'
        ],
        126601: [
            46,
            46,
            46,
            42,
            47,
            47,
            '%'
        ],
        126801: [
            46,
            46,
            46,
            42,
            48,
            48,
            '%'
        ],
        131201: [
            46,
            47,
            46,
            42,
            48,
            48,
            '%'
        ],
        132401: [
            47,
            47,
            46,
            42,
            48,
            48,
            '%'
        ],
        134801: [
            47,
            47,
            47,
            42,
            48,
            48,
            '%'
        ],
        137401: [
            47,
            47,
            47,
            43,
            48,
            48,
            '%'
        ],
        142401: [
            47,
            48,
            47,
            43,
            48,
            48,
            '%'
        ],
        143601: [
            47,
            48,
            47,
            43,
            49,
            49,
            '%'
        ],
        144001: [
            48,
            48,
            48,
            43,
            49,
            49,
            '%'
        ],
        154601: [
            48,
            48,
            48,
            44,
            49,
            49,
            '%'
        ],
        155601: [
            48,
            49,
            49,
            44,
            49,
            49,
            '%'
        ],
        157801: [
            49,
            49,
            49,
            44,
            49,
            49,
            '%'
        ],
        165801: [
            49,
            49,
            49,
            44,
            50,
            50,
            '%'
        ],
        171601: [
            49,
            50,
            50,
            44,
            50,
            50,
            '%'
        ],
        174401: [
            50,
            50,
            50,
            44,
            50,
            50,
            '%'
        ],
        176601: [
            50,
            50,
            50,
            45,
            50,
            50,
            '%'
        ],
        196001: [
            51,
            51,
            51,
            45,
            51,
            51,
            '%'
        ],
        205801: [
            51,
            51,
            51,
            46,
            51,
            51,
            '%'
        ],
        239601: [
            52,
            52,
            52,
            46,
            52,
            52,
            '%'
        ],
        246601: [
            52,
            52,
            52,
            47,
            52,
            52,
            '%'
        ],
        307801: [
            52,
            52,
            52,
            48,
            52,
            52,
            '%'
        ],
        308001: [
            53,
            53,
            53,
            48,
            53,
            53,
            '%'
        ],
        409001: [
            53,
            53,
            53,
            49,
            53,
            53,
            '%'
        ],
        431201: [
            54,
            54,
            54,
            49,
            54,
            54,
            '%'
        ],
        609401: [
            54,
            54,
            54,
            50,
            54,
            54,
            '%'
        ],
        718801: [
            55,
            55,
            55,
            50,
            55,
            55,
            '%'
        ],
        1195201: [
            55,
            55,
            55,
            51,
            55,
            55,
            '%'
        ]
    },
    37: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            28
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            66
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            104
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            142
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            180
        ],
        2501: [
            195,
            0,
            183,
            12,
            218,
            218
        ],
        2601: [
            206,
            0,
            191,
            14,
            256,
            256
        ],
        2701: [
            216,
            0,
            200,
            16,
            294,
            294
        ],
        2801: [
            218,
            0,
            200,
            18,
            332,
            332
        ],
        2901: [
            229,
            0,
            208,
            21,
            370,
            370
        ],
        3001: [
            239,
            0,
            216,
            23,
            408,
            408
        ],
        3101: [
            250,
            0,
            225,
            25,
            446,
            446
        ],
        3201: [
            260,
            0,
            233,
            27,
            484,
            484
        ],
        3301: [
            271,
            0,
            241,
            29,
            522,
            522
        ],
        3401: [
            273,
            0,
            241,
            31,
            560,
            560
        ],
        3501: [
            284,
            0,
            250,
            34,
            598,
            598
        ],
        3601: [
            294,
            0,
            258,
            36,
            636,
            636
        ],
        3701: [
            305,
            0,
            266,
            38,
            674,
            674
        ],
        3801: [
            315,
            0,
            275,
            40,
            712,
            712
        ],
        3901: [
            325,
            0,
            283,
            42,
            750,
            750
        ],
        4001: [
            328,
            0,
            283,
            44,
            788,
            788
        ],
        4101: [
            338,
            0,
            291,
            46,
            826,
            826
        ],
        4201: [
            349,
            0,
            300,
            49,
            864,
            864
        ],
        4301: [
            359,
            0,
            308,
            64,
            902,
            902
        ],
        4401: [
            370,
            0,
            316,
            90,
            940,
            940
        ],
        4501: [
            380,
            0,
            325,
            115,
            978,
            978
        ],
        4601: [
            382,
            0,
            325,
            141,
            1016,
            1016
        ],
        4701: [
            392,
            0,
            333,
            167,
            1048,
            1048
        ],
        4801: [
            403,
            0,
            341,
            192,
            1079,
            1079
        ],
        4901: [
            413,
            0,
            350,
            217,
            1111,
            1111
        ],
        5001: [
            423,
            0,
            358,
            243,
            1139,
            1139
        ],
        5101: [
            433,
            0,
            366,
            268,
            1171,
            1171
        ],
        5201: [
            443,
            0,
            375,
            293,
            1200,
            1200
        ],
        5301: [
            445,
            25,
            376,
            319,
            1231,
            1231
        ],
        5401: [
            455,
            53,
            386,
            344,
            1263,
            1263
        ],
        5501: [
            465,
            82,
            396,
            369,
            1291,
            1291
        ],
        5601: [
            475,
            110,
            406,
            394,
            1322,
            1322
        ],
        5701: [
            485,
            139,
            416,
            419,
            1350,
            1350
        ],
        5801: [
            495,
            167,
            426,
            443,
            1381,
            1381
        ],
        5901: [
            497,
            196,
            427,
            468,
            1412,
            1412
        ],
        6001: [
            507,
            224,
            437,
            493,
            1440,
            1440
        ],
        6101: [
            517,
            253,
            447,
            518,
            1471,
            1471
        ],
        6201: [
            527,
            278,
            457,
            542,
            1499,
            1499
        ],
        6301: [
            537,
            307,
            467,
            567,
            1530,
            1530
        ],
        6401: [
            556,
            335,
            477,
            592,
            1561,
            1561
        ],
        6501: [
            579,
            364,
            479,
            617,
            1589,
            1589
        ],
        6601: [
            602,
            392,
            488,
            642,
            1620,
            1620
        ],
        6701: [
            625,
            421,
            498,
            666,
            1648,
            1648
        ],
        6801: [
            648,
            449,
            508,
            691,
            1679,
            1679
        ],
        6901: [
            671,
            478,
            518,
            716,
            1710,
            1710
        ],
        7001: [
            694,
            506,
            528,
            740,
            1738,
            1738
        ],
        7101: [
            717,
            535,
            530,
            765,
            1769,
            1769
        ],
        7201: [
            740,
            563,
            540,
            790,
            1797,
            1797
        ],
        7301: [
            764,
            592,
            550,
            815,
            1828,
            1828
        ],
        7401: [
            787,
            617,
            560,
            840,
            1859,
            1859
        ],
        7501: [
            810,
            645,
            570,
            864,
            1887,
            1887
        ],
        7601: [
            833,
            674,
            580,
            889,
            1918,
            1918
        ],
        7701: [
            856,
            702,
            589,
            914,
            1946,
            1946
        ],
        7801: [
            879,
            731,
            591,
            939,
            1977,
            1977
        ],
        7901: [
            902,
            759,
            601,
            963,
            2008,
            2008
        ],
        8001: [
            925,
            788,
            611,
            988,
            2036,
            2036
        ],
        8101: [
            948,
            816,
            621,
            1013,
            2067,
            2067
        ],
        8201: [
            971,
            845,
            631,
            1038,
            2095,
            2095
        ],
        8301: [
            994,
            873,
            641,
            1063,
            2126,
            2126
        ],
        8401: [
            1017,
            902,
            642,
            1087,
            2157,
            2157
        ],
        8501: [
            1040,
            930,
            652,
            1112,
            2185,
            2185
        ],
        8601: [
            1064,
            956,
            662,
            1137,
            2216,
            2216
        ],
        8701: [
            1087,
            984,
            672,
            1161,
            2244,
            2244
        ],
        8801: [
            1110,
            1013,
            682,
            1186,
            2275,
            2275
        ],
        8901: [
            1133,
            1041,
            692,
            1211,
            2306,
            2306
        ],
        9001: [
            1156,
            1070,
            694,
            1236,
            2334,
            2334
        ],
        9101: [
            1179,
            1098,
            704,
            1261,
            2365,
            2365
        ],
        9201: [
            1202,
            1127,
            714,
            1285,
            2393,
            2393
        ],
        9301: [
            1225,
            1146,
            723,
            1310,
            2424,
            2424
        ],
        9401: [
            1248,
            1171,
            733,
            1335,
            2455,
            2455
        ],
        9501: [
            1271,
            1193,
            742,
            1360,
            2483,
            2483
        ],
        9601: [
            1294,
            1215,
            744,
            1384,
            2514,
            2514
        ],
        9701: [
            1317,
            1238,
            753,
            1409,
            2542,
            2542
        ],
        9801: [
            1341,
            1263,
            763,
            1434,
            2573,
            2573
        ],
        9901: [
            1364,
            1285,
            773,
            1459,
            2604,
            2604
        ],
        10001: [
            1387,
            1307,
            782,
            1483,
            2632,
            2632
        ],
        10101: [
            1410,
            1328,
            792,
            1508,
            2663,
            2663
        ],
        10201: [
            1433,
            1353,
            802,
            1533,
            2691,
            2691
        ],
        10301: [
            1456,
            1375,
            803,
            1558,
            2722,
            2722
        ],
        10401: [
            1479,
            1397,
            812,
            1583,
            2753,
            2753
        ],
        10501: [
            1502,
            1418,
            822,
            1607,
            2781,
            2781
        ],
        10601: [
            1525,
            1443,
            832,
            1632,
            2812,
            2812
        ],
        10701: [
            1548,
            1465,
            841,
            1657,
            2840,
            2840
        ],
        10801: [
            1571,
            1487,
            851,
            1682,
            2871,
            2871
        ],
        10901: [
            1595,
            1508,
            852,
            1706,
            2902,
            2902
        ],
        11001: [
            1618,
            1533,
            862,
            1731,
            2930,
            2930
        ],
        11101: [
            1641,
            1555,
            872,
            1756,
            2961,
            2961
        ],
        11201: [
            1664,
            1577,
            881,
            1781,
            2989,
            2989
        ],
        11301: [
            1687,
            1601,
            891,
            1805,
            3020,
            3020
        ],
        11401: [
            1710,
            1623,
            901,
            1830,
            3051,
            3051
        ],
        11501: [
            1733,
            1645,
            902,
            1855,
            3079,
            3079
        ],
        11601: [
            1756,
            1667,
            911,
            1880,
            3110,
            3110
        ],
        11701: [
            1779,
            1691,
            921,
            1904,
            3138,
            3138
        ],
        11801: [
            1802,
            1713,
            931,
            1929,
            3169,
            3169
        ],
        11901: [
            1825,
            1735,
            940,
            1954,
            3200,
            3200
        ],
        12001: [
            1848,
            1757,
            950,
            1979,
            3228,
            3228
        ],
        12101: [
            1872,
            1781,
            951,
            2004,
            3259,
            3259
        ],
        12201: [
            1895,
            1803,
            961,
            2028,
            3287,
            3287
        ],
        12301: [
            1918,
            1825,
            971,
            2053,
            3318,
            3318
        ],
        12401: [
            1941,
            1847,
            980,
            2078,
            3349,
            3349
        ],
        12501: [
            1964,
            1871,
            990,
            2103,
            3377,
            3377
        ],
        12601: [
            1987,
            1893,
            1000,
            2127,
            3408,
            3408
        ],
        12701: [
            2010,
            1915,
            1009,
            2152,
            3436,
            3436
        ],
        12801: [
            2033,
            1937,
            1010,
            2177,
            3467,
            3467
        ],
        12901: [
            2056,
            1961,
            1020,
            2202,
            3495,
            3495
        ],
        13001: [
            2079,
            1983,
            1030,
            2227,
            3532,
            3532
        ],
        13101: [
            2103,
            2005,
            1039,
            2252,
            3569,
            3569
        ],
        13201: [
            2126,
            2030,
            1049,
            2277,
            3607,
            3607
        ],
        13301: [
            2150,
            2052,
            1059,
            2302,
            3644,
            3644
        ],
        13401: [
            2173,
            2073,
            1060,
            2327,
            3681,
            3681
        ],
        13501: [
            2196,
            2095,
            1070,
            2352,
            3718,
            3718
        ],
        13601: [
            2220,
            2120,
            1079,
            2377,
            3756,
            3756
        ],
        13701: [
            2243,
            2142,
            1089,
            2402,
            3793,
            3793
        ],
        13801: [
            2266,
            2163,
            1099,
            2426,
            3830,
            3830
        ],
        13901: [
            2288,
            2185,
            1108,
            2450,
            3866,
            3866
        ],
        14001: [
            2311,
            2210,
            1110,
            2475,
            3902,
            3902
        ],
        14101: [
            2333,
            2232,
            1119,
            2499,
            3938,
            3938
        ],
        14201: [
            2355,
            2253,
            1129,
            2523,
            3975,
            3975
        ],
        14301: [
            2378,
            2275,
            1138,
            2547,
            4011,
            4011
        ],
        14401: [
            2400,
            2300,
            1148,
            2571,
            4047,
            4047
        ],
        14501: [
            2422,
            2322,
            1158,
            2595,
            4083,
            4083
        ],
        14601: [
            2445,
            2343,
            1159,
            2619,
            4120,
            4120
        ],
        14701: [
            2467,
            2365,
            1169,
            2643,
            4156,
            4156
        ],
        14801: [
            2489,
            2390,
            1178,
            2667,
            4195,
            4195
        ],
        14901: [
            2512,
            2412,
            1188,
            2691,
            4234,
            4234
        ],
        15001: [
            2534,
            2433,
            1198,
            2715,
            4274,
            4274
        ],
        15101: [
            2557,
            2455,
            1207,
            2739,
            4313,
            4313
        ],
        15201: [
            2579,
            2480,
            1217,
            2763,
            4355,
            4355
        ],
        15301: [
            2602,
            2502,
            1218,
            2787,
            4395,
            4395
        ],
        15401: [
            2626,
            2526,
            1228,
            2814,
            4434,
            4434
        ],
        15501: [
            2656,
            2557,
            1238,
            2846,
            4473,
            4473
        ],
        15601: [
            2686,
            2589,
            1248,
            2878,
            4512,
            4512
        ],
        15701: [
            2717,
            2620,
            1258,
            2909,
            4555,
            4555
        ],
        15801: [
            2747,
            2651,
            1268,
            2941,
            4594,
            4594
        ],
        15901: [
            2778,
            2682,
            1270,
            2973,
            4633,
            4633
        ],
        16001: [
            2808,
            2713,
            1280,
            3005,
            4672,
            4672
        ],
        16101: [
            2838,
            2744,
            1291,
            3036,
            4712,
            4712
        ],
        16201: [
            2869,
            2775,
            1301,
            3068,
            4754,
            4754
        ],
        16301: [
            2899,
            2806,
            1311,
            3100,
            4793,
            4793
        ],
        16401: [
            2930,
            2837,
            1321,
            3131,
            4833,
            4833
        ],
        16501: [
            2960,
            2868,
            1323,
            3163,
            4872,
            4872
        ],
        16601: [
            2990,
            2899,
            1333,
            3195,
            4911,
            4911
        ],
        16701: [
            3021,
            2930,
            1343,
            3226,
            4953,
            4953
        ],
        16801: [
            3051,
            2961,
            1353,
            3258,
            4993,
            4993
        ],
        16901: [
            3082,
            2992,
            1363,
            3290,
            5032,
            5032
        ],
        17001: [
            3112,
            3020,
            1373,
            3322,
            5071,
            5071
        ],
        17101: [
            3143,
            3051,
            1375,
            3353,
            5111,
            5111
        ],
        17201: [
            3173,
            3082,
            1385,
            3385,
            5153,
            5153
        ],
        17301: [
            3203,
            3113,
            1395,
            3417,
            5192,
            5192
        ],
        17401: [
            3234,
            3144,
            1405,
            3448,
            5231,
            5231
        ],
        17501: [
            3264,
            3175,
            1416,
            3480,
            5271,
            5271
        ],
        17601: [
            3294,
            3206,
            1426,
            3512,
            5310,
            5310
        ],
        17701: [
            3325,
            3237,
            1436,
            3543,
            5352,
            5352
        ],
        17801: [
            3355,
            3268,
            1438,
            3575,
            5391,
            5391
        ],
        17901: [
            3386,
            3299,
            1448,
            3607,
            5431,
            5431
        ],
        18001: [
            3416,
            3330,
            1458,
            3639,
            5470,
            5470
        ],
        18101: [
            3446,
            3362,
            1468,
            3670,
            5509,
            5509
        ],
        18201: [
            3477,
            3393,
            1478,
            3702,
            5551,
            5551
        ],
        18301: [
            3507,
            3424,
            1488,
            3734,
            5591,
            5591
        ],
        18401: [
            3538,
            3455,
            1490,
            3765,
            5630,
            5630
        ],
        18501: [
            3568,
            3486,
            1500,
            3797,
            5669,
            5669
        ],
        18601: [
            3598,
            3517,
            1510,
            3829,
            5709,
            5709
        ],
        18701: [
            3629,
            3548,
            1520,
            3860,
            5751,
            5751
        ],
        18801: [
            3659,
            3579,
            1531,
            3892,
            5790,
            5790
        ],
        18901: [
            3690,
            3610,
            1541,
            3924,
            5829,
            5829
        ],
        19001: [
            3720,
            3641,
            1543,
            3956,
            5869,
            5869
        ],
        19101: [
            3751,
            3672,
            1553,
            3987,
            5908,
            5908
        ],
        19201: [
            3781,
            3703,
            1563,
            4019,
            5950,
            5950
        ],
        19301: [
            3811,
            3734,
            1573,
            4051,
            5990,
            5990
        ],
        19401: [
            3842,
            3765,
            1583,
            4082,
            6029,
            6029
        ],
        19501: [
            3872,
            3796,
            1593,
            4114,
            6068,
            6068
        ],
        19601: [
            3902,
            3827,
            1595,
            4146,
            6107,
            6107
        ],
        19701: [
            3933,
            3857,
            1604,
            4178,
            6150,
            6150
        ],
        19801: [
            3963,
            3887,
            1613,
            4209,
            6189,
            6189
        ],
        19901: [
            3994,
            3917,
            1622,
            4241,
            6228,
            6228
        ],
        20001: [
            4055,
            3978,
            1641,
            4304,
            6307,
            6307
        ],
        20201: [
            4115,
            4035,
            1651,
            4368,
            6388,
            6388
        ],
        20401: [
            4176,
            4095,
            1670,
            4431,
            6467,
            6467
        ],
        20601: [
            4237,
            4156,
            1688,
            4495,
            6548,
            6548
        ],
        20801: [
            4298,
            4216,
            1699,
            4558,
            6627,
            6627
        ],
        21001: [
            4359,
            4277,
            1717,
            4621,
            6705,
            6705
        ],
        21201: [
            4419,
            4337,
            1736,
            4685,
            6787,
            6787
        ],
        21401: [
            4480,
            4398,
            1746,
            4748,
            6866,
            6866
        ],
        21601: [
            4541,
            4458,
            1765,
            4812,
            6947,
            6947
        ],
        21801: [
            4602,
            4518,
            1783,
            4875,
            7026,
            7026
        ],
        22001: [
            4663,
            4579,
            1794,
            4938,
            7104,
            7104
        ],
        22201: [
            4723,
            4639,
            1813,
            5002,
            7186,
            7186
        ],
        22401: [
            4784,
            4700,
            1859,
            5065,
            7264,
            7264
        ],
        22601: [
            4845,
            4760,
            1906,
            5129,
            7346,
            7346
        ],
        22801: [
            4907,
            4820,
            1952,
            5193,
            7425,
            7425
        ],
        23001: [
            4969,
            4881,
            1999,
            5258,
            7506,
            7506
        ],
        23201: [
            5032,
            4941,
            2045,
            5323,
            7589,
            7589
        ],
        23401: [
            5094,
            4999,
            2089,
            5388,
            7669,
            7669
        ],
        23601: [
            5157,
            5059,
            2135,
            5453,
            7752,
            7752
        ],
        23801: [
            5219,
            5116,
            2178,
            5518,
            7832,
            7832
        ],
        24001: [
            5281,
            5180,
            2228,
            5583,
            7913,
            7913
        ],
        24201: [
            5344,
            5240,
            2274,
            5648,
            7996,
            7996
        ],
        24401: [
            5406,
            5304,
            2324,
            5713,
            8076,
            8076
        ],
        24601: [
            5469,
            5364,
            2370,
            5779,
            8159,
            8159
        ],
        24801: [
            5531,
            5425,
            2416,
            5844,
            8239,
            8239
        ],
        25001: [
            5594,
            5488,
            2483,
            5909,
            8320,
            8320
        ],
        25201: [
            5656,
            5548,
            2544,
            5974,
            8403,
            8403
        ],
        25401: [
            5719,
            5609,
            2604,
            6039,
            8483,
            8483
        ],
        25601: [
            5781,
            5672,
            2667,
            6104,
            8566,
            8566
        ],
        25801: [
            5844,
            5733,
            2728,
            6169,
            8646,
            8646
        ],
        26001: [
            5906,
            5793,
            2788,
            6234,
            8727,
            8727
        ],
        26201: [
            5969,
            5857,
            2852,
            6299,
            8810,
            8810
        ],
        26401: [
            6031,
            5917,
            2912,
            6364,
            8890,
            8890
        ],
        26601: [
            6093,
            5980,
            2976,
            6429,
            8973,
            8973
        ],
        26801: [
            6156,
            6041,
            3036,
            6494,
            9053,
            9053
        ],
        27001: [
            6218,
            6101,
            3096,
            6559,
            9134,
            9134
        ],
        27201: [
            6281,
            6165,
            3160,
            6624,
            9217,
            9217
        ],
        27401: [
            6343,
            6225,
            3220,
            6689,
            9297,
            9297
        ],
        27601: [
            6406,
            6286,
            3281,
            6754,
            9380,
            9380
        ],
        27801: [
            6468,
            6349,
            3344,
            6819,
            9460,
            9460
        ],
        28001: [
            6531,
            6409,
            3405,
            6884,
            9541,
            9541
        ],
        28201: [
            6593,
            6473,
            3468,
            6950,
            9624,
            9624
        ],
        28401: [
            6656,
            6533,
            3528,
            7015,
            9704,
            9704
        ],
        28601: [
            6718,
            6594,
            3589,
            7080,
            9787,
            9787
        ],
        28801: [
            6780,
            6657,
            3652,
            7145,
            9867,
            9867
        ],
        29001: [
            6843,
            6718,
            3713,
            7210,
            9948,
            9948
        ],
        29201: [
            6905,
            6778,
            3773,
            7275,
            10031,
            10031
        ],
        29401: [
            6968,
            6841,
            3837,
            7340,
            10111,
            10111
        ],
        29601: [
            7030,
            6902,
            3897,
            7405,
            10194,
            10194
        ],
        29801: [
            7093,
            6965,
            3960,
            7470,
            10274,
            10274
        ],
        30001: [
            7155,
            7026,
            4021,
            7535,
            10355,
            10355
        ],
        30201: [
            7218,
            7086,
            4081,
            7600,
            10438,
            10438
        ],
        30401: [
            7280,
            7150,
            4145,
            7665,
            10518,
            10518
        ],
        30601: [
            7343,
            7210,
            4205,
            7730,
            10601,
            10601
        ],
        30801: [
            7405,
            7270,
            4266,
            7795,
            10681,
            10681
        ],
        31001: [
            7467,
            7334,
            4329,
            7860,
            10762,
            10762
        ],
        31201: [
            7530,
            7395,
            4390,
            7925,
            10845,
            10845
        ],
        31401: [
            7592,
            7456,
            4451,
            7990,
            10925,
            10925
        ],
        31601: [
            7655,
            7521,
            4516,
            8056,
            11008,
            11008
        ],
        31801: [
            7717,
            7583,
            4578,
            8121,
            11088,
            11088
        ],
        32001: [
            7780,
            7647,
            4643,
            8186,
            11169,
            11169
        ],
        32201: [
            7842,
            7709,
            4704,
            8251,
            11252,
            11252
        ],
        32401: [
            7905,
            7771,
            4766,
            8316,
            11332,
            11332
        ],
        32601: [
            7967,
            7835,
            4831,
            8381,
            11415,
            11415
        ],
        32801: [
            8030,
            7897,
            4892,
            8446,
            11495,
            11495
        ],
        33001: [
            8092,
            7959,
            4954,
            8511,
            11576,
            11576
        ],
        33201: [
            8154,
            8024,
            5019,
            8576,
            11659,
            11659
        ],
        33401: [
            8217,
            8085,
            5080,
            8641,
            11739,
            11739
        ],
        33601: [
            8279,
            8150,
            5145,
            8706,
            11822,
            11822
        ],
        33801: [
            8342,
            8212,
            5207,
            8771,
            11902,
            11902
        ],
        34001: [
            8404,
            8273,
            5268,
            8836,
            11983,
            11983
        ],
        34201: [
            8467,
            8338,
            5333,
            8901,
            12066,
            12066
        ],
        34401: [
            8529,
            8400,
            5395,
            8966,
            12146,
            12146
        ],
        34601: [
            8592,
            8461,
            5457,
            9031,
            12229,
            12229
        ],
        34801: [
            8654,
            8526,
            5521,
            9096,
            12309,
            12309
        ],
        35001: [
            8717,
            8588,
            5583,
            9161,
            12390,
            12390
        ],
        35201: [
            8779,
            8649,
            5645,
            9227,
            12473,
            12473
        ],
        35401: [
            8841,
            8714,
            5709,
            9292,
            12553,
            12553
        ],
        35601: [
            8904,
            8776,
            5771,
            9357,
            12636,
            12636
        ],
        35801: [
            8966,
            8841,
            5836,
            9422,
            12716,
            12716
        ],
        36001: [
            9029,
            8902,
            5897,
            9487,
            12797,
            12797
        ],
        36201: [
            9091,
            8964,
            5959,
            9552,
            12880,
            12880
        ],
        36401: [
            9154,
            9029,
            6024,
            9617,
            12960,
            12960
        ],
        36601: [
            9216,
            9090,
            6086,
            9682,
            13043,
            13043
        ],
        36801: [
            9279,
            9152,
            6147,
            9747,
            13123,
            13123
        ],
        37001: [
            9341,
            9217,
            6212,
            9812,
            13204,
            13204
        ],
        37201: [
            9404,
            9278,
            6274,
            9877,
            13287,
            13287
        ],
        37401: [
            9466,
            9343,
            6338,
            9942,
            13367,
            13367
        ],
        37601: [
            9528,
            9402,
            6397,
            10007,
            13444,
            13444
        ],
        37801: [
            9591,
            9463,
            6459,
            10072,
            13518,
            13518
        ],
        38001: [
            9653,
            9525,
            6520,
            10137,
            13592,
            13592
        ],
        38201: [
            9715,
            9590,
            6585,
            10202,
            13666,
            13666
        ],
        38401: [
            9779,
            9652,
            6647,
            10266,
            13740,
            13740
        ],
        38601: [
            9853,
            9726,
            6721,
            10331,
            13814,
            13814
        ],
        38801: [
            9927,
            9800,
            6795,
            10396,
            13888,
            13888
        ],
        39001: [
            10001,
            9874,
            6869,
            10461,
            13962,
            13962
        ],
        39201: [
            10075,
            9948,
            6943,
            10526,
            14036,
            14036
        ],
        39401: [
            10149,
            10022,
            7017,
            10590,
            14110,
            14110
        ],
        39601: [
            10223,
            10096,
            7091,
            10655,
            14184,
            14184
        ],
        39801: [
            10297,
            10170,
            7165,
            10720,
            14258,
            14258
        ],
        40001: [
            10371,
            10244,
            7239,
            10785,
            14332,
            14332
        ],
        40201: [
            10445,
            10318,
            7313,
            10850,
            14406,
            14406
        ],
        40401: [
            10519,
            10392,
            7387,
            10915,
            14480,
            14480
        ],
        40601: [
            10593,
            10466,
            7461,
            10979,
            14554,
            14554
        ],
        40801: [
            10667,
            10540,
            7535,
            11044,
            14628,
            14628
        ],
        41001: [
            10741,
            10614,
            7609,
            11109,
            14702,
            14702
        ],
        41201: [
            10815,
            10688,
            7683,
            11174,
            14776,
            14776
        ],
        41401: [
            10889,
            10762,
            7757,
            11239,
            14850,
            14850
        ],
        41601: [
            10963,
            10836,
            7831,
            11304,
            14924,
            14924
        ],
        41801: [
            11037,
            10910,
            7905,
            11368,
            14998,
            14998
        ],
        42001: [
            11111,
            10984,
            7979,
            11433,
            15072,
            15072
        ],
        42201: [
            11185,
            11058,
            8053,
            11498,
            15146,
            15146
        ],
        42401: [
            11259,
            11132,
            8127,
            11563,
            15220,
            15220
        ],
        42601: [
            11333,
            11206,
            8201,
            11628,
            15294,
            15294
        ],
        42801: [
            11407,
            11280,
            8275,
            11692,
            15368,
            15368
        ],
        43001: [
            11481,
            11354,
            8349,
            11757,
            15442,
            15442
        ],
        43201: [
            11555,
            11428,
            8423,
            11822,
            15516,
            15516
        ],
        43401: [
            11629,
            11502,
            8497,
            11887,
            15590,
            15590
        ],
        43601: [
            11703,
            11576,
            8571,
            11952,
            15664,
            15664
        ],
        43801: [
            11777,
            11650,
            8645,
            12017,
            15738,
            15738
        ],
        44001: [
            11851,
            11724,
            8719,
            12081,
            15812,
            15812
        ],
        44201: [
            11925,
            11798,
            8793,
            12146,
            15886,
            15886
        ],
        44401: [
            11999,
            11872,
            8867,
            12211,
            15960,
            15960
        ],
        44601: [
            12073,
            11946,
            8941,
            12276,
            16034,
            16034
        ],
        44801: [
            12147,
            12020,
            9015,
            12341,
            16108,
            16108
        ],
        45001: [
            12221,
            12094,
            9089,
            12406,
            16182,
            16182
        ],
        45201: [
            12295,
            12168,
            9163,
            12470,
            16256,
            16256
        ],
        45401: [
            12369,
            12242,
            9237,
            12535,
            16330,
            16330
        ],
        45601: [
            12443,
            12316,
            9311,
            12600,
            16404,
            16404
        ],
        45801: [
            12517,
            12390,
            9385,
            12665,
            16478,
            16478
        ],
        46001: [
            12591,
            12464,
            9459,
            12730,
            16552,
            16552
        ],
        46201: [
            12665,
            12538,
            9533,
            12795,
            16626,
            16626
        ],
        46401: [
            12739,
            12612,
            9607,
            12859,
            16700,
            16700
        ],
        46601: [
            12813,
            12686,
            9681,
            12924,
            16774,
            16774
        ],
        46801: [
            12887,
            12760,
            9755,
            12989,
            16848,
            16848
        ],
        47001: [
            12961,
            12834,
            9829,
            13054,
            16922,
            16922
        ],
        47201: [
            13035,
            12908,
            9903,
            13119,
            16996,
            16996
        ],
        47401: [
            13109,
            12982,
            9977,
            13183,
            17070,
            17070
        ],
        47601: [
            13183,
            13056,
            10051,
            13248,
            17144,
            17144
        ],
        47801: [
            13257,
            13130,
            10125,
            13313,
            17218,
            17218
        ],
        48001: [
            13331,
            13204,
            10199,
            13378,
            17292,
            17292
        ],
        48201: [
            13405,
            13278,
            10273,
            13443,
            17366,
            17366
        ],
        48401: [
            13479,
            13352,
            10347,
            13508,
            17440,
            17440
        ],
        48601: [
            13553,
            13426,
            10421,
            13572,
            17514,
            17514
        ],
        48801: [
            13627,
            13500,
            10495,
            13637,
            17588,
            17588
        ],
        49001: [
            13701,
            13574,
            10569,
            13702,
            17662,
            17662
        ],
        49201: [
            13775,
            13648,
            10643,
            13767,
            17736,
            17736
        ],
        49401: [
            13849,
            13722,
            10717,
            13832,
            17810,
            17810
        ],
        49601: [
            13923,
            13796,
            10791,
            13897,
            17884,
            17884
        ],
        49801: [
            13997,
            13870,
            10865,
            13961,
            17958,
            17958
        ],
        50001: [
            14071,
            13944,
            10943,
            14026,
            18032,
            18032
        ],
        50201: [
            14145,
            14018,
            11023,
            14091,
            18106,
            18106
        ],
        50401: [
            14219,
            14092,
            11103,
            14156,
            18180,
            18180
        ],
        50601: [
            14293,
            14166,
            11183,
            14221,
            18254,
            18254
        ],
        50801: [
            14367,
            14240,
            11263,
            14285,
            18328,
            18328
        ],
        51001: [
            14441,
            14314,
            11343,
            14350,
            18402,
            18402
        ],
        51201: [
            14540,
            14415,
            11451,
            14440,
            18501,
            18501
        ],
        51401: [
            14654,
            14536,
            11577,
            14545,
            18615,
            18615
        ],
        51601: [
            14768,
            14656,
            11704,
            14650,
            18729,
            18729
        ],
        51801: [
            14882,
            14773,
            11827,
            14755,
            18843,
            18843
        ],
        52001: [
            14996,
            14893,
            11953,
            14859,
            18957,
            18957
        ],
        52201: [
            15110,
            15013,
            12079,
            14964,
            19071,
            19071
        ],
        52401: [
            15224,
            15134,
            12206,
            15069,
            19185,
            19185
        ],
        52601: [
            15338,
            15254,
            12332,
            15174,
            19299,
            19299
        ],
        52801: [
            15452,
            15374,
            12458,
            15279,
            19413,
            19413
        ],
        53001: [
            15566,
            15494,
            12584,
            15384,
            19527,
            19527
        ],
        53201: [
            15680,
            15615,
            12711,
            15488,
            19641,
            19641
        ],
        53401: [
            15794,
            15732,
            12834,
            15593,
            19755,
            19755
        ],
        53601: [
            15908,
            15852,
            12960,
            15698,
            19869,
            19869
        ],
        53801: [
            16022,
            15972,
            13086,
            15803,
            19983,
            19983
        ],
        54001: [
            16136,
            16093,
            13212,
            15908,
            20097,
            20097
        ],
        54201: [
            16250,
            16213,
            13339,
            16013,
            20211,
            20211
        ],
        54401: [
            16364,
            16333,
            13465,
            16117,
            20325,
            20325
        ],
        54601: [
            16478,
            16453,
            13591,
            16222,
            20439,
            20439
        ],
        54801: [
            16592,
            16574,
            13717,
            16327,
            20553,
            20553
        ],
        55001: [
            16706,
            16694,
            13844,
            16432,
            20667,
            20667
        ],
        55201: [
            16820,
            16811,
            13967,
            16537,
            20781,
            20781
        ],
        55401: [
            16934,
            16931,
            14093,
            16641,
            20895,
            20895
        ],
        55601: [
            17048,
            17052,
            14219,
            16746,
            21009,
            21009
        ],
        55801: [
            17162,
            17172,
            14346,
            16851,
            21123,
            21123
        ],
        56001: [
            17276,
            17292,
            14472,
            16956,
            21237,
            21237
        ],
        56201: [
            17390,
            17412,
            14598,
            17061,
            21351,
            21351
        ],
        56401: [
            17504,
            17533,
            14724,
            17166,
            21465,
            21465
        ],
        56601: [
            17618,
            17653,
            14851,
            17270,
            21579,
            21579
        ],
        56801: [
            17732,
            17770,
            14974,
            17375,
            21693,
            21693
        ],
        57001: [
            17846,
            17890,
            15100,
            17480,
            21807,
            21807
        ],
        57201: [
            17960,
            18010,
            15226,
            17585,
            21921,
            21921
        ],
        57401: [
            18074,
            18131,
            15353,
            17690,
            22035,
            22035
        ],
        57601: [
            18188,
            18251,
            15479,
            17795,
            22149,
            22149
        ],
        57801: [
            18302,
            18371,
            15605,
            17899,
            22263,
            22263
        ],
        58001: [
            18416,
            18482,
            15722,
            18004,
            22377,
            22377
        ],
        58201: [
            18530,
            18596,
            15842,
            18109,
            22491,
            22491
        ],
        58401: [
            18644,
            18710,
            15962,
            18214,
            22605,
            22605
        ],
        58601: [
            18758,
            18824,
            16082,
            18319,
            22719,
            22719
        ],
        58801: [
            18872,
            18938,
            16202,
            18423,
            22833,
            22833
        ],
        59001: [
            18986,
            19052,
            16322,
            18528,
            22947,
            22947
        ],
        59201: [
            19100,
            19166,
            16442,
            18633,
            23061,
            23061
        ],
        59401: [
            19214,
            19280,
            16562,
            18738,
            23175,
            23175
        ],
        59601: [
            19328,
            19394,
            16682,
            18843,
            23289,
            23289
        ],
        59801: [
            19442,
            19508,
            16802,
            18948,
            23403,
            23403
        ],
        60001: [
            19556,
            19622,
            16922,
            19052,
            23517,
            23517
        ],
        60201: [
            19670,
            19736,
            17042,
            19157,
            23631,
            23631
        ],
        60401: [
            19784,
            19850,
            17162,
            19262,
            23745,
            23745
        ],
        60601: [
            19898,
            19964,
            17282,
            19367,
            23859,
            23859
        ],
        60801: [
            20012,
            20078,
            17402,
            19472,
            23973,
            23973
        ],
        61001: [
            20126,
            20192,
            17522,
            19577,
            24087,
            24087
        ],
        61201: [
            20240,
            20306,
            17642,
            19681,
            24201,
            24201
        ],
        61401: [
            20354,
            20420,
            17762,
            19786,
            24315,
            24315
        ],
        61601: [
            20468,
            20534,
            17882,
            19891,
            24429,
            24429
        ],
        61801: [
            20582,
            20648,
            18002,
            19996,
            24543,
            24543
        ],
        62001: [
            20696,
            20762,
            18122,
            20101,
            24657,
            24657
        ],
        62201: [
            20810,
            20876,
            18242,
            20206,
            24771,
            24771
        ],
        62401: [
            20924,
            20990,
            18362,
            20310,
            24885,
            24885
        ],
        62601: [
            21038,
            21104,
            18482,
            20415,
            24999,
            24999
        ],
        62801: [
            21152,
            21218,
            18602,
            20520,
            25113,
            25113
        ],
        63001: [
            21266,
            21332,
            18722,
            20625,
            25227,
            25227
        ],
        63201: [
            21380,
            21446,
            18842,
            20730,
            25341,
            25341
        ],
        63401: [
            21494,
            21560,
            18962,
            20834,
            25455,
            25455
        ],
        63601: [
            21608,
            21674,
            19082,
            20939,
            25569,
            25569
        ],
        63801: [
            21722,
            21788,
            19202,
            21044,
            25683,
            25683
        ],
        64001: [
            21836,
            21902,
            19322,
            21149,
            25797,
            25797
        ],
        64201: [
            21950,
            22016,
            19442,
            21254,
            25911,
            25911
        ],
        64401: [
            22064,
            22130,
            19562,
            21359,
            26025,
            26025
        ],
        64601: [
            22182,
            22254,
            19691,
            21463,
            26139,
            26139
        ],
        64801: [
            22302,
            22372,
            19816,
            21568,
            26253,
            26253
        ],
        65001: [
            22422,
            22496,
            19946,
            21673,
            26367,
            26367
        ],
        65201: [
            22542,
            22615,
            20070,
            21778,
            26481,
            26481
        ],
        65401: [
            22662,
            22733,
            20195,
            21883,
            26595,
            26595
        ],
        65601: [
            22782,
            22857,
            20325,
            21988,
            26709,
            26709
        ],
        65801: [
            22902,
            22976,
            20449,
            22092,
            26823,
            26823
        ],
        66001: [
            23022,
            23099,
            20579,
            22197,
            26937,
            26937
        ],
        66201: [
            23142,
            23218,
            20704,
            22302,
            27051,
            27051
        ],
        66401: [
            23262,
            23337,
            20828,
            22407,
            27165,
            27165
        ],
        66601: [
            23382,
            23460,
            20958,
            22512,
            27279,
            27279
        ],
        66801: [
            23502,
            23579,
            21083,
            22616,
            27393,
            27393
        ],
        67001: [
            23622,
            23698,
            21207,
            22721,
            27507,
            27507
        ],
        67201: [
            23742,
            23821,
            21337,
            22826,
            27621,
            27621
        ],
        67401: [
            23862,
            23940,
            21462,
            22931,
            27735,
            27735
        ],
        67601: [
            23982,
            24063,
            21591,
            23036,
            27849,
            27849
        ],
        67801: [
            24102,
            24182,
            21716,
            23141,
            27963,
            27963
        ],
        68001: [
            24222,
            24301,
            21841,
            23245,
            28077,
            28077
        ],
        68201: [
            24342,
            24424,
            21970,
            23350,
            28191,
            28191
        ],
        68401: [
            24462,
            24543,
            22095,
            23455,
            28305,
            28305
        ],
        68601: [
            24582,
            24662,
            22220,
            23560,
            28419,
            28419
        ],
        68801: [
            24702,
            24785,
            22349,
            23665,
            28533,
            28533
        ],
        69001: [
            24822,
            24904,
            22474,
            23770,
            28647,
            28647
        ],
        69201: [
            24942,
            25028,
            22603,
            23874,
            28761,
            28761
        ],
        69401: [
            25062,
            25146,
            22728,
            23979,
            28875,
            28875
        ],
        69601: [
            25182,
            25265,
            22853,
            24084,
            28989,
            28989
        ],
        69801: [
            25302,
            25389,
            22982,
            24189,
            29103,
            29103
        ],
        70001: [
            25422,
            25507,
            23107,
            24294,
            29217,
            29217
        ],
        70201: [
            25542,
            25626,
            23232,
            24399,
            29331,
            29331
        ],
        70401: [
            25662,
            25750,
            23361,
            24503,
            29445,
            29445
        ],
        70601: [
            25782,
            25868,
            23486,
            24608,
            29559,
            29559
        ],
        70801: [
            25902,
            25992,
            23616,
            24713,
            29673,
            29673
        ],
        71001: [
            26022,
            26111,
            23740,
            24818,
            29787,
            29787
        ],
        71201: [
            26142,
            26229,
            23865,
            24923,
            29901,
            29901
        ],
        71401: [
            26262,
            26353,
            23995,
            25027,
            30015,
            30015
        ],
        71601: [
            26382,
            26472,
            24119,
            25132,
            30129,
            30129
        ],
        71801: [
            26502,
            26595,
            24249,
            25237,
            30243,
            30243
        ],
        72001: [
            26622,
            26714,
            24374,
            25342,
            30357,
            30357
        ],
        72201: [
            26742,
            26833,
            24498,
            25447,
            30471,
            30471
        ],
        72401: [
            26862,
            26956,
            24628,
            25552,
            30585,
            30585
        ],
        72601: [
            26982,
            27075,
            24753,
            25656,
            30699,
            30699
        ],
        72801: [
            27102,
            27194,
            24877,
            25761,
            30813,
            30813
        ],
        73001: [
            27222,
            27317,
            25007,
            25866,
            30927,
            30927
        ],
        73201: [
            27342,
            27436,
            25132,
            25971,
            31041,
            31041
        ],
        73401: [
            27462,
            27559,
            25261,
            26076,
            31155,
            31155
        ],
        73601: [
            27582,
            27678,
            25386,
            26181,
            31269,
            31269
        ],
        73801: [
            27702,
            27797,
            25511,
            26285,
            31383,
            31383
        ],
        74001: [
            27822,
            27920,
            25640,
            26390,
            31497,
            31497
        ],
        74201: [
            27942,
            28039,
            25765,
            26495,
            31611,
            31611
        ],
        74401: [
            28062,
            28158,
            25890,
            26600,
            31725,
            31725
        ],
        74601: [
            28182,
            28281,
            26019,
            26705,
            31839,
            31839
        ],
        74801: [
            28302,
            28400,
            26144,
            26809,
            31953,
            31953
        ],
        75001: [
            28422,
            28524,
            26273,
            26914,
            32067,
            32067
        ],
        75201: [
            28542,
            28642,
            26398,
            27019,
            32181,
            32181
        ],
        75401: [
            28662,
            28761,
            26523,
            27124,
            32295,
            32295
        ],
        75601: [
            28782,
            28885,
            26652,
            27229,
            32409,
            32409
        ],
        75801: [
            28902,
            29003,
            26777,
            27334,
            32523,
            32523
        ],
        76001: [
            29022,
            29122,
            26902,
            27438,
            32637,
            32637
        ],
        76201: [
            29142,
            29246,
            27031,
            27543,
            32751,
            32751
        ],
        76401: [
            29262,
            29364,
            27156,
            27648,
            32865,
            32865
        ],
        76601: [
            29382,
            29488,
            27286,
            27753,
            32979,
            32979
        ],
        76801: [
            29502,
            29607,
            27410,
            27858,
            33093,
            33093
        ],
        77001: [
            29622,
            29725,
            27535,
            27963,
            33207,
            33207
        ],
        77201: [
            29742,
            29849,
            27665,
            28067,
            33321,
            33321
        ],
        77401: [
            29862,
            29968,
            27789,
            28172,
            33435,
            33435
        ],
        77601: [
            29982,
            30086,
            27914,
            28277,
            33549,
            33549
        ],
        77801: [
            30102,
            30210,
            28044,
            28382,
            33663,
            33663
        ],
        78001: [
            30222,
            30329,
            28168,
            28487,
            33777,
            33777
        ],
        78201: [
            30342,
            30452,
            28298,
            28592,
            33891,
            33891
        ],
        78401: [
            30462,
            30571,
            28423,
            28696,
            34005,
            34005
        ],
        78601: [
            30582,
            30690,
            28547,
            28801,
            34119,
            34119
        ],
        78801: [
            30702,
            30813,
            28677,
            28906,
            34233,
            34233
        ],
        79001: [
            30822,
            30932,
            28802,
            29011,
            34347,
            34347
        ],
        79201: [
            30942,
            31051,
            28926,
            29116,
            34461,
            34461
        ],
        79401: [
            31062,
            31174,
            29056,
            29220,
            34575,
            34575
        ],
        79601: [
            31182,
            31293,
            29181,
            29325,
            34689,
            34689
        ],
        79801: [
            31302,
            31416,
            29310,
            29430,
            34803,
            34803
        ],
        80001: [
            39,
            39,
            37,
            37,
            44,
            44,
            '%'
        ],
        80801: [
            39,
            40,
            37,
            37,
            44,
            44,
            '%'
        ],
        81401: [
            40,
            40,
            37,
            37,
            44,
            44,
            '%'
        ],
        82601: [
            40,
            40,
            38,
            37,
            44,
            44,
            '%'
        ],
        83801: [
            40,
            40,
            38,
            38,
            44,
            44,
            '%'
        ],
        84801: [
            40,
            41,
            38,
            38,
            44,
            44,
            '%'
        ],
        85601: [
            41,
            41,
            38,
            38,
            44,
            44,
            '%'
        ],
        86001: [
            41,
            41,
            39,
            38,
            44,
            44,
            '%'
        ],
        86201: [
            41,
            41,
            39,
            38,
            45,
            45,
            '%'
        ],
        89401: [
            41,
            42,
            39,
            38,
            45,
            45,
            '%'
        ],
        89601: [
            41,
            42,
            40,
            38,
            45,
            45,
            '%'
        ],
        89801: [
            41,
            42,
            40,
            39,
            45,
            45,
            '%'
        ],
        90201: [
            42,
            42,
            40,
            39,
            45,
            45,
            '%'
        ],
        93401: [
            42,
            42,
            41,
            39,
            45,
            45,
            '%'
        ],
        93801: [
            42,
            42,
            41,
            39,
            46,
            46,
            '%'
        ],
        94401: [
            42,
            43,
            41,
            39,
            46,
            46,
            '%'
        ],
        95401: [
            43,
            43,
            41,
            39,
            46,
            46,
            '%'
        ],
        96801: [
            43,
            43,
            41,
            40,
            46,
            46,
            '%'
        ],
        97801: [
            43,
            43,
            42,
            40,
            46,
            46,
            '%'
        ],
        100001: [
            43,
            44,
            42,
            40,
            46,
            46,
            '%'
        ],
        101001: [
            44,
            44,
            42,
            40,
            46,
            46,
            '%'
        ],
        102401: [
            44,
            44,
            43,
            40,
            46,
            46,
            '%'
        ],
        102801: [
            44,
            44,
            43,
            40,
            47,
            47,
            '%'
        ],
        104801: [
            44,
            44,
            43,
            41,
            47,
            47,
            '%'
        ],
        106401: [
            44,
            45,
            43,
            41,
            47,
            47,
            '%'
        ],
        107601: [
            45,
            45,
            44,
            41,
            47,
            47,
            '%'
        ],
        113401: [
            45,
            45,
            45,
            41,
            47,
            47,
            '%'
        ],
        113601: [
            45,
            46,
            45,
            41,
            48,
            48,
            '%'
        ],
        114401: [
            45,
            46,
            45,
            42,
            48,
            48,
            '%'
        ],
        115001: [
            46,
            46,
            45,
            42,
            48,
            48,
            '%'
        ],
        119801: [
            46,
            46,
            46,
            42,
            48,
            48,
            '%'
        ],
        122001: [
            46,
            47,
            46,
            42,
            48,
            48,
            '%'
        ],
        123601: [
            47,
            47,
            46,
            42,
            48,
            48,
            '%'
        ],
        126001: [
            47,
            47,
            46,
            43,
            48,
            48,
            '%'
        ],
        127001: [
            47,
            47,
            47,
            43,
            49,
            49,
            '%'
        ],
        131401: [
            47,
            48,
            47,
            43,
            49,
            49,
            '%'
        ],
        133401: [
            48,
            48,
            47,
            43,
            49,
            49,
            '%'
        ],
        135001: [
            48,
            48,
            48,
            43,
            49,
            49,
            '%'
        ],
        140201: [
            48,
            48,
            48,
            44,
            49,
            49,
            '%'
        ],
        142601: [
            48,
            49,
            48,
            44,
            49,
            49,
            '%'
        ],
        143801: [
            48,
            49,
            48,
            44,
            50,
            50,
            '%'
        ],
        144201: [
            48,
            49,
            49,
            44,
            50,
            50,
            '%'
        ],
        145001: [
            49,
            49,
            49,
            44,
            50,
            50,
            '%'
        ],
        155801: [
            49,
            50,
            50,
            44,
            50,
            50,
            '%'
        ],
        157801: [
            49,
            50,
            50,
            45,
            50,
            50,
            '%'
        ],
        159001: [
            50,
            50,
            50,
            45,
            50,
            50,
            '%'
        ],
        166001: [
            50,
            50,
            50,
            45,
            51,
            51,
            '%'
        ],
        171801: [
            50,
            51,
            51,
            45,
            51,
            51,
            '%'
        ],
        175601: [
            51,
            51,
            51,
            45,
            51,
            51,
            '%'
        ],
        180801: [
            51,
            51,
            51,
            46,
            51,
            51,
            '%'
        ],
        196201: [
            51,
            52,
            52,
            46,
            52,
            52,
            '%'
        ],
        196401: [
            52,
            52,
            52,
            46,
            52,
            52,
            '%'
        ],
        211401: [
            52,
            52,
            52,
            47,
            52,
            52,
            '%'
        ],
        239801: [
            53,
            53,
            53,
            47,
            53,
            53,
            '%'
        ],
        254401: [
            53,
            53,
            53,
            48,
            53,
            53,
            '%'
        ],
        308401: [
            54,
            54,
            54,
            48,
            54,
            54,
            '%'
        ],
        319401: [
            54,
            54,
            54,
            49,
            54,
            54,
            '%'
        ],
        429001: [
            54,
            54,
            54,
            50,
            54,
            54,
            '%'
        ],
        431801: [
            55,
            55,
            55,
            50,
            55,
            55,
            '%'
        ],
        653401: [
            55,
            55,
            55,
            51,
            55,
            55,
            '%'
        ],
        719601: [
            56,
            56,
            56,
            51,
            56,
            56,
            '%'
        ],
        1369801: [
            56,
            56,
            56,
            52,
            56,
            56,
            '%'
        ]
    },
    38: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            29
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            68
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            107
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            146
        ],
        2401: [
            185,
            0,
            175,
            10,
            185,
            185
        ],
        2501: [
            195,
            0,
            183,
            12,
            224,
            224
        ],
        2601: [
            206,
            0,
            191,
            14,
            263,
            263
        ],
        2701: [
            216,
            0,
            200,
            16,
            302,
            302
        ],
        2801: [
            218,
            0,
            200,
            18,
            341,
            341
        ],
        2901: [
            229,
            0,
            208,
            21,
            380,
            380
        ],
        3001: [
            239,
            0,
            216,
            23,
            419,
            419
        ],
        3101: [
            250,
            0,
            225,
            25,
            458,
            458
        ],
        3201: [
            260,
            0,
            233,
            27,
            497,
            497
        ],
        3301: [
            271,
            0,
            241,
            29,
            536,
            536
        ],
        3401: [
            273,
            0,
            241,
            31,
            575,
            575
        ],
        3501: [
            284,
            0,
            250,
            34,
            614,
            614
        ],
        3601: [
            294,
            0,
            258,
            36,
            653,
            653
        ],
        3701: [
            305,
            0,
            266,
            38,
            692,
            692
        ],
        3801: [
            315,
            0,
            275,
            40,
            731,
            731
        ],
        3901: [
            325,
            0,
            283,
            42,
            770,
            770
        ],
        4001: [
            328,
            0,
            283,
            44,
            809,
            809
        ],
        4101: [
            338,
            0,
            291,
            46,
            848,
            848
        ],
        4201: [
            349,
            0,
            300,
            49,
            887,
            887
        ],
        4301: [
            359,
            0,
            308,
            64,
            926,
            926
        ],
        4401: [
            370,
            0,
            316,
            91,
            965,
            965
        ],
        4501: [
            380,
            0,
            325,
            117,
            1004,
            1004
        ],
        4601: [
            382,
            0,
            325,
            143,
            1043,
            1043
        ],
        4701: [
            392,
            0,
            333,
            170,
            1075,
            1075
        ],
        4801: [
            403,
            0,
            341,
            196,
            1108,
            1108
        ],
        4901: [
            413,
            0,
            350,
            222,
            1140,
            1140
        ],
        5001: [
            423,
            0,
            358,
            248,
            1169,
            1169
        ],
        5101: [
            433,
            0,
            366,
            274,
            1202,
            1202
        ],
        5201: [
            443,
            0,
            375,
            300,
            1231,
            1231
        ],
        5301: [
            445,
            25,
            376,
            326,
            1264,
            1264
        ],
        5401: [
            455,
            55,
            386,
            352,
            1296,
            1296
        ],
        5501: [
            465,
            84,
            396,
            377,
            1325,
            1325
        ],
        5601: [
            475,
            113,
            406,
            403,
            1357,
            1357
        ],
        5701: [
            485,
            142,
            416,
            428,
            1386,
            1386
        ],
        5801: [
            495,
            172,
            426,
            454,
            1417,
            1417
        ],
        5901: [
            497,
            201,
            427,
            479,
            1449,
            1449
        ],
        6001: [
            507,
            230,
            437,
            504,
            1478,
            1478
        ],
        6101: [
            517,
            259,
            447,
            530,
            1510,
            1510
        ],
        6201: [
            527,
            285,
            457,
            555,
            1539,
            1539
        ],
        6301: [
            545,
            315,
            467,
            581,
            1570,
            1570
        ],
        6401: [
            569,
            344,
            477,
            606,
            1602,
            1602
        ],
        6501: [
            593,
            373,
            479,
            632,
            1631,
            1631
        ],
        6601: [
            616,
            402,
            488,
            657,
            1663,
            1663
        ],
        6701: [
            640,
            432,
            498,
            682,
            1692,
            1692
        ],
        6801: [
            664,
            461,
            508,
            708,
            1723,
            1723
        ],
        6901: [
            687,
            490,
            518,
            733,
            1755,
            1755
        ],
        7001: [
            711,
            519,
            528,
            759,
            1784,
            1784
        ],
        7101: [
            735,
            549,
            530,
            784,
            1816,
            1816
        ],
        7201: [
            758,
            578,
            540,
            809,
            1845,
            1845
        ],
        7301: [
            782,
            607,
            550,
            835,
            1876,
            1876
        ],
        7401: [
            806,
            633,
            560,
            860,
            1908,
            1908
        ],
        7501: [
            830,
            662,
            570,
            886,
            1937,
            1937
        ],
        7601: [
            853,
            692,
            580,
            911,
            1969,
            1969
        ],
        7701: [
            877,
            721,
            589,
            937,
            1998,
            1998
        ],
        7801: [
            901,
            750,
            591,
            962,
            2029,
            2029
        ],
        7901: [
            924,
            779,
            601,
            988,
            2061,
            2061
        ],
        8001: [
            948,
            809,
            611,
            1013,
            2090,
            2090
        ],
        8101: [
            972,
            838,
            621,
            1038,
            2122,
            2122
        ],
        8201: [
            995,
            867,
            631,
            1064,
            2151,
            2151
        ],
        8301: [
            1019,
            896,
            641,
            1089,
            2182,
            2182
        ],
        8401: [
            1043,
            926,
            642,
            1115,
            2214,
            2214
        ],
        8501: [
            1066,
            955,
            652,
            1140,
            2243,
            2243
        ],
        8601: [
            1090,
            981,
            662,
            1166,
            2275,
            2275
        ],
        8701: [
            1114,
            1010,
            672,
            1191,
            2304,
            2304
        ],
        8801: [
            1138,
            1039,
            682,
            1216,
            2336,
            2336
        ],
        8901: [
            1161,
            1069,
            692,
            1242,
            2367,
            2367
        ],
        9001: [
            1185,
            1098,
            694,
            1267,
            2396,
            2396
        ],
        9101: [
            1209,
            1127,
            704,
            1293,
            2428,
            2428
        ],
        9201: [
            1232,
            1156,
            714,
            1318,
            2457,
            2457
        ],
        9301: [
            1256,
            1176,
            723,
            1343,
            2488,
            2488
        ],
        9401: [
            1280,
            1202,
            733,
            1369,
            2520,
            2520
        ],
        9501: [
            1303,
            1225,
            742,
            1394,
            2549,
            2549
        ],
        9601: [
            1327,
            1247,
            744,
            1420,
            2581,
            2581
        ],
        9701: [
            1351,
            1270,
            753,
            1445,
            2610,
            2610
        ],
        9801: [
            1375,
            1296,
            763,
            1471,
            2641,
            2641
        ],
        9901: [
            1398,
            1319,
            773,
            1496,
            2673,
            2673
        ],
        10001: [
            1422,
            1341,
            782,
            1521,
            2702,
            2702
        ],
        10101: [
            1446,
            1363,
            792,
            1547,
            2734,
            2734
        ],
        10201: [
            1469,
            1389,
            802,
            1572,
            2763,
            2763
        ],
        10301: [
            1493,
            1411,
            803,
            1598,
            2794,
            2794
        ],
        10401: [
            1517,
            1433,
            812,
            1623,
            2826,
            2826
        ],
        10501: [
            1540,
            1456,
            822,
            1648,
            2855,
            2855
        ],
        10601: [
            1564,
            1481,
            832,
            1674,
            2887,
            2887
        ],
        10701: [
            1588,
            1503,
            841,
            1699,
            2916,
            2916
        ],
        10801: [
            1612,
            1526,
            851,
            1725,
            2947,
            2947
        ],
        10901: [
            1635,
            1548,
            852,
            1750,
            2979,
            2979
        ],
        11001: [
            1659,
            1574,
            862,
            1776,
            3008,
            3008
        ],
        11101: [
            1683,
            1596,
            872,
            1801,
            3040,
            3040
        ],
        11201: [
            1706,
            1618,
            881,
            1826,
            3069,
            3069
        ],
        11301: [
            1730,
            1644,
            891,
            1852,
            3100,
            3100
        ],
        11401: [
            1754,
            1666,
            901,
            1877,
            3132,
            3132
        ],
        11501: [
            1777,
            1688,
            902,
            1903,
            3161,
            3161
        ],
        11601: [
            1801,
            1711,
            911,
            1928,
            3193,
            3193
        ],
        11701: [
            1825,
            1736,
            921,
            1954,
            3222,
            3222
        ],
        11801: [
            1849,
            1758,
            931,
            1979,
            3253,
            3253
        ],
        11901: [
            1872,
            1781,
            940,
            2005,
            3285,
            3285
        ],
        12001: [
            1896,
            1803,
            950,
            2030,
            3314,
            3314
        ],
        12101: [
            1920,
            1829,
            951,
            2055,
            3346,
            3346
        ],
        12201: [
            1943,
            1851,
            961,
            2081,
            3375,
            3375
        ],
        12301: [
            1967,
            1873,
            971,
            2106,
            3406,
            3406
        ],
        12401: [
            1991,
            1896,
            980,
            2132,
            3438,
            3438
        ],
        12501: [
            2014,
            1921,
            990,
            2157,
            3467,
            3467
        ],
        12601: [
            2038,
            1943,
            1000,
            2182,
            3499,
            3499
        ],
        12701: [
            2062,
            1966,
            1009,
            2208,
            3528,
            3528
        ],
        12801: [
            2086,
            1988,
            1010,
            2233,
            3559,
            3559
        ],
        12901: [
            2109,
            2013,
            1020,
            2259,
            3588,
            3588
        ],
        13001: [
            2133,
            2036,
            1030,
            2284,
            3626,
            3626
        ],
        13101: [
            2157,
            2058,
            1039,
            2310,
            3665,
            3665
        ],
        13201: [
            2181,
            2084,
            1049,
            2336,
            3703,
            3703
        ],
        13301: [
            2205,
            2106,
            1059,
            2361,
            3741,
            3741
        ],
        13401: [
            2229,
            2128,
            1060,
            2387,
            3779,
            3779
        ],
        13501: [
            2253,
            2151,
            1070,
            2413,
            3818,
            3818
        ],
        13601: [
            2277,
            2176,
            1079,
            2439,
            3856,
            3856
        ],
        13701: [
            2301,
            2198,
            1089,
            2464,
            3894,
            3894
        ],
        13801: [
            2324,
            2221,
            1099,
            2489,
            3932,
            3932
        ],
        13901: [
            2347,
            2243,
            1108,
            2514,
            3969,
            3969
        ],
        14001: [
            2370,
            2268,
            1110,
            2539,
            4006,
            4006
        ],
        14101: [
            2393,
            2291,
            1119,
            2564,
            4044,
            4044
        ],
        14201: [
            2416,
            2313,
            1129,
            2588,
            4081,
            4081
        ],
        14301: [
            2439,
            2336,
            1138,
            2613,
            4118,
            4118
        ],
        14401: [
            2462,
            2361,
            1148,
            2638,
            4155,
            4155
        ],
        14501: [
            2485,
            2383,
            1158,
            2662,
            4193,
            4193
        ],
        14601: [
            2508,
            2406,
            1159,
            2687,
            4230,
            4230
        ],
        14701: [
            2531,
            2428,
            1169,
            2712,
            4267,
            4267
        ],
        14801: [
            2554,
            2453,
            1178,
            2737,
            4307,
            4307
        ],
        14901: [
            2577,
            2476,
            1188,
            2761,
            4348,
            4348
        ],
        15001: [
            2600,
            2498,
            1198,
            2786,
            4388,
            4388
        ],
        15101: [
            2623,
            2520,
            1207,
            2811,
            4428,
            4428
        ],
        15201: [
            2646,
            2546,
            1217,
            2836,
            4472,
            4472
        ],
        15301: [
            2669,
            2568,
            1218,
            2860,
            4512,
            4512
        ],
        15401: [
            2694,
            2594,
            1228,
            2888,
            4553,
            4553
        ],
        15501: [
            2725,
            2625,
            1238,
            2920,
            4593,
            4593
        ],
        15601: [
            2756,
            2657,
            1248,
            2953,
            4633,
            4633
        ],
        15701: [
            2788,
            2689,
            1258,
            2986,
            4677,
            4677
        ],
        15801: [
            2819,
            2721,
            1268,
            3018,
            4717,
            4717
        ],
        15901: [
            2850,
            2753,
            1270,
            3051,
            4757,
            4757
        ],
        16001: [
            2881,
            2785,
            1280,
            3083,
            4798,
            4798
        ],
        16101: [
            2913,
            2817,
            1291,
            3116,
            4838,
            4838
        ],
        16201: [
            2944,
            2849,
            1301,
            3148,
            4882,
            4882
        ],
        16301: [
            2975,
            2880,
            1311,
            3181,
            4922,
            4922
        ],
        16401: [
            3006,
            2912,
            1321,
            3214,
            4962,
            4962
        ],
        16501: [
            3038,
            2944,
            1323,
            3246,
            5003,
            5003
        ],
        16601: [
            3069,
            2976,
            1333,
            3279,
            5043,
            5043
        ],
        16701: [
            3100,
            3008,
            1343,
            3311,
            5087,
            5087
        ],
        16801: [
            3131,
            3040,
            1353,
            3344,
            5127,
            5127
        ],
        16901: [
            3163,
            3072,
            1363,
            3377,
            5167,
            5167
        ],
        17001: [
            3194,
            3100,
            1373,
            3409,
            5208,
            5208
        ],
        17101: [
            3225,
            3132,
            1375,
            3442,
            5248,
            5248
        ],
        17201: [
            3256,
            3164,
            1385,
            3474,
            5291,
            5291
        ],
        17301: [
            3288,
            3196,
            1395,
            3507,
            5332,
            5332
        ],
        17401: [
            3319,
            3228,
            1405,
            3539,
            5372,
            5372
        ],
        17501: [
            3350,
            3260,
            1416,
            3572,
            5412,
            5412
        ],
        17601: [
            3381,
            3292,
            1426,
            3605,
            5453,
            5453
        ],
        17701: [
            3413,
            3324,
            1436,
            3637,
            5496,
            5496
        ],
        17801: [
            3444,
            3355,
            1438,
            3670,
            5537,
            5537
        ],
        17901: [
            3475,
            3387,
            1448,
            3702,
            5577,
            5577
        ],
        18001: [
            3506,
            3419,
            1458,
            3735,
            5617,
            5617
        ],
        18101: [
            3537,
            3451,
            1468,
            3767,
            5658,
            5658
        ],
        18201: [
            3569,
            3483,
            1478,
            3800,
            5701,
            5701
        ],
        18301: [
            3600,
            3515,
            1488,
            3833,
            5742,
            5742
        ],
        18401: [
            3631,
            3547,
            1490,
            3865,
            5782,
            5782
        ],
        18501: [
            3662,
            3579,
            1500,
            3898,
            5822,
            5822
        ],
        18601: [
            3694,
            3611,
            1510,
            3930,
            5863,
            5863
        ],
        18701: [
            3725,
            3642,
            1520,
            3963,
            5906,
            5906
        ],
        18801: [
            3756,
            3674,
            1531,
            3996,
            5946,
            5946
        ],
        18901: [
            3787,
            3706,
            1541,
            4028,
            5987,
            5987
        ],
        19001: [
            3819,
            3738,
            1543,
            4061,
            6027,
            6027
        ],
        19101: [
            3850,
            3770,
            1553,
            4093,
            6067,
            6067
        ],
        19201: [
            3881,
            3802,
            1563,
            4126,
            6111,
            6111
        ],
        19301: [
            3912,
            3834,
            1573,
            4158,
            6151,
            6151
        ],
        19401: [
            3944,
            3865,
            1583,
            4191,
            6192,
            6192
        ],
        19501: [
            3975,
            3897,
            1593,
            4223,
            6232,
            6232
        ],
        19601: [
            4006,
            3929,
            1595,
            4256,
            6272,
            6272
        ],
        19701: [
            4037,
            3960,
            1604,
            4289,
            6316,
            6316
        ],
        19801: [
            4068,
            3991,
            1613,
            4321,
            6356,
            6356
        ],
        19901: [
            4100,
            4022,
            1622,
            4354,
            6396,
            6396
        ],
        20001: [
            4162,
            4084,
            1641,
            4419,
            6477,
            6477
        ],
        20201: [
            4225,
            4143,
            1651,
            4484,
            6561,
            6561
        ],
        20401: [
            4287,
            4205,
            1670,
            4549,
            6642,
            6642
        ],
        20601: [
            4350,
            4267,
            1688,
            4614,
            6726,
            6726
        ],
        20801: [
            4412,
            4329,
            1699,
            4680,
            6806,
            6806
        ],
        21001: [
            4475,
            4391,
            1717,
            4745,
            6887,
            6887
        ],
        21201: [
            4537,
            4453,
            1736,
            4810,
            6971,
            6971
        ],
        21401: [
            4600,
            4515,
            1746,
            4875,
            7051,
            7051
        ],
        21601: [
            4662,
            4577,
            1793,
            4940,
            7135,
            7135
        ],
        21801: [
            4724,
            4640,
            1841,
            5005,
            7216,
            7216
        ],
        22001: [
            4787,
            4702,
            1889,
            5070,
            7297,
            7297
        ],
        22201: [
            4849,
            4764,
            1938,
            5136,
            7381,
            7381
        ],
        22401: [
            4912,
            4826,
            1986,
            5201,
            7461,
            7461
        ],
        22601: [
            4974,
            4888,
            2034,
            5266,
            7545,
            7545
        ],
        22801: [
            5038,
            4950,
            2082,
            5332,
            7627,
            7627
        ],
        23001: [
            5102,
            5012,
            2130,
            5399,
            7709,
            7709
        ],
        23201: [
            5166,
            5074,
            2178,
            5466,
            7795,
            7795
        ],
        23401: [
            5230,
            5133,
            2223,
            5532,
            7877,
            7877
        ],
        23601: [
            5294,
            5195,
            2271,
            5599,
            7962,
            7962
        ],
        23801: [
            5358,
            5254,
            2316,
            5666,
            8045,
            8045
        ],
        24001: [
            5423,
            5319,
            2367,
            5733,
            8127,
            8127
        ],
        24201: [
            5487,
            5381,
            2415,
            5800,
            8213,
            8213
        ],
        24401: [
            5551,
            5447,
            2466,
            5866,
            8295,
            8295
        ],
        24601: [
            5615,
            5509,
            2515,
            5933,
            8380,
            8380
        ],
        24801: [
            5679,
            5571,
            2563,
            6000,
            8463,
            8463
        ],
        25001: [
            5743,
            5636,
            2631,
            6067,
            8545,
            8545
        ],
        25201: [
            5807,
            5698,
            2693,
            6134,
            8631,
            8631
        ],
        25401: [
            5871,
            5760,
            2755,
            6200,
            8713,
            8713
        ],
        25601: [
            5936,
            5825,
            2820,
            6267,
            8798,
            8798
        ],
        25801: [
            6000,
            5887,
            2883,
            6334,
            8881,
            8881
        ],
        26001: [
            6064,
            5949,
            2945,
            6401,
            8963,
            8963
        ],
        26201: [
            6128,
            6015,
            3010,
            6468,
            9049,
            9049
        ],
        26401: [
            6192,
            6077,
            3072,
            6534,
            9131,
            9131
        ],
        26601: [
            6256,
            6142,
            3137,
            6601,
            9216,
            9216
        ],
        26801: [
            6320,
            6204,
            3199,
            6668,
            9299,
            9299
        ],
        27001: [
            6384,
            6266,
            3261,
            6735,
            9381,
            9381
        ],
        27201: [
            6449,
            6331,
            3326,
            6802,
            9467,
            9467
        ],
        27401: [
            6513,
            6393,
            3389,
            6868,
            9549,
            9549
        ],
        27601: [
            6577,
            6455,
            3451,
            6935,
            9634,
            9634
        ],
        27801: [
            6641,
            6521,
            3516,
            7002,
            9717,
            9717
        ],
        28001: [
            6705,
            6583,
            3578,
            7069,
            9799,
            9799
        ],
        28201: [
            6769,
            6648,
            3643,
            7136,
            9885,
            9885
        ],
        28401: [
            6833,
            6710,
            3705,
            7202,
            9967,
            9967
        ],
        28601: [
            6898,
            6772,
            3767,
            7269,
            10052,
            10052
        ],
        28801: [
            6962,
            6837,
            3832,
            7336,
            10135,
            10135
        ],
        29001: [
            7026,
            6899,
            3895,
            7403,
            10217,
            10217
        ],
        29201: [
            7090,
            6961,
            3957,
            7470,
            10303,
            10303
        ],
        29401: [
            7154,
            7027,
            4022,
            7536,
            10385,
            10385
        ],
        29601: [
            7218,
            7089,
            4084,
            7603,
            10470,
            10470
        ],
        29801: [
            7282,
            7154,
            4149,
            7670,
            10553,
            10553
        ],
        30001: [
            7346,
            7216,
            4211,
            7737,
            10635,
            10635
        ],
        30201: [
            7411,
            7278,
            4273,
            7804,
            10721,
            10721
        ],
        30401: [
            7475,
            7343,
            4339,
            7870,
            10803,
            10803
        ],
        30601: [
            7539,
            7405,
            4401,
            7937,
            10888,
            10888
        ],
        30801: [
            7603,
            7467,
            4463,
            8004,
            10971,
            10971
        ],
        31001: [
            7667,
            7533,
            4528,
            8071,
            11053,
            11053
        ],
        31201: [
            7731,
            7595,
            4590,
            8138,
            11139,
            11139
        ],
        31401: [
            7795,
            7658,
            4654,
            8204,
            11221,
            11221
        ],
        31601: [
            7859,
            7725,
            4720,
            8271,
            11306,
            11306
        ],
        31801: [
            7924,
            7788,
            4783,
            8338,
            11389,
            11389
        ],
        32001: [
            7988,
            7855,
            4850,
            8405,
            11471,
            11471
        ],
        32201: [
            8052,
            7918,
            4913,
            8472,
            11557,
            11557
        ],
        32401: [
            8116,
            7981,
            4977,
            8538,
            11639,
            11639
        ],
        32601: [
            8180,
            8048,
            5043,
            8605,
            11724,
            11724
        ],
        32801: [
            8244,
            8111,
            5106,
            8672,
            11807,
            11807
        ],
        33001: [
            8308,
            8175,
            5170,
            8739,
            11889,
            11889
        ],
        33201: [
            8372,
            8241,
            5236,
            8806,
            11975,
            11975
        ],
        33401: [
            8437,
            8304,
            5300,
            8872,
            12057,
            12057
        ],
        33601: [
            8501,
            8371,
            5366,
            8939,
            12142,
            12142
        ],
        33801: [
            8565,
            8434,
            5429,
            9006,
            12225,
            12225
        ],
        34001: [
            8629,
            8498,
            5493,
            9073,
            12307,
            12307
        ],
        34201: [
            8693,
            8564,
            5559,
            9140,
            12393,
            12393
        ],
        34401: [
            8757,
            8627,
            5623,
            9206,
            12475,
            12475
        ],
        34601: [
            8821,
            8691,
            5686,
            9273,
            12560,
            12560
        ],
        34801: [
            8886,
            8757,
            5752,
            9340,
            12643,
            12643
        ],
        35001: [
            8950,
            8821,
            5816,
            9407,
            12725,
            12725
        ],
        35201: [
            9014,
            8884,
            5879,
            9474,
            12811,
            12811
        ],
        35401: [
            9078,
            8950,
            5946,
            9540,
            12893,
            12893
        ],
        35601: [
            9142,
            9014,
            6009,
            9607,
            12978,
            12978
        ],
        35801: [
            9206,
            9080,
            6075,
            9674,
            13061,
            13061
        ],
        36001: [
            9270,
            9144,
            6139,
            9741,
            13143,
            13143
        ],
        36201: [
            9334,
            9207,
            6202,
            9808,
            13229,
            13229
        ],
        36401: [
            9398,
            9273,
            6269,
            9874,
            13311,
            13311
        ],
        36601: [
            9463,
            9337,
            6332,
            9941,
            13396,
            13396
        ],
        36801: [
            9527,
            9400,
            6395,
            10008,
            13479,
            13479
        ],
        37001: [
            9591,
            9467,
            6462,
            10075,
            13561,
            13561
        ],
        37201: [
            9655,
            9530,
            6525,
            10142,
            13647,
            13647
        ],
        37401: [
            9719,
            9596,
            6592,
            10208,
            13729,
            13729
        ],
        37601: [
            9783,
            9657,
            6652,
            10275,
            13808,
            13808
        ],
        37801: [
            9847,
            9720,
            6715,
            10342,
            13884,
            13884
        ],
        38001: [
            9911,
            9783,
            6778,
            10408,
            13960,
            13960
        ],
        38201: [
            9975,
            9850,
            6845,
            10475,
            14036,
            14036
        ],
        38401: [
            10040,
            9913,
            6908,
            10541,
            14112,
            14112
        ],
        38601: [
            10116,
            9989,
            6984,
            10608,
            14188,
            14188
        ],
        38801: [
            10192,
            10065,
            7060,
            10675,
            14264,
            14264
        ],
        39001: [
            10268,
            10141,
            7136,
            10741,
            14340,
            14340
        ],
        39201: [
            10344,
            10217,
            7212,
            10808,
            14416,
            14416
        ],
        39401: [
            10420,
            10293,
            7288,
            10874,
            14492,
            14492
        ],
        39601: [
            10496,
            10369,
            7364,
            10941,
            14568,
            14568
        ],
        39801: [
            10572,
            10445,
            7440,
            11007,
            14644,
            14644
        ],
        40001: [
            10648,
            10521,
            7516,
            11074,
            14720,
            14720
        ],
        40201: [
            10724,
            10597,
            7592,
            11141,
            14796,
            14796
        ],
        40401: [
            10800,
            10673,
            7668,
            11207,
            14872,
            14872
        ],
        40601: [
            10876,
            10749,
            7744,
            11274,
            14948,
            14948
        ],
        40801: [
            10952,
            10825,
            7820,
            11340,
            15024,
            15024
        ],
        41001: [
            11028,
            10901,
            7896,
            11407,
            15100,
            15100
        ],
        41201: [
            11104,
            10977,
            7972,
            11473,
            15176,
            15176
        ],
        41401: [
            11180,
            11053,
            8048,
            11540,
            15252,
            15252
        ],
        41601: [
            11256,
            11129,
            8124,
            11607,
            15328,
            15328
        ],
        41801: [
            11332,
            11205,
            8200,
            11673,
            15404,
            15404
        ],
        42001: [
            11408,
            11281,
            8276,
            11740,
            15480,
            15480
        ],
        42201: [
            11484,
            11357,
            8352,
            11806,
            15556,
            15556
        ],
        42401: [
            11560,
            11433,
            8428,
            11873,
            15632,
            15632
        ],
        42601: [
            11636,
            11509,
            8504,
            11939,
            15708,
            15708
        ],
        42801: [
            11712,
            11585,
            8580,
            12006,
            15784,
            15784
        ],
        43001: [
            11788,
            11661,
            8656,
            12073,
            15860,
            15860
        ],
        43201: [
            11864,
            11737,
            8732,
            12139,
            15936,
            15936
        ],
        43401: [
            11940,
            11813,
            8808,
            12206,
            16012,
            16012
        ],
        43601: [
            12016,
            11889,
            8884,
            12272,
            16088,
            16088
        ],
        43801: [
            12092,
            11965,
            8960,
            12339,
            16164,
            16164
        ],
        44001: [
            12168,
            12041,
            9036,
            12405,
            16240,
            16240
        ],
        44201: [
            12244,
            12117,
            9112,
            12472,
            16316,
            16316
        ],
        44401: [
            12320,
            12193,
            9188,
            12538,
            16392,
            16392
        ],
        44601: [
            12396,
            12269,
            9264,
            12605,
            16468,
            16468
        ],
        44801: [
            12472,
            12345,
            9340,
            12672,
            16544,
            16544
        ],
        45001: [
            12548,
            12421,
            9416,
            12738,
            16620,
            16620
        ],
        45201: [
            12624,
            12497,
            9492,
            12805,
            16696,
            16696
        ],
        45401: [
            12700,
            12573,
            9568,
            12871,
            16772,
            16772
        ],
        45601: [
            12776,
            12649,
            9644,
            12938,
            16848,
            16848
        ],
        45801: [
            12852,
            12725,
            9720,
            13004,
            16924,
            16924
        ],
        46001: [
            12928,
            12801,
            9796,
            13071,
            17000,
            17000
        ],
        46201: [
            13004,
            12877,
            9872,
            13138,
            17076,
            17076
        ],
        46401: [
            13080,
            12953,
            9948,
            13204,
            17152,
            17152
        ],
        46601: [
            13156,
            13029,
            10024,
            13271,
            17228,
            17228
        ],
        46801: [
            13232,
            13105,
            10100,
            13337,
            17304,
            17304
        ],
        47001: [
            13308,
            13181,
            10176,
            13404,
            17380,
            17380
        ],
        47201: [
            13384,
            13257,
            10252,
            13470,
            17456,
            17456
        ],
        47401: [
            13460,
            13333,
            10328,
            13537,
            17532,
            17532
        ],
        47601: [
            13536,
            13409,
            10404,
            13604,
            17608,
            17608
        ],
        47801: [
            13612,
            13485,
            10480,
            13670,
            17684,
            17684
        ],
        48001: [
            13688,
            13561,
            10556,
            13737,
            17760,
            17760
        ],
        48201: [
            13764,
            13637,
            10632,
            13803,
            17836,
            17836
        ],
        48401: [
            13840,
            13713,
            10708,
            13870,
            17912,
            17912
        ],
        48601: [
            13916,
            13789,
            10784,
            13936,
            17988,
            17988
        ],
        48801: [
            13992,
            13865,
            10860,
            14003,
            18064,
            18064
        ],
        49001: [
            14068,
            13941,
            10936,
            14070,
            18140,
            18140
        ],
        49201: [
            14144,
            14017,
            11012,
            14136,
            18216,
            18216
        ],
        49401: [
            14220,
            14093,
            11088,
            14203,
            18292,
            18292
        ],
        49601: [
            14296,
            14169,
            11164,
            14269,
            18368,
            18368
        ],
        49801: [
            14372,
            14245,
            11240,
            14336,
            18444,
            18444
        ],
        50001: [
            14448,
            14321,
            11321,
            14402,
            18520,
            18520
        ],
        50201: [
            14524,
            14397,
            11403,
            14469,
            18596,
            18596
        ],
        50401: [
            14600,
            14473,
            11485,
            14536,
            18672,
            18672
        ],
        50601: [
            14676,
            14549,
            11567,
            14602,
            18748,
            18748
        ],
        50801: [
            14752,
            14625,
            11649,
            14669,
            18824,
            18824
        ],
        51001: [
            14828,
            14701,
            11731,
            14735,
            18900,
            18900
        ],
        51201: [
            14929,
            14806,
            11841,
            14827,
            19001,
            19001
        ],
        51401: [
            15045,
            14929,
            11971,
            14933,
            19117,
            19117
        ],
        51601: [
            15161,
            15053,
            12100,
            15040,
            19233,
            19233
        ],
        51801: [
            15277,
            15173,
            12227,
            15147,
            19349,
            19349
        ],
        52001: [
            15393,
            15296,
            12356,
            15253,
            19465,
            19465
        ],
        52201: [
            15509,
            15420,
            12486,
            15360,
            19581,
            19581
        ],
        52401: [
            15625,
            15543,
            12615,
            15466,
            19697,
            19697
        ],
        52601: [
            15741,
            15667,
            12745,
            15573,
            19813,
            19813
        ],
        52801: [
            15857,
            15790,
            12874,
            15679,
            19929,
            19929
        ],
        53001: [
            15973,
            15914,
            13004,
            15786,
            20045,
            20045
        ],
        53201: [
            16089,
            16037,
            13133,
            15893,
            20161,
            20161
        ],
        53401: [
            16205,
            16158,
            13260,
            15999,
            20277,
            20277
        ],
        53601: [
            16321,
            16281,
            13389,
            16106,
            20393,
            20393
        ],
        53801: [
            16437,
            16405,
            13519,
            16212,
            20509,
            20509
        ],
        54001: [
            16553,
            16528,
            13648,
            16319,
            20625,
            20625
        ],
        54201: [
            16669,
            16652,
            13778,
            16425,
            20741,
            20741
        ],
        54401: [
            16785,
            16775,
            13907,
            16532,
            20857,
            20857
        ],
        54601: [
            16901,
            16899,
            14037,
            16639,
            20973,
            20973
        ],
        54801: [
            17017,
            17022,
            14166,
            16745,
            21089,
            21089
        ],
        55001: [
            17133,
            17146,
            14296,
            16852,
            21205,
            21205
        ],
        55201: [
            17249,
            17266,
            14422,
            16958,
            21321,
            21321
        ],
        55401: [
            17365,
            17390,
            14551,
            17065,
            21437,
            21437
        ],
        55601: [
            17481,
            17513,
            14681,
            17171,
            21553,
            21553
        ],
        55801: [
            17597,
            17637,
            14810,
            17278,
            21669,
            21669
        ],
        56001: [
            17713,
            17760,
            14940,
            17384,
            21785,
            21785
        ],
        56201: [
            17829,
            17884,
            15069,
            17491,
            21901,
            21901
        ],
        56401: [
            17945,
            18007,
            15199,
            17598,
            22017,
            22017
        ],
        56601: [
            18061,
            18131,
            15328,
            17704,
            22133,
            22133
        ],
        56801: [
            18177,
            18251,
            15455,
            17811,
            22249,
            22249
        ],
        57001: [
            18293,
            18374,
            15584,
            17917,
            22365,
            22365
        ],
        57201: [
            18409,
            18498,
            15714,
            18024,
            22481,
            22481
        ],
        57401: [
            18525,
            18621,
            15843,
            18130,
            22597,
            22597
        ],
        57601: [
            18641,
            18745,
            15973,
            18237,
            22713,
            22713
        ],
        57801: [
            18757,
            18868,
            16102,
            18344,
            22829,
            22829
        ],
        58001: [
            18873,
            18982,
            16222,
            18450,
            22945,
            22945
        ],
        58201: [
            18989,
            19098,
            16344,
            18557,
            23061,
            23061
        ],
        58401: [
            19105,
            19214,
            16466,
            18663,
            23177,
            23177
        ],
        58601: [
            19221,
            19330,
            16588,
            18770,
            23293,
            23293
        ],
        58801: [
            19337,
            19446,
            16710,
            18876,
            23409,
            23409
        ],
        59001: [
            19453,
            19562,
            16832,
            18983,
            23525,
            23525
        ],
        59201: [
            19569,
            19678,
            16954,
            19090,
            23641,
            23641
        ],
        59401: [
            19685,
            19794,
            17076,
            19196,
            23757,
            23757
        ],
        59601: [
            19801,
            19910,
            17198,
            19303,
            23873,
            23873
        ],
        59801: [
            19917,
            20026,
            17320,
            19409,
            23989,
            23989
        ],
        60001: [
            20033,
            20142,
            17442,
            19516,
            24105,
            24105
        ],
        60201: [
            20149,
            20258,
            17564,
            19622,
            24221,
            24221
        ],
        60401: [
            20265,
            20374,
            17686,
            19729,
            24337,
            24337
        ],
        60601: [
            20381,
            20490,
            17808,
            19836,
            24453,
            24453
        ],
        60801: [
            20497,
            20606,
            17930,
            19942,
            24569,
            24569
        ],
        61001: [
            20613,
            20722,
            18052,
            20049,
            24685,
            24685
        ],
        61201: [
            20729,
            20838,
            18174,
            20155,
            24801,
            24801
        ],
        61401: [
            20845,
            20954,
            18296,
            20262,
            24917,
            24917
        ],
        61601: [
            20961,
            21070,
            18418,
            20368,
            25033,
            25033
        ],
        61801: [
            21077,
            21186,
            18540,
            20475,
            25149,
            25149
        ],
        62001: [
            21193,
            21302,
            18662,
            20582,
            25265,
            25265
        ],
        62201: [
            21309,
            21418,
            18784,
            20688,
            25381,
            25381
        ],
        62401: [
            21425,
            21534,
            18906,
            20795,
            25497,
            25497
        ],
        62601: [
            21541,
            21650,
            19028,
            20901,
            25613,
            25613
        ],
        62801: [
            21657,
            21766,
            19150,
            21008,
            25729,
            25729
        ],
        63001: [
            21773,
            21882,
            19272,
            21114,
            25845,
            25845
        ],
        63201: [
            21889,
            21998,
            19394,
            21221,
            25961,
            25961
        ],
        63401: [
            22005,
            22114,
            19516,
            21328,
            26077,
            26077
        ],
        63601: [
            22121,
            22230,
            19638,
            21434,
            26193,
            26193
        ],
        63801: [
            22237,
            22346,
            19760,
            21541,
            26309,
            26309
        ],
        64001: [
            22353,
            22462,
            19882,
            21647,
            26425,
            26425
        ],
        64201: [
            22469,
            22578,
            20004,
            21754,
            26541,
            26541
        ],
        64401: [
            22585,
            22694,
            20126,
            21860,
            26657,
            26657
        ],
        64601: [
            22705,
            22819,
            20257,
            21967,
            26773,
            26773
        ],
        64801: [
            22827,
            22940,
            20384,
            22074,
            26889,
            26889
        ],
        65001: [
            22949,
            23066,
            20516,
            22180,
            27005,
            27005
        ],
        65201: [
            23071,
            23187,
            20643,
            22287,
            27121,
            27121
        ],
        65401: [
            23193,
            23308,
            20769,
            22393,
            27237,
            27237
        ],
        65601: [
            23315,
            23433,
            20901,
            22500,
            27353,
            27353
        ],
        65801: [
            23437,
            23554,
            21028,
            22606,
            27469,
            27469
        ],
        66001: [
            23559,
            23680,
            21160,
            22713,
            27585,
            27585
        ],
        66201: [
            23682,
            23801,
            21286,
            22820,
            27701,
            27701
        ],
        66401: [
            23803,
            23921,
            21413,
            22926,
            27817,
            27817
        ],
        66601: [
            23925,
            24047,
            21545,
            23033,
            27933,
            27933
        ],
        66801: [
            24047,
            24168,
            21672,
            23139,
            28049,
            28049
        ],
        67001: [
            24169,
            24289,
            21799,
            23246,
            28165,
            28165
        ],
        67201: [
            24291,
            24414,
            21930,
            23352,
            28281,
            28281
        ],
        67401: [
            24413,
            24535,
            22057,
            23459,
            28397,
            28397
        ],
        67601: [
            24535,
            24661,
            22189,
            23565,
            28513,
            28513
        ],
        67801: [
            24657,
            24782,
            22316,
            23672,
            28629,
            28629
        ],
        68001: [
            24779,
            24903,
            22442,
            23779,
            28745,
            28745
        ],
        68201: [
            24901,
            25028,
            22574,
            23885,
            28861,
            28861
        ],
        68401: [
            25023,
            25149,
            22701,
            23992,
            28977,
            28977
        ],
        68601: [
            25145,
            25270,
            22828,
            24098,
            29093,
            29093
        ],
        68801: [
            25267,
            25396,
            22959,
            24205,
            29209,
            29209
        ],
        69001: [
            25389,
            25516,
            23086,
            24311,
            29325,
            29325
        ],
        69201: [
            25511,
            25642,
            23218,
            24418,
            29441,
            29441
        ],
        69401: [
            25633,
            25763,
            23345,
            24525,
            29557,
            29557
        ],
        69601: [
            25755,
            25884,
            23472,
            24631,
            29673,
            29673
        ],
        69801: [
            25877,
            26009,
            23603,
            24738,
            29789,
            29789
        ],
        70001: [
            25999,
            26130,
            23730,
            24844,
            29905,
            29905
        ],
        70201: [
            26121,
            26251,
            23857,
            24951,
            30021,
            30021
        ],
        70401: [
            26243,
            26377,
            23989,
            25057,
            30137,
            30137
        ],
        70601: [
            26365,
            26498,
            24115,
            25164,
            30253,
            30253
        ],
        70801: [
            26487,
            26623,
            24247,
            25271,
            30369,
            30369
        ],
        71001: [
            26609,
            26744,
            24374,
            25377,
            30485,
            30485
        ],
        71201: [
            26732,
            26865,
            24501,
            25484,
            30601,
            30601
        ],
        71401: [
            26853,
            26991,
            24632,
            25590,
            30717,
            30717
        ],
        71601: [
            26975,
            27111,
            24759,
            25697,
            30833,
            30833
        ],
        71801: [
            27097,
            27237,
            24891,
            25803,
            30949,
            30949
        ],
        72001: [
            27219,
            27358,
            25018,
            25910,
            31065,
            31065
        ],
        72201: [
            27341,
            27479,
            25145,
            26017,
            31181,
            31181
        ],
        72401: [
            27463,
            27604,
            25276,
            26123,
            31297,
            31297
        ],
        72601: [
            27585,
            27725,
            25403,
            26230,
            31413,
            31413
        ],
        72801: [
            27707,
            27846,
            25530,
            26336,
            31529,
            31529
        ],
        73001: [
            27829,
            27972,
            25662,
            26443,
            31645,
            31645
        ],
        73201: [
            27951,
            28093,
            25788,
            26549,
            31761,
            31761
        ],
        73401: [
            28073,
            28218,
            25920,
            26656,
            31877,
            31877
        ],
        73601: [
            28195,
            28339,
            26047,
            26763,
            31993,
            31993
        ],
        73801: [
            28317,
            28460,
            26174,
            26869,
            32109,
            32109
        ],
        74001: [
            28439,
            28586,
            26305,
            26976,
            32225,
            32225
        ],
        74201: [
            28561,
            28706,
            26432,
            27082,
            32341,
            32341
        ],
        74401: [
            28683,
            28827,
            26559,
            27189,
            32457,
            32457
        ],
        74601: [
            28805,
            28953,
            26691,
            27295,
            32573,
            32573
        ],
        74801: [
            28927,
            29074,
            26818,
            27402,
            32689,
            32689
        ],
        75001: [
            29049,
            29199,
            26949,
            27509,
            32805,
            32805
        ],
        75201: [
            29171,
            29320,
            27076,
            27615,
            32921,
            32921
        ],
        75401: [
            29293,
            29441,
            27203,
            27722,
            33037,
            33037
        ],
        75601: [
            29415,
            29567,
            27335,
            27828,
            33153,
            33153
        ],
        75801: [
            29537,
            29688,
            27461,
            27935,
            33269,
            33269
        ],
        76001: [
            29659,
            29808,
            27588,
            28041,
            33385,
            33385
        ],
        76201: [
            29782,
            29934,
            27720,
            28148,
            33501,
            33501
        ],
        76401: [
            29903,
            30055,
            27847,
            28255,
            33617,
            33617
        ],
        76601: [
            30025,
            30181,
            27978,
            28361,
            33733,
            33733
        ],
        76801: [
            30147,
            30301,
            28105,
            28468,
            33849,
            33849
        ],
        77001: [
            30269,
            30422,
            28232,
            28574,
            33965,
            33965
        ],
        77201: [
            30391,
            30548,
            28364,
            28681,
            34081,
            34081
        ],
        77401: [
            30513,
            30669,
            28491,
            28787,
            34197,
            34197
        ],
        77601: [
            30635,
            30790,
            28617,
            28894,
            34313,
            34313
        ],
        77801: [
            30757,
            30915,
            28749,
            29001,
            34429,
            34429
        ],
        78001: [
            30879,
            31036,
            28876,
            29107,
            34545,
            34545
        ],
        78201: [
            31001,
            31162,
            29008,
            29214,
            34661,
            34661
        ],
        78401: [
            31123,
            31283,
            29134,
            29320,
            34777,
            34777
        ],
        78601: [
            31245,
            31403,
            29261,
            29427,
            34893,
            34893
        ],
        78801: [
            31367,
            31529,
            29393,
            29533,
            35009,
            35009
        ],
        79001: [
            31489,
            31650,
            29520,
            29640,
            35125,
            35125
        ],
        79201: [
            31611,
            31771,
            29647,
            29746,
            35241,
            35241
        ],
        79401: [
            31733,
            31896,
            29778,
            29853,
            35357,
            35357
        ],
        79601: [
            31855,
            32017,
            29905,
            29960,
            35473,
            35473
        ],
        79801: [
            31977,
            32143,
            30037,
            30066,
            35589,
            35589
        ],
        80001: [
            40,
            40,
            38,
            38,
            45,
            45,
            '%'
        ],
        81201: [
            40,
            41,
            38,
            38,
            45,
            45,
            '%'
        ],
        82001: [
            41,
            41,
            38,
            38,
            45,
            45,
            '%'
        ],
        82801: [
            41,
            41,
            39,
            38,
            45,
            45,
            '%'
        ],
        84801: [
            41,
            41,
            39,
            39,
            45,
            45,
            '%'
        ],
        85201: [
            41,
            42,
            39,
            39,
            45,
            45,
            '%'
        ],
        86201: [
            42,
            42,
            40,
            39,
            45,
            45,
            '%'
        ],
        86401: [
            42,
            42,
            40,
            39,
            46,
            46,
            '%'
        ],
        89801: [
            42,
            43,
            41,
            39,
            46,
            46,
            '%'
        ],
        90801: [
            43,
            43,
            41,
            39,
            46,
            46,
            '%'
        ],
        91001: [
            43,
            43,
            41,
            40,
            46,
            46,
            '%'
        ],
        93801: [
            43,
            43,
            42,
            40,
            46,
            46,
            '%'
        ],
        94001: [
            43,
            43,
            42,
            40,
            47,
            47,
            '%'
        ],
        94801: [
            43,
            44,
            42,
            40,
            47,
            47,
            '%'
        ],
        96001: [
            44,
            44,
            42,
            40,
            47,
            47,
            '%'
        ],
        98001: [
            44,
            44,
            43,
            40,
            47,
            47,
            '%'
        ],
        98201: [
            44,
            44,
            43,
            41,
            47,
            47,
            '%'
        ],
        100401: [
            44,
            45,
            43,
            41,
            47,
            47,
            '%'
        ],
        101801: [
            45,
            45,
            43,
            41,
            47,
            47,
            '%'
        ],
        102801: [
            45,
            45,
            44,
            41,
            48,
            48,
            '%'
        ],
        106401: [
            45,
            45,
            44,
            42,
            48,
            48,
            '%'
        ],
        106801: [
            45,
            46,
            44,
            42,
            48,
            48,
            '%'
        ],
        108001: [
            45,
            46,
            45,
            42,
            48,
            48,
            '%'
        ],
        108401: [
            46,
            46,
            45,
            42,
            48,
            48,
            '%'
        ],
        113601: [
            46,
            46,
            45,
            42,
            49,
            49,
            '%'
        ],
        113801: [
            46,
            46,
            46,
            42,
            49,
            49,
            '%'
        ],
        114001: [
            46,
            47,
            46,
            42,
            49,
            49,
            '%'
        ],
        116001: [
            47,
            47,
            46,
            42,
            49,
            49,
            '%'
        ],
        116401: [
            47,
            47,
            46,
            43,
            49,
            49,
            '%'
        ],
        120001: [
            47,
            47,
            47,
            43,
            49,
            49,
            '%'
        ],
        122201: [
            47,
            48,
            47,
            43,
            49,
            49,
            '%'
        ],
        124601: [
            48,
            48,
            47,
            43,
            49,
            49,
            '%'
        ],
        127001: [
            48,
            48,
            47,
            43,
            50,
            50,
            '%'
        ],
        127201: [
            48,
            48,
            48,
            43,
            50,
            50,
            '%'
        ],
        128201: [
            48,
            48,
            48,
            44,
            50,
            50,
            '%'
        ],
        131801: [
            48,
            49,
            48,
            44,
            50,
            50,
            '%'
        ],
        134401: [
            49,
            49,
            48,
            44,
            50,
            50,
            '%'
        ],
        135201: [
            49,
            49,
            49,
            44,
            50,
            50,
            '%'
        ],
        142801: [
            49,
            49,
            49,
            45,
            50,
            50,
            '%'
        ],
        143001: [
            49,
            50,
            49,
            45,
            50,
            50,
            '%'
        ],
        144001: [
            49,
            50,
            49,
            45,
            51,
            51,
            '%'
        ],
        144401: [
            49,
            50,
            50,
            45,
            51,
            51,
            '%'
        ],
        146201: [
            50,
            50,
            50,
            45,
            51,
            51,
            '%'
        ],
        156201: [
            50,
            51,
            51,
            45,
            51,
            51,
            '%'
        ],
        160201: [
            51,
            51,
            51,
            45,
            51,
            51,
            '%'
        ],
        161201: [
            51,
            51,
            51,
            46,
            51,
            51,
            '%'
        ],
        166201: [
            51,
            51,
            51,
            46,
            52,
            52,
            '%'
        ],
        172001: [
            51,
            52,
            52,
            46,
            52,
            52,
            '%'
        ],
        177001: [
            52,
            52,
            52,
            46,
            52,
            52,
            '%'
        ],
        185001: [
            52,
            52,
            52,
            47,
            52,
            52,
            '%'
        ],
        196401: [
            52,
            53,
            53,
            47,
            53,
            53,
            '%'
        ],
        197801: [
            53,
            53,
            53,
            47,
            53,
            53,
            '%'
        ],
        217001: [
            53,
            53,
            53,
            48,
            53,
            53,
            '%'
        ],
        240201: [
            54,
            54,
            54,
            48,
            54,
            54,
            '%'
        ],
        262401: [
            54,
            54,
            54,
            49,
            54,
            54,
            '%'
        ],
        308801: [
            55,
            55,
            55,
            49,
            55,
            55,
            '%'
        ],
        331801: [
            55,
            55,
            55,
            50,
            55,
            55,
            '%'
        ],
        432401: [
            56,
            56,
            56,
            50,
            56,
            56,
            '%'
        ],
        451001: [
            56,
            56,
            56,
            51,
            56,
            56,
            '%'
        ],
        703801: [
            56,
            56,
            56,
            52,
            56,
            56,
            '%'
        ],
        720601: [
            57,
            57,
            57,
            52,
            57,
            57,
            '%'
        ],
        1601001: [
            57,
            57,
            57,
            53,
            57,
            57,
            '%'
        ]
    },
    39: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            29
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            69
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            109
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            149
        ],
        2401: [
            185,
            0,
            175,
            10,
            189,
            189
        ],
        2501: [
            195,
            0,
            183,
            12,
            229,
            229
        ],
        2601: [
            206,
            0,
            191,
            14,
            269,
            269
        ],
        2701: [
            216,
            0,
            200,
            16,
            309,
            309
        ],
        2801: [
            218,
            0,
            200,
            18,
            349,
            349
        ],
        2901: [
            229,
            0,
            208,
            21,
            389,
            389
        ],
        3001: [
            239,
            0,
            216,
            23,
            429,
            429
        ],
        3101: [
            250,
            0,
            225,
            25,
            469,
            469
        ],
        3201: [
            260,
            0,
            233,
            27,
            509,
            509
        ],
        3301: [
            271,
            0,
            241,
            29,
            549,
            549
        ],
        3401: [
            273,
            0,
            241,
            31,
            589,
            589
        ],
        3501: [
            284,
            0,
            250,
            34,
            629,
            629
        ],
        3601: [
            294,
            0,
            258,
            36,
            669,
            669
        ],
        3701: [
            305,
            0,
            266,
            38,
            709,
            709
        ],
        3801: [
            315,
            0,
            275,
            40,
            750,
            750
        ],
        3901: [
            325,
            0,
            283,
            42,
            789,
            789
        ],
        4001: [
            328,
            0,
            283,
            44,
            829,
            829
        ],
        4101: [
            338,
            0,
            291,
            46,
            869,
            869
        ],
        4201: [
            349,
            0,
            300,
            49,
            909,
            909
        ],
        4301: [
            359,
            0,
            308,
            64,
            949,
            949
        ],
        4401: [
            370,
            0,
            316,
            92,
            989,
            989
        ],
        4501: [
            380,
            0,
            325,
            119,
            1029,
            1029
        ],
        4601: [
            382,
            0,
            325,
            146,
            1069,
            1069
        ],
        4701: [
            392,
            0,
            333,
            173,
            1103,
            1103
        ],
        4801: [
            403,
            0,
            341,
            199,
            1136,
            1136
        ],
        4901: [
            413,
            0,
            350,
            226,
            1169,
            1169
        ],
        5001: [
            423,
            0,
            358,
            253,
            1199,
            1199
        ],
        5101: [
            433,
            0,
            366,
            279,
            1233,
            1233
        ],
        5201: [
            443,
            0,
            375,
            306,
            1263,
            1263
        ],
        5301: [
            445,
            26,
            376,
            333,
            1296,
            1296
        ],
        5401: [
            455,
            56,
            386,
            359,
            1329,
            1329
        ],
        5501: [
            465,
            86,
            396,
            386,
            1359,
            1359
        ],
        5601: [
            475,
            116,
            406,
            412,
            1392,
            1392
        ],
        5701: [
            485,
            146,
            416,
            438,
            1421,
            1421
        ],
        5801: [
            495,
            176,
            426,
            464,
            1454,
            1454
        ],
        5901: [
            497,
            206,
            427,
            490,
            1487,
            1487
        ],
        6001: [
            507,
            236,
            437,
            516,
            1516,
            1516
        ],
        6101: [
            517,
            266,
            447,
            542,
            1549,
            1549
        ],
        6201: [
            534,
            293,
            457,
            568,
            1578,
            1578
        ],
        6301: [
            558,
            323,
            467,
            594,
            1611,
            1611
        ],
        6401: [
            582,
            353,
            477,
            620,
            1644,
            1644
        ],
        6501: [
            606,
            383,
            479,
            646,
            1673,
            1673
        ],
        6601: [
            631,
            413,
            488,
            673,
            1706,
            1706
        ],
        6701: [
            655,
            443,
            498,
            699,
            1735,
            1735
        ],
        6801: [
            679,
            473,
            508,
            725,
            1768,
            1768
        ],
        6901: [
            704,
            503,
            518,
            751,
            1801,
            1801
        ],
        7001: [
            728,
            533,
            528,
            777,
            1830,
            1830
        ],
        7101: [
            752,
            563,
            530,
            803,
            1863,
            1863
        ],
        7201: [
            777,
            593,
            540,
            829,
            1892,
            1892
        ],
        7301: [
            801,
            623,
            550,
            855,
            1925,
            1925
        ],
        7401: [
            825,
            649,
            560,
            881,
            1958,
            1958
        ],
        7501: [
            850,
            679,
            570,
            907,
            1987,
            1987
        ],
        7601: [
            874,
            709,
            580,
            933,
            2020,
            2020
        ],
        7701: [
            898,
            739,
            589,
            959,
            2049,
            2049
        ],
        7801: [
            922,
            769,
            591,
            986,
            2082,
            2082
        ],
        7901: [
            947,
            799,
            601,
            1012,
            2115,
            2115
        ],
        8001: [
            971,
            829,
            611,
            1038,
            2144,
            2144
        ],
        8101: [
            995,
            859,
            621,
            1064,
            2177,
            2177
        ],
        8201: [
            1020,
            889,
            631,
            1090,
            2206,
            2206
        ],
        8301: [
            1044,
            919,
            641,
            1116,
            2239,
            2239
        ],
        8401: [
            1068,
            949,
            642,
            1142,
            2272,
            2272
        ],
        8501: [
            1093,
            979,
            652,
            1168,
            2301,
            2301
        ],
        8601: [
            1117,
            1006,
            662,
            1194,
            2334,
            2334
        ],
        8701: [
            1141,
            1036,
            672,
            1220,
            2363,
            2363
        ],
        8801: [
            1166,
            1066,
            682,
            1246,
            2396,
            2396
        ],
        8901: [
            1190,
            1096,
            692,
            1272,
            2429,
            2429
        ],
        9001: [
            1214,
            1126,
            694,
            1298,
            2458,
            2458
        ],
        9101: [
            1239,
            1156,
            704,
            1325,
            2491,
            2491
        ],
        9201: [
            1263,
            1186,
            714,
            1351,
            2520,
            2520
        ],
        9301: [
            1287,
            1206,
            723,
            1377,
            2553,
            2553
        ],
        9401: [
            1311,
            1233,
            733,
            1403,
            2586,
            2586
        ],
        9501: [
            1336,
            1256,
            742,
            1429,
            2615,
            2615
        ],
        9601: [
            1360,
            1279,
            744,
            1455,
            2648,
            2648
        ],
        9701: [
            1384,
            1303,
            753,
            1481,
            2677,
            2677
        ],
        9801: [
            1409,
            1329,
            763,
            1507,
            2710,
            2710
        ],
        9901: [
            1433,
            1352,
            773,
            1533,
            2743,
            2743
        ],
        10001: [
            1457,
            1375,
            782,
            1559,
            2772,
            2772
        ],
        10101: [
            1482,
            1398,
            792,
            1586,
            2805,
            2805
        ],
        10201: [
            1506,
            1424,
            802,
            1611,
            2834,
            2834
        ],
        10301: [
            1530,
            1447,
            803,
            1638,
            2867,
            2867
        ],
        10401: [
            1555,
            1470,
            812,
            1664,
            2900,
            2900
        ],
        10501: [
            1579,
            1493,
            822,
            1690,
            2929,
            2929
        ],
        10601: [
            1603,
            1519,
            832,
            1716,
            2962,
            2962
        ],
        10701: [
            1627,
            1542,
            841,
            1742,
            2991,
            2991
        ],
        10801: [
            1652,
            1565,
            851,
            1768,
            3024,
            3024
        ],
        10901: [
            1676,
            1588,
            852,
            1794,
            3057,
            3057
        ],
        11001: [
            1700,
            1614,
            862,
            1820,
            3086,
            3086
        ],
        11101: [
            1725,
            1637,
            872,
            1846,
            3119,
            3119
        ],
        11201: [
            1749,
            1660,
            881,
            1872,
            3148,
            3148
        ],
        11301: [
            1773,
            1686,
            891,
            1898,
            3181,
            3181
        ],
        11401: [
            1798,
            1709,
            901,
            1925,
            3214,
            3214
        ],
        11501: [
            1822,
            1732,
            902,
            1951,
            3243,
            3243
        ],
        11601: [
            1846,
            1755,
            911,
            1977,
            3276,
            3276
        ],
        11701: [
            1870,
            1781,
            921,
            2003,
            3305,
            3305
        ],
        11801: [
            1895,
            1804,
            931,
            2029,
            3338,
            3338
        ],
        11901: [
            1919,
            1827,
            940,
            2055,
            3371,
            3371
        ],
        12001: [
            1943,
            1850,
            950,
            2081,
            3400,
            3400
        ],
        12101: [
            1968,
            1876,
            951,
            2107,
            3433,
            3433
        ],
        12201: [
            1992,
            1899,
            961,
            2133,
            3462,
            3462
        ],
        12301: [
            2016,
            1922,
            971,
            2159,
            3495,
            3495
        ],
        12401: [
            2041,
            1944,
            980,
            2185,
            3528,
            3528
        ],
        12501: [
            2065,
            1971,
            990,
            2211,
            3557,
            3557
        ],
        12601: [
            2089,
            1994,
            1000,
            2238,
            3590,
            3590
        ],
        12701: [
            2114,
            2016,
            1009,
            2264,
            3619,
            3619
        ],
        12801: [
            2138,
            2039,
            1010,
            2290,
            3652,
            3652
        ],
        12901: [
            2162,
            2065,
            1020,
            2316,
            3681,
            3681
        ],
        13001: [
            2187,
            2088,
            1030,
            2342,
            3721,
            3721
        ],
        13101: [
            2211,
            2111,
            1039,
            2368,
            3760,
            3760
        ],
        13201: [
            2236,
            2137,
            1049,
            2395,
            3799,
            3799
        ],
        13301: [
            2261,
            2160,
            1059,
            2421,
            3838,
            3838
        ],
        13401: [
            2285,
            2183,
            1060,
            2448,
            3878,
            3878
        ],
        13501: [
            2310,
            2206,
            1070,
            2474,
            3917,
            3917
        ],
        13601: [
            2334,
            2232,
            1079,
            2500,
            3956,
            3956
        ],
        13701: [
            2359,
            2255,
            1089,
            2527,
            3995,
            3995
        ],
        13801: [
            2383,
            2278,
            1099,
            2552,
            4034,
            4034
        ],
        13901: [
            2406,
            2301,
            1108,
            2578,
            4072,
            4072
        ],
        14001: [
            2430,
            2327,
            1110,
            2603,
            4111,
            4111
        ],
        14101: [
            2454,
            2350,
            1119,
            2629,
            4149,
            4149
        ],
        14201: [
            2477,
            2373,
            1129,
            2654,
            4187,
            4187
        ],
        14301: [
            2501,
            2396,
            1138,
            2679,
            4225,
            4225
        ],
        14401: [
            2524,
            2422,
            1148,
            2705,
            4263,
            4263
        ],
        14501: [
            2548,
            2445,
            1158,
            2730,
            4302,
            4302
        ],
        14601: [
            2572,
            2468,
            1159,
            2755,
            4340,
            4340
        ],
        14701: [
            2595,
            2491,
            1169,
            2781,
            4378,
            4378
        ],
        14801: [
            2619,
            2517,
            1178,
            2806,
            4420,
            4420
        ],
        14901: [
            2642,
            2540,
            1188,
            2831,
            4461,
            4461
        ],
        15001: [
            2666,
            2563,
            1198,
            2857,
            4503,
            4503
        ],
        15101: [
            2690,
            2586,
            1207,
            2882,
            4544,
            4544
        ],
        15201: [
            2713,
            2612,
            1217,
            2908,
            4589,
            4589
        ],
        15301: [
            2737,
            2635,
            1218,
            2933,
            4630,
            4630
        ],
        15401: [
            2762,
            2661,
            1228,
            2962,
            4671,
            4671
        ],
        15501: [
            2794,
            2693,
            1238,
            2995,
            4713,
            4713
        ],
        15601: [
            2826,
            2726,
            1248,
            3028,
            4754,
            4754
        ],
        15701: [
            2859,
            2759,
            1258,
            3062,
            4799,
            4799
        ],
        15801: [
            2891,
            2792,
            1268,
            3095,
            4840,
            4840
        ],
        15901: [
            2923,
            2824,
            1270,
            3129,
            4882,
            4882
        ],
        16001: [
            2955,
            2857,
            1280,
            3162,
            4923,
            4923
        ],
        16101: [
            2987,
            2890,
            1291,
            3196,
            4965,
            4965
        ],
        16201: [
            3019,
            2922,
            1301,
            3229,
            5009,
            5009
        ],
        16301: [
            3051,
            2955,
            1311,
            3263,
            5051,
            5051
        ],
        16401: [
            3083,
            2988,
            1321,
            3296,
            5092,
            5092
        ],
        16501: [
            3115,
            3021,
            1323,
            3329,
            5134,
            5134
        ],
        16601: [
            3147,
            3053,
            1333,
            3363,
            5175,
            5175
        ],
        16701: [
            3179,
            3086,
            1343,
            3396,
            5220,
            5220
        ],
        16801: [
            3211,
            3119,
            1353,
            3430,
            5261,
            5261
        ],
        16901: [
            3243,
            3151,
            1363,
            3463,
            5303,
            5303
        ],
        17001: [
            3276,
            3181,
            1373,
            3497,
            5344,
            5344
        ],
        17101: [
            3308,
            3214,
            1375,
            3530,
            5386,
            5386
        ],
        17201: [
            3340,
            3246,
            1385,
            3564,
            5430,
            5430
        ],
        17301: [
            3372,
            3279,
            1395,
            3597,
            5472,
            5472
        ],
        17401: [
            3404,
            3312,
            1405,
            3631,
            5513,
            5513
        ],
        17501: [
            3436,
            3344,
            1416,
            3664,
            5554,
            5554
        ],
        17601: [
            3468,
            3377,
            1426,
            3697,
            5596,
            5596
        ],
        17701: [
            3500,
            3410,
            1436,
            3731,
            5640,
            5640
        ],
        17801: [
            3532,
            3443,
            1438,
            3764,
            5682,
            5682
        ],
        17901: [
            3564,
            3475,
            1448,
            3798,
            5723,
            5723
        ],
        18001: [
            3596,
            3508,
            1458,
            3831,
            5765,
            5765
        ],
        18101: [
            3628,
            3541,
            1468,
            3865,
            5806,
            5806
        ],
        18201: [
            3660,
            3573,
            1478,
            3898,
            5851,
            5851
        ],
        18301: [
            3693,
            3606,
            1488,
            3932,
            5892,
            5892
        ],
        18401: [
            3725,
            3639,
            1490,
            3965,
            5934,
            5934
        ],
        18501: [
            3757,
            3671,
            1500,
            3998,
            5975,
            5975
        ],
        18601: [
            3789,
            3704,
            1510,
            4032,
            6017,
            6017
        ],
        18701: [
            3821,
            3737,
            1520,
            4065,
            6061,
            6061
        ],
        18801: [
            3853,
            3770,
            1531,
            4099,
            6103,
            6103
        ],
        18901: [
            3885,
            3802,
            1541,
            4132,
            6144,
            6144
        ],
        19001: [
            3917,
            3835,
            1543,
            4166,
            6186,
            6186
        ],
        19101: [
            3949,
            3868,
            1553,
            4199,
            6227,
            6227
        ],
        19201: [
            3981,
            3900,
            1563,
            4233,
            6272,
            6272
        ],
        19301: [
            4013,
            3933,
            1573,
            4266,
            6313,
            6313
        ],
        19401: [
            4045,
            3966,
            1583,
            4299,
            6354,
            6354
        ],
        19501: [
            4077,
            3999,
            1593,
            4333,
            6396,
            6396
        ],
        19601: [
            4109,
            4031,
            1595,
            4366,
            6437,
            6437
        ],
        19701: [
            4142,
            4063,
            1604,
            4400,
            6482,
            6482
        ],
        19801: [
            4174,
            4095,
            1613,
            4433,
            6523,
            6523
        ],
        19901: [
            4206,
            4126,
            1622,
            4467,
            6565,
            6565
        ],
        20001: [
            4270,
            4190,
            1641,
            4534,
            6648,
            6648
        ],
        20201: [
            4334,
            4251,
            1651,
            4601,
            6734,
            6734
        ],
        20401: [
            4398,
            4314,
            1670,
            4667,
            6817,
            6817
        ],
        20601: [
            4462,
            4378,
            1688,
            4734,
            6903,
            6903
        ],
        20801: [
            4526,
            4442,
            1714,
            4801,
            6986,
            6986
        ],
        21001: [
            4591,
            4506,
            1764,
            4868,
            7068,
            7068
        ],
        21201: [
            4655,
            4569,
            1813,
            4935,
            7155,
            7155
        ],
        21401: [
            4719,
            4633,
            1863,
            5002,
            7237,
            7237
        ],
        21601: [
            4783,
            4697,
            1913,
            5069,
            7323,
            7323
        ],
        21801: [
            4847,
            4761,
            1963,
            5136,
            7406,
            7406
        ],
        22001: [
            4911,
            4824,
            2012,
            5203,
            7489,
            7489
        ],
        22201: [
            4976,
            4888,
            2062,
            5269,
            7575,
            7575
        ],
        22401: [
            5040,
            4952,
            2112,
            5336,
            7658,
            7658
        ],
        22601: [
            5104,
            5016,
            2162,
            5403,
            7744,
            7744
        ],
        22801: [
            5169,
            5079,
            2211,
            5471,
            7828,
            7828
        ],
        23001: [
            5235,
            5143,
            2261,
            5540,
            7913,
            7913
        ],
        23201: [
            5301,
            5207,
            2311,
            5608,
            8000,
            8000
        ],
        23401: [
            5366,
            5268,
            2357,
            5677,
            8085,
            8085
        ],
        23601: [
            5432,
            5331,
            2407,
            5745,
            8173,
            8173
        ],
        23801: [
            5498,
            5392,
            2454,
            5814,
            8257,
            8257
        ],
        24001: [
            5564,
            5459,
            2507,
            5882,
            8342,
            8342
        ],
        24201: [
            5630,
            5523,
            2556,
            5951,
            8429,
            8429
        ],
        24401: [
            5695,
            5589,
            2609,
            6019,
            8514,
            8514
        ],
        24601: [
            5761,
            5653,
            2659,
            6088,
            8602,
            8602
        ],
        24801: [
            5827,
            5717,
            2709,
            6157,
            8686,
            8686
        ],
        25001: [
            5893,
            5784,
            2779,
            6225,
            8771,
            8771
        ],
        25201: [
            5958,
            5848,
            2843,
            6294,
            8858,
            8858
        ],
        25401: [
            6024,
            5911,
            2907,
            6362,
            8943,
            8943
        ],
        25601: [
            6090,
            5978,
            2973,
            6431,
            9031,
            9031
        ],
        25801: [
            6156,
            6042,
            3037,
            6499,
            9115,
            9115
        ],
        26001: [
            6222,
            6106,
            3101,
            6568,
            9200,
            9200
        ],
        26201: [
            6287,
            6173,
            3168,
            6636,
            9287,
            9287
        ],
        26401: [
            6353,
            6237,
            3232,
            6705,
            9372,
            9372
        ],
        26601: [
            6419,
            6303,
            3299,
            6773,
            9460,
            9460
        ],
        26801: [
            6485,
            6367,
            3362,
            6842,
            9544,
            9544
        ],
        27001: [
            6551,
            6431,
            3426,
            6910,
            9629,
            9629
        ],
        27201: [
            6616,
            6498,
            3493,
            6979,
            9716,
            9716
        ],
        27401: [
            6682,
            6562,
            3557,
            7048,
            9801,
            9801
        ],
        27601: [
            6748,
            6625,
            3621,
            7116,
            9889,
            9889
        ],
        27801: [
            6814,
            6692,
            3687,
            7185,
            9973,
            9973
        ],
        28001: [
            6880,
            6756,
            3751,
            7253,
            10058,
            10058
        ],
        28201: [
            6945,
            6823,
            3818,
            7322,
            10145,
            10145
        ],
        28401: [
            7011,
            6887,
            3882,
            7390,
            10230,
            10230
        ],
        28601: [
            7077,
            6951,
            3946,
            7459,
            10318,
            10318
        ],
        28801: [
            7143,
            7017,
            4013,
            7527,
            10402,
            10402
        ],
        29001: [
            7209,
            7081,
            4076,
            7596,
            10487,
            10487
        ],
        29201: [
            7274,
            7145,
            4140,
            7665,
            10574,
            10574
        ],
        29401: [
            7340,
            7212,
            4207,
            7733,
            10659,
            10659
        ],
        29601: [
            7406,
            7276,
            4271,
            7802,
            10747,
            10747
        ],
        29801: [
            7472,
            7343,
            4338,
            7870,
            10831,
            10831
        ],
        30001: [
            7538,
            7406,
            4401,
            7939,
            10916,
            10916
        ],
        30201: [
            7603,
            7470,
            4465,
            8007,
            11003,
            11003
        ],
        30401: [
            7669,
            7537,
            4532,
            8076,
            11088,
            11088
        ],
        30601: [
            7735,
            7601,
            4596,
            8144,
            11176,
            11176
        ],
        30801: [
            7801,
            7665,
            4660,
            8213,
            11260,
            11260
        ],
        31001: [
            7867,
            7731,
            4727,
            8281,
            11345,
            11345
        ],
        31201: [
            7933,
            7796,
            4791,
            8350,
            11432,
            11432
        ],
        31401: [
            7998,
            7861,
            4856,
            8418,
            11517,
            11517
        ],
        31601: [
            8064,
            7929,
            4924,
            8487,
            11605,
            11605
        ],
        31801: [
            8130,
            7994,
            4989,
            8556,
            11689,
            11689
        ],
        32001: [
            8196,
            8062,
            5057,
            8624,
            11774,
            11774
        ],
        32201: [
            8261,
            8127,
            5122,
            8693,
            11861,
            11861
        ],
        32401: [
            8327,
            8192,
            5187,
            8761,
            11946,
            11946
        ],
        32601: [
            8393,
            8260,
            5255,
            8830,
            12034,
            12034
        ],
        32801: [
            8459,
            8325,
            5320,
            8898,
            12118,
            12118
        ],
        33001: [
            8525,
            8390,
            5385,
            8967,
            12203,
            12203
        ],
        33201: [
            8590,
            8459,
            5454,
            9035,
            12290,
            12290
        ],
        33401: [
            8656,
            8524,
            5519,
            9104,
            12375,
            12375
        ],
        33601: [
            8722,
            8592,
            5587,
            9173,
            12463,
            12463
        ],
        33801: [
            8788,
            8657,
            5652,
            9241,
            12547,
            12547
        ],
        34001: [
            8854,
            8722,
            5717,
            9310,
            12632,
            12632
        ],
        34201: [
            8919,
            8790,
            5785,
            9378,
            12719,
            12719
        ],
        34401: [
            8985,
            8855,
            5850,
            9447,
            12804,
            12804
        ],
        34601: [
            9051,
            8920,
            5915,
            9515,
            12892,
            12892
        ],
        34801: [
            9117,
            8988,
            5983,
            9584,
            12976,
            12976
        ],
        35001: [
            9183,
            9053,
            6048,
            9652,
            13061,
            13061
        ],
        35201: [
            9248,
            9118,
            6113,
            9721,
            13148,
            13148
        ],
        35401: [
            9314,
            9187,
            6182,
            9789,
            13233,
            13233
        ],
        35601: [
            9380,
            9252,
            6247,
            9858,
            13321,
            13321
        ],
        35801: [
            9446,
            9320,
            6315,
            9927,
            13405,
            13405
        ],
        36001: [
            9512,
            9385,
            6380,
            9995,
            13490,
            13490
        ],
        36201: [
            9577,
            9450,
            6445,
            10064,
            13577,
            13577
        ],
        36401: [
            9643,
            9518,
            6513,
            10132,
            13662,
            13662
        ],
        36601: [
            9709,
            9583,
            6578,
            10201,
            13750,
            13750
        ],
        36801: [
            9775,
            9648,
            6643,
            10269,
            13834,
            13834
        ],
        37001: [
            9841,
            9716,
            6711,
            10338,
            13919,
            13919
        ],
        37201: [
            9906,
            9781,
            6776,
            10406,
            14006,
            14006
        ],
        37401: [
            9972,
            9850,
            6845,
            10475,
            14091,
            14091
        ],
        37601: [
            10038,
            9911,
            6906,
            10543,
            14172,
            14172
        ],
        37801: [
            10103,
            9976,
            6971,
            10612,
            14250,
            14250
        ],
        38001: [
            10169,
            10041,
            7036,
            10680,
            14328,
            14328
        ],
        38201: [
            10234,
            10110,
            7105,
            10748,
            14406,
            14406
        ],
        38401: [
            10302,
            10175,
            7170,
            10817,
            14484,
            14484
        ],
        38601: [
            10380,
            10253,
            7248,
            10885,
            14562,
            14562
        ],
        38801: [
            10458,
            10331,
            7326,
            10953,
            14640,
            14640
        ],
        39001: [
            10536,
            10409,
            7404,
            11021,
            14718,
            14718
        ],
        39201: [
            10614,
            10487,
            7482,
            11090,
            14796,
            14796
        ],
        39401: [
            10692,
            10565,
            7560,
            11158,
            14874,
            14874
        ],
        39601: [
            10770,
            10643,
            7638,
            11226,
            14952,
            14952
        ],
        39801: [
            10848,
            10721,
            7716,
            11295,
            15030,
            15030
        ],
        40001: [
            10926,
            10799,
            7794,
            11363,
            15108,
            15108
        ],
        40201: [
            11004,
            10877,
            7872,
            11431,
            15186,
            15186
        ],
        40401: [
            11082,
            10955,
            7950,
            11500,
            15264,
            15264
        ],
        40601: [
            11160,
            11033,
            8028,
            11568,
            15342,
            15342
        ],
        40801: [
            11238,
            11111,
            8106,
            11636,
            15420,
            15420
        ],
        41001: [
            11316,
            11189,
            8184,
            11705,
            15498,
            15498
        ],
        41201: [
            11394,
            11267,
            8262,
            11773,
            15576,
            15576
        ],
        41401: [
            11472,
            11345,
            8340,
            11841,
            15654,
            15654
        ],
        41601: [
            11550,
            11423,
            8418,
            11910,
            15732,
            15732
        ],
        41801: [
            11628,
            11501,
            8496,
            11978,
            15810,
            15810
        ],
        42001: [
            11706,
            11579,
            8574,
            12046,
            15888,
            15888
        ],
        42201: [
            11784,
            11657,
            8652,
            12114,
            15966,
            15966
        ],
        42401: [
            11862,
            11735,
            8730,
            12183,
            16044,
            16044
        ],
        42601: [
            11940,
            11813,
            8808,
            12251,
            16122,
            16122
        ],
        42801: [
            12018,
            11891,
            8886,
            12319,
            16200,
            16200
        ],
        43001: [
            12096,
            11969,
            8964,
            12388,
            16278,
            16278
        ],
        43201: [
            12174,
            12047,
            9042,
            12456,
            16356,
            16356
        ],
        43401: [
            12252,
            12125,
            9120,
            12524,
            16434,
            16434
        ],
        43601: [
            12330,
            12203,
            9198,
            12593,
            16512,
            16512
        ],
        43801: [
            12408,
            12281,
            9276,
            12661,
            16590,
            16590
        ],
        44001: [
            12486,
            12359,
            9354,
            12729,
            16668,
            16668
        ],
        44201: [
            12564,
            12437,
            9432,
            12798,
            16746,
            16746
        ],
        44401: [
            12642,
            12515,
            9510,
            12866,
            16824,
            16824
        ],
        44601: [
            12720,
            12593,
            9588,
            12934,
            16902,
            16902
        ],
        44801: [
            12798,
            12671,
            9666,
            13003,
            16980,
            16980
        ],
        45001: [
            12876,
            12749,
            9744,
            13071,
            17058,
            17058
        ],
        45201: [
            12954,
            12827,
            9822,
            13139,
            17136,
            17136
        ],
        45401: [
            13032,
            12905,
            9900,
            13207,
            17214,
            17214
        ],
        45601: [
            13110,
            12983,
            9978,
            13276,
            17292,
            17292
        ],
        45801: [
            13188,
            13061,
            10056,
            13344,
            17370,
            17370
        ],
        46001: [
            13266,
            13139,
            10134,
            13412,
            17448,
            17448
        ],
        46201: [
            13344,
            13217,
            10212,
            13481,
            17526,
            17526
        ],
        46401: [
            13422,
            13295,
            10290,
            13549,
            17604,
            17604
        ],
        46601: [
            13500,
            13373,
            10368,
            13617,
            17682,
            17682
        ],
        46801: [
            13578,
            13451,
            10446,
            13686,
            17760,
            17760
        ],
        47001: [
            13656,
            13529,
            10524,
            13754,
            17838,
            17838
        ],
        47201: [
            13734,
            13607,
            10602,
            13822,
            17916,
            17916
        ],
        47401: [
            13812,
            13685,
            10680,
            13891,
            17994,
            17994
        ],
        47601: [
            13890,
            13763,
            10758,
            13959,
            18072,
            18072
        ],
        47801: [
            13968,
            13841,
            10836,
            14027,
            18150,
            18150
        ],
        48001: [
            14046,
            13919,
            10914,
            14096,
            18228,
            18228
        ],
        48201: [
            14124,
            13997,
            10992,
            14164,
            18306,
            18306
        ],
        48401: [
            14202,
            14075,
            11070,
            14232,
            18384,
            18384
        ],
        48601: [
            14280,
            14153,
            11148,
            14300,
            18462,
            18462
        ],
        48801: [
            14358,
            14231,
            11226,
            14369,
            18540,
            18540
        ],
        49001: [
            14436,
            14309,
            11304,
            14437,
            18618,
            18618
        ],
        49201: [
            14514,
            14387,
            11382,
            14505,
            18696,
            18696
        ],
        49401: [
            14592,
            14465,
            11460,
            14574,
            18774,
            18774
        ],
        49601: [
            14670,
            14543,
            11538,
            14642,
            18852,
            18852
        ],
        49801: [
            14748,
            14621,
            11616,
            14710,
            18930,
            18930
        ],
        50001: [
            14826,
            14699,
            11698,
            14779,
            19008,
            19008
        ],
        50201: [
            14904,
            14777,
            11782,
            14847,
            19086,
            19086
        ],
        50401: [
            14982,
            14855,
            11866,
            14915,
            19164,
            19164
        ],
        50601: [
            15060,
            14933,
            11950,
            14984,
            19242,
            19242
        ],
        50801: [
            15138,
            15011,
            12034,
            15052,
            19320,
            19320
        ],
        51001: [
            15216,
            15089,
            12118,
            15120,
            19398,
            19398
        ],
        51201: [
            15319,
            15196,
            12232,
            15214,
            19501,
            19501
        ],
        51401: [
            15437,
            15323,
            12364,
            15322,
            19619,
            19619
        ],
        51601: [
            15555,
            15449,
            12497,
            15430,
            19737,
            19737
        ],
        51801: [
            15673,
            15573,
            12627,
            15538,
            19855,
            19855
        ],
        52001: [
            15791,
            15700,
            12759,
            15647,
            19973,
            19973
        ],
        52201: [
            15909,
            15826,
            12892,
            15755,
            20091,
            20091
        ],
        52401: [
            16027,
            15953,
            13025,
            15863,
            20209,
            20209
        ],
        52601: [
            16145,
            16080,
            13158,
            15972,
            20327,
            20327
        ],
        52801: [
            16263,
            16207,
            13290,
            16080,
            20445,
            20445
        ],
        53001: [
            16381,
            16333,
            13423,
            16188,
            20563,
            20563
        ],
        53201: [
            16499,
            16460,
            13556,
            16297,
            20681,
            20681
        ],
        53401: [
            16617,
            16584,
            13685,
            16405,
            20799,
            20799
        ],
        53601: [
            16735,
            16710,
            13818,
            16513,
            20917,
            20917
        ],
        53801: [
            16853,
            16837,
            13951,
            16622,
            21035,
            21035
        ],
        54001: [
            16971,
            16964,
            14084,
            16730,
            21153,
            21153
        ],
        54201: [
            17089,
            17091,
            14216,
            16838,
            21271,
            21271
        ],
        54401: [
            17207,
            17217,
            14349,
            16947,
            21389,
            21389
        ],
        54601: [
            17325,
            17344,
            14482,
            17055,
            21507,
            21507
        ],
        54801: [
            17443,
            17471,
            14615,
            17163,
            21625,
            21625
        ],
        55001: [
            17561,
            17598,
            14747,
            17271,
            21743,
            21743
        ],
        55201: [
            17679,
            17721,
            14877,
            17380,
            21861,
            21861
        ],
        55401: [
            17797,
            17848,
            15010,
            17488,
            21979,
            21979
        ],
        55601: [
            17915,
            17975,
            15142,
            17596,
            22097,
            22097
        ],
        55801: [
            18033,
            18101,
            15275,
            17705,
            22215,
            22215
        ],
        56001: [
            18151,
            18228,
            15408,
            17813,
            22333,
            22333
        ],
        56201: [
            18269,
            18355,
            15541,
            17921,
            22451,
            22451
        ],
        56401: [
            18387,
            18482,
            15673,
            18030,
            22569,
            22569
        ],
        56601: [
            18505,
            18608,
            15806,
            18138,
            22687,
            22687
        ],
        56801: [
            18623,
            18732,
            15936,
            18246,
            22805,
            22805
        ],
        57001: [
            18741,
            18859,
            16068,
            18355,
            22923,
            22923
        ],
        57201: [
            18859,
            18985,
            16201,
            18463,
            23041,
            23041
        ],
        57401: [
            18977,
            19112,
            16334,
            18571,
            23159,
            23159
        ],
        57601: [
            19095,
            19239,
            16467,
            18680,
            23277,
            23277
        ],
        57801: [
            19213,
            19366,
            16599,
            18788,
            23395,
            23395
        ],
        58001: [
            19331,
            19481,
            16721,
            18896,
            23513,
            23513
        ],
        58201: [
            19449,
            19599,
            16845,
            19004,
            23631,
            23631
        ],
        58401: [
            19567,
            19717,
            16969,
            19113,
            23749,
            23749
        ],
        58601: [
            19685,
            19835,
            17093,
            19221,
            23867,
            23867
        ],
        58801: [
            19803,
            19953,
            17217,
            19329,
            23985,
            23985
        ],
        59001: [
            19921,
            20071,
            17341,
            19438,
            24103,
            24103
        ],
        59201: [
            20039,
            20189,
            17465,
            19546,
            24221,
            24221
        ],
        59401: [
            20157,
            20307,
            17589,
            19654,
            24339,
            24339
        ],
        59601: [
            20275,
            20425,
            17713,
            19763,
            24457,
            24457
        ],
        59801: [
            20393,
            20543,
            17837,
            19871,
            24575,
            24575
        ],
        60001: [
            20511,
            20661,
            17961,
            19979,
            24693,
            24693
        ],
        60201: [
            20629,
            20779,
            18085,
            20088,
            24811,
            24811
        ],
        60401: [
            20747,
            20897,
            18209,
            20196,
            24929,
            24929
        ],
        60601: [
            20865,
            21015,
            18333,
            20304,
            25047,
            25047
        ],
        60801: [
            20983,
            21133,
            18457,
            20413,
            25165,
            25165
        ],
        61001: [
            21101,
            21251,
            18581,
            20521,
            25283,
            25283
        ],
        61201: [
            21219,
            21369,
            18705,
            20629,
            25401,
            25401
        ],
        61401: [
            21337,
            21487,
            18829,
            20737,
            25519,
            25519
        ],
        61601: [
            21455,
            21605,
            18953,
            20846,
            25637,
            25637
        ],
        61801: [
            21573,
            21723,
            19077,
            20954,
            25755,
            25755
        ],
        62001: [
            21691,
            21841,
            19201,
            21062,
            25873,
            25873
        ],
        62201: [
            21809,
            21959,
            19325,
            21171,
            25991,
            25991
        ],
        62401: [
            21927,
            22077,
            19449,
            21279,
            26109,
            26109
        ],
        62601: [
            22045,
            22195,
            19573,
            21387,
            26227,
            26227
        ],
        62801: [
            22163,
            22313,
            19697,
            21496,
            26345,
            26345
        ],
        63001: [
            22281,
            22431,
            19821,
            21604,
            26463,
            26463
        ],
        63201: [
            22399,
            22549,
            19945,
            21712,
            26581,
            26581
        ],
        63401: [
            22517,
            22667,
            20069,
            21821,
            26699,
            26699
        ],
        63601: [
            22635,
            22785,
            20193,
            21929,
            26817,
            26817
        ],
        63801: [
            22753,
            22903,
            20317,
            22037,
            26935,
            26935
        ],
        64001: [
            22871,
            23021,
            20441,
            22146,
            27053,
            27053
        ],
        64201: [
            22989,
            23139,
            20565,
            22254,
            27171,
            27171
        ],
        64401: [
            23107,
            23257,
            20689,
            22362,
            27289,
            27289
        ],
        64601: [
            23229,
            23385,
            20823,
            22471,
            27407,
            27407
        ],
        64801: [
            23353,
            23508,
            20952,
            22579,
            27525,
            27525
        ],
        65001: [
            23477,
            23636,
            21086,
            22687,
            27643,
            27643
        ],
        65201: [
            23601,
            23759,
            21215,
            22795,
            27761,
            27761
        ],
        65401: [
            23725,
            23882,
            21344,
            22904,
            27879,
            27879
        ],
        65601: [
            23849,
            24010,
            21477,
            23012,
            27997,
            27997
        ],
        65801: [
            23973,
            24133,
            21606,
            23120,
            28115,
            28115
        ],
        66001: [
            24097,
            24260,
            21740,
            23229,
            28233,
            28233
        ],
        66201: [
            24221,
            24383,
            21869,
            23337,
            28351,
            28351
        ],
        66401: [
            24345,
            24506,
            21998,
            23445,
            28469,
            28469
        ],
        66601: [
            24469,
            24634,
            22132,
            23554,
            28587,
            28587
        ],
        66801: [
            24593,
            24757,
            22261,
            23662,
            28705,
            28705
        ],
        67001: [
            24717,
            24880,
            22390,
            23770,
            28823,
            28823
        ],
        67201: [
            24841,
            25008,
            22524,
            23879,
            28941,
            28941
        ],
        67401: [
            24965,
            25131,
            22652,
            23987,
            29059,
            29059
        ],
        67601: [
            25089,
            25258,
            22786,
            24095,
            29177,
            29177
        ],
        67801: [
            25213,
            25381,
            22915,
            24204,
            29295,
            29295
        ],
        68001: [
            25337,
            25504,
            23044,
            24312,
            29413,
            29413
        ],
        68201: [
            25461,
            25632,
            23178,
            24420,
            29531,
            29531
        ],
        68401: [
            25585,
            25755,
            23307,
            24528,
            29649,
            29649
        ],
        68601: [
            25709,
            25878,
            23436,
            24637,
            29767,
            29767
        ],
        68801: [
            25833,
            26006,
            23570,
            24745,
            29885,
            29885
        ],
        69001: [
            25957,
            26129,
            23699,
            24853,
            30003,
            30003
        ],
        69201: [
            26081,
            26257,
            23832,
            24962,
            30121,
            30121
        ],
        69401: [
            26205,
            26379,
            23961,
            25070,
            30239,
            30239
        ],
        69601: [
            26329,
            26502,
            24090,
            25178,
            30357,
            30357
        ],
        69801: [
            26453,
            26630,
            24224,
            25287,
            30475,
            30475
        ],
        70001: [
            26577,
            26753,
            24353,
            25395,
            30593,
            30593
        ],
        70201: [
            26701,
            26876,
            24482,
            25503,
            30711,
            30711
        ],
        70401: [
            26825,
            27004,
            24616,
            25612,
            30829,
            30829
        ],
        70601: [
            26949,
            27127,
            24745,
            25720,
            30947,
            30947
        ],
        70801: [
            27073,
            27255,
            24878,
            25828,
            31065,
            31065
        ],
        71001: [
            27197,
            27378,
            25007,
            25937,
            31183,
            31183
        ],
        71201: [
            27321,
            27500,
            25136,
            26045,
            31301,
            31301
        ],
        71401: [
            27445,
            27628,
            25270,
            26153,
            31419,
            31419
        ],
        71601: [
            27569,
            27751,
            25399,
            26261,
            31537,
            31537
        ],
        71801: [
            27693,
            27879,
            25533,
            26370,
            31655,
            31655
        ],
        72001: [
            27817,
            28002,
            25662,
            26478,
            31773,
            31773
        ],
        72201: [
            27941,
            28125,
            25791,
            26586,
            31891,
            31891
        ],
        72401: [
            28065,
            28253,
            25925,
            26695,
            32009,
            32009
        ],
        72601: [
            28189,
            28376,
            26053,
            26803,
            32127,
            32127
        ],
        72801: [
            28313,
            28499,
            26182,
            26911,
            32245,
            32245
        ],
        73001: [
            28437,
            28626,
            26316,
            27020,
            32363,
            32363
        ],
        73201: [
            28561,
            28749,
            26445,
            27128,
            32481,
            32481
        ],
        73401: [
            28685,
            28877,
            26579,
            27236,
            32599,
            32599
        ],
        73601: [
            28809,
            29000,
            26708,
            27345,
            32717,
            32717
        ],
        73801: [
            28933,
            29123,
            26837,
            27453,
            32835,
            32835
        ],
        74001: [
            29057,
            29251,
            26971,
            27561,
            32953,
            32953
        ],
        74201: [
            29181,
            29374,
            27100,
            27670,
            33071,
            33071
        ],
        74401: [
            29305,
            29497,
            27228,
            27778,
            33189,
            33189
        ],
        74601: [
            29429,
            29624,
            27362,
            27886,
            33307,
            33307
        ],
        74801: [
            29553,
            29747,
            27491,
            27994,
            33425,
            33425
        ],
        75001: [
            29677,
            29875,
            27625,
            28103,
            33543,
            33543
        ],
        75201: [
            29801,
            29998,
            27754,
            28211,
            33661,
            33661
        ],
        75401: [
            29925,
            30121,
            27883,
            28319,
            33779,
            33779
        ],
        75601: [
            30049,
            30249,
            28017,
            28428,
            33897,
            33897
        ],
        75801: [
            30173,
            30372,
            28146,
            28536,
            34015,
            34015
        ],
        76001: [
            30297,
            30495,
            28275,
            28644,
            34133,
            34133
        ],
        76201: [
            30421,
            30623,
            28408,
            28753,
            34251,
            34251
        ],
        76401: [
            30545,
            30745,
            28537,
            28861,
            34369,
            34369
        ],
        76601: [
            30669,
            30873,
            28671,
            28969,
            34487,
            34487
        ],
        76801: [
            30793,
            30996,
            28800,
            29078,
            34605,
            34605
        ],
        77001: [
            30917,
            31119,
            28929,
            29186,
            34723,
            34723
        ],
        77201: [
            31041,
            31247,
            29063,
            29294,
            34841,
            34841
        ],
        77401: [
            31165,
            31370,
            29192,
            29403,
            34959,
            34959
        ],
        77601: [
            31289,
            31493,
            29321,
            29511,
            35077,
            35077
        ],
        77801: [
            31413,
            31621,
            29454,
            29619,
            35195,
            35195
        ],
        78001: [
            31537,
            31744,
            29583,
            29727,
            35313,
            35313
        ],
        78201: [
            31661,
            31871,
            29717,
            29836,
            35431,
            35431
        ],
        78401: [
            31785,
            31994,
            29846,
            29944,
            35549,
            35549
        ],
        78601: [
            31909,
            32117,
            29975,
            30052,
            35667,
            35667
        ],
        78801: [
            32033,
            32245,
            30109,
            30161,
            35785,
            35785
        ],
        79001: [
            32157,
            32368,
            30238,
            30269,
            35903,
            35903
        ],
        79201: [
            32281,
            32491,
            30367,
            30377,
            36021,
            36021
        ],
        79401: [
            32405,
            32619,
            30501,
            30486,
            36139,
            36139
        ],
        79601: [
            32529,
            32742,
            30629,
            30594,
            36257,
            36257
        ],
        79801: [
            32653,
            32869,
            30763,
            30702,
            36375,
            36375
        ],
        80001: [
            41,
            41,
            39,
            38,
            46,
            46,
            '%'
        ],
        80601: [
            41,
            41,
            39,
            39,
            46,
            46,
            '%'
        ],
        81401: [
            41,
            42,
            39,
            39,
            46,
            46,
            '%'
        ],
        82601: [
            42,
            42,
            39,
            39,
            46,
            46,
            '%'
        ],
        83201: [
            42,
            42,
            40,
            39,
            46,
            46,
            '%'
        ],
        85601: [
            42,
            43,
            40,
            39,
            46,
            46,
            '%'
        ],
        86001: [
            42,
            43,
            40,
            40,
            46,
            46,
            '%'
        ],
        86401: [
            42,
            43,
            41,
            40,
            47,
            47,
            '%'
        ],
        86801: [
            43,
            43,
            41,
            40,
            47,
            47,
            '%'
        ],
        90201: [
            43,
            44,
            42,
            40,
            47,
            47,
            '%'
        ],
        91601: [
            44,
            44,
            42,
            40,
            47,
            47,
            '%'
        ],
        92401: [
            44,
            44,
            42,
            41,
            47,
            47,
            '%'
        ],
        94001: [
            44,
            44,
            43,
            41,
            48,
            48,
            '%'
        ],
        95201: [
            44,
            45,
            43,
            41,
            48,
            48,
            '%'
        ],
        96801: [
            45,
            45,
            43,
            41,
            48,
            48,
            '%'
        ],
        98401: [
            45,
            45,
            44,
            41,
            48,
            48,
            '%'
        ],
        99601: [
            45,
            45,
            44,
            42,
            48,
            48,
            '%'
        ],
        100801: [
            45,
            46,
            44,
            42,
            48,
            48,
            '%'
        ],
        102601: [
            46,
            46,
            44,
            42,
            48,
            48,
            '%'
        ],
        103001: [
            46,
            46,
            45,
            42,
            49,
            49,
            '%'
        ],
        107201: [
            46,
            47,
            45,
            42,
            49,
            49,
            '%'
        ],
        108201: [
            46,
            47,
            46,
            43,
            49,
            49,
            '%'
        ],
        109201: [
            47,
            47,
            46,
            43,
            49,
            49,
            '%'
        ],
        113801: [
            47,
            47,
            46,
            43,
            50,
            50,
            '%'
        ],
        114001: [
            47,
            47,
            47,
            43,
            50,
            50,
            '%'
        ],
        114401: [
            47,
            48,
            47,
            43,
            50,
            50,
            '%'
        ],
        116801: [
            48,
            48,
            47,
            43,
            50,
            50,
            '%'
        ],
        118401: [
            48,
            48,
            47,
            44,
            50,
            50,
            '%'
        ],
        120401: [
            48,
            48,
            48,
            44,
            50,
            50,
            '%'
        ],
        122601: [
            48,
            49,
            48,
            44,
            50,
            50,
            '%'
        ],
        125401: [
            49,
            49,
            48,
            44,
            50,
            50,
            '%'
        ],
        127201: [
            49,
            49,
            48,
            44,
            51,
            51,
            '%'
        ],
        127601: [
            49,
            49,
            49,
            44,
            51,
            51,
            '%'
        ],
        130601: [
            49,
            49,
            49,
            45,
            51,
            51,
            '%'
        ],
        132201: [
            49,
            50,
            49,
            45,
            51,
            51,
            '%'
        ],
        135401: [
            50,
            50,
            49,
            45,
            51,
            51,
            '%'
        ],
        135601: [
            50,
            50,
            50,
            45,
            51,
            51,
            '%'
        ],
        143201: [
            50,
            51,
            50,
            45,
            51,
            51,
            '%'
        ],
        144201: [
            50,
            51,
            50,
            45,
            52,
            52,
            '%'
        ],
        144601: [
            50,
            51,
            51,
            45,
            52,
            52,
            '%'
        ],
        145801: [
            50,
            51,
            51,
            46,
            52,
            52,
            '%'
        ],
        147201: [
            51,
            51,
            51,
            46,
            52,
            52,
            '%'
        ],
        156401: [
            51,
            52,
            52,
            46,
            52,
            52,
            '%'
        ],
        161201: [
            52,
            52,
            52,
            46,
            52,
            52,
            '%'
        ],
        164801: [
            52,
            52,
            52,
            47,
            52,
            52,
            '%'
        ],
        166401: [
            52,
            52,
            52,
            47,
            53,
            53,
            '%'
        ],
        172201: [
            52,
            53,
            53,
            47,
            53,
            53,
            '%'
        ],
        178201: [
            53,
            53,
            53,
            47,
            53,
            53,
            '%'
        ],
        189601: [
            53,
            53,
            53,
            48,
            53,
            53,
            '%'
        ],
        196801: [
            53,
            54,
            54,
            48,
            54,
            54,
            '%'
        ],
        199201: [
            54,
            54,
            54,
            48,
            54,
            54,
            '%'
        ],
        223001: [
            54,
            54,
            54,
            49,
            54,
            54,
            '%'
        ],
        240401: [
            55,
            55,
            55,
            49,
            55,
            55,
            '%'
        ],
        271001: [
            55,
            55,
            55,
            50,
            55,
            55,
            '%'
        ],
        309201: [
            56,
            56,
            56,
            50,
            56,
            56,
            '%'
        ],
        345201: [
            56,
            56,
            56,
            51,
            56,
            56,
            '%'
        ],
        433001: [
            57,
            57,
            57,
            51,
            57,
            57,
            '%'
        ],
        475001: [
            57,
            57,
            57,
            52,
            57,
            57,
            '%'
        ],
        721601: [
            58,
            58,
            58,
            52,
            58,
            58,
            '%'
        ],
        762001: [
            58,
            58,
            58,
            53,
            58,
            58,
            '%'
        ],
        1922601: [
            58,
            58,
            58,
            54,
            58,
            58,
            '%'
        ]
    },
    40: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            30
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            71
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            112
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            153
        ],
        2401: [
            185,
            0,
            175,
            10,
            194,
            194
        ],
        2501: [
            195,
            0,
            183,
            12,
            235,
            235
        ],
        2601: [
            206,
            0,
            191,
            14,
            276,
            276
        ],
        2701: [
            216,
            0,
            200,
            16,
            317,
            317
        ],
        2801: [
            218,
            0,
            200,
            18,
            358,
            358
        ],
        2901: [
            229,
            0,
            208,
            21,
            399,
            399
        ],
        3001: [
            239,
            0,
            216,
            23,
            440,
            440
        ],
        3101: [
            250,
            0,
            225,
            25,
            481,
            481
        ],
        3201: [
            260,
            0,
            233,
            27,
            522,
            522
        ],
        3301: [
            271,
            0,
            241,
            29,
            563,
            563
        ],
        3401: [
            273,
            0,
            241,
            31,
            604,
            604
        ],
        3501: [
            284,
            0,
            250,
            34,
            645,
            645
        ],
        3601: [
            294,
            0,
            258,
            36,
            686,
            686
        ],
        3701: [
            305,
            0,
            266,
            38,
            727,
            727
        ],
        3801: [
            315,
            0,
            275,
            40,
            768,
            768
        ],
        3901: [
            325,
            0,
            283,
            42,
            809,
            809
        ],
        4001: [
            328,
            0,
            283,
            44,
            850,
            850
        ],
        4101: [
            338,
            0,
            291,
            46,
            891,
            891
        ],
        4201: [
            349,
            0,
            300,
            49,
            932,
            932
        ],
        4301: [
            359,
            0,
            308,
            65,
            973,
            973
        ],
        4401: [
            370,
            0,
            316,
            93,
            1014,
            1014
        ],
        4501: [
            380,
            0,
            325,
            120,
            1055,
            1055
        ],
        4601: [
            382,
            0,
            325,
            148,
            1096,
            1096
        ],
        4701: [
            392,
            0,
            333,
            176,
            1130,
            1130
        ],
        4801: [
            403,
            0,
            341,
            203,
            1165,
            1165
        ],
        4901: [
            413,
            0,
            350,
            230,
            1199,
            1199
        ],
        5001: [
            423,
            0,
            358,
            258,
            1229,
            1229
        ],
        5101: [
            433,
            0,
            366,
            285,
            1264,
            1264
        ],
        5201: [
            443,
            0,
            375,
            312,
            1294,
            1294
        ],
        5301: [
            445,
            27,
            376,
            340,
            1329,
            1329
        ],
        5401: [
            455,
            58,
            386,
            367,
            1363,
            1363
        ],
        5501: [
            465,
            88,
            396,
            394,
            1393,
            1393
        ],
        5601: [
            475,
            119,
            406,
            421,
            1427,
            1427
        ],
        5701: [
            485,
            150,
            416,
            447,
            1457,
            1457
        ],
        5801: [
            495,
            181,
            426,
            474,
            1490,
            1490
        ],
        5901: [
            497,
            211,
            427,
            501,
            1524,
            1524
        ],
        6001: [
            507,
            242,
            437,
            528,
            1554,
            1554
        ],
        6101: [
            521,
            273,
            447,
            554,
            1588,
            1588
        ],
        6201: [
            546,
            300,
            457,
            581,
            1618,
            1618
        ],
        6301: [
            570,
            331,
            467,
            608,
            1651,
            1651
        ],
        6401: [
            595,
            362,
            477,
            635,
            1685,
            1685
        ],
        6501: [
            620,
            392,
            479,
            661,
            1715,
            1715
        ],
        6601: [
            645,
            423,
            488,
            688,
            1749,
            1749
        ],
        6701: [
            670,
            454,
            498,
            715,
            1779,
            1779
        ],
        6801: [
            695,
            485,
            508,
            742,
            1812,
            1812
        ],
        6901: [
            720,
            515,
            518,
            768,
            1846,
            1846
        ],
        7001: [
            745,
            546,
            528,
            795,
            1876,
            1876
        ],
        7101: [
            770,
            577,
            530,
            822,
            1910,
            1910
        ],
        7201: [
            795,
            608,
            540,
            848,
            1940,
            1940
        ],
        7301: [
            820,
            638,
            550,
            875,
            1973,
            1973
        ],
        7401: [
            845,
            666,
            560,
            902,
            2007,
            2007
        ],
        7501: [
            869,
            696,
            570,
            929,
            2037,
            2037
        ],
        7601: [
            894,
            727,
            580,
            956,
            2071,
            2071
        ],
        7701: [
            919,
            758,
            589,
            982,
            2101,
            2101
        ],
        7801: [
            944,
            789,
            591,
            1009,
            2134,
            2134
        ],
        7901: [
            969,
            819,
            601,
            1036,
            2168,
            2168
        ],
        8001: [
            994,
            850,
            611,
            1062,
            2198,
            2198
        ],
        8101: [
            1019,
            881,
            621,
            1089,
            2232,
            2232
        ],
        8201: [
            1044,
            912,
            631,
            1116,
            2262,
            2262
        ],
        8301: [
            1069,
            942,
            641,
            1143,
            2295,
            2295
        ],
        8401: [
            1094,
            973,
            642,
            1169,
            2329,
            2329
        ],
        8501: [
            1119,
            1004,
            652,
            1196,
            2359,
            2359
        ],
        8601: [
            1144,
            1031,
            662,
            1223,
            2393,
            2393
        ],
        8701: [
            1168,
            1062,
            672,
            1250,
            2423,
            2423
        ],
        8801: [
            1194,
            1093,
            682,
            1277,
            2456,
            2456
        ],
        8901: [
            1218,
            1124,
            692,
            1303,
            2490,
            2490
        ],
        9001: [
            1243,
            1154,
            694,
            1330,
            2520,
            2520
        ],
        9101: [
            1268,
            1185,
            704,
            1357,
            2554,
            2554
        ],
        9201: [
            1293,
            1216,
            714,
            1383,
            2584,
            2584
        ],
        9301: [
            1318,
            1236,
            723,
            1410,
            2617,
            2617
        ],
        9401: [
            1343,
            1264,
            733,
            1437,
            2651,
            2651
        ],
        9501: [
            1368,
            1288,
            742,
            1464,
            2681,
            2681
        ],
        9601: [
            1393,
            1311,
            744,
            1490,
            2715,
            2715
        ],
        9701: [
            1418,
            1335,
            753,
            1517,
            2745,
            2745
        ],
        9801: [
            1443,
            1363,
            763,
            1544,
            2778,
            2778
        ],
        9901: [
            1468,
            1386,
            773,
            1571,
            2812,
            2812
        ],
        10001: [
            1492,
            1410,
            782,
            1597,
            2842,
            2842
        ],
        10101: [
            1518,
            1433,
            792,
            1624,
            2876,
            2876
        ],
        10201: [
            1542,
            1460,
            802,
            1651,
            2906,
            2906
        ],
        10301: [
            1567,
            1484,
            803,
            1678,
            2939,
            2939
        ],
        10401: [
            1592,
            1507,
            812,
            1704,
            2973,
            2973
        ],
        10501: [
            1617,
            1531,
            822,
            1731,
            3003,
            3003
        ],
        10601: [
            1642,
            1557,
            832,
            1758,
            3037,
            3037
        ],
        10701: [
            1667,
            1581,
            841,
            1784,
            3067,
            3067
        ],
        10801: [
            1692,
            1604,
            851,
            1811,
            3100,
            3100
        ],
        10901: [
            1717,
            1628,
            852,
            1838,
            3134,
            3134
        ],
        11001: [
            1742,
            1655,
            862,
            1865,
            3164,
            3164
        ],
        11101: [
            1767,
            1678,
            872,
            1892,
            3198,
            3198
        ],
        11201: [
            1792,
            1702,
            881,
            1918,
            3228,
            3228
        ],
        11301: [
            1817,
            1728,
            891,
            1945,
            3261,
            3261
        ],
        11401: [
            1842,
            1752,
            901,
            1972,
            3295,
            3295
        ],
        11501: [
            1866,
            1775,
            902,
            1998,
            3325,
            3325
        ],
        11601: [
            1891,
            1799,
            911,
            2025,
            3359,
            3359
        ],
        11701: [
            1916,
            1826,
            921,
            2052,
            3389,
            3389
        ],
        11801: [
            1941,
            1849,
            931,
            2079,
            3422,
            3422
        ],
        11901: [
            1966,
            1873,
            940,
            2105,
            3456,
            3456
        ],
        12001: [
            1991,
            1896,
            950,
            2132,
            3486,
            3486
        ],
        12101: [
            2016,
            1923,
            951,
            2159,
            3520,
            3520
        ],
        12201: [
            2041,
            1946,
            961,
            2186,
            3550,
            3550
        ],
        12301: [
            2066,
            1970,
            971,
            2212,
            3583,
            3583
        ],
        12401: [
            2091,
            1993,
            980,
            2239,
            3617,
            3617
        ],
        12501: [
            2115,
            2020,
            990,
            2266,
            3647,
            3647
        ],
        12601: [
            2140,
            2044,
            1000,
            2293,
            3681,
            3681
        ],
        12701: [
            2165,
            2067,
            1009,
            2319,
            3711,
            3711
        ],
        12801: [
            2190,
            2091,
            1010,
            2346,
            3744,
            3744
        ],
        12901: [
            2215,
            2117,
            1020,
            2373,
            3774,
            3774
        ],
        13001: [
            2240,
            2141,
            1030,
            2400,
            3815,
            3815
        ],
        13101: [
            2266,
            2164,
            1039,
            2427,
            3855,
            3855
        ],
        13201: [
            2291,
            2191,
            1049,
            2454,
            3895,
            3895
        ],
        13301: [
            2316,
            2215,
            1059,
            2481,
            3935,
            3935
        ],
        13401: [
            2341,
            2238,
            1060,
            2508,
            3976,
            3976
        ],
        13501: [
            2366,
            2262,
            1070,
            2535,
            4016,
            4016
        ],
        13601: [
            2392,
            2289,
            1079,
            2562,
            4056,
            4056
        ],
        13701: [
            2417,
            2312,
            1089,
            2589,
            4096,
            4096
        ],
        13801: [
            2441,
            2336,
            1099,
            2615,
            4136,
            4136
        ],
        13901: [
            2466,
            2359,
            1108,
            2641,
            4175,
            4175
        ],
        14001: [
            2490,
            2386,
            1110,
            2668,
            4215,
            4215
        ],
        14101: [
            2514,
            2409,
            1119,
            2694,
            4254,
            4254
        ],
        14201: [
            2538,
            2433,
            1129,
            2720,
            4293,
            4293
        ],
        14301: [
            2562,
            2456,
            1138,
            2746,
            4332,
            4332
        ],
        14401: [
            2587,
            2483,
            1148,
            2772,
            4372,
            4372
        ],
        14501: [
            2611,
            2507,
            1158,
            2798,
            4411,
            4411
        ],
        14601: [
            2635,
            2530,
            1159,
            2824,
            4450,
            4450
        ],
        14701: [
            2659,
            2554,
            1169,
            2850,
            4489,
            4489
        ],
        14801: [
            2683,
            2580,
            1178,
            2876,
            4532,
            4532
        ],
        14901: [
            2708,
            2604,
            1188,
            2902,
            4574,
            4574
        ],
        15001: [
            2732,
            2627,
            1198,
            2928,
            4617,
            4617
        ],
        15101: [
            2756,
            2651,
            1207,
            2954,
            4659,
            4659
        ],
        15201: [
            2781,
            2678,
            1217,
            2980,
            4705,
            4705
        ],
        15301: [
            2805,
            2701,
            1218,
            3006,
            4748,
            4748
        ],
        15401: [
            2831,
            2728,
            1228,
            3035,
            4790,
            4790
        ],
        15501: [
            2864,
            2761,
            1238,
            3069,
            4833,
            4833
        ],
        15601: [
            2897,
            2795,
            1248,
            3104,
            4875,
            4875
        ],
        15701: [
            2930,
            2829,
            1258,
            3138,
            4921,
            4921
        ],
        15801: [
            2962,
            2862,
            1268,
            3172,
            4964,
            4964
        ],
        15901: [
            2995,
            2896,
            1270,
            3207,
            5006,
            5006
        ],
        16001: [
            3028,
            2929,
            1280,
            3241,
            5049,
            5049
        ],
        16101: [
            3061,
            2963,
            1291,
            3275,
            5091,
            5091
        ],
        16201: [
            3094,
            2996,
            1301,
            3310,
            5137,
            5137
        ],
        16301: [
            3127,
            3030,
            1311,
            3344,
            5180,
            5180
        ],
        16401: [
            3160,
            3063,
            1321,
            3378,
            5222,
            5222
        ],
        16501: [
            3193,
            3097,
            1323,
            3413,
            5265,
            5265
        ],
        16601: [
            3226,
            3130,
            1333,
            3447,
            5307,
            5307
        ],
        16701: [
            3259,
            3164,
            1343,
            3481,
            5353,
            5353
        ],
        16801: [
            3292,
            3198,
            1353,
            3516,
            5395,
            5395
        ],
        16901: [
            3324,
            3231,
            1363,
            3550,
            5438,
            5438
        ],
        17001: [
            3357,
            3261,
            1373,
            3584,
            5480,
            5480
        ],
        17101: [
            3390,
            3295,
            1375,
            3619,
            5523,
            5523
        ],
        17201: [
            3423,
            3328,
            1385,
            3653,
            5569,
            5569
        ],
        17301: [
            3456,
            3362,
            1395,
            3687,
            5611,
            5611
        ],
        17401: [
            3489,
            3395,
            1405,
            3722,
            5654,
            5654
        ],
        17501: [
            3522,
            3429,
            1416,
            3756,
            5696,
            5696
        ],
        17601: [
            3555,
            3463,
            1426,
            3790,
            5739,
            5739
        ],
        17701: [
            3588,
            3496,
            1436,
            3825,
            5785,
            5785
        ],
        17801: [
            3621,
            3530,
            1438,
            3859,
            5827,
            5827
        ],
        17901: [
            3653,
            3563,
            1448,
            3893,
            5870,
            5870
        ],
        18001: [
            3686,
            3597,
            1458,
            3928,
            5912,
            5912
        ],
        18101: [
            3719,
            3630,
            1468,
            3962,
            5955,
            5955
        ],
        18201: [
            3752,
            3664,
            1478,
            3996,
            6000,
            6000
        ],
        18301: [
            3785,
            3697,
            1488,
            4030,
            6043,
            6043
        ],
        18401: [
            3818,
            3731,
            1490,
            4065,
            6086,
            6086
        ],
        18501: [
            3851,
            3764,
            1500,
            4099,
            6128,
            6128
        ],
        18601: [
            3884,
            3798,
            1510,
            4133,
            6171,
            6171
        ],
        18701: [
            3917,
            3831,
            1520,
            4168,
            6216,
            6216
        ],
        18801: [
            3950,
            3865,
            1531,
            4202,
            6259,
            6259
        ],
        18901: [
            3983,
            3899,
            1541,
            4236,
            6301,
            6301
        ],
        19001: [
            4016,
            3932,
            1543,
            4271,
            6344,
            6344
        ],
        19101: [
            4048,
            3966,
            1553,
            4305,
            6386,
            6386
        ],
        19201: [
            4081,
            3999,
            1563,
            4339,
            6432,
            6432
        ],
        19301: [
            4114,
            4033,
            1573,
            4374,
            6475,
            6475
        ],
        19401: [
            4147,
            4066,
            1583,
            4408,
            6517,
            6517
        ],
        19501: [
            4180,
            4100,
            1593,
            4442,
            6560,
            6560
        ],
        19601: [
            4213,
            4133,
            1595,
            4477,
            6602,
            6602
        ],
        19701: [
            4246,
            4166,
            1604,
            4511,
            6648,
            6648
        ],
        19801: [
            4279,
            4198,
            1613,
            4545,
            6691,
            6691
        ],
        19901: [
            4312,
            4231,
            1622,
            4580,
            6733,
            6733
        ],
        20001: [
            4378,
            4296,
            1641,
            4648,
            6818,
            6818
        ],
        20201: [
            4443,
            4359,
            1672,
            4717,
            6907,
            6907
        ],
        20401: [
            4509,
            4424,
            1724,
            4785,
            6992,
            6992
        ],
        20601: [
            4575,
            4489,
            1775,
            4854,
            7080,
            7080
        ],
        20801: [
            4641,
            4555,
            1827,
            4923,
            7165,
            7165
        ],
        21001: [
            4707,
            4620,
            1878,
            4991,
            7250,
            7250
        ],
        21201: [
            4772,
            4686,
            1929,
            5060,
            7338,
            7338
        ],
        21401: [
            4838,
            4751,
            1981,
            5129,
            7423,
            7423
        ],
        21601: [
            4904,
            4816,
            2032,
            5197,
            7512,
            7512
        ],
        21801: [
            4970,
            4882,
            2084,
            5266,
            7597,
            7597
        ],
        22001: [
            5036,
            4947,
            2135,
            5335,
            7682,
            7682
        ],
        22201: [
            5102,
            5013,
            2187,
            5403,
            7770,
            7770
        ],
        22401: [
            5167,
            5078,
            2238,
            5472,
            7855,
            7855
        ],
        22601: [
            5233,
            5144,
            2289,
            5541,
            7943,
            7943
        ],
        22801: [
            5300,
            5209,
            2341,
            5610,
            8029,
            8029
        ],
        23001: [
            5367,
            5274,
            2392,
            5680,
            8116,
            8116
        ],
        23201: [
            5435,
            5340,
            2444,
            5751,
            8206,
            8206
        ],
        23401: [
            5502,
            5402,
            2492,
            5821,
            8293,
            8293
        ],
        23601: [
            5570,
            5467,
            2543,
            5891,
            8383,
            8383
        ],
        23801: [
            5637,
            5529,
            2591,
            5962,
            8469,
            8469
        ],
        24001: [
            5705,
            5598,
            2646,
            6032,
            8556,
            8556
        ],
        24201: [
            5772,
            5664,
            2697,
            6102,
            8646,
            8646
        ],
        24401: [
            5840,
            5732,
            2752,
            6172,
            8733,
            8733
        ],
        24601: [
            5907,
            5798,
            2804,
            6243,
            8823,
            8823
        ],
        24801: [
            5975,
            5863,
            2855,
            6313,
            8909,
            8909
        ],
        25001: [
            6042,
            5932,
            2927,
            6383,
            8996,
            8996
        ],
        25201: [
            6110,
            5997,
            2992,
            6454,
            9086,
            9086
        ],
        25401: [
            6177,
            6063,
            3058,
            6524,
            9173,
            9173
        ],
        25601: [
            6245,
            6131,
            3126,
            6594,
            9263,
            9263
        ],
        25801: [
            6312,
            6197,
            3192,
            6664,
            9349,
            9349
        ],
        26001: [
            6379,
            6262,
            3257,
            6735,
            9436,
            9436
        ],
        26201: [
            6447,
            6331,
            3326,
            6805,
            9526,
            9526
        ],
        26401: [
            6514,
            6396,
            3391,
            6875,
            9613,
            9613
        ],
        26601: [
            6582,
            6465,
            3460,
            6946,
            9703,
            9703
        ],
        26801: [
            6649,
            6530,
            3526,
            7016,
            9789,
            9789
        ],
        27001: [
            6717,
            6596,
            3591,
            7086,
            9876,
            9876
        ],
        27201: [
            6784,
            6664,
            3660,
            7156,
            9966,
            9966
        ],
        27401: [
            6852,
            6730,
            3725,
            7227,
            10053,
            10053
        ],
        27601: [
            6919,
            6795,
            3790,
            7297,
            10143,
            10143
        ],
        27801: [
            6987,
            6864,
            3859,
            7367,
            10229,
            10229
        ],
        28001: [
            7054,
            6929,
            3925,
            7438,
            10316,
            10316
        ],
        28201: [
            7122,
            6998,
            3993,
            7508,
            10406,
            10406
        ],
        28401: [
            7189,
            7064,
            4059,
            7578,
            10493,
            10493
        ],
        28601: [
            7257,
            7129,
            4124,
            7649,
            10583,
            10583
        ],
        28801: [
            7324,
            7198,
            4193,
            7719,
            10669,
            10669
        ],
        29001: [
            7391,
            7263,
            4258,
            7789,
            10756,
            10756
        ],
        29201: [
            7459,
            7328,
            4324,
            7859,
            10846,
            10846
        ],
        29401: [
            7526,
            7397,
            4392,
            7930,
            10933,
            10933
        ],
        29601: [
            7594,
            7463,
            4458,
            8000,
            11023,
            11023
        ],
        29801: [
            7661,
            7531,
            4526,
            8070,
            11109,
            11109
        ],
        30001: [
            7729,
            7597,
            4592,
            8140,
            11196,
            11196
        ],
        30201: [
            7796,
            7662,
            4657,
            8211,
            11286,
            11286
        ],
        30401: [
            7864,
            7731,
            4726,
            8281,
            11373,
            11373
        ],
        30601: [
            7931,
            7796,
            4791,
            8351,
            11463,
            11463
        ],
        30801: [
            7999,
            7862,
            4857,
            8422,
            11549,
            11549
        ],
        31001: [
            8066,
            7930,
            4925,
            8492,
            11636,
            11636
        ],
        31201: [
            8134,
            7996,
            4991,
            8562,
            11726,
            11726
        ],
        31401: [
            8201,
            8063,
            5058,
            8633,
            11813,
            11813
        ],
        31601: [
            8269,
            8133,
            5128,
            8703,
            11903,
            11903
        ],
        31801: [
            8336,
            8199,
            5195,
            8773,
            11989,
            11989
        ],
        32001: [
            8404,
            8269,
            5265,
            8843,
            12076,
            12076
        ],
        32201: [
            8471,
            8336,
            5331,
            8914,
            12166,
            12166
        ],
        32401: [
            8538,
            8403,
            5398,
            8984,
            12253,
            12253
        ],
        32601: [
            8606,
            8473,
            5468,
            9054,
            12343,
            12343
        ],
        32801: [
            8673,
            8539,
            5535,
            9125,
            12429,
            12429
        ],
        33001: [
            8741,
            8606,
            5601,
            9195,
            12516,
            12516
        ],
        33201: [
            8808,
            8676,
            5671,
            9265,
            12606,
            12606
        ],
        33401: [
            8876,
            8743,
            5738,
            9335,
            12693,
            12693
        ],
        33601: [
            8943,
            8813,
            5808,
            9406,
            12783,
            12783
        ],
        33801: [
            9011,
            8879,
            5875,
            9476,
            12869,
            12869
        ],
        34001: [
            9078,
            8946,
            5941,
            9546,
            12956,
            12956
        ],
        34201: [
            9146,
            9016,
            6011,
            9617,
            13046,
            13046
        ],
        34401: [
            9213,
            9083,
            6078,
            9687,
            13133,
            13133
        ],
        34601: [
            9281,
            9149,
            6145,
            9757,
            13223,
            13223
        ],
        34801: [
            9348,
            9219,
            6215,
            9828,
            13309,
            13309
        ],
        35001: [
            9416,
            9286,
            6281,
            9898,
            13396,
            13396
        ],
        35201: [
            9483,
            9353,
            6348,
            9968,
            13486,
            13486
        ],
        35401: [
            9551,
            9423,
            6418,
            10038,
            13573,
            13573
        ],
        35601: [
            9618,
            9489,
            6485,
            10109,
            13663,
            13663
        ],
        35801: [
            9685,
            9559,
            6555,
            10179,
            13749,
            13749
        ],
        36001: [
            9753,
            9626,
            6621,
            10249,
            13836,
            13836
        ],
        36201: [
            9820,
            9693,
            6688,
            10320,
            13926,
            13926
        ],
        36401: [
            9888,
            9763,
            6758,
            10390,
            14013,
            14013
        ],
        36601: [
            9955,
            9829,
            6825,
            10460,
            14103,
            14103
        ],
        36801: [
            10023,
            9896,
            6891,
            10530,
            14189,
            14189
        ],
        37001: [
            10090,
            9966,
            6961,
            10601,
            14276,
            14276
        ],
        37201: [
            10158,
            10033,
            7028,
            10671,
            14366,
            14366
        ],
        37401: [
            10225,
            10103,
            7098,
            10741,
            14453,
            14453
        ],
        37601: [
            10293,
            10166,
            7161,
            10811,
            14536,
            14536
        ],
        37801: [
            10360,
            10233,
            7228,
            10881,
            14616,
            14616
        ],
        38001: [
            10427,
            10299,
            7295,
            10951,
            14696,
            14696
        ],
        38201: [
            10494,
            10369,
            7365,
            11022,
            14776,
            14776
        ],
        38401: [
            10563,
            10436,
            7431,
            11092,
            14856,
            14856
        ],
        38601: [
            10643,
            10516,
            7511,
            11162,
            14936,
            14936
        ],
        38801: [
            10723,
            10596,
            7591,
            11232,
            15016,
            15016
        ],
        39001: [
            10803,
            10676,
            7671,
            11302,
            15096,
            15096
        ],
        39201: [
            10883,
            10756,
            7751,
            11372,
            15176,
            15176
        ],
        39401: [
            10963,
            10836,
            7831,
            11442,
            15256,
            15256
        ],
        39601: [
            11043,
            10916,
            7911,
            11512,
            15336,
            15336
        ],
        39801: [
            11123,
            10996,
            7991,
            11582,
            15416,
            15416
        ],
        40001: [
            11203,
            11076,
            8071,
            11652,
            15496,
            15496
        ],
        40201: [
            11283,
            11156,
            8151,
            11722,
            15576,
            15576
        ],
        40401: [
            11363,
            11236,
            8231,
            11792,
            15656,
            15656
        ],
        40601: [
            11443,
            11316,
            8311,
            11862,
            15736,
            15736
        ],
        40801: [
            11523,
            11396,
            8391,
            11932,
            15816,
            15816
        ],
        41001: [
            11603,
            11476,
            8471,
            12002,
            15896,
            15896
        ],
        41201: [
            11683,
            11556,
            8551,
            12072,
            15976,
            15976
        ],
        41401: [
            11763,
            11636,
            8631,
            12142,
            16056,
            16056
        ],
        41601: [
            11843,
            11716,
            8711,
            12212,
            16136,
            16136
        ],
        41801: [
            11923,
            11796,
            8791,
            12283,
            16216,
            16216
        ],
        42001: [
            12003,
            11876,
            8871,
            12353,
            16296,
            16296
        ],
        42201: [
            12083,
            11956,
            8951,
            12423,
            16376,
            16376
        ],
        42401: [
            12163,
            12036,
            9031,
            12493,
            16456,
            16456
        ],
        42601: [
            12243,
            12116,
            9111,
            12563,
            16536,
            16536
        ],
        42801: [
            12323,
            12196,
            9191,
            12633,
            16616,
            16616
        ],
        43001: [
            12403,
            12276,
            9271,
            12703,
            16696,
            16696
        ],
        43201: [
            12483,
            12356,
            9351,
            12773,
            16776,
            16776
        ],
        43401: [
            12563,
            12436,
            9431,
            12843,
            16856,
            16856
        ],
        43601: [
            12643,
            12516,
            9511,
            12913,
            16936,
            16936
        ],
        43801: [
            12723,
            12596,
            9591,
            12983,
            17016,
            17016
        ],
        44001: [
            12803,
            12676,
            9671,
            13053,
            17096,
            17096
        ],
        44201: [
            12883,
            12756,
            9751,
            13123,
            17176,
            17176
        ],
        44401: [
            12963,
            12836,
            9831,
            13193,
            17256,
            17256
        ],
        44601: [
            13043,
            12916,
            9911,
            13263,
            17336,
            17336
        ],
        44801: [
            13123,
            12996,
            9991,
            13333,
            17416,
            17416
        ],
        45001: [
            13203,
            13076,
            10071,
            13403,
            17496,
            17496
        ],
        45201: [
            13283,
            13156,
            10151,
            13474,
            17576,
            17576
        ],
        45401: [
            13363,
            13236,
            10231,
            13544,
            17656,
            17656
        ],
        45601: [
            13443,
            13316,
            10311,
            13614,
            17736,
            17736
        ],
        45801: [
            13523,
            13396,
            10391,
            13684,
            17816,
            17816
        ],
        46001: [
            13603,
            13476,
            10471,
            13754,
            17896,
            17896
        ],
        46201: [
            13683,
            13556,
            10551,
            13824,
            17976,
            17976
        ],
        46401: [
            13763,
            13636,
            10631,
            13894,
            18056,
            18056
        ],
        46601: [
            13843,
            13716,
            10711,
            13964,
            18136,
            18136
        ],
        46801: [
            13923,
            13796,
            10791,
            14034,
            18216,
            18216
        ],
        47001: [
            14003,
            13876,
            10871,
            14104,
            18296,
            18296
        ],
        47201: [
            14083,
            13956,
            10951,
            14174,
            18376,
            18376
        ],
        47401: [
            14163,
            14036,
            11031,
            14244,
            18456,
            18456
        ],
        47601: [
            14243,
            14116,
            11111,
            14314,
            18536,
            18536
        ],
        47801: [
            14323,
            14196,
            11191,
            14384,
            18616,
            18616
        ],
        48001: [
            14403,
            14276,
            11271,
            14454,
            18696,
            18696
        ],
        48201: [
            14483,
            14356,
            11351,
            14524,
            18776,
            18776
        ],
        48401: [
            14563,
            14436,
            11431,
            14594,
            18856,
            18856
        ],
        48601: [
            14643,
            14516,
            11511,
            14664,
            18936,
            18936
        ],
        48801: [
            14723,
            14596,
            11591,
            14735,
            19016,
            19016
        ],
        49001: [
            14803,
            14676,
            11671,
            14805,
            19096,
            19096
        ],
        49201: [
            14883,
            14756,
            11751,
            14875,
            19176,
            19176
        ],
        49401: [
            14963,
            14836,
            11831,
            14945,
            19256,
            19256
        ],
        49601: [
            15043,
            14916,
            11911,
            15015,
            19336,
            19336
        ],
        49801: [
            15123,
            14996,
            11991,
            15085,
            19416,
            19416
        ],
        50001: [
            15203,
            15076,
            12076,
            15155,
            19496,
            19496
        ],
        50201: [
            15283,
            15156,
            12162,
            15225,
            19576,
            19576
        ],
        50401: [
            15363,
            15236,
            12248,
            15295,
            19656,
            19656
        ],
        50601: [
            15443,
            15316,
            12334,
            15365,
            19736,
            19736
        ],
        50801: [
            15523,
            15396,
            12420,
            15435,
            19816,
            19816
        ],
        51001: [
            15603,
            15476,
            12506,
            15505,
            19896,
            19896
        ],
        51201: [
            15708,
            15586,
            12622,
            15600,
            20001,
            20001
        ],
        51401: [
            15828,
            15716,
            12758,
            15710,
            20121,
            20121
        ],
        51601: [
            15948,
            15846,
            12894,
            15820,
            20241,
            20241
        ],
        51801: [
            16068,
            15973,
            13027,
            15930,
            20361,
            20361
        ],
        52001: [
            16188,
            16103,
            13163,
            16040,
            20481,
            20481
        ],
        52201: [
            16308,
            16233,
            13299,
            16151,
            20601,
            20601
        ],
        52401: [
            16428,
            16363,
            13435,
            16261,
            20721,
            20721
        ],
        52601: [
            16548,
            16493,
            13571,
            16371,
            20841,
            20841
        ],
        52801: [
            16668,
            16623,
            13707,
            16481,
            20961,
            20961
        ],
        53001: [
            16788,
            16753,
            13843,
            16591,
            21081,
            21081
        ],
        53201: [
            16908,
            16883,
            13979,
            16701,
            21201,
            21201
        ],
        53401: [
            17028,
            17009,
            14111,
            16811,
            21321,
            21321
        ],
        53601: [
            17148,
            17139,
            14247,
            16921,
            21441,
            21441
        ],
        53801: [
            17268,
            17269,
            14383,
            17031,
            21561,
            21561
        ],
        54001: [
            17388,
            17399,
            14519,
            17141,
            21681,
            21681
        ],
        54201: [
            17508,
            17529,
            14655,
            17251,
            21801,
            21801
        ],
        54401: [
            17628,
            17659,
            14791,
            17361,
            21921,
            21921
        ],
        54601: [
            17748,
            17789,
            14927,
            17471,
            22041,
            22041
        ],
        54801: [
            17868,
            17919,
            15063,
            17581,
            22161,
            22161
        ],
        55001: [
            17988,
            18049,
            15199,
            17691,
            22281,
            22281
        ],
        55201: [
            18108,
            18176,
            15332,
            17801,
            22401,
            22401
        ],
        55401: [
            18228,
            18306,
            15468,
            17911,
            22521,
            22521
        ],
        55601: [
            18348,
            18436,
            15604,
            18021,
            22641,
            22641
        ],
        55801: [
            18468,
            18566,
            15740,
            18132,
            22761,
            22761
        ],
        56001: [
            18588,
            18696,
            15876,
            18242,
            22881,
            22881
        ],
        56201: [
            18708,
            18826,
            16012,
            18352,
            23001,
            23001
        ],
        56401: [
            18828,
            18956,
            16148,
            18462,
            23121,
            23121
        ],
        56601: [
            18948,
            19086,
            16284,
            18572,
            23241,
            23241
        ],
        56801: [
            19068,
            19213,
            16417,
            18682,
            23361,
            23361
        ],
        57001: [
            19188,
            19343,
            16553,
            18792,
            23481,
            23481
        ],
        57201: [
            19308,
            19473,
            16689,
            18902,
            23601,
            23601
        ],
        57401: [
            19428,
            19603,
            16825,
            19012,
            23721,
            23721
        ],
        57601: [
            19548,
            19733,
            16961,
            19122,
            23841,
            23841
        ],
        57801: [
            19668,
            19863,
            17097,
            19232,
            23961,
            23961
        ],
        58001: [
            19788,
            19981,
            17221,
            19342,
            24081,
            24081
        ],
        58201: [
            19908,
            20101,
            17347,
            19452,
            24201,
            24201
        ],
        58401: [
            20028,
            20221,
            17473,
            19562,
            24321,
            24321
        ],
        58601: [
            20148,
            20341,
            17599,
            19672,
            24441,
            24441
        ],
        58801: [
            20268,
            20461,
            17725,
            19782,
            24561,
            24561
        ],
        59001: [
            20388,
            20581,
            17851,
            19892,
            24681,
            24681
        ],
        59201: [
            20508,
            20701,
            17977,
            20003,
            24801,
            24801
        ],
        59401: [
            20628,
            20821,
            18103,
            20113,
            24921,
            24921
        ],
        59601: [
            20748,
            20941,
            18229,
            20223,
            25041,
            25041
        ],
        59801: [
            20868,
            21061,
            18355,
            20333,
            25161,
            25161
        ],
        60001: [
            20988,
            21181,
            18481,
            20443,
            25281,
            25281
        ],
        60201: [
            21108,
            21301,
            18607,
            20553,
            25401,
            25401
        ],
        60401: [
            21228,
            21421,
            18733,
            20663,
            25521,
            25521
        ],
        60601: [
            21348,
            21541,
            18859,
            20773,
            25641,
            25641
        ],
        60801: [
            21468,
            21661,
            18985,
            20883,
            25761,
            25761
        ],
        61001: [
            21588,
            21781,
            19111,
            20993,
            25881,
            25881
        ],
        61201: [
            21708,
            21901,
            19237,
            21103,
            26001,
            26001
        ],
        61401: [
            21828,
            22021,
            19363,
            21213,
            26121,
            26121
        ],
        61601: [
            21948,
            22141,
            19489,
            21323,
            26241,
            26241
        ],
        61801: [
            22068,
            22261,
            19615,
            21433,
            26361,
            26361
        ],
        62001: [
            22188,
            22381,
            19741,
            21543,
            26481,
            26481
        ],
        62201: [
            22308,
            22501,
            19867,
            21653,
            26601,
            26601
        ],
        62401: [
            22428,
            22621,
            19993,
            21763,
            26721,
            26721
        ],
        62601: [
            22548,
            22741,
            20119,
            21873,
            26841,
            26841
        ],
        62801: [
            22668,
            22861,
            20245,
            21984,
            26961,
            26961
        ],
        63001: [
            22788,
            22981,
            20371,
            22094,
            27081,
            27081
        ],
        63201: [
            22908,
            23101,
            20497,
            22204,
            27201,
            27201
        ],
        63401: [
            23028,
            23221,
            20623,
            22314,
            27321,
            27321
        ],
        63601: [
            23148,
            23341,
            20749,
            22424,
            27441,
            27441
        ],
        63801: [
            23268,
            23461,
            20875,
            22534,
            27561,
            27561
        ],
        64001: [
            23388,
            23581,
            21001,
            22644,
            27681,
            27681
        ],
        64201: [
            23508,
            23701,
            21127,
            22754,
            27801,
            27801
        ],
        64401: [
            23628,
            23821,
            21253,
            22864,
            27921,
            27921
        ],
        64601: [
            23752,
            23951,
            21389,
            22974,
            28041,
            28041
        ],
        64801: [
            23878,
            24076,
            21520,
            23084,
            28161,
            28161
        ],
        65001: [
            24004,
            24206,
            21656,
            23194,
            28281,
            28281
        ],
        65201: [
            24130,
            24331,
            21787,
            23304,
            28401,
            28401
        ],
        65401: [
            24256,
            24456,
            21918,
            23414,
            28521,
            28521
        ],
        65601: [
            24382,
            24586,
            22054,
            23524,
            28641,
            28641
        ],
        65801: [
            24508,
            24711,
            22185,
            23634,
            28761,
            28761
        ],
        66001: [
            24634,
            24841,
            22321,
            23744,
            28881,
            28881
        ],
        66201: [
            24760,
            24966,
            22452,
            23855,
            29001,
            29001
        ],
        66401: [
            24886,
            25091,
            22583,
            23965,
            29121,
            29121
        ],
        66601: [
            25012,
            25221,
            22719,
            24075,
            29241,
            29241
        ],
        66801: [
            25138,
            25346,
            22850,
            24185,
            29361,
            29361
        ],
        67001: [
            25264,
            25471,
            22981,
            24295,
            29481,
            29481
        ],
        67201: [
            25390,
            25601,
            23117,
            24405,
            29601,
            29601
        ],
        67401: [
            25516,
            25726,
            23248,
            24515,
            29721,
            29721
        ],
        67601: [
            25642,
            25856,
            23384,
            24625,
            29841,
            29841
        ],
        67801: [
            25768,
            25981,
            23515,
            24735,
            29961,
            29961
        ],
        68001: [
            25894,
            26106,
            23646,
            24845,
            30081,
            30081
        ],
        68201: [
            26020,
            26236,
            23782,
            24955,
            30201,
            30201
        ],
        68401: [
            26146,
            26361,
            23913,
            25065,
            30321,
            30321
        ],
        68601: [
            26272,
            26486,
            24044,
            25175,
            30441,
            30441
        ],
        68801: [
            26398,
            26616,
            24180,
            25285,
            30561,
            30561
        ],
        69001: [
            26524,
            26741,
            24311,
            25395,
            30681,
            30681
        ],
        69201: [
            26650,
            26871,
            24447,
            25505,
            30801,
            30801
        ],
        69401: [
            26776,
            26996,
            24578,
            25615,
            30921,
            30921
        ],
        69601: [
            26902,
            27121,
            24709,
            25725,
            31041,
            31041
        ],
        69801: [
            27028,
            27251,
            24845,
            25836,
            31161,
            31161
        ],
        70001: [
            27154,
            27376,
            24976,
            25946,
            31281,
            31281
        ],
        70201: [
            27280,
            27501,
            25107,
            26056,
            31401,
            31401
        ],
        70401: [
            27406,
            27631,
            25243,
            26166,
            31521,
            31521
        ],
        70601: [
            27532,
            27756,
            25374,
            26276,
            31641,
            31641
        ],
        70801: [
            27658,
            27886,
            25510,
            26386,
            31761,
            31761
        ],
        71001: [
            27784,
            28011,
            25641,
            26496,
            31881,
            31881
        ],
        71201: [
            27910,
            28136,
            25772,
            26606,
            32001,
            32001
        ],
        71401: [
            28036,
            28266,
            25908,
            26716,
            32121,
            32121
        ],
        71601: [
            28162,
            28391,
            26039,
            26826,
            32241,
            32241
        ],
        71801: [
            28288,
            28521,
            26175,
            26936,
            32361,
            32361
        ],
        72001: [
            28414,
            28646,
            26306,
            27046,
            32481,
            32481
        ],
        72201: [
            28540,
            28771,
            26437,
            27156,
            32601,
            32601
        ],
        72401: [
            28666,
            28901,
            26573,
            27266,
            32721,
            32721
        ],
        72601: [
            28792,
            29026,
            26704,
            27376,
            32841,
            32841
        ],
        72801: [
            28918,
            29151,
            26835,
            27486,
            32961,
            32961
        ],
        73001: [
            29044,
            29281,
            26971,
            27596,
            33081,
            33081
        ],
        73201: [
            29170,
            29406,
            27102,
            27706,
            33201,
            33201
        ],
        73401: [
            29296,
            29536,
            27238,
            27817,
            33321,
            33321
        ],
        73601: [
            29422,
            29661,
            27369,
            27927,
            33441,
            33441
        ],
        73801: [
            29548,
            29786,
            27500,
            28037,
            33561,
            33561
        ],
        74001: [
            29674,
            29916,
            27636,
            28147,
            33681,
            33681
        ],
        74201: [
            29800,
            30041,
            27767,
            28257,
            33801,
            33801
        ],
        74401: [
            29926,
            30166,
            27898,
            28367,
            33921,
            33921
        ],
        74601: [
            30052,
            30296,
            28034,
            28477,
            34041,
            34041
        ],
        74801: [
            30178,
            30421,
            28165,
            28587,
            34161,
            34161
        ],
        75001: [
            30304,
            30551,
            28301,
            28697,
            34281,
            34281
        ],
        75201: [
            30430,
            30676,
            28432,
            28807,
            34401,
            34401
        ],
        75401: [
            30556,
            30801,
            28563,
            28917,
            34521,
            34521
        ],
        75601: [
            30682,
            30931,
            28699,
            29027,
            34641,
            34641
        ],
        75801: [
            30808,
            31056,
            28830,
            29137,
            34761,
            34761
        ],
        76001: [
            30934,
            31181,
            28961,
            29247,
            34881,
            34881
        ],
        76201: [
            31060,
            31311,
            29097,
            29357,
            35001,
            35001
        ],
        76401: [
            31186,
            31436,
            29228,
            29467,
            35121,
            35121
        ],
        76601: [
            31312,
            31566,
            29364,
            29577,
            35241,
            35241
        ],
        76801: [
            31438,
            31691,
            29495,
            29688,
            35361,
            35361
        ],
        77001: [
            31564,
            31816,
            29626,
            29798,
            35481,
            35481
        ],
        77201: [
            31690,
            31946,
            29762,
            29908,
            35601,
            35601
        ],
        77401: [
            31816,
            32071,
            29893,
            30018,
            35721,
            35721
        ],
        77601: [
            31942,
            32196,
            30024,
            30128,
            35841,
            35841
        ],
        77801: [
            32068,
            32326,
            30160,
            30238,
            35961,
            35961
        ],
        78001: [
            32194,
            32451,
            30291,
            30348,
            36081,
            36081
        ],
        78201: [
            32320,
            32581,
            30427,
            30458,
            36201,
            36201
        ],
        78401: [
            32446,
            32706,
            30558,
            30568,
            36321,
            36321
        ],
        78601: [
            32572,
            32831,
            30689,
            30678,
            36441,
            36441
        ],
        78801: [
            32698,
            32961,
            30825,
            30788,
            36561,
            36561
        ],
        79001: [
            32824,
            33086,
            30956,
            30898,
            36681,
            36681
        ],
        79201: [
            32950,
            33211,
            31087,
            31008,
            36801,
            36801
        ],
        79401: [
            33076,
            33341,
            31223,
            31118,
            36921,
            36921
        ],
        79601: [
            33202,
            33466,
            31354,
            31228,
            37041,
            37041
        ],
        79801: [
            33328,
            33596,
            31490,
            31338,
            37161,
            37161
        ],
        80001: [
            42,
            42,
            39,
            39,
            46,
            46,
            '%'
        ],
        80201: [
            42,
            42,
            39,
            39,
            47,
            47,
            '%'
        ],
        80401: [
            42,
            42,
            40,
            39,
            47,
            47,
            '%'
        ],
        81601: [
            42,
            42,
            40,
            40,
            47,
            47,
            '%'
        ],
        81801: [
            42,
            43,
            40,
            40,
            47,
            47,
            '%'
        ],
        83201: [
            43,
            43,
            40,
            40,
            47,
            47,
            '%'
        ],
        83401: [
            43,
            43,
            41,
            40,
            47,
            47,
            '%'
        ],
        86001: [
            43,
            44,
            41,
            40,
            47,
            47,
            '%'
        ],
        86601: [
            43,
            44,
            41,
            40,
            48,
            48,
            '%'
        ],
        86801: [
            43,
            44,
            42,
            40,
            48,
            48,
            '%'
        ],
        87201: [
            43,
            44,
            42,
            41,
            48,
            48,
            '%'
        ],
        87401: [
            44,
            44,
            42,
            41,
            48,
            48,
            '%'
        ],
        90401: [
            44,
            45,
            43,
            41,
            48,
            48,
            '%'
        ],
        92201: [
            45,
            45,
            43,
            41,
            48,
            48,
            '%'
        ],
        93601: [
            45,
            45,
            43,
            42,
            48,
            48,
            '%'
        ],
        94201: [
            45,
            45,
            43,
            42,
            49,
            49,
            '%'
        ],
        94401: [
            45,
            45,
            44,
            42,
            49,
            49,
            '%'
        ],
        95601: [
            45,
            46,
            44,
            42,
            49,
            49,
            '%'
        ],
        97401: [
            46,
            46,
            44,
            42,
            49,
            49,
            '%'
        ],
        98601: [
            46,
            46,
            45,
            42,
            49,
            49,
            '%'
        ],
        101201: [
            46,
            47,
            45,
            43,
            49,
            49,
            '%'
        ],
        103201: [
            46,
            47,
            45,
            43,
            50,
            50,
            '%'
        ],
        103401: [
            47,
            47,
            46,
            43,
            50,
            50,
            '%'
        ],
        107601: [
            47,
            48,
            46,
            43,
            50,
            50,
            '%'
        ],
        108601: [
            47,
            48,
            47,
            43,
            50,
            50,
            '%'
        ],
        110001: [
            48,
            48,
            47,
            44,
            50,
            50,
            '%'
        ],
        114001: [
            48,
            48,
            47,
            44,
            51,
            51,
            '%'
        ],
        114201: [
            48,
            48,
            48,
            44,
            51,
            51,
            '%'
        ],
        114801: [
            48,
            49,
            48,
            44,
            51,
            51,
            '%'
        ],
        117601: [
            49,
            49,
            48,
            44,
            51,
            51,
            '%'
        ],
        120401: [
            49,
            49,
            48,
            45,
            51,
            51,
            '%'
        ],
        120601: [
            49,
            49,
            49,
            45,
            51,
            51,
            '%'
        ],
        123001: [
            49,
            50,
            49,
            45,
            51,
            51,
            '%'
        ],
        126401: [
            50,
            50,
            49,
            45,
            51,
            51,
            '%'
        ],
        127401: [
            50,
            50,
            49,
            45,
            52,
            52,
            '%'
        ],
        127801: [
            50,
            50,
            50,
            45,
            52,
            52,
            '%'
        ],
        132401: [
            50,
            51,
            50,
            45,
            52,
            52,
            '%'
        ],
        133001: [
            50,
            51,
            50,
            46,
            52,
            52,
            '%'
        ],
        135801: [
            50,
            51,
            51,
            46,
            52,
            52,
            '%'
        ],
        136401: [
            51,
            51,
            51,
            46,
            52,
            52,
            '%'
        ],
        143601: [
            51,
            52,
            51,
            46,
            52,
            52,
            '%'
        ],
        144401: [
            51,
            52,
            51,
            46,
            53,
            53,
            '%'
        ],
        144801: [
            51,
            52,
            52,
            46,
            53,
            53,
            '%'
        ],
        148401: [
            52,
            52,
            52,
            46,
            53,
            53,
            '%'
        ],
        148601: [
            52,
            52,
            52,
            47,
            53,
            53,
            '%'
        ],
        156601: [
            52,
            53,
            53,
            47,
            53,
            53,
            '%'
        ],
        162401: [
            53,
            53,
            53,
            47,
            53,
            53,
            '%'
        ],
        166601: [
            53,
            53,
            53,
            47,
            54,
            54,
            '%'
        ],
        168401: [
            53,
            53,
            53,
            48,
            54,
            54,
            '%'
        ],
        172401: [
            53,
            54,
            54,
            48,
            54,
            54,
            '%'
        ],
        179601: [
            54,
            54,
            54,
            48,
            54,
            54,
            '%'
        ],
        194201: [
            54,
            54,
            54,
            49,
            54,
            54,
            '%'
        ],
        197001: [
            54,
            55,
            55,
            49,
            55,
            55,
            '%'
        ],
        200801: [
            55,
            55,
            55,
            49,
            55,
            55,
            '%'
        ],
        229401: [
            55,
            55,
            55,
            50,
            55,
            55,
            '%'
        ],
        240801: [
            56,
            56,
            56,
            50,
            56,
            56,
            '%'
        ],
        280001: [
            56,
            56,
            56,
            51,
            56,
            56,
            '%'
        ],
        309601: [
            57,
            57,
            57,
            51,
            57,
            57,
            '%'
        ],
        359401: [
            57,
            57,
            57,
            52,
            57,
            57,
            '%'
        ],
        433401: [
            58,
            58,
            58,
            52,
            58,
            58,
            '%'
        ],
        501601: [
            58,
            58,
            58,
            53,
            58,
            58,
            '%'
        ],
        722401: [
            59,
            59,
            59,
            53,
            59,
            59,
            '%'
        ],
        829801: [
            59,
            59,
            59,
            54,
            59,
            59,
            '%'
        ]
    },
    41: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            31
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            73
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            115
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            157
        ],
        2401: [
            185,
            0,
            175,
            10,
            199,
            199
        ],
        2501: [
            195,
            0,
            183,
            12,
            241,
            241
        ],
        2601: [
            206,
            0,
            191,
            14,
            283,
            283
        ],
        2701: [
            216,
            0,
            200,
            16,
            325,
            325
        ],
        2801: [
            218,
            0,
            200,
            18,
            367,
            367
        ],
        2901: [
            229,
            0,
            208,
            21,
            409,
            409
        ],
        3001: [
            239,
            0,
            216,
            23,
            451,
            451
        ],
        3101: [
            250,
            0,
            225,
            25,
            493,
            493
        ],
        3201: [
            260,
            0,
            233,
            27,
            535,
            535
        ],
        3301: [
            271,
            0,
            241,
            29,
            577,
            577
        ],
        3401: [
            273,
            0,
            241,
            31,
            619,
            619
        ],
        3501: [
            284,
            0,
            250,
            34,
            661,
            661
        ],
        3601: [
            294,
            0,
            258,
            36,
            703,
            703
        ],
        3701: [
            305,
            0,
            266,
            38,
            745,
            745
        ],
        3801: [
            315,
            0,
            275,
            40,
            787,
            787
        ],
        3901: [
            325,
            0,
            283,
            42,
            829,
            829
        ],
        4001: [
            328,
            0,
            283,
            44,
            871,
            871
        ],
        4101: [
            338,
            0,
            291,
            46,
            913,
            913
        ],
        4201: [
            349,
            0,
            300,
            49,
            955,
            955
        ],
        4301: [
            359,
            0,
            308,
            65,
            997,
            997
        ],
        4401: [
            370,
            0,
            316,
            94,
            1039,
            1039
        ],
        4501: [
            380,
            0,
            325,
            122,
            1081,
            1081
        ],
        4601: [
            382,
            0,
            325,
            150,
            1123,
            1123
        ],
        4701: [
            392,
            0,
            333,
            179,
            1158,
            1158
        ],
        4801: [
            403,
            0,
            341,
            207,
            1193,
            1193
        ],
        4901: [
            413,
            0,
            350,
            235,
            1228,
            1228
        ],
        5001: [
            423,
            0,
            358,
            263,
            1259,
            1259
        ],
        5101: [
            433,
            0,
            366,
            291,
            1294,
            1294
        ],
        5201: [
            443,
            0,
            375,
            319,
            1326,
            1326
        ],
        5301: [
            445,
            27,
            376,
            347,
            1361,
            1361
        ],
        5401: [
            455,
            59,
            386,
            375,
            1396,
            1396
        ],
        5501: [
            465,
            90,
            396,
            402,
            1427,
            1427
        ],
        5601: [
            475,
            122,
            406,
            430,
            1461,
            1461
        ],
        5701: [
            485,
            153,
            416,
            457,
            1492,
            1492
        ],
        5801: [
            495,
            185,
            426,
            484,
            1527,
            1527
        ],
        5901: [
            497,
            216,
            427,
            512,
            1561,
            1561
        ],
        6001: [
            507,
            248,
            437,
            539,
            1592,
            1592
        ],
        6101: [
            532,
            279,
            447,
            567,
            1626,
            1626
        ],
        6201: [
            557,
            307,
            457,
            594,
            1657,
            1657
        ],
        6301: [
            583,
            339,
            467,
            621,
            1692,
            1692
        ],
        6401: [
            609,
            370,
            477,
            649,
            1726,
            1726
        ],
        6501: [
            634,
            402,
            479,
            676,
            1757,
            1757
        ],
        6601: [
            660,
            433,
            488,
            704,
            1791,
            1791
        ],
        6701: [
            685,
            465,
            498,
            731,
            1822,
            1822
        ],
        6801: [
            711,
            496,
            508,
            758,
            1857,
            1857
        ],
        6901: [
            736,
            528,
            518,
            786,
            1891,
            1891
        ],
        7001: [
            762,
            559,
            528,
            813,
            1922,
            1922
        ],
        7101: [
            787,
            591,
            530,
            841,
            1956,
            1956
        ],
        7201: [
            813,
            622,
            540,
            868,
            1987,
            1987
        ],
        7301: [
            838,
            654,
            550,
            895,
            2022,
            2022
        ],
        7401: [
            864,
            682,
            560,
            923,
            2056,
            2056
        ],
        7501: [
            889,
            713,
            570,
            950,
            2087,
            2087
        ],
        7601: [
            915,
            745,
            580,
            978,
            2121,
            2121
        ],
        7701: [
            940,
            776,
            589,
            1005,
            2152,
            2152
        ],
        7801: [
            966,
            808,
            591,
            1032,
            2187,
            2187
        ],
        7901: [
            992,
            839,
            601,
            1060,
            2221,
            2221
        ],
        8001: [
            1017,
            871,
            611,
            1087,
            2252,
            2252
        ],
        8101: [
            1043,
            902,
            621,
            1115,
            2286,
            2286
        ],
        8201: [
            1068,
            934,
            631,
            1142,
            2317,
            2317
        ],
        8301: [
            1094,
            965,
            641,
            1169,
            2352,
            2352
        ],
        8401: [
            1119,
            997,
            642,
            1197,
            2386,
            2386
        ],
        8501: [
            1145,
            1028,
            652,
            1224,
            2417,
            2417
        ],
        8601: [
            1170,
            1056,
            662,
            1252,
            2451,
            2451
        ],
        8701: [
            1196,
            1088,
            672,
            1279,
            2482,
            2482
        ],
        8801: [
            1221,
            1119,
            682,
            1307,
            2517,
            2517
        ],
        8901: [
            1247,
            1151,
            692,
            1334,
            2551,
            2551
        ],
        9001: [
            1272,
            1182,
            694,
            1361,
            2582,
            2582
        ],
        9101: [
            1298,
            1214,
            704,
            1389,
            2616,
            2616
        ],
        9201: [
            1323,
            1245,
            714,
            1416,
            2647,
            2647
        ],
        9301: [
            1349,
            1266,
            723,
            1443,
            2682,
            2682
        ],
        9401: [
            1375,
            1294,
            733,
            1471,
            2716,
            2716
        ],
        9501: [
            1400,
            1319,
            742,
            1498,
            2747,
            2747
        ],
        9601: [
            1426,
            1343,
            744,
            1526,
            2781,
            2781
        ],
        9701: [
            1451,
            1368,
            753,
            1553,
            2812,
            2812
        ],
        9801: [
            1477,
            1396,
            763,
            1581,
            2847,
            2847
        ],
        9901: [
            1502,
            1420,
            773,
            1608,
            2881,
            2881
        ],
        10001: [
            1528,
            1444,
            782,
            1635,
            2912,
            2912
        ],
        10101: [
            1553,
            1468,
            792,
            1663,
            2946,
            2946
        ],
        10201: [
            1579,
            1496,
            802,
            1690,
            2977,
            2977
        ],
        10301: [
            1604,
            1520,
            803,
            1718,
            3012,
            3012
        ],
        10401: [
            1630,
            1544,
            812,
            1745,
            3046,
            3046
        ],
        10501: [
            1655,
            1568,
            822,
            1772,
            3077,
            3077
        ],
        10601: [
            1681,
            1595,
            832,
            1800,
            3111,
            3111
        ],
        10701: [
            1706,
            1619,
            841,
            1827,
            3142,
            3142
        ],
        10801: [
            1732,
            1644,
            851,
            1855,
            3177,
            3177
        ],
        10901: [
            1758,
            1668,
            852,
            1882,
            3211,
            3211
        ],
        11001: [
            1783,
            1695,
            862,
            1909,
            3242,
            3242
        ],
        11101: [
            1809,
            1719,
            872,
            1937,
            3276,
            3276
        ],
        11201: [
            1834,
            1743,
            881,
            1964,
            3307,
            3307
        ],
        11301: [
            1860,
            1771,
            891,
            1992,
            3342,
            3342
        ],
        11401: [
            1885,
            1795,
            901,
            2019,
            3376,
            3376
        ],
        11501: [
            1911,
            1819,
            902,
            2046,
            3407,
            3407
        ],
        11601: [
            1936,
            1843,
            911,
            2074,
            3441,
            3441
        ],
        11701: [
            1962,
            1870,
            921,
            2101,
            3472,
            3472
        ],
        11801: [
            1987,
            1894,
            931,
            2129,
            3507,
            3507
        ],
        11901: [
            2013,
            1919,
            940,
            2156,
            3541,
            3541
        ],
        12001: [
            2038,
            1943,
            950,
            2183,
            3572,
            3572
        ],
        12101: [
            2064,
            1970,
            951,
            2211,
            3606,
            3606
        ],
        12201: [
            2089,
            1994,
            961,
            2238,
            3637,
            3637
        ],
        12301: [
            2115,
            2018,
            971,
            2266,
            3672,
            3672
        ],
        12401: [
            2141,
            2042,
            980,
            2293,
            3706,
            3706
        ],
        12501: [
            2166,
            2070,
            990,
            2320,
            3737,
            3737
        ],
        12601: [
            2192,
            2094,
            1000,
            2348,
            3771,
            3771
        ],
        12701: [
            2217,
            2118,
            1009,
            2375,
            3802,
            3802
        ],
        12801: [
            2243,
            2142,
            1010,
            2403,
            3837,
            3837
        ],
        12901: [
            2268,
            2169,
            1020,
            2430,
            3868,
            3868
        ],
        13001: [
            2294,
            2194,
            1030,
            2458,
            3909,
            3909
        ],
        13101: [
            2320,
            2218,
            1039,
            2485,
            3950,
            3950
        ],
        13201: [
            2346,
            2245,
            1049,
            2513,
            3991,
            3991
        ],
        13301: [
            2371,
            2269,
            1059,
            2541,
            4033,
            4033
        ],
        13401: [
            2397,
            2293,
            1060,
            2568,
            4074,
            4074
        ],
        13501: [
            2423,
            2317,
            1070,
            2596,
            4115,
            4115
        ],
        13601: [
            2449,
            2345,
            1079,
            2624,
            4156,
            4156
        ],
        13701: [
            2475,
            2369,
            1089,
            2651,
            4198,
            4198
        ],
        13801: [
            2500,
            2393,
            1099,
            2678,
            4238,
            4238
        ],
        13901: [
            2525,
            2417,
            1108,
            2705,
            4279,
            4279
        ],
        14001: [
            2550,
            2444,
            1110,
            2732,
            4319,
            4319
        ],
        14101: [
            2574,
            2469,
            1119,
            2759,
            4359,
            4359
        ],
        14201: [
            2599,
            2493,
            1129,
            2785,
            4399,
            4399
        ],
        14301: [
            2624,
            2517,
            1138,
            2812,
            4440,
            4440
        ],
        14401: [
            2649,
            2544,
            1148,
            2839,
            4480,
            4480
        ],
        14501: [
            2674,
            2568,
            1158,
            2865,
            4520,
            4520
        ],
        14601: [
            2698,
            2592,
            1159,
            2892,
            4560,
            4560
        ],
        14701: [
            2723,
            2616,
            1169,
            2919,
            4601,
            4601
        ],
        14801: [
            2748,
            2644,
            1178,
            2945,
            4644,
            4644
        ],
        14901: [
            2773,
            2668,
            1188,
            2972,
            4688,
            4688
        ],
        15001: [
            2798,
            2692,
            1198,
            2999,
            4731,
            4731
        ],
        15101: [
            2823,
            2716,
            1207,
            3026,
            4775,
            4775
        ],
        15201: [
            2848,
            2744,
            1217,
            3052,
            4822,
            4822
        ],
        15301: [
            2872,
            2768,
            1218,
            3079,
            4866,
            4866
        ],
        15401: [
            2899,
            2795,
            1228,
            3109,
            4909,
            4909
        ],
        15501: [
            2933,
            2829,
            1238,
            3144,
            4953,
            4953
        ],
        15601: [
            2967,
            2864,
            1248,
            3179,
            4996,
            4996
        ],
        15701: [
            3000,
            2898,
            1258,
            3214,
            5043,
            5043
        ],
        15801: [
            3034,
            2933,
            1268,
            3250,
            5087,
            5087
        ],
        15901: [
            3068,
            2967,
            1270,
            3285,
            5130,
            5130
        ],
        16001: [
            3102,
            3001,
            1280,
            3320,
            5174,
            5174
        ],
        16101: [
            3135,
            3036,
            1291,
            3355,
            5218,
            5218
        ],
        16201: [
            3169,
            3070,
            1301,
            3390,
            5265,
            5265
        ],
        16301: [
            3203,
            3104,
            1311,
            3426,
            5308,
            5308
        ],
        16401: [
            3237,
            3139,
            1321,
            3461,
            5352,
            5352
        ],
        16501: [
            3270,
            3173,
            1323,
            3496,
            5395,
            5395
        ],
        16601: [
            3304,
            3208,
            1333,
            3531,
            5439,
            5439
        ],
        16701: [
            3338,
            3242,
            1343,
            3566,
            5486,
            5486
        ],
        16801: [
            3372,
            3276,
            1353,
            3602,
            5530,
            5530
        ],
        16901: [
            3405,
            3311,
            1363,
            3637,
            5573,
            5573
        ],
        17001: [
            3439,
            3342,
            1373,
            3672,
            5617,
            5617
        ],
        17101: [
            3473,
            3376,
            1375,
            3707,
            5661,
            5661
        ],
        17201: [
            3507,
            3410,
            1385,
            3742,
            5707,
            5707
        ],
        17301: [
            3540,
            3445,
            1395,
            3778,
            5751,
            5751
        ],
        17401: [
            3574,
            3479,
            1405,
            3813,
            5795,
            5795
        ],
        17501: [
            3608,
            3514,
            1416,
            3848,
            5838,
            5838
        ],
        17601: [
            3642,
            3548,
            1426,
            3883,
            5882,
            5882
        ],
        17701: [
            3675,
            3582,
            1436,
            3918,
            5929,
            5929
        ],
        17801: [
            3709,
            3617,
            1438,
            3953,
            5972,
            5972
        ],
        17901: [
            3743,
            3651,
            1448,
            3989,
            6016,
            6016
        ],
        18001: [
            3777,
            3685,
            1458,
            4024,
            6060,
            6060
        ],
        18101: [
            3810,
            3720,
            1468,
            4059,
            6103,
            6103
        ],
        18201: [
            3844,
            3754,
            1478,
            4094,
            6150,
            6150
        ],
        18301: [
            3878,
            3789,
            1488,
            4129,
            6194,
            6194
        ],
        18401: [
            3912,
            3823,
            1490,
            4165,
            6237,
            6237
        ],
        18501: [
            3945,
            3857,
            1500,
            4200,
            6281,
            6281
        ],
        18601: [
            3979,
            3892,
            1510,
            4235,
            6325,
            6325
        ],
        18701: [
            4013,
            3926,
            1520,
            4270,
            6372,
            6372
        ],
        18801: [
            4047,
            3960,
            1531,
            4305,
            6415,
            6415
        ],
        18901: [
            4080,
            3995,
            1541,
            4341,
            6459,
            6459
        ],
        19001: [
            4114,
            4029,
            1543,
            4376,
            6502,
            6502
        ],
        19101: [
            4148,
            4064,
            1553,
            4411,
            6546,
            6546
        ],
        19201: [
            4181,
            4098,
            1563,
            4446,
            6593,
            6593
        ],
        19301: [
            4215,
            4132,
            1573,
            4481,
            6637,
            6637
        ],
        19401: [
            4249,
            4167,
            1583,
            4517,
            6680,
            6680
        ],
        19501: [
            4283,
            4201,
            1593,
            4552,
            6724,
            6724
        ],
        19601: [
            4316,
            4235,
            1598,
            4587,
            6767,
            6767
        ],
        19701: [
            4350,
            4268,
            1624,
            4622,
            6814,
            6814
        ],
        19801: [
            4384,
            4302,
            1651,
            4657,
            6858,
            6858
        ],
        19901: [
            4418,
            4336,
            1677,
            4692,
            6901,
            6901
        ],
        20001: [
            4485,
            4403,
            1731,
            4763,
            6989,
            6989
        ],
        20201: [
            4553,
            4466,
            1780,
            4833,
            7079,
            7079
        ],
        20401: [
            4620,
            4533,
            1833,
            4904,
            7166,
            7166
        ],
        20601: [
            4688,
            4601,
            1886,
            4974,
            7257,
            7257
        ],
        20801: [
            4755,
            4668,
            1939,
            5044,
            7344,
            7344
        ],
        21001: [
            4823,
            4735,
            1993,
            5115,
            7431,
            7431
        ],
        21201: [
            4890,
            4802,
            2046,
            5185,
            7522,
            7522
        ],
        21401: [
            4958,
            4869,
            2099,
            5256,
            7609,
            7609
        ],
        21601: [
            5025,
            4936,
            2152,
            5326,
            7700,
            7700
        ],
        21801: [
            5093,
            5003,
            2205,
            5396,
            7787,
            7787
        ],
        22001: [
            5160,
            5070,
            2258,
            5467,
            7874,
            7874
        ],
        22201: [
            5228,
            5137,
            2311,
            5537,
            7965,
            7965
        ],
        22401: [
            5295,
            5204,
            2364,
            5607,
            8052,
            8052
        ],
        22601: [
            5363,
            5271,
            2417,
            5678,
            8143,
            8143
        ],
        22801: [
            5431,
            5338,
            2470,
            5749,
            8231,
            8231
        ],
        23001: [
            5500,
            5406,
            2523,
            5821,
            8320,
            8320
        ],
        23201: [
            5569,
            5473,
            2576,
            5893,
            8412,
            8412
        ],
        23401: [
            5638,
            5536,
            2626,
            5965,
            8501,
            8501
        ],
        23601: [
            5708,
            5603,
            2679,
            6037,
            8593,
            8593
        ],
        23801: [
            5777,
            5667,
            2729,
            6109,
            8682,
            8682
        ],
        24001: [
            5846,
            5738,
            2785,
            6181,
            8771,
            8771
        ],
        24201: [
            5915,
            5805,
            2839,
            6253,
            8863,
            8863
        ],
        24401: [
            5984,
            5875,
            2895,
            6325,
            8952,
            8952
        ],
        24601: [
            6053,
            5942,
            2948,
            6398,
            9044,
            9044
        ],
        24801: [
            6122,
            6009,
            3001,
            6469,
            9133,
            9133
        ],
        25001: [
            6192,
            6080,
            3075,
            6541,
            9222,
            9222
        ],
        25201: [
            6261,
            6147,
            3142,
            6614,
            9314,
            9314
        ],
        25401: [
            6330,
            6214,
            3209,
            6686,
            9403,
            9403
        ],
        25601: [
            6399,
            6284,
            3279,
            6758,
            9495,
            9495
        ],
        25801: [
            6468,
            6351,
            3347,
            6830,
            9584,
            9584
        ],
        26001: [
            6537,
            6418,
            3414,
            6902,
            9673,
            9673
        ],
        26201: [
            6606,
            6489,
            3484,
            6974,
            9765,
            9765
        ],
        26401: [
            6676,
            6556,
            3551,
            7046,
            9854,
            9854
        ],
        26601: [
            6745,
            6626,
            3622,
            7118,
            9946,
            9946
        ],
        26801: [
            6814,
            6694,
            3689,
            7190,
            10035,
            10035
        ],
        27001: [
            6883,
            6761,
            3756,
            7262,
            10124,
            10124
        ],
        27201: [
            6952,
            6831,
            3826,
            7334,
            10216,
            10216
        ],
        27401: [
            7021,
            6898,
            3893,
            7406,
            10305,
            10305
        ],
        27601: [
            7091,
            6965,
            3960,
            7478,
            10397,
            10397
        ],
        27801: [
            7160,
            7036,
            4031,
            7550,
            10486,
            10486
        ],
        28001: [
            7229,
            7103,
            4098,
            7622,
            10575,
            10575
        ],
        28201: [
            7298,
            7173,
            4168,
            7694,
            10667,
            10667
        ],
        28401: [
            7367,
            7240,
            4235,
            7766,
            10756,
            10756
        ],
        28601: [
            7436,
            7307,
            4303,
            7838,
            10848,
            10848
        ],
        28801: [
            7505,
            7378,
            4373,
            7910,
            10937,
            10937
        ],
        29001: [
            7574,
            7445,
            4440,
            7982,
            11026,
            11026
        ],
        29201: [
            7644,
            7512,
            4507,
            8054,
            11118,
            11118
        ],
        29401: [
            7713,
            7582,
            4578,
            8126,
            11207,
            11207
        ],
        29601: [
            7782,
            7649,
            4645,
            8198,
            11299,
            11299
        ],
        29801: [
            7851,
            7720,
            4715,
            8270,
            11388,
            11388
        ],
        30001: [
            7920,
            7787,
            4782,
            8342,
            11477,
            11477
        ],
        30201: [
            7989,
            7854,
            4849,
            8414,
            11569,
            11569
        ],
        30401: [
            8058,
            7925,
            4920,
            8486,
            11658,
            11658
        ],
        30601: [
            8128,
            7992,
            4987,
            8558,
            11750,
            11750
        ],
        30801: [
            8197,
            8059,
            5054,
            8630,
            11839,
            11839
        ],
        31001: [
            8266,
            8129,
            5124,
            8702,
            11928,
            11928
        ],
        31201: [
            8335,
            8197,
            5192,
            8775,
            12020,
            12020
        ],
        31401: [
            8404,
            8265,
            5260,
            8847,
            12109,
            12109
        ],
        31601: [
            8473,
            8337,
            5332,
            8919,
            12201,
            12201
        ],
        31801: [
            8542,
            8405,
            5400,
            8991,
            12290,
            12290
        ],
        32001: [
            8611,
            8477,
            5472,
            9063,
            12379,
            12379
        ],
        32201: [
            8681,
            8545,
            5540,
            9135,
            12471,
            12471
        ],
        32401: [
            8750,
            8613,
            5609,
            9207,
            12560,
            12560
        ],
        32601: [
            8819,
            8685,
            5680,
            9279,
            12652,
            12652
        ],
        32801: [
            8888,
            8753,
            5749,
            9351,
            12741,
            12741
        ],
        33001: [
            8957,
            8822,
            5817,
            9423,
            12830,
            12830
        ],
        33201: [
            9026,
            8894,
            5889,
            9495,
            12922,
            12922
        ],
        33401: [
            9095,
            8962,
            5957,
            9567,
            13011,
            13011
        ],
        33601: [
            9165,
            9034,
            6029,
            9639,
            13103,
            13103
        ],
        33801: [
            9234,
            9102,
            6097,
            9711,
            13192,
            13192
        ],
        34001: [
            9303,
            9170,
            6165,
            9783,
            13281,
            13281
        ],
        34201: [
            9372,
            9242,
            6237,
            9855,
            13373,
            13373
        ],
        34401: [
            9441,
            9310,
            6306,
            9927,
            13462,
            13462
        ],
        34601: [
            9510,
            9379,
            6374,
            9999,
            13554,
            13554
        ],
        34801: [
            9580,
            9450,
            6446,
            10071,
            13643,
            13643
        ],
        35001: [
            9649,
            9519,
            6514,
            10143,
            13732,
            13732
        ],
        35201: [
            9718,
            9587,
            6582,
            10215,
            13824,
            13824
        ],
        35401: [
            9787,
            9659,
            6654,
            10287,
            13913,
            13913
        ],
        35601: [
            9856,
            9727,
            6722,
            10359,
            14005,
            14005
        ],
        35801: [
            9925,
            9799,
            6794,
            10431,
            14094,
            14094
        ],
        36001: [
            9994,
            9867,
            6862,
            10503,
            14183,
            14183
        ],
        36201: [
            10063,
            9936,
            6931,
            10575,
            14275,
            14275
        ],
        36401: [
            10133,
            10007,
            7003,
            10647,
            14364,
            14364
        ],
        36601: [
            10202,
            10076,
            7071,
            10719,
            14456,
            14456
        ],
        36801: [
            10271,
            10144,
            7139,
            10791,
            14545,
            14545
        ],
        37001: [
            10340,
            10216,
            7211,
            10863,
            14634,
            14634
        ],
        37201: [
            10409,
            10284,
            7279,
            10936,
            14726,
            14726
        ],
        37401: [
            10478,
            10356,
            7351,
            11008,
            14815,
            14815
        ],
        37601: [
            10547,
            10421,
            7416,
            11079,
            14900,
            14900
        ],
        37801: [
            10616,
            10489,
            7484,
            11151,
            14982,
            14982
        ],
        38001: [
            10685,
            10557,
            7553,
            11223,
            15064,
            15064
        ],
        38201: [
            10754,
            10629,
            7624,
            11295,
            15146,
            15146
        ],
        38401: [
            10824,
            10698,
            7693,
            11367,
            15228,
            15228
        ],
        38601: [
            10906,
            10780,
            7775,
            11438,
            15310,
            15310
        ],
        38801: [
            10988,
            10862,
            7857,
            11510,
            15392,
            15392
        ],
        39001: [
            11070,
            10944,
            7939,
            11582,
            15474,
            15474
        ],
        39201: [
            11152,
            11026,
            8021,
            11654,
            15556,
            15556
        ],
        39401: [
            11234,
            11108,
            8103,
            11726,
            15638,
            15638
        ],
        39601: [
            11316,
            11190,
            8185,
            11797,
            15720,
            15720
        ],
        39801: [
            11398,
            11272,
            8267,
            11869,
            15802,
            15802
        ],
        40001: [
            11480,
            11354,
            8349,
            11941,
            15884,
            15884
        ],
        40201: [
            11562,
            11436,
            8431,
            12013,
            15966,
            15966
        ],
        40401: [
            11644,
            11518,
            8513,
            12085,
            16048,
            16048
        ],
        40601: [
            11726,
            11600,
            8595,
            12156,
            16130,
            16130
        ],
        40801: [
            11808,
            11682,
            8677,
            12228,
            16212,
            16212
        ],
        41001: [
            11890,
            11764,
            8759,
            12300,
            16294,
            16294
        ],
        41201: [
            11973,
            11846,
            8841,
            12372,
            16376,
            16376
        ],
        41401: [
            12054,
            11928,
            8923,
            12444,
            16458,
            16458
        ],
        41601: [
            12136,
            12010,
            9005,
            12515,
            16540,
            16540
        ],
        41801: [
            12218,
            12092,
            9087,
            12587,
            16622,
            16622
        ],
        42001: [
            12300,
            12174,
            9169,
            12659,
            16704,
            16704
        ],
        42201: [
            12382,
            12256,
            9251,
            12731,
            16786,
            16786
        ],
        42401: [
            12464,
            12338,
            9333,
            12803,
            16868,
            16868
        ],
        42601: [
            12546,
            12420,
            9415,
            12874,
            16950,
            16950
        ],
        42801: [
            12628,
            12502,
            9497,
            12946,
            17032,
            17032
        ],
        43001: [
            12710,
            12584,
            9579,
            13018,
            17114,
            17114
        ],
        43201: [
            12792,
            12666,
            9661,
            13090,
            17196,
            17196
        ],
        43401: [
            12874,
            12748,
            9743,
            13162,
            17278,
            17278
        ],
        43601: [
            12956,
            12830,
            9825,
            13233,
            17360,
            17360
        ],
        43801: [
            13038,
            12912,
            9907,
            13305,
            17442,
            17442
        ],
        44001: [
            13120,
            12994,
            9989,
            13377,
            17524,
            17524
        ],
        44201: [
            13202,
            13076,
            10071,
            13449,
            17606,
            17606
        ],
        44401: [
            13284,
            13158,
            10153,
            13521,
            17688,
            17688
        ],
        44601: [
            13366,
            13240,
            10235,
            13592,
            17770,
            17770
        ],
        44801: [
            13448,
            13322,
            10317,
            13664,
            17852,
            17852
        ],
        45001: [
            13530,
            13404,
            10399,
            13736,
            17934,
            17934
        ],
        45201: [
            13612,
            13486,
            10481,
            13808,
            18016,
            18016
        ],
        45401: [
            13694,
            13568,
            10563,
            13880,
            18098,
            18098
        ],
        45601: [
            13776,
            13650,
            10645,
            13951,
            18180,
            18180
        ],
        45801: [
            13858,
            13732,
            10727,
            14023,
            18262,
            18262
        ],
        46001: [
            13940,
            13814,
            10809,
            14095,
            18344,
            18344
        ],
        46201: [
            14023,
            13896,
            10891,
            14167,
            18426,
            18426
        ],
        46401: [
            14104,
            13978,
            10973,
            14239,
            18508,
            18508
        ],
        46601: [
            14186,
            14060,
            11055,
            14310,
            18590,
            18590
        ],
        46801: [
            14268,
            14142,
            11137,
            14382,
            18672,
            18672
        ],
        47001: [
            14350,
            14224,
            11219,
            14454,
            18754,
            18754
        ],
        47201: [
            14432,
            14306,
            11301,
            14526,
            18836,
            18836
        ],
        47401: [
            14514,
            14388,
            11383,
            14598,
            18918,
            18918
        ],
        47601: [
            14596,
            14470,
            11465,
            14670,
            19000,
            19000
        ],
        47801: [
            14678,
            14552,
            11547,
            14741,
            19082,
            19082
        ],
        48001: [
            14760,
            14634,
            11629,
            14813,
            19164,
            19164
        ],
        48201: [
            14842,
            14716,
            11711,
            14885,
            19246,
            19246
        ],
        48401: [
            14924,
            14798,
            11793,
            14957,
            19328,
            19328
        ],
        48601: [
            15006,
            14880,
            11875,
            15029,
            19410,
            19410
        ],
        48801: [
            15088,
            14962,
            11957,
            15100,
            19492,
            19492
        ],
        49001: [
            15170,
            15044,
            12039,
            15172,
            19574,
            19574
        ],
        49201: [
            15252,
            15126,
            12121,
            15244,
            19656,
            19656
        ],
        49401: [
            15334,
            15208,
            12203,
            15316,
            19738,
            19738
        ],
        49601: [
            15416,
            15290,
            12285,
            15388,
            19820,
            19820
        ],
        49801: [
            15498,
            15372,
            12367,
            15459,
            19902,
            19902
        ],
        50001: [
            15580,
            15454,
            12453,
            15531,
            19984,
            19984
        ],
        50201: [
            15662,
            15536,
            12541,
            15603,
            20066,
            20066
        ],
        50401: [
            15744,
            15618,
            12629,
            15675,
            20148,
            20148
        ],
        50601: [
            15826,
            15700,
            12717,
            15747,
            20230,
            20230
        ],
        50801: [
            15908,
            15782,
            12805,
            15818,
            20312,
            20312
        ],
        51001: [
            15990,
            15864,
            12893,
            15890,
            20394,
            20394
        ],
        51201: [
            16098,
            15976,
            13012,
            15987,
            20501,
            20501
        ],
        51401: [
            16219,
            16110,
            13151,
            16099,
            20623,
            20623
        ],
        51601: [
            16341,
            16243,
            13291,
            16211,
            20745,
            20745
        ],
        51801: [
            16463,
            16373,
            13426,
            16322,
            20867,
            20867
        ],
        52001: [
            16585,
            16506,
            13566,
            16434,
            20989,
            20989
        ],
        52201: [
            16707,
            16639,
            13705,
            16546,
            21111,
            21111
        ],
        52401: [
            16829,
            16772,
            13844,
            16658,
            21233,
            21233
        ],
        52601: [
            16951,
            16906,
            13983,
            16770,
            21355,
            21355
        ],
        52801: [
            17073,
            17039,
            14123,
            16881,
            21477,
            21477
        ],
        53001: [
            17195,
            17172,
            14262,
            16993,
            21599,
            21599
        ],
        53201: [
            17317,
            17305,
            14401,
            17105,
            21721,
            21721
        ],
        53401: [
            17439,
            17435,
            14537,
            17217,
            21843,
            21843
        ],
        53601: [
            17561,
            17568,
            14676,
            17329,
            21965,
            21965
        ],
        53801: [
            17683,
            17702,
            14816,
            17440,
            22087,
            22087
        ],
        54001: [
            17805,
            17835,
            14955,
            17552,
            22209,
            22209
        ],
        54201: [
            17927,
            17968,
            15094,
            17664,
            22331,
            22331
        ],
        54401: [
            18049,
            18101,
            15233,
            17776,
            22453,
            22453
        ],
        54601: [
            18171,
            18235,
            15373,
            17888,
            22575,
            22575
        ],
        54801: [
            18293,
            18368,
            15512,
            17999,
            22697,
            22697
        ],
        55001: [
            18415,
            18501,
            15651,
            18111,
            22819,
            22819
        ],
        55201: [
            18537,
            18631,
            15787,
            18223,
            22941,
            22941
        ],
        55401: [
            18659,
            18764,
            15926,
            18335,
            23063,
            23063
        ],
        55601: [
            18781,
            18898,
            16065,
            18447,
            23185,
            23185
        ],
        55801: [
            18903,
            19031,
            16205,
            18558,
            23307,
            23307
        ],
        56001: [
            19025,
            19164,
            16344,
            18670,
            23429,
            23429
        ],
        56201: [
            19148,
            19297,
            16483,
            18782,
            23551,
            23551
        ],
        56401: [
            19269,
            19431,
            16622,
            18894,
            23673,
            23673
        ],
        56601: [
            19391,
            19564,
            16762,
            19006,
            23795,
            23795
        ],
        56801: [
            19513,
            19694,
            16897,
            19117,
            23917,
            23917
        ],
        57001: [
            19635,
            19827,
            17037,
            19229,
            24039,
            24039
        ],
        57201: [
            19757,
            19960,
            17176,
            19341,
            24161,
            24161
        ],
        57401: [
            19879,
            20093,
            17315,
            19453,
            24283,
            24283
        ],
        57601: [
            20001,
            20227,
            17454,
            19565,
            24405,
            24405
        ],
        57801: [
            20123,
            20360,
            17594,
            19676,
            24527,
            24527
        ],
        58001: [
            20245,
            20481,
            17721,
            19788,
            24649,
            24649
        ],
        58201: [
            20367,
            20603,
            17849,
            19900,
            24771,
            24771
        ],
        58401: [
            20489,
            20725,
            17977,
            20012,
            24893,
            24893
        ],
        58601: [
            20611,
            20847,
            18105,
            20124,
            25015,
            25015
        ],
        58801: [
            20733,
            20969,
            18233,
            20235,
            25137,
            25137
        ],
        59001: [
            20855,
            21091,
            18361,
            20347,
            25259,
            25259
        ],
        59201: [
            20977,
            21213,
            18489,
            20459,
            25381,
            25381
        ],
        59401: [
            21099,
            21335,
            18617,
            20571,
            25503,
            25503
        ],
        59601: [
            21221,
            21457,
            18745,
            20683,
            25625,
            25625
        ],
        59801: [
            21343,
            21579,
            18873,
            20794,
            25747,
            25747
        ],
        60001: [
            21465,
            21701,
            19001,
            20906,
            25869,
            25869
        ],
        60201: [
            21587,
            21823,
            19129,
            21018,
            25991,
            25991
        ],
        60401: [
            21709,
            21945,
            19257,
            21130,
            26113,
            26113
        ],
        60601: [
            21831,
            22067,
            19385,
            21242,
            26235,
            26235
        ],
        60801: [
            21953,
            22189,
            19513,
            21353,
            26357,
            26357
        ],
        61001: [
            22075,
            22311,
            19641,
            21465,
            26479,
            26479
        ],
        61201: [
            22198,
            22433,
            19769,
            21577,
            26601,
            26601
        ],
        61401: [
            22319,
            22555,
            19897,
            21689,
            26723,
            26723
        ],
        61601: [
            22441,
            22677,
            20025,
            21801,
            26845,
            26845
        ],
        61801: [
            22563,
            22799,
            20153,
            21912,
            26967,
            26967
        ],
        62001: [
            22685,
            22921,
            20281,
            22024,
            27089,
            27089
        ],
        62201: [
            22807,
            23043,
            20409,
            22136,
            27211,
            27211
        ],
        62401: [
            22929,
            23165,
            20537,
            22248,
            27333,
            27333
        ],
        62601: [
            23051,
            23287,
            20665,
            22360,
            27455,
            27455
        ],
        62801: [
            23173,
            23409,
            20793,
            22471,
            27577,
            27577
        ],
        63001: [
            23295,
            23531,
            20921,
            22583,
            27699,
            27699
        ],
        63201: [
            23417,
            23653,
            21049,
            22695,
            27821,
            27821
        ],
        63401: [
            23539,
            23775,
            21177,
            22807,
            27943,
            27943
        ],
        63601: [
            23661,
            23897,
            21305,
            22919,
            28065,
            28065
        ],
        63801: [
            23783,
            24019,
            21433,
            23030,
            28187,
            28187
        ],
        64001: [
            23905,
            24141,
            21561,
            23142,
            28309,
            28309
        ],
        64201: [
            24027,
            24263,
            21689,
            23254,
            28431,
            28431
        ],
        64401: [
            24149,
            24385,
            21817,
            23366,
            28553,
            28553
        ],
        64601: [
            24276,
            24517,
            21955,
            23478,
            28675,
            28675
        ],
        64801: [
            24404,
            24644,
            22088,
            23589,
            28797,
            28797
        ],
        65001: [
            24532,
            24776,
            22226,
            23701,
            28919,
            28919
        ],
        65201: [
            24660,
            24903,
            22359,
            23813,
            29041,
            29041
        ],
        65401: [
            24788,
            25030,
            22492,
            23925,
            29163,
            29163
        ],
        65601: [
            24916,
            25162,
            22630,
            24037,
            29285,
            29285
        ],
        65801: [
            25044,
            25290,
            22763,
            24148,
            29407,
            29407
        ],
        66001: [
            25172,
            25422,
            22902,
            24260,
            29529,
            29529
        ],
        66201: [
            25300,
            25549,
            23035,
            24372,
            29651,
            29651
        ],
        66401: [
            25428,
            25676,
            23168,
            24484,
            29773,
            29773
        ],
        66601: [
            25556,
            25808,
            23306,
            24596,
            29895,
            29895
        ],
        66801: [
            25684,
            25935,
            23439,
            24707,
            30017,
            30017
        ],
        67001: [
            25812,
            26062,
            23572,
            24819,
            30139,
            30139
        ],
        67201: [
            25940,
            26194,
            23710,
            24931,
            30261,
            30261
        ],
        67401: [
            26068,
            26321,
            23843,
            25043,
            30383,
            30383
        ],
        67601: [
            26196,
            26454,
            23981,
            25155,
            30505,
            30505
        ],
        67801: [
            26324,
            26581,
            24115,
            25266,
            30627,
            30627
        ],
        68001: [
            26452,
            26708,
            24248,
            25378,
            30749,
            30749
        ],
        68201: [
            26580,
            26840,
            24386,
            25490,
            30871,
            30871
        ],
        68401: [
            26708,
            26967,
            24519,
            25602,
            30993,
            30993
        ],
        68601: [
            26836,
            27094,
            24652,
            25714,
            31115,
            31115
        ],
        68801: [
            26964,
            27226,
            24790,
            25825,
            31237,
            31237
        ],
        69001: [
            27092,
            27353,
            24923,
            25937,
            31359,
            31359
        ],
        69201: [
            27220,
            27486,
            25061,
            26049,
            31481,
            31481
        ],
        69401: [
            27348,
            27613,
            25194,
            26161,
            31603,
            31603
        ],
        69601: [
            27476,
            27740,
            25328,
            26273,
            31725,
            31725
        ],
        69801: [
            27604,
            27872,
            25466,
            26384,
            31847,
            31847
        ],
        70001: [
            27732,
            27999,
            25599,
            26496,
            31969,
            31969
        ],
        70201: [
            27860,
            28126,
            25732,
            26608,
            32091,
            32091
        ],
        70401: [
            27988,
            28258,
            25870,
            26720,
            32213,
            32213
        ],
        70601: [
            28116,
            28385,
            26003,
            26832,
            32335,
            32335
        ],
        70801: [
            28244,
            28517,
            26141,
            26943,
            32457,
            32457
        ],
        71001: [
            28372,
            28645,
            26274,
            27055,
            32579,
            32579
        ],
        71201: [
            28500,
            28772,
            26407,
            27167,
            32701,
            32701
        ],
        71401: [
            28628,
            28904,
            26546,
            27279,
            32823,
            32823
        ],
        71601: [
            28756,
            29031,
            26679,
            27391,
            32945,
            32945
        ],
        71801: [
            28884,
            29163,
            26817,
            27502,
            33067,
            33067
        ],
        72001: [
            29012,
            29290,
            26950,
            27614,
            33189,
            33189
        ],
        72201: [
            29140,
            29417,
            27083,
            27726,
            33311,
            33311
        ],
        72401: [
            29268,
            29549,
            27221,
            27838,
            33433,
            33433
        ],
        72601: [
            29396,
            29676,
            27354,
            27950,
            33555,
            33555
        ],
        72801: [
            29524,
            29804,
            27487,
            28061,
            33677,
            33677
        ],
        73001: [
            29652,
            29936,
            27626,
            28173,
            33799,
            33799
        ],
        73201: [
            29780,
            30063,
            27759,
            28285,
            33921,
            33921
        ],
        73401: [
            29908,
            30195,
            27897,
            28397,
            34043,
            34043
        ],
        73601: [
            30036,
            30322,
            28030,
            28509,
            34165,
            34165
        ],
        73801: [
            30164,
            30449,
            28163,
            28620,
            34287,
            34287
        ],
        74001: [
            30292,
            30581,
            28301,
            28732,
            34409,
            34409
        ],
        74201: [
            30420,
            30708,
            28434,
            28844,
            34531,
            34531
        ],
        74401: [
            30548,
            30835,
            28567,
            28956,
            34653,
            34653
        ],
        74601: [
            30676,
            30968,
            28705,
            29068,
            34775,
            34775
        ],
        74801: [
            30804,
            31095,
            28839,
            29179,
            34897,
            34897
        ],
        75001: [
            30932,
            31227,
            28977,
            29291,
            35019,
            35019
        ],
        75201: [
            31060,
            31354,
            29110,
            29403,
            35141,
            35141
        ],
        75401: [
            31188,
            31481,
            29243,
            29515,
            35263,
            35263
        ],
        75601: [
            31316,
            31613,
            29381,
            29627,
            35385,
            35385
        ],
        75801: [
            31444,
            31740,
            29514,
            29738,
            35507,
            35507
        ],
        76001: [
            31572,
            31867,
            29647,
            29850,
            35629,
            35629
        ],
        76201: [
            31700,
            32000,
            29785,
            29962,
            35751,
            35751
        ],
        76401: [
            31828,
            32127,
            29918,
            30074,
            35873,
            35873
        ],
        76601: [
            31956,
            32259,
            30057,
            30186,
            35995,
            35995
        ],
        76801: [
            32084,
            32386,
            30190,
            30297,
            36117,
            36117
        ],
        77001: [
            32212,
            32513,
            30323,
            30409,
            36239,
            36239
        ],
        77201: [
            32340,
            32645,
            30461,
            30521,
            36361,
            36361
        ],
        77401: [
            32468,
            32772,
            30594,
            30633,
            36483,
            36483
        ],
        77601: [
            32596,
            32899,
            30727,
            30745,
            36605,
            36605
        ],
        77801: [
            32724,
            33031,
            30865,
            30856,
            36727,
            36727
        ],
        78001: [
            32852,
            33159,
            30998,
            30968,
            36849,
            36849
        ],
        78201: [
            32980,
            33291,
            31137,
            31080,
            36971,
            36971
        ],
        78401: [
            33108,
            33418,
            31270,
            31192,
            37093,
            37093
        ],
        78601: [
            33236,
            33545,
            31403,
            31304,
            37215,
            37215
        ],
        78801: [
            33364,
            33677,
            31541,
            31415,
            37337,
            37337
        ],
        79001: [
            33492,
            33804,
            31674,
            31527,
            37459,
            37459
        ],
        79201: [
            33620,
            33931,
            31807,
            31639,
            37581,
            37581
        ],
        79401: [
            33748,
            34063,
            31945,
            31751,
            37703,
            37703
        ],
        79601: [
            33876,
            34190,
            32078,
            31863,
            37825,
            37825
        ],
        79801: [
            34004,
            34323,
            32216,
            31974,
            37947,
            37947
        ],
        80001: [
            43,
            43,
            40,
            40,
            47,
            47,
            '%'
        ],
        80201: [
            43,
            43,
            40,
            40,
            48,
            48,
            '%'
        ],
        80601: [
            43,
            43,
            41,
            40,
            48,
            48,
            '%'
        ],
        82201: [
            43,
            44,
            41,
            40,
            48,
            48,
            '%'
        ],
        82601: [
            43,
            44,
            41,
            41,
            48,
            48,
            '%'
        ],
        83601: [
            43,
            44,
            42,
            41,
            48,
            48,
            '%'
        ],
        83801: [
            44,
            44,
            42,
            41,
            48,
            48,
            '%'
        ],
        86201: [
            44,
            45,
            42,
            41,
            48,
            48,
            '%'
        ],
        86801: [
            44,
            45,
            42,
            41,
            49,
            49,
            '%'
        ],
        87001: [
            44,
            45,
            43,
            41,
            49,
            49,
            '%'
        ],
        88001: [
            45,
            45,
            43,
            41,
            49,
            49,
            '%'
        ],
        88401: [
            45,
            45,
            43,
            42,
            49,
            49,
            '%'
        ],
        90601: [
            45,
            45,
            44,
            42,
            49,
            49,
            '%'
        ],
        90801: [
            45,
            46,
            44,
            42,
            49,
            49,
            '%'
        ],
        92801: [
            46,
            46,
            44,
            42,
            49,
            49,
            '%'
        ],
        94201: [
            46,
            46,
            44,
            42,
            50,
            50,
            '%'
        ],
        94601: [
            46,
            46,
            45,
            42,
            50,
            50,
            '%'
        ],
        95001: [
            46,
            46,
            45,
            43,
            50,
            50,
            '%'
        ],
        95801: [
            46,
            47,
            45,
            43,
            50,
            50,
            '%'
        ],
        98201: [
            47,
            47,
            45,
            43,
            50,
            50,
            '%'
        ],
        99001: [
            47,
            47,
            46,
            43,
            50,
            50,
            '%'
        ],
        101601: [
            47,
            48,
            46,
            43,
            50,
            50,
            '%'
        ],
        102601: [
            47,
            48,
            46,
            44,
            50,
            50,
            '%'
        ],
        103201: [
            47,
            48,
            46,
            44,
            51,
            51,
            '%'
        ],
        103601: [
            47,
            48,
            47,
            44,
            51,
            51,
            '%'
        ],
        104201: [
            48,
            48,
            47,
            44,
            51,
            51,
            '%'
        ],
        108001: [
            48,
            49,
            47,
            44,
            51,
            51,
            '%'
        ],
        108801: [
            48,
            49,
            48,
            44,
            51,
            51,
            '%'
        ],
        110801: [
            49,
            49,
            48,
            44,
            51,
            51,
            '%'
        ],
        111601: [
            49,
            49,
            48,
            45,
            51,
            51,
            '%'
        ],
        114201: [
            49,
            49,
            48,
            45,
            52,
            52,
            '%'
        ],
        114601: [
            49,
            49,
            49,
            45,
            52,
            52,
            '%'
        ],
        115001: [
            49,
            50,
            49,
            45,
            52,
            52,
            '%'
        ],
        118401: [
            50,
            50,
            49,
            45,
            52,
            52,
            '%'
        ],
        121001: [
            50,
            50,
            50,
            45,
            52,
            52,
            '%'
        ],
        122401: [
            50,
            50,
            50,
            46,
            52,
            52,
            '%'
        ],
        123201: [
            50,
            51,
            50,
            46,
            52,
            52,
            '%'
        ],
        127201: [
            51,
            51,
            50,
            46,
            52,
            52,
            '%'
        ],
        127601: [
            51,
            51,
            50,
            46,
            53,
            53,
            '%'
        ],
        128001: [
            51,
            51,
            51,
            46,
            53,
            53,
            '%'
        ],
        132801: [
            51,
            52,
            51,
            46,
            53,
            53,
            '%'
        ],
        135401: [
            51,
            52,
            51,
            47,
            53,
            53,
            '%'
        ],
        136001: [
            51,
            52,
            52,
            47,
            53,
            53,
            '%'
        ],
        137401: [
            52,
            52,
            52,
            47,
            53,
            53,
            '%'
        ],
        144001: [
            52,
            53,
            52,
            47,
            53,
            53,
            '%'
        ],
        144601: [
            52,
            53,
            52,
            47,
            54,
            54,
            '%'
        ],
        145201: [
            52,
            53,
            53,
            47,
            54,
            54,
            '%'
        ],
        149401: [
            53,
            53,
            53,
            47,
            54,
            54,
            '%'
        ],
        151601: [
            53,
            53,
            53,
            48,
            54,
            54,
            '%'
        ],
        157001: [
            53,
            54,
            54,
            48,
            54,
            54,
            '%'
        ],
        163601: [
            54,
            54,
            54,
            48,
            54,
            54,
            '%'
        ],
        166801: [
            54,
            54,
            54,
            48,
            55,
            55,
            '%'
        ],
        172201: [
            54,
            54,
            54,
            49,
            55,
            55,
            '%'
        ],
        172601: [
            54,
            55,
            55,
            49,
            55,
            55,
            '%'
        ],
        181001: [
            55,
            55,
            55,
            49,
            55,
            55,
            '%'
        ],
        197201: [
            55,
            56,
            56,
            49,
            56,
            56,
            '%'
        ],
        199001: [
            55,
            56,
            56,
            50,
            56,
            56,
            '%'
        ],
        202201: [
            56,
            56,
            56,
            50,
            56,
            56,
            '%'
        ],
        236001: [
            56,
            56,
            56,
            51,
            56,
            56,
            '%'
        ],
        241001: [
            57,
            57,
            57,
            51,
            57,
            57,
            '%'
        ],
        289601: [
            57,
            57,
            57,
            52,
            57,
            57,
            '%'
        ],
        310001: [
            58,
            58,
            58,
            52,
            58,
            58,
            '%'
        ],
        374801: [
            58,
            58,
            58,
            53,
            58,
            58,
            '%'
        ],
        434001: [
            59,
            59,
            59,
            53,
            59,
            59,
            '%'
        ],
        530801: [
            59,
            59,
            59,
            54,
            59,
            59,
            '%'
        ],
        723401: [
            60,
            60,
            60,
            54,
            60,
            60,
            '%'
        ],
        910001: [
            60,
            60,
            60,
            55,
            60,
            60,
            '%'
        ]
    },
    42: {
        1: [
            0,
            0,
            0,
            0,
            0,
            0
        ],
        2001: [
            151,
            0,
            150,
            1,
            151,
            32
        ],
        2101: [
            153,
            0,
            150,
            3,
            153,
            75
        ],
        2201: [
            164,
            0,
            158,
            5,
            164,
            118
        ],
        2301: [
            174,
            0,
            166,
            8,
            174,
            161
        ],
        2401: [
            185,
            0,
            175,
            10,
            204,
            204
        ],
        2501: [
            195,
            0,
            183,
            12,
            247,
            247
        ],
        2601: [
            206,
            0,
            191,
            14,
            290,
            290
        ],
        2701: [
            216,
            0,
            200,
            16,
            333,
            333
        ],
        2801: [
            218,
            0,
            200,
            18,
            376,
            376
        ],
        2901: [
            229,
            0,
            208,
            21,
            419,
            419
        ],
        3001: [
            239,
            0,
            216,
            23,
            462,
            462
        ],
        3101: [
            250,
            0,
            225,
            25,
            505,
            505
        ],
        3201: [
            260,
            0,
            233,
            27,
            548,
            548
        ],
        3301: [
            271,
            0,
            241,
            29,
            591,
            591
        ],
        3401: [
            273,
            0,
            241,
            31,
            634,
            634
        ],
        3501: [
            284,
            0,
            250,
            34,
            677,
            677
        ],
        3601: [
            294,
            0,
            258,
            36,
            720,
            720
        ],
        3701: [
            305,
            0,
            266,
            38,
            763,
            763
        ],
        3801: [
            315,
            0,
            275,
            40,
            806,
            806
        ],
        3901: [
            325,
            0,
            283,
            42,
            849,
            849
        ],
        4001: [
            328,
            0,
            283,
            44,
            892,
            892
        ],
        4101: [
            338,
            0,
            291,
            46,
            935,
            935
        ],
        4201: [
            349,
            0,
            300,
            49,
            978,
            978
        ],
        4301: [
            359,
            0,
            308,
            66,
            1021,
            1021
        ],
        4401: [
            370,
            0,
            316,
            95,
            1064,
            1064
        ],
        4501: [
            380,
            0,
            325,
            124,
            1107,
            1107
        ],
        4601: [
            382,
            0,
            325,
            153,
            1150,
            1150
        ],
        4701: [
            392,
            0,
            333,
            182,
            1186,
            1186
        ],
        4801: [
            403,
            0,
            341,
            210,
            1221,
            1221
        ],
        4901: [
            413,
            0,
            350,
            239,
            1257,
            1257
        ],
        5001: [
            423,
            0,
            358,
            268,
            1289,
            1289
        ],
        5101: [
            433,
            0,
            366,
            296,
            1325,
            1325
        ],
        5201: [
            443,
            0,
            375,
            325,
            1358,
            1358
        ],
        5301: [
            445,
            28,
            376,
            354,
            1393,
            1393
        ],
        5401: [
            455,
            60,
            386,
            382,
            1429,
            1429
        ],
        5501: [
            465,
            93,
            396,
            410,
            1461,
            1461
        ],
        5601: [
            475,
            125,
            406,
            439,
            1496,
            1496
        ],
        5701: [
            485,
            157,
            416,
            467,
            1528,
            1528
        ],
        5801: [
            495,
            189,
            426,
            495,
            1563,
            1563
        ],
        5901: [
            497,
            222,
            427,
            523,
            1598,
            1598
        ],
        6001: [
            517,
            254,
            437,
            551,
            1630,
            1630
        ],
        6101: [
            543,
            286,
            447,
            579,
            1665,
            1665
        ],
        6201: [
            569,
            315,
            457,
            607,
            1697,
            1697
        ],
        6301: [
            596,
            347,
            467,
            635,
            1732,
            1732
        ],
        6401: [
            622,
            379,
            477,
            663,
            1767,
            1767
        ],
        6501: [
            648,
            412,
            479,
            691,
            1799,
            1799
        ],
        6601: [
            674,
            444,
            488,
            719,
            1834,
            1834
        ],
        6701: [
            700,
            476,
            498,
            747,
            1866,
            1866
        ],
        6801: [
            726,
            508,
            508,
            775,
            1901,
            1901
        ],
        6901: [
            753,
            541,
            518,
            803,
            1936,
            1936
        ],
        7001: [
            779,
            573,
            528,
            831,
            1968,
            1968
        ],
        7101: [
            805,
            605,
            530,
            859,
            2003,
            2003
        ],
        7201: [
            831,
            637,
            540,
            887,
            2035,
            2035
        ],
        7301: [
            857,
            670,
            550,
            916,
            2070,
            2070
        ],
        7401: [
            883,
            698,
            560,
            944,
            2105,
            2105
        ],
        7501: [
            909,
            730,
            570,
            972,
            2137,
            2137
        ],
        7601: [
            936,
            763,
            580,
            1000,
            2172,
            2172
        ],
        7701: [
            962,
            795,
            589,
            1028,
            2204,
            2204
        ],
        7801: [
            988,
            827,
            591,
            1056,
            2239,
            2239
        ],
        7901: [
            1014,
            859,
            601,
            1084,
            2274,
            2274
        ],
        8001: [
            1040,
            892,
            611,
            1112,
            2306,
            2306
        ],
        8101: [
            1066,
            924,
            621,
            1140,
            2341,
            2341
        ],
        8201: [
            1092,
            956,
            631,
            1168,
            2373,
            2373
        ],
        8301: [
            1119,
            988,
            641,
            1196,
            2408,
            2408
        ],
        8401: [
            1145,
            1021,
            642,
            1224,
            2443,
            2443
        ],
        8501: [
            1171,
            1053,
            652,
            1252,
            2475,
            2475
        ],
        8601: [
            1197,
            1082,
            662,
            1280,
            2510,
            2510
        ],
        8701: [
            1223,
            1114,
            672,
            1308,
            2542,
            2542
        ],
        8801: [
            1249,
            1146,
            682,
            1337,
            2577,
            2577
        ],
        8901: [
            1275,
            1178,
            692,
            1365,
            2612,
            2612
        ],
        9001: [
            1301,
            1211,
            694,
            1393,
            2644,
            2644
        ],
        9101: [
            1328,
            1243,
            704,
            1421,
            2679,
            2679
        ],
        9201: [
            1354,
            1275,
            714,
            1449,
            2711,
            2711
        ],
        9301: [
            1380,
            1297,
            723,
            1477,
            2746,
            2746
        ],
        9401: [
            1406,
            1325,
            733,
            1505,
            2781,
            2781
        ],
        9501: [
            1432,
            1350,
            742,
            1533,
            2813,
            2813
        ],
        9601: [
            1458,
            1375,
            744,
            1561,
            2848,
            2848
        ],
        9701: [
            1485,
            1401,
            753,
            1589,
            2880,
            2880
        ],
        9801: [
            1511,
            1429,
            763,
            1617,
            2915,
            2915
        ],
        9901: [
            1537,
            1454,
            773,
            1645,
            2950,
            2950
        ],
        10001: [
            1563,
            1479,
            782,
            1673,
            2982,
            2982
        ],
        10101: [
            1589,
            1503,
            792,
            1701,
            3017,
            3017
        ],
        10201: [
            1615,
            1531,
            802,
            1729,
            3049,
            3049
        ],
        10301: [
            1641,
            1556,
            803,
            1757,
            3084,
            3084
        ],
        10401: [
            1668,
            1581,
            812,
            1786,
            3119,
            3119
        ],
        10501: [
            1694,
            1605,
            822,
            1813,
            3151,
            3151
        ],
        10601: [
            1720,
            1634,
            832,
            1842,
            3186,
            3186
        ],
        10701: [
            1746,
            1658,
            841,
            1870,
            3218,
            3218
        ],
        10801: [
            1772,
            1683,
            851,
            1898,
            3253,
            3253
        ],
        10901: [
            1798,
            1707,
            852,
            1926,
            3288,
            3288
        ],
        11001: [
            1824,
            1736,
            862,
            1954,
            3320,
            3320
        ],
        11101: [
            1851,
            1760,
            872,
            1982,
            3355,
            3355
        ],
        11201: [
            1877,
            1785,
            881,
            2010,
            3387,
            3387
        ],
        11301: [
            1903,
            1813,
            891,
            2038,
            3422,
            3422
        ],
        11401: [
            1929,
            1838,
            901,
            2066,
            3457,
            3457
        ],
        11501: [
            1955,
            1862,
            902,
            2094,
            3489,
            3489
        ],
        11601: [
            1981,
            1887,
            911,
            2122,
            3524,
            3524
        ],
        11701: [
            2007,
            1915,
            921,
            2150,
            3556,
            3556
        ],
        11801: [
            2034,
            1940,
            931,
            2178,
            3591,
            3591
        ],
        11901: [
            2060,
            1964,
            940,
            2206,
            3626,
            3626
        ],
        12001: [
            2086,
            1989,
            950,
            2234,
            3658,
            3658
        ],
        12101: [
            2112,
            2017,
            951,
            2263,
            3693,
            3693
        ],
        12201: [
            2138,
            2042,
            961,
            2291,
            3725,
            3725
        ],
        12301: [
            2164,
            2067,
            971,
            2319,
            3760,
            3760
        ],
        12401: [
            2191,
            2091,
            980,
            2347,
            3795,
            3795
        ],
        12501: [
            2217,
            2119,
            990,
            2375,
            3827,
            3827
        ],
        12601: [
            2243,
            2144,
            1000,
            2403,
            3862,
            3862
        ],
        12701: [
            2269,
            2169,
            1009,
            2431,
            3894,
            3894
        ],
        12801: [
            2295,
            2193,
            1010,
            2459,
            3929,
            3929
        ],
        12901: [
            2321,
            2221,
            1020,
            2487,
            3961,
            3961
        ],
        13001: [
            2348,
            2246,
            1030,
            2515,
            4003,
            4003
        ],
        13101: [
            2374,
            2271,
            1039,
            2544,
            4045,
            4045
        ],
        13201: [
            2400,
            2299,
            1049,
            2572,
            4088,
            4088
        ],
        13301: [
            2427,
            2324,
            1059,
            2600,
            4130,
            4130
        ],
        13401: [
            2453,
            2348,
            1060,
            2629,
            4172,
            4172
        ],
        13501: [
            2480,
            2373,
            1070,
            2657,
            4214,
            4214
        ],
        13601: [
            2506,
            2401,
            1079,
            2685,
            4257,
            4257
        ],
        13701: [
            2533,
            2426,
            1089,
            2714,
            4299,
            4299
        ],
        13801: [
            2558,
            2450,
            1099,
            2741,
            4340,
            4340
        ],
        13901: [
            2584,
            2475,
            1108,
            2769,
            4382,
            4382
        ],
        14001: [
            2609,
            2503,
            1110,
            2796,
            4423,
            4423
        ],
        14101: [
            2635,
            2528,
            1119,
            2824,
            4464,
            4464
        ],
        14201: [
            2660,
            2552,
            1129,
            2851,
            4505,
            4505
        ],
        14301: [
            2686,
            2577,
            1138,
            2878,
            4547,
            4547
        ],
        14401: [
            2711,
            2605,
            1148,
            2906,
            4588,
            4588
        ],
        14501: [
            2736,
            2630,
            1158,
            2933,
            4629,
            4629
        ],
        14601: [
            2762,
            2655,
            1159,
            2960,
            4670,
            4670
        ],
        14701: [
            2787,
            2679,
            1169,
            2988,
            4712,
            4712
        ],
        14801: [
            2813,
            2707,
            1178,
            3015,
            4756,
            4756
        ],
        14901: [
            2838,
            2732,
            1188,
            3042,
            4801,
            4801
        ],
        15001: [
            2864,
            2757,
            1198,
            3070,
            4846,
            4846
        ],
        15101: [
            2889,
            2781,
            1207,
            3097,
            4890,
            4890
        ],
        15201: [
            2915,
            2809,
            1217,
            3125,
            4939,
            4939
        ],
        15301: [
            2940,
            2834,
            1218,
            3152,
            4983,
            4983
        ],
        15401: [
            2968,
            2862,
            1228,
            3182,
            5028,
            5028
        ],
        15501: [
            3002,
            2897,
            1238,
            3219,
            5073,
            5073
        ],
        15601: [
            3037,
            2933,
            1248,
            3255,
            5117,
            5117
        ],
        15701: [
            3071,
            2968,
            1258,
            3291,
            5165,
            5165
        ],
        15801: [
            3106,
            3003,
            1268,
            3327,
            5210,
            5210
        ],
        15901: [
            3140,
            3038,
            1270,
            3363,
            5255,
            5255
        ],
        16001: [
            3175,
            3074,
            1280,
            3399,
            5300,
            5300
        ],
        16101: [
            3210,
            3109,
            1291,
            3435,
            5344,
            5344
        ],
        16201: [
            3244,
            3144,
            1301,
            3471,
            5392,
            5392
        ],
        16301: [
            3279,
            3179,
            1311,
            3507,
            5437,
            5437
        ],
        16401: [
            3313,
            3214,
            1321,
            3543,
            5482,
            5482
        ],
        16501: [
            3348,
            3250,
            1323,
            3579,
            5526,
            5526
        ],
        16601: [
            3382,
            3285,
            1333,
            3615,
            5571,
            5571
        ],
        16701: [
            3417,
            3320,
            1343,
            3651,
            5619,
            5619
        ],
        16801: [
            3452,
            3355,
            1353,
            3687,
            5664,
            5664
        ],
        16901: [
            3486,
            3390,
            1363,
            3723,
            5709,
            5709
        ],
        17001: [
            3521,
            3422,
            1373,
            3760,
            5753,
            5753
        ],
        17101: [
            3555,
            3457,
            1375,
            3796,
            5798,
            5798
        ],
        17201: [
            3590,
            3493,
            1385,
            3832,
            5846,
            5846
        ],
        17301: [
            3625,
            3528,
            1395,
            3868,
            5891,
            5891
        ],
        17401: [
            3659,
            3563,
            1405,
            3904,
            5935,
            5935
        ],
        17501: [
            3694,
            3598,
            1416,
            3940,
            5980,
            5980
        ],
        17601: [
            3728,
            3633,
            1426,
            3976,
            6025,
            6025
        ],
        17701: [
            3763,
            3669,
            1436,
            4012,
            6073,
            6073
        ],
        17801: [
            3797,
            3704,
            1438,
            4048,
            6118,
            6118
        ],
        17901: [
            3832,
            3739,
            1448,
            4084,
            6162,
            6162
        ],
        18001: [
            3867,
            3774,
            1458,
            4120,
            6207,
            6207
        ],
        18101: [
            3901,
            3809,
            1468,
            4156,
            6252,
            6252
        ],
        18201: [
            3936,
            3845,
            1478,
            4192,
            6300,
            6300
        ],
        18301: [
            3970,
            3880,
            1488,
            4228,
            6345,
            6345
        ],
        18401: [
            4005,
            3915,
            1490,
            4264,
            6389,
            6389
        ],
        18501: [
            4040,
            3950,
            1500,
            4300,
            6434,
            6434
        ],
        18601: [
            4074,
            3986,
            1510,
            4336,
            6479,
            6479
        ],
        18701: [
            4109,
            4021,
            1520,
            4373,
            6527,
            6527
        ],
        18801: [
            4143,
            4056,
            1531,
            4409,
            6571,
            6571
        ],
        18901: [
            4178,
            4091,
            1541,
            4445,
            6616,
            6616
        ],
        19001: [
            4212,
            4126,
            1543,
            4481,
            6661,
            6661
        ],
        19101: [
            4247,
            4162,
            1559,
            4517,
            6705,
            6705
        ],
        19201: [
            4282,
            4197,
            1588,
            4553,
            6754,
            6754
        ],
        19301: [
            4316,
            4232,
            1616,
            4589,
            6798,
            6798
        ],
        19401: [
            4351,
            4267,
            1644,
            4625,
            6843,
            6843
        ],
        19501: [
            4385,
            4302,
            1672,
            4661,
            6888,
            6888
        ],
        19601: [
            4420,
            4337,
            1700,
            4697,
            6932,
            6932
        ],
        19701: [
            4455,
            4371,
            1727,
            4733,
            6980,
            6980
        ],
        19801: [
            4489,
            4406,
            1755,
            4769,
            7025,
            7025
        ],
        19901: [
            4524,
            4440,
            1782,
            4805,
            7070,
            7070
        ],
        20001: [
            4593,
            4509,
            1837,
            4877,
            7159,
            7159
        ],
        20201: [
            4662,
            4574,
            1888,
            4950,
            7252,
            7252
        ],
        20401: [
            4731,
            4643,
            1943,
            5022,
            7341,
            7341
        ],
        20601: [
            4800,
            4712,
            1998,
            5094,
            7434,
            7434
        ],
        20801: [
            4869,
            4780,
            2052,
            5166,
            7524,
            7524
        ],
        21001: [
            4939,
            4849,
            2107,
            5238,
            7613,
            7613
        ],
        21201: [
            5008,
            4918,
            2162,
            5310,
            7706,
            7706
        ],
        21401: [
            5077,
            4987,
            2217,
            5382,
            7795,
            7795
        ],
        21601: [
            5146,
            5055,
            2271,
            5455,
            7888,
            7888
        ],
        21801: [
            5215,
            5124,
            2326,
            5527,
            7977,
            7977
        ],
        22001: [
            5284,
            5193,
            2381,
            5599,
            8067,
            8067
        ],
        22201: [
            5354,
            5262,
            2436,
            5671,
            8160,
            8160
        ],
        22401: [
            5423,
            5330,
            2490,
            5743,
            8249,
            8249
        ],
        22601: [
            5492,
            5399,
            2545,
            5815,
            8342,
            8342
        ],
        22801: [
            5562,
            5468,
            2600,
            5888,
            8432,
            8432
        ],
        23001: [
            5633,
            5537,
            2655,
            5962,
            8523,
            8523
        ],
        23201: [
            5704,
            5605,
            2709,
            6036,
            8618,
            8618
        ],
        23401: [
            5775,
            5671,
            2761,
            6110,
            8709,
            8709
        ],
        23601: [
            5845,
            5740,
            2815,
            6183,
            8803,
            8803
        ],
        23801: [
            5916,
            5805,
            2867,
            6257,
            8894,
            8894
        ],
        24001: [
            5987,
            5877,
            2925,
            6331,
            8985,
            8985
        ],
        24201: [
            6058,
            5946,
            2980,
            6405,
            9080,
            9080
        ],
        24401: [
            6129,
            6018,
            3038,
            6478,
            9171,
            9171
        ],
        24601: [
            6199,
            6087,
            3093,
            6552,
            9265,
            9265
        ],
        24801: [
            6270,
            6155,
            3147,
            6626,
            9356,
            9356
        ],
        25001: [
            6341,
            6228,
            3223,
            6700,
            9447,
            9447
        ],
        25201: [
            6412,
            6296,
            3292,
            6774,
            9542,
            9542
        ],
        25401: [
            6483,
            6365,
            3360,
            6847,
            9633,
            9633
        ],
        25601: [
            6553,
            6437,
            3432,
            6921,
            9727,
            9727
        ],
        25801: [
            6624,
            6506,
            3501,
            6995,
            9818,
            9818
        ],
        26001: [
            6695,
            6575,
            3570,
            7069,
            9909,
            9909
        ],
        26201: [
            6766,
            6647,
            3642,
            7142,
            10004,
            10004
        ],
        26401: [
            6837,
            6716,
            3711,
            7216,
            10095,
            10095
        ],
        26601: [
            6908,
            6788,
            3783,
            7290,
            10189,
            10189
        ],
        26801: [
            6978,
            6857,
            3852,
            7364,
            10280,
            10280
        ],
        27001: [
            7049,
            6925,
            3921,
            7437,
            10371,
            10371
        ],
        27201: [
            7120,
            6998,
            3993,
            7511,
            10466,
            10466
        ],
        27401: [
            7191,
            7066,
            4062,
            7585,
            10557,
            10557
        ],
        27601: [
            7262,
            7135,
            4130,
            7659,
            10651,
            10651
        ],
        27801: [
            7332,
            7207,
            4202,
            7733,
            10742,
            10742
        ],
        28001: [
            7403,
            7276,
            4271,
            7806,
            10833,
            10833
        ],
        28201: [
            7474,
            7348,
            4343,
            7880,
            10928,
            10928
        ],
        28401: [
            7545,
            7417,
            4412,
            7954,
            11019,
            11019
        ],
        28601: [
            7616,
            7486,
            4481,
            8028,
            11113,
            11113
        ],
        28801: [
            7686,
            7558,
            4553,
            8102,
            11204,
            11204
        ],
        29001: [
            7757,
            7627,
            4622,
            8175,
            11295,
            11295
        ],
        29201: [
            7828,
            7695,
            4691,
            8249,
            11390,
            11390
        ],
        29401: [
            7899,
            7768,
            4763,
            8323,
            11481,
            11481
        ],
        29601: [
            7970,
            7836,
            4832,
            8397,
            11575,
            11575
        ],
        29801: [
            8041,
            7909,
            4904,
            8470,
            11666,
            11666
        ],
        30001: [
            8111,
            7977,
            4972,
            8544,
            11757,
            11757
        ],
        30201: [
            8182,
            8046,
            5041,
            8618,
            11852,
            11852
        ],
        30401: [
            8253,
            8118,
            5114,
            8692,
            11943,
            11943
        ],
        30601: [
            8324,
            8187,
            5182,
            8766,
            12037,
            12037
        ],
        30801: [
            8395,
            8256,
            5251,
            8839,
            12128,
            12128
        ],
        31001: [
            8465,
            8328,
            5323,
            8913,
            12219,
            12219
        ],
        31201: [
            8536,
            8397,
            5392,
            8987,
            12314,
            12314
        ],
        31401: [
            8607,
            8467,
            5462,
            9061,
            12405,
            12405
        ],
        31601: [
            8678,
            8541,
            5536,
            9134,
            12499,
            12499
        ],
        31801: [
            8749,
            8611,
            5606,
            9208,
            12590,
            12590
        ],
        32001: [
            8819,
            8684,
            5679,
            9282,
            12681,
            12681
        ],
        32201: [
            8890,
            8754,
            5749,
            9356,
            12776,
            12776
        ],
        32401: [
            8961,
            8824,
            5819,
            9429,
            12867,
            12867
        ],
        32601: [
            9032,
            8898,
            5893,
            9503,
            12961,
            12961
        ],
        32801: [
            9103,
            8968,
            5963,
            9577,
            13052,
            13052
        ],
        33001: [
            9173,
            9038,
            6033,
            9651,
            13143,
            13143
        ],
        33201: [
            9244,
            9111,
            6106,
            9725,
            13238,
            13238
        ],
        33401: [
            9315,
            9181,
            6176,
            9798,
            13329,
            13329
        ],
        33601: [
            9386,
            9255,
            6250,
            9872,
            13423,
            13423
        ],
        33801: [
            9457,
            9325,
            6320,
            9946,
            13514,
            13514
        ],
        34001: [
            9528,
            9395,
            6390,
            10020,
            13605,
            13605
        ],
        34201: [
            9598,
            9468,
            6463,
            10094,
            13700,
            13700
        ],
        34401: [
            9669,
            9538,
            6533,
            10167,
            13791,
            13791
        ],
        34601: [
            9740,
            9608,
            6603,
            10241,
            13885,
            13885
        ],
        34801: [
            9811,
            9682,
            6677,
            10315,
            13976,
            13976
        ],
        35001: [
            9882,
            9752,
            6747,
            10389,
            14067,
            14067
        ],
        35201: [
            9952,
            9822,
            6817,
            10462,
            14162,
            14162
        ],
        35401: [
            10023,
            9895,
            6890,
            10536,
            14253,
            14253
        ],
        35601: [
            10094,
            9965,
            6960,
            10610,
            14347,
            14347
        ],
        35801: [
            10165,
            10039,
            7034,
            10684,
            14438,
            14438
        ],
        36001: [
            10236,
            10109,
            7104,
            10757,
            14529,
            14529
        ],
        36201: [
            10307,
            10179,
            7174,
            10831,
            14624,
            14624
        ],
        36401: [
            10377,
            10252,
            7247,
            10905,
            14715,
            14715
        ],
        36601: [
            10448,
            10322,
            7317,
            10979,
            14809,
            14809
        ],
        36801: [
            10519,
            10392,
            7387,
            11053,
            14900,
            14900
        ],
        37001: [
            10590,
            10466,
            7461,
            11126,
            14991,
            14991
        ],
        37201: [
            10661,
            10536,
            7531,
            11200,
            15086,
            15086
        ],
        37401: [
            10731,
            10609,
            7604,
            11274,
            15177,
            15177
        ],
        37601: [
            10802,
            10676,
            7671,
            11348,
            15264,
            15264
        ],
        37801: [
            10873,
            10746,
            7741,
            11421,
            15348,
            15348
        ],
        38001: [
            10943,
            10816,
            7811,
            11495,
            15432,
            15432
        ],
        38201: [
            11014,
            10889,
            7884,
            11568,
            15516,
            15516
        ],
        38401: [
            11086,
            10959,
            7954,
            11642,
            15600,
            15600
        ],
        38601: [
            11170,
            11043,
            8038,
            11715,
            15684,
            15684
        ],
        38801: [
            11254,
            11127,
            8122,
            11789,
            15768,
            15768
        ],
        39001: [
            11338,
            11211,
            8206,
            11862,
            15852,
            15852
        ],
        39201: [
            11422,
            11295,
            8290,
            11936,
            15936,
            15936
        ],
        39401: [
            11506,
            11379,
            8374,
            12009,
            16020,
            16020
        ],
        39601: [
            11590,
            11463,
            8458,
            12083,
            16104,
            16104
        ],
        39801: [
            11674,
            11547,
            8542,
            12157,
            16188,
            16188
        ],
        40001: [
            11758,
            11631,
            8626,
            12230,
            16272,
            16272
        ],
        40201: [
            11842,
            11715,
            8710,
            12304,
            16356,
            16356
        ],
        40401: [
            11926,
            11799,
            8794,
            12377,
            16440,
            16440
        ],
        40601: [
            12010,
            11883,
            8878,
            12451,
            16524,
            16524
        ],
        40801: [
            12094,
            11967,
            8962,
            12524,
            16608,
            16608
        ],
        41001: [
            12178,
            12051,
            9046,
            12598,
            16692,
            16692
        ],
        41201: [
            12262,
            12135,
            9130,
            12671,
            16776,
            16776
        ],
        41401: [
            12346,
            12219,
            9214,
            12745,
            16860,
            16860
        ],
        41601: [
            12430,
            12303,
            9298,
            12818,
            16944,
            16944
        ],
        41801: [
            12514,
            12387,
            9382,
            12892,
            17028,
            17028
        ],
        42001: [
            12598,
            12471,
            9466,
            12966,
            17112,
            17112
        ],
        42201: [
            12682,
            12555,
            9550,
            13039,
            17196,
            17196
        ],
        42401: [
            12766,
            12639,
            9634,
            13113,
            17280,
            17280
        ],
        42601: [
            12850,
            12723,
            9718,
            13186,
            17364,
            17364
        ],
        42801: [
            12934,
            12807,
            9802,
            13260,
            17448,
            17448
        ],
        43001: [
            13018,
            12891,
            9886,
            13333,
            17532,
            17532
        ],
        43201: [
            13102,
            12975,
            9970,
            13407,
            17616,
            17616
        ],
        43401: [
            13186,
            13059,
            10054,
            13480,
            17700,
            17700
        ],
        43601: [
            13270,
            13143,
            10138,
            13554,
            17784,
            17784
        ],
        43801: [
            13354,
            13227,
            10222,
            13627,
            17868,
            17868
        ],
        44001: [
            13438,
            13311,
            10306,
            13701,
            17952,
            17952
        ],
        44201: [
            13522,
            13395,
            10390,
            13775,
            18036,
            18036
        ],
        44401: [
            13606,
            13479,
            10474,
            13848,
            18120,
            18120
        ],
        44601: [
            13690,
            13563,
            10558,
            13922,
            18204,
            18204
        ],
        44801: [
            13774,
            13647,
            10642,
            13995,
            18288,
            18288
        ],
        45001: [
            13858,
            13731,
            10726,
            14069,
            18372,
            18372
        ],
        45201: [
            13942,
            13815,
            10810,
            14142,
            18456,
            18456
        ],
        45401: [
            14026,
            13899,
            10894,
            14216,
            18540,
            18540
        ],
        45601: [
            14110,
            13983,
            10978,
            14289,
            18624,
            18624
        ],
        45801: [
            14194,
            14067,
            11062,
            14363,
            18708,
            18708
        ],
        46001: [
            14278,
            14151,
            11146,
            14436,
            18792,
            18792
        ],
        46201: [
            14362,
            14235,
            11230,
            14510,
            18876,
            18876
        ],
        46401: [
            14446,
            14319,
            11314,
            14584,
            18960,
            18960
        ],
        46601: [
            14530,
            14403,
            11398,
            14657,
            19044,
            19044
        ],
        46801: [
            14614,
            14487,
            11482,
            14731,
            19128,
            19128
        ],
        47001: [
            14698,
            14571,
            11566,
            14804,
            19212,
            19212
        ],
        47201: [
            14782,
            14655,
            11650,
            14878,
            19296,
            19296
        ],
        47401: [
            14866,
            14739,
            11734,
            14951,
            19380,
            19380
        ],
        47601: [
            14950,
            14823,
            11818,
            15025,
            19464,
            19464
        ],
        47801: [
            15034,
            14907,
            11902,
            15098,
            19548,
            19548
        ],
        48001: [
            15118,
            14991,
            11986,
            15172,
            19632,
            19632
        ],
        48201: [
            15202,
            15075,
            12070,
            15245,
            19716,
            19716
        ],
        48401: [
            15286,
            15159,
            12154,
            15319,
            19800,
            19800
        ],
        48601: [
            15370,
            15243,
            12238,
            15393,
            19884,
            19884
        ],
        48801: [
            15454,
            15327,
            12322,
            15466,
            19968,
            19968
        ],
        49001: [
            15538,
            15411,
            12406,
            15540,
            20052,
            20052
        ],
        49201: [
            15622,
            15495,
            12490,
            15613,
            20136,
            20136
        ],
        49401: [
            15706,
            15579,
            12574,
            15687,
            20220,
            20220
        ],
        49601: [
            15790,
            15663,
            12658,
            15760,
            20304,
            20304
        ],
        49801: [
            15874,
            15747,
            12742,
            15834,
            20388,
            20388
        ],
        50001: [
            15958,
            15831,
            12831,
            15907,
            20472,
            20472
        ],
        50201: [
            16042,
            15915,
            12921,
            15981,
            20556,
            20556
        ],
        50401: [
            16126,
            15999,
            13011,
            16054,
            20640,
            20640
        ],
        50601: [
            16210,
            16083,
            13101,
            16128,
            20724,
            20724
        ],
        50801: [
            16294,
            16167,
            13191,
            16202,
            20808,
            20808
        ],
        51001: [
            16378,
            16251,
            13281,
            16275,
            20892,
            20892
        ],
        51201: [
            16487,
            16367,
            13402,
            16374,
            21001,
            21001
        ],
        51401: [
            16611,
            16503,
            13545,
            16487,
            21125,
            21125
        ],
        51601: [
            16735,
            16640,
            13687,
            16601,
            21249,
            21249
        ],
        51801: [
            16859,
            16773,
            13826,
            16714,
            21373,
            21373
        ],
        52001: [
            16983,
            16909,
            13969,
            16828,
            21497,
            21497
        ],
        52201: [
            17107,
            17046,
            14111,
            16941,
            21621,
            21621
        ],
        52401: [
            17231,
            17182,
            14254,
            17055,
            21745,
            21745
        ],
        52601: [
            17355,
            17319,
            14396,
            17168,
            21869,
            21869
        ],
        52801: [
            17479,
            17455,
            14539,
            17282,
            21993,
            21993
        ],
        53001: [
            17603,
            17592,
            14681,
            17396,
            22117,
            22117
        ],
        53201: [
            17727,
            17728,
            14824,
            17509,
            22241,
            22241
        ],
        53401: [
            17851,
            17861,
            14963,
            17623,
            22365,
            22365
        ],
        53601: [
            17975,
            17998,
            15105,
            17736,
            22489,
            22489
        ],
        53801: [
            18099,
            18134,
            15248,
            17850,
            22613,
            22613
        ],
        54001: [
            18223,
            18271,
            15390,
            17963,
            22737,
            22737
        ],
        54201: [
            18347,
            18407,
            15533,
            18077,
            22861,
            22861
        ],
        54401: [
            18471,
            18544,
            15675,
            18190,
            22985,
            22985
        ],
        54601: [
            18595,
            18680,
            15818,
            18304,
            23109,
            23109
        ],
        54801: [
            18719,
            18817,
            15960,
            18417,
            23233,
            23233
        ],
        55001: [
            18843,
            18953,
            16103,
            18531,
            23357,
            23357
        ],
        55201: [
            18967,
            19086,
            16242,
            18645,
            23481,
            23481
        ],
        55401: [
            19091,
            19223,
            16384,
            18758,
            23605,
            23605
        ],
        55601: [
            19215,
            19359,
            16527,
            18872,
            23729,
            23729
        ],
        55801: [
            19339,
            19496,
            16669,
            18985,
            23853,
            23853
        ],
        56001: [
            19463,
            19632,
            16812,
            19099,
            23977,
            23977
        ],
        56201: [
            19587,
            19769,
            16954,
            19212,
            24101,
            24101
        ],
        56401: [
            19711,
            19905,
            17097,
            19326,
            24225,
            24225
        ],
        56601: [
            19835,
            20042,
            17239,
            19439,
            24349,
            24349
        ],
        56801: [
            19959,
            20175,
            17378,
            19553,
            24473,
            24473
        ],
        57001: [
            20083,
            20311,
            17521,
            19666,
            24597,
            24597
        ],
        57201: [
            20207,
            20448,
            17663,
            19780,
            24721,
            24721
        ],
        57401: [
            20331,
            20584,
            17806,
            19894,
            24845,
            24845
        ],
        57601: [
            20455,
            20721,
            17948,
            20007,
            24969,
            24969
        ],
        57801: [
            20579,
            20857,
            18091,
            20121,
            25093,
            25093
        ],
        58001: [
            20703,
            20980,
            18220,
            20234,
            25217,
            25217
        ],
        58201: [
            20827,
            21104,
            18350,
            20348,
            25341,
            25341
        ],
        58401: [
            20951,
            21228,
            18480,
            20461,
            25465,
            25465
        ],
        58601: [
            21075,
            21352,
            18610,
            20575,
            25589,
            25589
        ],
        58801: [
            21199,
            21476,
            18740,
            20688,
            25713,
            25713
        ],
        59001: [
            21323,
            21600,
            18870,
            20802,
            25837,
            25837
        ],
        59201: [
            21447,
            21724,
            19000,
            20915,
            25961,
            25961
        ],
        59401: [
            21571,
            21848,
            19130,
            21029,
            26085,
            26085
        ],
        59601: [
            21695,
            21972,
            19260,
            21142,
            26209,
            26209
        ],
        59801: [
            21819,
            22096,
            19390,
            21256,
            26333,
            26333
        ],
        60001: [
            21943,
            22220,
            19520,
            21370,
            26457,
            26457
        ],
        60201: [
            22067,
            22344,
            19650,
            21483,
            26581,
            26581
        ],
        60401: [
            22191,
            22468,
            19780,
            21597,
            26705,
            26705
        ],
        60601: [
            22315,
            22592,
            19910,
            21710,
            26829,
            26829
        ],
        60801: [
            22439,
            22716,
            20040,
            21824,
            26953,
            26953
        ],
        61001: [
            22563,
            22840,
            20170,
            21937,
            27077,
            27077
        ],
        61201: [
            22687,
            22964,
            20300,
            22051,
            27201,
            27201
        ],
        61401: [
            22811,
            23088,
            20430,
            22164,
            27325,
            27325
        ],
        61601: [
            22935,
            23212,
            20560,
            22278,
            27449,
            27449
        ],
        61801: [
            23059,
            23336,
            20690,
            22391,
            27573,
            27573
        ],
        62001: [
            23183,
            23460,
            20820,
            22505,
            27697,
            27697
        ],
        62201: [
            23307,
            23584,
            20950,
            22619,
            27821,
            27821
        ],
        62401: [
            23431,
            23708,
            21080,
            22732,
            27945,
            27945
        ],
        62601: [
            23555,
            23832,
            21210,
            22846,
            28069,
            28069
        ],
        62801: [
            23679,
            23956,
            21340,
            22959,
            28193,
            28193
        ],
        63001: [
            23803,
            24080,
            21470,
            23073,
            28317,
            28317
        ],
        63201: [
            23927,
            24204,
            21600,
            23186,
            28441,
            28441
        ],
        63401: [
            24051,
            24328,
            21730,
            23300,
            28565,
            28565
        ],
        63601: [
            24175,
            24452,
            21860,
            23413,
            28689,
            28689
        ],
        63801: [
            24299,
            24576,
            21990,
            23527,
            28813,
            28813
        ],
        64001: [
            24423,
            24700,
            22120,
            23640,
            28937,
            28937
        ],
        64201: [
            24547,
            24824,
            22250,
            23754,
            29061,
            29061
        ],
        64401: [
            24671,
            24948,
            22380,
            23868,
            29185,
            29185
        ],
        64601: [
            24799,
            25083,
            22521,
            23981,
            29309,
            29309
        ],
        64801: [
            24929,
            25212,
            22656,
            24095,
            29433,
            29433
        ],
        65001: [
            25059,
            25346,
            22796,
            24208,
            29557,
            29557
        ],
        65201: [
            25189,
            25475,
            22931,
            24322,
            29681,
            29681
        ],
        65401: [
            25319,
            25605,
            23066,
            24435,
            29805,
            29805
        ],
        65601: [
            25449,
            25739,
            23207,
            24549,
            29929,
            29929
        ],
        65801: [
            25579,
            25868,
            23342,
            24662,
            30053,
            30053
        ],
        66001: [
            25709,
            26002,
            23482,
            24776,
            30177,
            30177
        ],
        66201: [
            25839,
            26132,
            23617,
            24890,
            30301,
            30301
        ],
        66401: [
            25969,
            26261,
            23753,
            25003,
            30425,
            30425
        ],
        66601: [
            26099,
            26395,
            23893,
            25117,
            30549,
            30549
        ],
        66801: [
            26229,
            26524,
            24028,
            25230,
            30673,
            30673
        ],
        67001: [
            26359,
            26653,
            24163,
            25344,
            30797,
            30797
        ],
        67201: [
            26489,
            26788,
            24304,
            25457,
            30921,
            30921
        ],
        67401: [
            26619,
            26917,
            24439,
            25571,
            31045,
            31045
        ],
        67601: [
            26749,
            27051,
            24579,
            25684,
            31169,
            31169
        ],
        67801: [
            26879,
            27180,
            24714,
            25798,
            31293,
            31293
        ],
        68001: [
            27009,
            27310,
            24849,
            25911,
            31417,
            31417
        ],
        68201: [
            27139,
            27444,
            24990,
            26025,
            31541,
            31541
        ],
        68401: [
            27269,
            27573,
            25125,
            26138,
            31665,
            31665
        ],
        68601: [
            27399,
            27702,
            25260,
            26252,
            31789,
            31789
        ],
        68801: [
            27529,
            27837,
            25400,
            26366,
            31913,
            31913
        ],
        69001: [
            27659,
            27966,
            25536,
            26479,
            32037,
            32037
        ],
        69201: [
            27789,
            28100,
            25676,
            26593,
            32161,
            32161
        ],
        69401: [
            27919,
            28229,
            25811,
            26706,
            32285,
            32285
        ],
        69601: [
            28049,
            28358,
            25946,
            26820,
            32409,
            32409
        ],
        69801: [
            28179,
            28493,
            26087,
            26933,
            32533,
            32533
        ],
        70001: [
            28309,
            28622,
            26222,
            27047,
            32657,
            32657
        ],
        70201: [
            28439,
            28751,
            26357,
            27160,
            32781,
            32781
        ],
        70401: [
            28569,
            28885,
            26497,
            27274,
            32905,
            32905
        ],
        70601: [
            28699,
            29015,
            26632,
            27387,
            33029,
            33029
        ],
        70801: [
            28829,
            29149,
            26773,
            27501,
            33153,
            33153
        ],
        71001: [
            28959,
            29278,
            26908,
            27615,
            33277,
            33277
        ],
        71201: [
            29089,
            29407,
            27043,
            27728,
            33401,
            33401
        ],
        71401: [
            29219,
            29542,
            27183,
            27842,
            33525,
            33525
        ],
        71601: [
            29349,
            29671,
            27319,
            27955,
            33649,
            33649
        ],
        71801: [
            29479,
            29805,
            27459,
            28069,
            33773,
            33773
        ],
        72001: [
            29609,
            29934,
            27594,
            28182,
            33897,
            33897
        ],
        72201: [
            29739,
            30063,
            27729,
            28296,
            34021,
            34021
        ],
        72401: [
            29869,
            30198,
            27870,
            28409,
            34145,
            34145
        ],
        72601: [
            29999,
            30327,
            28005,
            28523,
            34269,
            34269
        ],
        72801: [
            30129,
            30456,
            28140,
            28636,
            34393,
            34393
        ],
        73001: [
            30259,
            30590,
            28280,
            28750,
            34517,
            34517
        ],
        73201: [
            30389,
            30720,
            28415,
            28864,
            34641,
            34641
        ],
        73401: [
            30519,
            30854,
            28556,
            28977,
            34765,
            34765
        ],
        73601: [
            30649,
            30983,
            28691,
            29091,
            34889,
            34889
        ],
        73801: [
            30779,
            31112,
            28826,
            29204,
            35013,
            35013
        ],
        74001: [
            30909,
            31247,
            28966,
            29318,
            35137,
            35137
        ],
        74201: [
            31039,
            31376,
            29102,
            29431,
            35261,
            35261
        ],
        74401: [
            31169,
            31505,
            29237,
            29545,
            35385,
            35385
        ],
        74601: [
            31299,
            31639,
            29377,
            29658,
            35509,
            35509
        ],
        74801: [
            31429,
            31768,
            29512,
            29772,
            35633,
            35633
        ],
        75001: [
            31559,
            31903,
            29653,
            29885,
            35757,
            35757
        ],
        75201: [
            31689,
            32032,
            29788,
            29999,
            35881,
            35881
        ],
        75401: [
            31819,
            32161,
            29923,
            30113,
            36005,
            36005
        ],
        75601: [
            31949,
            32295,
            30063,
            30226,
            36129,
            36129
        ],
        75801: [
            32079,
            32425,
            30198,
            30340,
            36253,
            36253
        ],
        76001: [
            32209,
            32554,
            30334,
            30453,
            36377,
            36377
        ],
        76201: [
            32339,
            32688,
            30474,
            30567,
            36501,
            36501
        ],
        76401: [
            32469,
            32817,
            30609,
            30680,
            36625,
            36625
        ],
        76601: [
            32599,
            32952,
            30749,
            30794,
            36749,
            36749
        ],
        76801: [
            32729,
            33081,
            30885,
            30907,
            36873,
            36873
        ],
        77001: [
            32859,
            33210,
            31020,
            31021,
            36997,
            36997
        ],
        77201: [
            32989,
            33344,
            31160,
            31134,
            37121,
            37121
        ],
        77401: [
            33119,
            33473,
            31295,
            31248,
            37245,
            37245
        ],
        77601: [
            33249,
            33603,
            31430,
            31362,
            37369,
            37369
        ],
        77801: [
            33379,
            33737,
            31571,
            31475,
            37493,
            37493
        ],
        78001: [
            33509,
            33866,
            31706,
            31589,
            37617,
            37617
        ],
        78201: [
            33639,
            34000,
            31846,
            31702,
            37741,
            37741
        ],
        78401: [
            33769,
            34130,
            31981,
            31816,
            37865,
            37865
        ],
        78601: [
            33899,
            34259,
            32117,
            31929,
            37989,
            37989
        ],
        78801: [
            34029,
            34393,
            32257,
            32043,
            38113,
            38113
        ],
        79001: [
            34159,
            34522,
            32392,
            32156,
            38237,
            38237
        ],
        79201: [
            34289,
            34651,
            32527,
            32270,
            38361,
            38361
        ],
        79401: [
            34419,
            34786,
            32668,
            32383,
            38485,
            38485
        ],
        79601: [
            34549,
            34915,
            32803,
            32497,
            38609,
            38609
        ],
        79801: [
            34679,
            35049,
            32943,
            32611,
            38733,
            38733
        ],
        80001: [
            43,
            44,
            41,
            41,
            48,
            48,
            '%'
        ],
        80401: [
            44,
            44,
            41,
            41,
            49,
            49,
            '%'
        ],
        80801: [
            44,
            44,
            42,
            41,
            49,
            49,
            '%'
        ],
        82601: [
            44,
            45,
            42,
            41,
            49,
            49,
            '%'
        ],
        83801: [
            44,
            45,
            42,
            42,
            49,
            49,
            '%'
        ],
        84001: [
            44,
            45,
            43,
            42,
            49,
            49,
            '%'
        ],
        84401: [
            45,
            45,
            43,
            42,
            49,
            49,
            '%'
        ],
        86601: [
            45,
            46,
            43,
            42,
            49,
            49,
            '%'
        ],
        86801: [
            45,
            46,
            43,
            42,
            50,
            50,
            '%'
        ],
        87401: [
            45,
            46,
            44,
            42,
            50,
            50,
            '%'
        ],
        88801: [
            46,
            46,
            44,
            42,
            50,
            50,
            '%'
        ],
        89601: [
            46,
            46,
            44,
            43,
            50,
            50,
            '%'
        ],
        91001: [
            46,
            46,
            45,
            43,
            50,
            50,
            '%'
        ],
        91201: [
            46,
            47,
            45,
            43,
            50,
            50,
            '%'
        ],
        93601: [
            47,
            47,
            45,
            43,
            50,
            50,
            '%'
        ],
        94401: [
            47,
            47,
            45,
            43,
            51,
            51,
            '%'
        ],
        94801: [
            47,
            47,
            46,
            43,
            51,
            51,
            '%'
        ],
        96201: [
            47,
            48,
            46,
            43,
            51,
            51,
            '%'
        ],
        96401: [
            47,
            48,
            46,
            44,
            51,
            51,
            '%'
        ],
        98801: [
            48,
            48,
            46,
            44,
            51,
            51,
            '%'
        ],
        99201: [
            48,
            48,
            47,
            44,
            51,
            51,
            '%'
        ],
        102001: [
            48,
            49,
            47,
            44,
            51,
            51,
            '%'
        ],
        103401: [
            48,
            49,
            47,
            44,
            52,
            52,
            '%'
        ],
        104001: [
            48,
            49,
            48,
            44,
            52,
            52,
            '%'
        ],
        104201: [
            48,
            49,
            48,
            45,
            52,
            52,
            '%'
        ],
        104801: [
            49,
            49,
            48,
            45,
            52,
            52,
            '%'
        ],
        108201: [
            49,
            50,
            48,
            45,
            52,
            52,
            '%'
        ],
        109001: [
            49,
            50,
            49,
            45,
            52,
            52,
            '%'
        ],
        111601: [
            50,
            50,
            49,
            45,
            52,
            52,
            '%'
        ],
        113601: [
            50,
            50,
            49,
            46,
            52,
            52,
            '%'
        ],
        114201: [
            50,
            50,
            49,
            46,
            53,
            53,
            '%'
        ],
        114801: [
            50,
            50,
            50,
            46,
            53,
            53,
            '%'
        ],
        115401: [
            50,
            51,
            50,
            46,
            53,
            53,
            '%'
        ],
        119401: [
            51,
            51,
            50,
            46,
            53,
            53,
            '%'
        ],
        121201: [
            51,
            51,
            51,
            46,
            53,
            53,
            '%'
        ],
        123601: [
            51,
            52,
            51,
            46,
            53,
            53,
            '%'
        ],
        124601: [
            51,
            52,
            51,
            47,
            53,
            53,
            '%'
        ],
        127801: [
            51,
            52,
            51,
            47,
            54,
            54,
            '%'
        ],
        128201: [
            52,
            52,
            51,
            47,
            54,
            54,
            '%'
        ],
        128401: [
            52,
            52,
            52,
            47,
            54,
            54,
            '%'
        ],
        133201: [
            52,
            53,
            52,
            47,
            54,
            54,
            '%'
        ],
        136401: [
            52,
            53,
            53,
            47,
            54,
            54,
            '%'
        ],
        138001: [
            52,
            53,
            53,
            48,
            54,
            54,
            '%'
        ],
        138401: [
            53,
            53,
            53,
            48,
            54,
            54,
            '%'
        ],
        144201: [
            53,
            54,
            53,
            48,
            54,
            54,
            '%'
        ],
        144801: [
            53,
            54,
            53,
            48,
            55,
            55,
            '%'
        ],
        145401: [
            53,
            54,
            54,
            48,
            55,
            55,
            '%'
        ],
        150601: [
            54,
            54,
            54,
            48,
            55,
            55,
            '%'
        ],
        154801: [
            54,
            54,
            54,
            49,
            55,
            55,
            '%'
        ],
        157201: [
            54,
            55,
            55,
            49,
            55,
            55,
            '%'
        ],
        164801: [
            55,
            55,
            55,
            49,
            55,
            55,
            '%'
        ],
        167001: [
            55,
            55,
            55,
            49,
            56,
            56,
            '%'
        ],
        172801: [
            55,
            56,
            56,
            49,
            56,
            56,
            '%'
        ],
        176001: [
            55,
            56,
            56,
            50,
            56,
            56,
            '%'
        ],
        182201: [
            56,
            56,
            56,
            50,
            56,
            56,
            '%'
        ],
        197401: [
            56,
            57,
            57,
            50,
            57,
            57,
            '%'
        ],
        203601: [
            57,
            57,
            57,
            50,
            57,
            57,
            '%'
        ],
        204001: [
            57,
            57,
            57,
            51,
            57,
            57,
            '%'
        ],
        241401: [
            58,
            58,
            58,
            51,
            58,
            58,
            '%'
        ],
        242801: [
            58,
            58,
            58,
            52,
            58,
            58,
            '%'
        ],
        299601: [
            58,
            58,
            58,
            53,
            58,
            58,
            '%'
        ],
        310401: [
            59,
            59,
            59,
            53,
            59,
            59,
            '%'
        ],
        391201: [
            59,
            59,
            59,
            54,
            59,
            59,
            '%'
        ],
        434601: [
            60,
            60,
            60,
            54,
            60,
            60,
            '%'
        ],
        563401: [
            60,
            60,
            60,
            55,
            60,
            60,
            '%'
        ],
        724401: [
            61,
            61,
            61,
            55,
            61,
            61,
            '%'
        ],
        1006401: [
            61,
            61,
            61,
            56,
            61,
            61,
            '%'
        ]
    }
};
